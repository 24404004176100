import { dropdownCodes } from '../../../dropdownCodes';

const {
  intent1,
  intent2,
  intent3,
  diag49,
  diag68,
  diag74,
  work1,
  work3,
} = dropdownCodes;

const VICTIM_ID_DEFAULT = {
  victim: '',
  guardian: '',
  address: '',
  city: '',
  zip: '',
  homePhone: '',
  workPhone: '',
  victimIsValid: false,
  addressIsValid: false,
  cityIsValid: false,
  zipIsValid: false,
  homePhoneIsValid: false,
  workPhoneIsValid: false,
  submitDisabled: true,
};

const dateToMonths = (dateIn) => {
  let months = 0;
  if (dateIn > 200) {
    months = dateIn - 200;
  } else {
    months = dateIn * 12;
  }
  return months;
};

const productInRange = (product, low, high) => {
  if (!product) {
    return false;
  }
  product = parseInt(product);
  if (low > product || high < product) {
    return false;
  }
  return true;
};

const victimIdInScope = (mainCaseData) => {
  const {
    diagnosis1,
    diagnosis2,
    intent,
    product1,
    product2,
    product3,
    treatmentDate,
    workRelated,
  } = mainCaseData;
  let pscope1;
  let pscope2;
  let pscope3;
  let lscope;
  const age = parseInt(mainCaseData.age);
  if (
    intent === intent3 ||
    ((intent === intent1 || intent === intent2) &&
      ((age >= 13 && age <= 115) || age === 0))
  ) {
    lscope = 1;
    pscope1 = 1;
    pscope2 = 1;
    pscope3 = 1;
    return { lscope, pscope1, pscope2, pscope3 };
  }
  if (diagnosis1 === diag74 || diagnosis2 === diag74) {
    if (product1.startsWith('1512')) pscope1 = 1;
    if (product2.startsWith('1512')) pscope2 = 1;
    if (product3.startsWith('1512')) pscope3 = 1;
    return { lscope, pscope1, pscope2, pscope3 };
  }
  if (workRelated === work1 || workRelated === work3) {
    lscope = 1;
    pscope1 = 1;
    pscope2 = 1;
    pscope3 = 1;
    return { lscope, pscope1, pscope2, pscope3 };
  }
  if (
    (product1.startsWith('1935') || product1.startsWith('3224') || product1.startsWith('3253')) &&
    (product2.startsWith('1935') || product2.startsWith('3224') || product2.startsWith('3253')) &&
    (product3.startsWith('1935') || product3.startsWith('3224') || product3.startsWith('3253'))
  ) {
    lscope = 1;
    pscope1 = 1;
    pscope2 = 1;
    pscope3 = 1;
    return { lscope, pscope1, pscope2, pscope3 };
  } else {
    if (product1.startsWith('1935') || product1.startsWith('3224') || product1.startsWith('3253'))
      pscope1 = 1;

    if (product2.startsWith('1935') || product2.startsWith('3224') || product2.startsWith('3253'))
      pscope2 = 1;

    if (product3.startsWith('1935') || product3.startsWith('3224') || product3.startsWith('3253'))
      pscope3 = 1;
  }
  if (
    (age >= 5 && age <= 115) ||
    age === 0 ||
    (diagnosis1 !== diag49 &&
      diagnosis1 !== diag68 &&
      diagnosis2 !== diag49 &&
      diagnosis2 !== diag68)
  ) {
    if (
      product1.startsWith('1913') ||
      product1.startsWith('1914') ||
      product1.startsWith('1915') ||
      product1.startsWith('1916') ||
      product1.startsWith('1923') ||
      product1.startsWith('1927') ||
      product1.startsWith('1928') ||
      product1.startsWith('1929') ||
      product1.startsWith('1930') ||
      product1.startsWith('1931') ||
      product1.startsWith('1932') ||
      product1.startsWith('1940') ||
      product1.startsWith('2640')
    )
      pscope1 = 1;
    if (
      product2.startsWith('1913') ||
      product2.startsWith('1914') ||
      product2.startsWith('1915') ||
      product2.startsWith('1916') ||
      product2.startsWith('1923') ||
      product2.startsWith('1927') ||
      product2.startsWith('1928') ||
      product2.startsWith('1929') ||
      product2.startsWith('1930') ||
      product2.startsWith('1931') ||
      product2.startsWith('1932') ||
      product2.startsWith('1940') ||
      product2.startsWith('2640')
    )
      pscope2 = 1;
    if (
      product3.startsWith('1913') ||
      product3.startsWith('1914') ||
      product3.startsWith('1915') ||
      product3.startsWith('1916') ||
      product3.startsWith('1923') ||
      product3.startsWith('1927') ||
      product3.startsWith('1928') ||
      product3.startsWith('1929') ||
      product3.startsWith('1930') ||
      product3.startsWith('1931') ||
      product3.startsWith('1932') ||
      product3.startsWith('1940') ||
      product3.startsWith('2640')
    )
      pscope1 = 3;
  }
  if (
    (treatmentDate >= '2009/01/01' && product1.startsWith('1909')) ||
    product2.startsWith('1909') ||
    product3.startsWith('1909')
  ) {
    if (product1.startsWith('1909')) pscope1 = 1;
    if (product2.startsWith('1909')) pscope2 = 1;
    if (product3.startsWith('1909')) pscope3 = 1;
  }
  if (
    pscope1 === 1 &&
    (pscope2 === 1 || product2) &&
    (pscope3 === 1 || product3)
  )
    return { lscope, pscope1, pscope2, pscope3 };
  if (
    ((age >= 201 && age <= 223) || (age >= 2 && age <= 4)) &&
    (diagnosis1 === diag49 ||
      diagnosis1 === diag68 ||
      diagnosis2 === diag49 ||
      diagnosis2 === diag68)
  ) {
    if (product1.startsWith('1903') || product1.startsWith('1913')) pscope1 = 0;
    if (product2.startsWith('1903') || product2.startsWith('1913')) pscope2 = 0;
    if (product3.startsWith('1903') || product3.startsWith('1913')) pscope3 = 0;
  }
  return { lscope, pscope1, pscope2, pscope3 };
};

// checks one victim id cat record against caseData and returns true or false
const victimIdCategoryCheck = (victimIdCategory, mainCaseData) => {
  // if any one fails the entire test returns false
  // test each product and then test combined results
  const isProductOneInRange = productInRange(
    mainCaseData.product1.split(' ')[0],
    victimIdCategory.fromProduct,
    victimIdCategory.toProduct
  );
  const isProductTwoInRange = productInRange(
    mainCaseData.product2.split(' ')[0],
    victimIdCategory.fromProduct,
    victimIdCategory.toProduct
  );
  const isProductThreeInRange = productInRange(
    mainCaseData.product3.split(' ')[0],
    victimIdCategory.fromProduct,
    victimIdCategory.toProduct
  );

  if (
    isProductOneInRange === false &&
    isProductTwoInRange === false &&
    isProductThreeInRange === false
  ) {
    return false;
  }

  // treatment date
  if (
    victimIdCategory.fromTreatmentDate &&
    new Date(mainCaseData.treatmentDate) <
      new Date(victimIdCategory.fromTreatmentDate)
  ) {
    return false;
  }

  if (
    victimIdCategory.toTreatmentDate &&
    new Date(mainCaseData.treatmentDate) >
      new Date(victimIdCategory.toTreatmentDate)
  ) {
    return false;
  }

  // age
  if (
    victimIdCategory.toTreatmentDate &&
    dateToMonths(victimIdCategory.fromAge) > dateToMonths(mainCaseData.age)
  ) {
    return false;
  }
  if (
    victimIdCategory.fromAge &&
    dateToMonths(victimIdCategory.toAge) < dateToMonths(mainCaseData.age)
  ) {
    return false;
  }
  // diagnosis
  if (
    victimIdCategory.diagnosis &&
    victimIdCategory.diagnosis !==
      parseInt(mainCaseData.diagnosis1.split(' ')[0]) &&
    victimIdCategory.diagnosis !==
      parseInt(mainCaseData.diagnosis2.split(' ')[0])
  ) {
    return false;
  }
  // body part
  if (
    victimIdCategory.bodyPart &&
    victimIdCategory.bodyPart !==
      parseInt(mainCaseData.bodyPart1.split(' ')[0]) &&
    victimIdCategory.bodyPart !== parseInt(mainCaseData.bodyPart2.split(' ')[0])
  ) {
    return false;
  }

  //location
  if (
    victimIdCategory.location &&
    victimIdCategory.location !== parseInt(mainCaseData.location.split(' ')[0])
  ) {
    return false;
  }
  // // fire
  if (
    victimIdCategory.fire &&
    victimIdCategory.fire !== parseInt(mainCaseData.fire.split(' ')[0])
  ) {
    return false;
  }
  //disposition
  if (
    victimIdCategory.disposition &&
    victimIdCategory.disposition !==
      parseInt(mainCaseData.disposition.split(' ')[0])
  ) {
    return false;
  }
  // Check the case's random case rate value and compare it to the category's specified case rate.
  // if the random val is outside the range of 0 to the current category rate inclusive, return false.
  // Data expectations:
  //   0.00 <= caseRandomValue < 1.00
  //   0.00 <= expectedRate <= 1.00  (0 means never and 1 means always)
  let expectedRate = victimIdCategory.rate;
  let caseRandomValue = mainCaseData.caseRate;
  if (caseRandomValue > expectedRate) {
    // NOTE: Per CPSC's request, the range check includes the upper bound. This will result in
    // the percentage of cases selected to have a victim id being about 1% higher than expected.
    // Effectively, for a case rate of 0.50 the result is that cases with a random number from
    // 0.00 to 0.50 get a victim, and cases with 0.51 to 0.99 do not. This results in a 51% victim
    // selection rate, but CPSC has explicitly requested that the range be set this way.
    return false;
  }
  // if nothing above fails, it's true
  return true;
};

const victimIdCheck = (victimIdCategories, mainCaseData) => {
  if (mainCaseData.hasVictimIdBeenEntered === true) {
    return false;
  }

  // if the case has the hasVictimIdBeenEntered flag set to false and the alwaysRequireVictimId flag set to true,
  // we want to collect the victim info even if the case is not eligible for it. This is to handle the case in which
  // an analyst has manually marked the case as needing a victim id external to WebNEISS.
  if (mainCaseData.hasVictimIdBeenEntered === false && mainCaseData.alwaysRequireVictimId === true) {
    return true;
  }

  const { product2, product3 } = mainCaseData;
  const { pscope1, pscope2, pscope3 } = victimIdInScope(mainCaseData);
  // if out of scope, stop
  if (
    pscope1 === 1 &&
    (pscope2 === 1 || product2) &&
    (pscope3 === 1 || product3)
  )
    return false;

  return isVictimIdRequired(victimIdCategories, mainCaseData);
};

const isVictimIdRequired = (victimIdCategories, mainCaseData) => {
  if (mainCaseData.alwaysRequireVictimId === true) {
    // the case is manually marked to require a victim id, likely by a CPSC auditor outside WebNEISS.
    return true;
  }

  // iterate thru the vic categories. If any one is true then return true
  if (victimIdCategories) {
    for (let i = 0; i < victimIdCategories.length; i++) {
      if (victimIdCategoryCheck(victimIdCategories[i], mainCaseData)) {
        return true;
      }
    }
  }
  return false;
};

export { VICTIM_ID_DEFAULT, victimIdCheck, isVictimIdRequired };
