import { request } from '../../api';
import { modalPresets } from '../../Components/CustomComponents/Modals';
import { isValidDate, dateFromArgs } from '../../utils';

const headerData = [
  {
    value: 'treatmentDate',
    name: 'Tr Date',
  },
  {
    value: 'collectionDate',
    name: 'Cl Date',
  },
  {
    value: 'caseNumber',
    name: 'Case #',
  },
  {
    value: 'age',
    name: 'Age',
  },
  {
    value: 'sex',
    name: 'Sex',
  },
  {
    value: 'product1',
    name: 'Product #1',
  },
  {
    value: 'product2',
    name: 'Product #2',
  },
  {
    value: 'product3',
    name: 'Product #3',
  },
  {
    value: 'status',
    name: 'Status',
  },
];

const DELETE_FORM = {
  password: '',
  reason: '',
  passwordIsValid: null,
  deleteRequiresPassword: false,
  deleteRequresReason: false,
};

const addSearch = async ({
  args,
  searchName,
  searchParams,
  setModal,
  setUserSearches,
}) => {
  const {
    treatmentDateStart,
    treatmentDateEnd,
    collectionDateStart,
    collectionDateEnd,
    caseNumber,
    product,
    status,
  } = searchParams;
  const newSearchItem = {
    name: searchName,
    treatmentDateStart: treatmentDateStart,
    treatmentDateEnd: treatmentDateEnd,
    collectionDateStart: collectionDateStart,
    collectionDateEnd: collectionDateEnd,
    caseNumber: caseNumber ? parseInt(caseNumber) : '',
    product: product,
    status: status,
  };
  try {
    const data = await request({
      ...args,
      def: 'addSearch',
      method: 'POST',
      body: newSearchItem,
    });
    setUserSearches((prevState) => [...prevState, data]);
    setModal(modalPresets('Your new search has been saved.').success);
  } catch (err) {
    console.log('ERROR:', err.message);
  }
};

const applyCardPreset = ({
  args,
  lastUpdateableDate,
  onSearch,
  preset,
  search,
  setCases,
  setModal,
  setPages,
  setSearchParams,
}) => {
  let startDate = '2000-01-01';
  let collectionDateStart = preset === 'submitted' ? calculateFirstOfMonth() : '';
  let endDate = calculateCasePeriodEnd();
  if (
    preset === 'needs attention' ||
    preset === 'reset' ||
    preset === 'ready to submit'
  ) {
    startDate = '';
    endDate = '';
  }
  if(preset === 'cases collected today') {
    const today = new Date()
    const dateStr = new Date(today.getTime() - (today.getTimezoneOffset() * 60000 ))
    .toISOString()
    .split("T")[0];
    startDate= '';
    collectionDateStart = dateStr;
    endDate = dateStr;
    preset = ''
  }
  if (preset === 'submitted') startDate = lastUpdateableDate;
  const params = {
    caseNumber: '',
    collectionDateStart: collectionDateStart,
    collectionDateEnd: endDate,
    orderBy: 'treatmentDate',
    orderDirection: 'desc',
    pageNumber: 1,
    product: '',
    status: preset === 'reset' ? '' : preset,
    treatmentDateStart: preset === 'submitted' ? calculateCasePeriodStart() : startDate,
    treatmentDateEnd: '',
  };
  setSearchParams(params);
  if (search) onSearch({ args, params, setCases, setModal, setPages });
};

const calculateCasePeriodEnd = () => {
  const currentDate = new Date();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();
  const year = currentDate.getFullYear();
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  let periodEnd = '';
  periodEnd = year + '-' + month + '-' + day;
  return periodEnd;
};

const calculateFirstOfMonth = () => {
  const currentDate = new Date();
  let month = currentDate.getMonth() + 1; // getMonth represents the month as 0-11
  let day = '01';
  const year = currentDate.getFullYear();
  if (month < 10) {
    month = '0' + month;
  }
  let periodEnd = '';
  periodEnd = year + '-' + month + '-' + day;
  return periodEnd;
};

const calculateCasePeriodStart = () => {
  const currentDate = new Date();
  let month = currentDate.getMonth();
  let day = '01';
  let year = currentDate.getFullYear();
  if (month < 1) {
    month = '12';
    year = year - 1;
  }
  if (month > 0 && month < 10) {
    month = '0' + month;
  }
  let periodStart = '';
  periodStart = year + '-' + month + '-' + day;
  return periodStart;
};

const calculateOOW = (treatmentDate) => {
  if(treatmentDate && isValidDate(treatmentDate)){
    const startWindow = new Date();
    const endWindow = new Date();
    startWindow.setHours(0, 0, 0, 0);
    endWindow.setHours(0, 0, 0, 0);
    startWindow.setDate(0);
    startWindow.setDate(1);
    const colDate = dateFromArgs(treatmentDate);
    if (colDate < startWindow || colDate > endWindow) {
      return true;
    } else return false;
  }
};

const removeSelectedCases = (caseIds, cases, setCases) => {
  let tempArray = [];
  for (let i = 0; i < cases.length; i++) {
    if (caseIds.indexOf(cases[i].id) === -1) {
      tempArray.push(cases[i]);
    }
  }
  setCases([...tempArray]);
};

const SEARCH_PARAMS = {
  caseNumber: '',
  collectionDateStart: calculateCasePeriodStart(),
  collectionDateEnd: calculateCasePeriodEnd(),
  orderBy: 'treatmentDate',
  orderDirection: 'desc',
  pageNumber: 1,
  product: '',
  status: '',
  treatmentDateStart: '',
  treatmentDateEnd: '',
};

const deleteCase = async ({
  args,
  cases,
  reason,
  password,
  selectedCase,
  setModal,
  setDeleteForm,
  setCases,
  setSelectedCases,
  setUserData,
}) => {
  try {
    await request({
      ...args,
      body: { reason, password },
      caseId: [selectedCase.id],
      def: 'deleteCase',
      method: 'DELETE',
    });
    const summary = await request({
      ...args,
      def: 'casesSummary',
      method: 'GET',
    });
    setUserData((prevState) => ({
      ...prevState,
      hospital: { ...prevState.hospital, ...summary },
    }));
    if (cases) {
      removeSelectedCases(selectedCase.id, cases, setCases);
      setSelectedCases([]);
    }
    setDeleteForm({
      password: '',
      reason: '',
      passwordIsValid: null,
      deleteRequiresPassword: false,
    });
    setModal(modalPresets('Case has been successfuly deleted.').success);
  } catch (err) {
    setDeleteForm((prevState) => ({
      ...prevState,
      passwordIsValid: false,
    }));
    console.log('ERROR:', err.message);
  }
};

const removeSearch = (searchId, userSearches, setUserSearches) => {
  const whichUserSearch = userSearches.findIndex((x) => x.id === searchId);
  let tempArray = userSearches;
  tempArray.splice(whichUserSearch, 1);
  setUserSearches([...tempArray]);
};

const deleteSearch = async ({
  args,
  searchId,
  setModal,
  setUserSearches,
  userSearches,
}) => {
  try {
    await request({
      ...args,
      def: 'deleteSearch',
      method: 'DELETE',
      queryString: searchId,
    });
    setModal(modalPresets('Your search has been deleted.').success);
    removeSearch(searchId, userSearches, setUserSearches);
  } catch (err) {
    console.log('ERROR:', err.message);
  }
};

const onSearch = async ({ args, params, setCases, setModal, setPages }) => {
  try {
    const data = await request({
      ...args,
      def: 'searchCaseData',
      method: 'GET',
      queryString: params,
    });
    setModal({
      open: false,
      error: false,
      loading: true,
      message: '',
    });
    const {
      pageNumber,
      pageSize,
      totalNumberOfPages,
      totalNumberOfRecords,
      results,
    } = data;
    const caseData = trimCases(results);
    setPages({
      pageNumber,
      pageSize,
      totalNumberOfPages,
      totalNumberOfRecords,
    });
    setCases([...caseData.cases]);
  } catch (err) {
    console.log('ERROR:', err.message);
  }
};

const submitCases = async ({
  args,
  cases,
  setCases,
  setModal,
  setSelectedCases,
  setUserData,
  userData,
  submitAll,
}) => {
  let caseIds = [];
  if (submitAll) {
    for (let i = 0; i < cases.length; i++) {
      let isOow = calculateOOW(cases[i].treatmentDate)
      if (
          (cases[i].status === 'ready to submit' && userData.oowValid) || 
          (cases[i].status === 'ready to submit' && !isOow)
          ) {
        caseIds.push(cases[i].id);
      }
    }
  } else {
    for (let i = 0; i < cases.length; i++) {
      caseIds.push(cases[i].id);
    }
  }
  try {
    await request({
      ...args,
      body: caseIds,
      def: 'submitCases',
      method: 'POST',
    });
    const summary = await request({
      ...args,
      def: 'casesSummary',
      method: 'GET',
    });
    setUserData((prevState) => ({
      ...prevState,
      hospital: { ...prevState.hospital, ...summary },
    }));
    setModal({
      open: true,
      error: false,
      loading: false,
      message: 'Case(s) have been submitted.',
    });
    removeSelectedCases(caseIds, cases, setCases);
    setSelectedCases([]);
    return true;
  } catch (err) {
    console.log('ERROR:', err.message);
  }
};

const trimCases = (cases) => {
  const data = [];
  let needsAttCt = 0;
  let readtCt = 0;
  let product9999Ct = 0;
  let victimIdCt = 0;
  let subCt = 0;
  let hasErrCt = 0;
  let needsCaseCt = 0;
  let needsStudyCt = 0;

  cases.forEach(
    ({
      age,
      caseNumber,
      collectionDate,
      comment,
      id,
      product1,
      product2,
      product3,
      treatmentDate,
      sex,
      status,
    }) => {
      const caseData = {
        age,
        caseNumber,
        checked: false,
        collectionDate,
        comment,
        id,
        product1,
        product2,
        product3,
        treatmentDate,
        sex,
        status,
      };
      data.push(caseData);
    }
  );
  return {
    cases,
    needsAttCt,
    readtCt,
    subCt,
    hasErrCt,
    needsCaseCt,
    needsStudyCt,
    product9999Ct,
    victimIdCt,
  };
};

/* Electron functions */
/* const getAllUnsaved = (hospitalId, setCases) => {
  window.api.store.clearRendererBindings();
  window.api.store.onReceive(readConfigResponse, (args) => {
    if (args.success) {
      const unsavedCases = args.value.unsavedCaseData.filter((unsaved) => {
        return unsaved.hospitalId === hospitalId;
      });
      setCases(unsavedCases);
    }
  });
  window.api.store.send(readConfigRequest, 'caseData');
};

const saveAllUnsaved = async ({
  args,
  setCases,
  setUnsavedCases,
  setModal,
  unsavedCases,
}) => {
  window.api.store.clearRendererBindings();
  try {
    setModal({
      open: true,
      error: false,
      loading: true,
      message: 'Saving Cases...',
    });
    const data = await request({
      ...args,
      body: unsavedCases,
      def: 'getBulkCases',
      method: 'GET',
    });
    if (data.length <= 0) {
      setModal({
        open: true,
        error: true,
        loading: false,
        message: `Failed to save cases. Please try again. ${data.status}: ${data.statusText}`,
      });
    } else {
      setModal({
        open: true,
        error: false,
        loading: false,
        message: 'Cases successfully saved.',
      });
      window.api.store.onReceive(readConfigResponse, (args) => {
        if (args.success) {
          const storedCases = args.value.caseData.filter((storedCase) => {
            return storedCase.hospitalId === args.hospitalId;
          });
          setCases(storedCases);
          setUnsavedCases(null);
          window.api.store.send(writeConfigRequest, 'caseData', {
            caseData: args.value.caseData,
            unsavedCaseData: [],
          });
        }
      });
      window.api.store.send(readConfigRequest, 'caseData');
    }
  } catch (err) {
    setModal({
      open: true,
      error: true,
      loading: false,
      message: `Failed to save cases. Please try again. ${err}`,
    });
    console.log('ERROR:', err.message);
  }
}; */

const parseLocalStorage = (id) => {
  let result
  try {
    result = JSON.parse(localStorage.getItem(id))
  } catch (e) {
    result = null
  }
  return result;
}

export {
  headerData,
  DELETE_FORM,
  addSearch,
  applyCardPreset,
  calculateCasePeriodEnd,
  calculateCasePeriodStart,
  calculateOOW,
  deleteCase,
  deleteSearch,
  onSearch,
  SEARCH_PARAMS,
  submitCases,
  trimCases,
  parseLocalStorage
};
