import React, { useEffect, useState, useContext } from 'react';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import {
  AddSearchModal,
  DeleteSearchModal,
} from '../../Components/CustomComponents/Modals';
import Pagination from '../../Components/Pagination';
import Combobox from '../../Components/CustomComponents/ComboBox';
import { OfflineModeContext } from '../../Context';
import { headerData, addSearch, applyCardPreset, deleteSearch } from './utils';

const DISABLE_DEFAULT = { search: true, submit: true, delete: true };

const SearchForm = ({
  applySavedSearch,
  caseNumber,
  collectionDateEnd,
  collectionDateStart,
  disableButtons,
  handleSearch,
  handleSearchElementChange,
  productCodes,
  searchParams,
  searchSelection,
  setDisableButtons,
  status,
  toggleAddSearchModal,
  toggleDeleteSearchModal,
  treatmentDateEnd,
  treatmentDateStart,
  userSearches,
}) => {
  return (
    <form
      className="dashboard-search"
      onSubmit={(e) => handleSearch(e, setDisableButtons)}
    >
      <div className="flex flex--space-between dashboard-search__top">
        <div className="flex__child dashboard-search__top-child">
          <fieldset className="flex dashboard-search__top-child-legend">
            <legend className="ut-only-sr">Treatment Date</legend>
            <label className="input-wrapper input-wrapper--left dashboard-search__from-date-label">
              <span className="label">
                Treatment Date <span className="ut-only-sr">From</span>:
              </span>
              <input
                type="date"
                className="input dashboard-search__date-input"
                name="treatmentDateStart"
                value={treatmentDateStart}
                max={treatmentDateEnd}
                onChange={(e) =>
                  handleSearchElementChange(e, 'treatmentDateStart')
                }
              />
            </label>
            <label className="input-wrapper  input-wrapper--left">
              <span className="label ut-margin-left-1-em dashboard-search__padded-label">
                To:
              </span>
              <input
                type="date"
                className="input dashboard-search__date-input"
                name="treatmentDateEnd"
                value={treatmentDateEnd}
                onChange={(e) =>
                  handleSearchElementChange(e, 'treatmentDateEnd')
                }
              />
            </label>
          </fieldset>
          <fieldset className="flex  dashboard-search__top-child-legend">
            <legend className="ut-only-sr">Collection Date</legend>
            <label className="input-wrapper  input-wrapper--left dashboard-search__from-date-label">
              <span className="label">
                Collection Date <span className="ut-only-sr">From</span>:
              </span>
              <input
                type="date"
                className="input dashboard-search__date-input"
                name="collectionDateStart"
                value={collectionDateStart}
                onChange={(e) =>
                  handleSearchElementChange(e, 'collectionDateStart')
                }
              />
            </label>
            <label className="input-wrapper  input-wrapper--left">
              <span className="label ut-margin-left-1-em dashboard-search__padded-label">
                To:
              </span>
              <input
                type="date"
                className="input dashboard-search__date-input"
                name="collectionDateEnd"
                value={collectionDateEnd}
                onChange={(e) =>
                  handleSearchElementChange(e, 'collectionDateEnd')
                }
              />
            </label>
          </fieldset>
        </div>

        <div className="flex__child dashboard-search__top-child">
          <label className="input-wrapper input-wrapper--right flex--align-items-center">
            <span className="label">Case #</span>
            <input
              type="number"
              className="input input--90"
              placeholder=""
              min="0"
              max="99999999"
              name="caseNumber"
              value={caseNumber}
              onChange={(e) => handleSearchElementChange(e, 'caseNumber')}
            />
          </label>
          <label className="input-wrapper input-wrapper--right flex--align-items-center">
            <span className="label">Product:</span>
            <Combobox
              el={{ id: 'product', size: '90' }}
              formData={searchParams}
              formErrors=""
              onChange={handleSearchElementChange}
              options={productCodes}
            />
          </label>
        </div>

        <div className="flex__child dashboard-search__top-child">
          <label className="input-wrapper input-wrapper--left">
            <span className="label">Status:</span>
            <select
              name="status"
              id="status"
              value={status}
              className="input input--select input--240"
              onChange={(e) => handleSearchElementChange(e, 'status')}
            >
              <option value="">All</option>
              <option value="needs attention">Needs Attention - All</option>
              <option value="needs attention - needs case data">
                - Needs Attention - Needs Case Data
              </option>
              <option value="needs attention - needs study data">
                - Needs Attention - Needs Study Data
              </option>
              <option value="needs attention - no victim id">
                - Needs Attention - No Victim ID
              </option>
              <option value="needs attention - 9999 product code errors">
                - Needs Attention - 9999 Product Code Errors
              </option>
              <option value="needs attention - has errors">
                - Needs Attention - Has Errors
              </option>
              <option value="option4" disabled>
                - Needs Attention - Possible Duplicates
              </option>
              <option value="ready to submit">Ready to Submit</option>
              <option value="submitted">Submitted</option>
            </select>
          </label>
        </div>
      </div>

      <div className="dashboard-search__bottom flex  flex--vert-center">
        {/* Search Button */}
        <div className="flex__child dashboard-search__bottom-child dashboard-search__bottom-child-search-button">
          <button
            type="submit"
            className="button button--primary dashboard-search__search-search-button"
            disabled={disableButtons.search}
          >
            Search
          </button>
        </div>
        {/* reset Button */}
        <div className="flex__child dashboard-search__bottom-child dashboard-search__bottom-child-reset-button">
          <button
            type="reset"
            value="reset"
            onClick={applySavedSearch}
            className="button button--secondary button--narrow dashboard-search__search-submit-button"
          >
            Reset
          </button>
        </div>
        {/* saved searches drowpdown */}
        <label className="flex__child dashboard-search__bottom-child dashboard-search__bottom-child-saved-searches">
          <span className="label">Saved Searches:</span>
          <select
            name="selectOption"
            id="selectOptions"
            className="input input--select"
            onChange={applySavedSearch}
            value={searchSelection}
          >
            <option value="" disabled>
              Choose Saved Search
            </option>

            <option value="needs attention">View All Needs Attention</option>
            <option value="ready to submit">View All Ready To Submit</option>
            <option value="submitted">View All Submitted this Period</option>
            <option value="cases collected today">Cases Collected Today</option>
            {userSearches && userSearches.length > 0 && (
              <>
                <option value="" disabled>
                  -------------------
                </option>
                {userSearches.map((search) => (
                  <option key={search.id} value={search.id}>
                    {search.name}
                  </option>
                ))}
              </>
            )}
          </select>
        </label>
        {/* saved searches add/delete buttons*/}
        <div className="flex__child dashboard-search__bottom-child dashboard-search__bottom-child-save-delete-buttons">
          <button
            className="button button--square ut-margin-left-1-em dashboard-search__search-save-button"
            onClick={(e) => toggleAddSearchModal(e)}
            disabled={userSearches && userSearches.length >= 10 ? true : false}
          >
            <span className="ut-only-sr">Save new saved search</span>
            <span className="fa-icon">&#xf055;</span>
          </button>

          <button
            className="button button--square  dashboard-search__search-save-button"
            onClick={toggleDeleteSearchModal}
            disabled={userSearches && userSearches.length === 0 ? true : false}
          >
            <span className="ut-only-sr">Delete Saved Search</span>
            <span className="fa-icon">&#xf056;</span>
          </button>
        </div>
        <div className="flex__child dashboard-search__bottom-divider"></div>
      </div>
    </form>
  );
};

const Search = ({
  args,
  cases,
  handleSearch,
  onSearch,
  pages,
  productCodes,
  searchParams,
  selectedCases,
  setAlertModal,
  setCases,
  setPages,
  setSearchParams,
  setSubmitModal,
  setUserSearches,
  sort,
  sortCases,
  toggleCheckbox,
  toggleDeleteSelectedModal,
  userSearches,
}) => {
  const { offlineMode } = useContext(OfflineModeContext);
  const [searchSelection, setSearchSelection] = useState('');
  const [comments, setComments] = useState(false);
  const [disableButtons, setDisableButtons] = useState(DISABLE_DEFAULT);
  const [deleteSearchModal, setDeleteSearchModal] = useState(false);
  const [addSearchModal, setAddSearchModal] = useState(false);
  const [disableDeleteSearchButton, setDisableDeleteSearchButton] =
    useState(true);
  const [newSearch, setNewSearch] = useState('');
  const {
    caseNumber,
    collectionDateStart,
    collectionDateEnd,
    status,
    treatmentDateStart,
    treatmentDateEnd,
  } = searchParams;
  const toggleComments = () => setComments((value) => !value);

  const onPageChange = (pageNumber) => {
    const params = { ...searchParams, pageNumber };
    onSearch({ args, params, setCases, setModal: setAlertModal, setPages });
  };

  const handleSearchElementChange = (e, name) => {
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setDisableButtons((prevState) => ({ ...prevState, search: false }));
  };

  // saved searches functions
  const applySavedSearch = (e) => {
    const { value } = e.target;
    setSearchSelection(value);
    switch (value) {
      case 'needs attention':
      case 'ready to submit':
      case 'submitted':
      case 'cases collected today':
        applyCardPreset({
          onSearch,
          preset: value,
          search: false,
          setSearchParams,
        });
        break;
      case 'reset':
        applyCardPreset({
          onSearch,
          preset: 'reset',
          search: false,
          setSearchParams,
        });
        break;
      default:
        const whichUserSearch = userSearches.findIndex((x) => x.id === value);
        let tempArray = userSearches[whichUserSearch];
        setSearchParams(tempArray);
        break;
    }
    setDisableButtons((prevState) => ({ ...prevState, search: false }));
  };

  const toggleAddSearchModal = (e) => {
    e.preventDefault();
    setAddSearchModal(!addSearchModal);
    setNewSearch('');
  };

  const toggleDeleteSearchModal = (e) => {
    e.preventDefault();
    setDisableDeleteSearchButton(true);
    setDeleteSearchModal(!deleteSearchModal);
  };

  const addSavedSearch = (e) => {
    e.preventDefault();
    toggleAddSearchModal(e);
    addSearch({
      args,
      searchName: newSearch,
      searchParams,
      setModal: setAlertModal,
      setUserSearches,
    });
    setNewSearch('');
  };

  const deleteSavedSearch = (e) => {
    e.preventDefault();
    const { value } = e.target.searches;
    toggleDeleteSearchModal(e);
    deleteSearch({
      args,
      searchId: value,
      setModal: setAlertModal,
      setUserSearches,
      userSearches,
    });
  };

  useEffect(() => {
    if (selectedCases) {
      const handleButtons = (selected) => {
        if (selected.length <= 0) {
          setDisableButtons((prevState) => ({
            ...prevState,
            submit: true,
            delete: true,
          }));
        } else {
          const sorted = selected.sort((a, b) =>
            b.status.toLowerCase().localeCompare(a.status.toLowerCase())
          );
          sorted.some((data) => {
            switch (data.status.toLowerCase()) {
              case 'submitted':
                if (selectedCases.length === 1) {
                  setDisableButtons((prevState) => ({
                    ...prevState,
                    delete: false,
                  }));
                } else {
                  setDisableButtons((prevState) => ({
                    ...prevState,
                    delete: true,
                  }));
                }
                setDisableButtons((prevState) => ({
                  ...prevState,
                  submit: true,
                }));
                return true;
              case 'ready to submit':
                setDisableButtons((prevState) => ({
                  ...prevState,
                  submit: false,
                }));
                if (selected.length === 1) {
                  setDisableButtons((prevState) => ({
                    ...prevState,
                    delete: false,
                  }));
                } else {
                  setDisableButtons((prevState) => ({
                    ...prevState,
                    delete: true,
                  }));
                }
                return false;
              case 'needs attention':
                if (selected.length === 1) {
                  setDisableButtons((prevState) => ({
                    ...prevState,
                    delete: false,
                  }));
                } else {
                  setDisableButtons((prevState) => ({
                    ...prevState,
                    delete: true,
                  }));
                }
                setDisableButtons((prevState) => ({
                  ...prevState,
                  submit: true,
                }));
                return true;
              default:
                setDisableButtons((prevState) => ({
                  ...prevState,
                  submit: true,
                  delete: true,
                }));
                return true;
            }
          });
        }
      };
      handleButtons(selectedCases);
    }
  }, [selectedCases]);

  return (
    <div>
      <section className="card ut-relative">
        <h2>Show NEISS Cases </h2>
        {/* ******************** top search form ************************* */}
        {offlineMode ? null : (
          <SearchForm
            applySavedSearch={applySavedSearch}
            caseNumber={caseNumber}
            collectionDateEnd={collectionDateEnd}
            collectionDateStart={collectionDateStart}
            disableButtons={disableButtons}
            handleSearch={handleSearch}
            handleSearchElementChange={handleSearchElementChange}
            productCodes={productCodes}
            searchParams={searchParams}
            searchSelection={searchSelection}
            setDisableButtons={setDisableButtons}
            status={status}
            toggleAddSearchModal={toggleAddSearchModal}
            toggleDeleteSearchModal={toggleDeleteSearchModal}
            treatmentDateEnd={treatmentDateEnd}
            treatmentDateStart={treatmentDateStart}
            userSearches={userSearches}
          />
        )}
        {/* ******************** search results ************************* */}
        <div className="dashboard-search-show-details">
          <label htmlFor="checkbox-t-all" className="label">
            Show Comments / Details
          </label>
          <input
            className="input"
            type="checkbox"
            id="checkbox-t-all"
            checked={comments}
            onChange={toggleComments}
          />{' '}
          <span className="dashboard-search-number-found">
            <span className="dashboard-search-number-found__divider">|</span>{' '}
            {pages && <b>{pages.totalNumberOfRecords}</b>} Cases Found
          </span>
        </div>
        <div className="dashboard-table-container">
          <table className="table dashboard-table">
            <thead>
              <tr className="table__head">
                <th className="table__heading">&nbsp;</th>
                {headerData.map(({ value, name }) => (
                  <TableHeader
                    key={value}
                    onClick={sortCases}
                    sort={sort}
                    value={value}
                    name={name}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {cases &&
                cases.map((caseInfo, index) => {
                  return (
                    <TableRow
                      key={caseInfo.id || caseInfo.tempId}
                      caseInfo={caseInfo}
                      comments={comments}
                      index={index}
                      onChange={toggleCheckbox}
                    />
                  );
                })}
              {cases && cases.length === 0 && (
                <tr className="table__row">
                  <td
                    colSpan="10"
                    className="table__cell dashboard-table-no-results"
                  >
                    There are no cases matching your search.
                    <br />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pages && (
          <Pagination
            currentPage={pages.pageNumber}
            totalCount={pages.totalNumberOfRecords}
            pageSize={pages.pageSize}
            onPageChange={onPageChange}
          />
        )}
        {offlineMode ? null : (
          <div className="dashboard-search-actions">
            <button
              className="button button--secondary button--secondary-warning button--small  ut-margin-right-1-em"
              disabled={disableButtons.delete}
              onClick={toggleDeleteSelectedModal}
            >
              Delete Selected Case
            </button>
            <button
              className="button button--secondary button--secondary-success button--small"
              disabled={disableButtons.submit}
              onClick={() => setSubmitModal(true)}
            >
              Submit Selected Cases
            </button>
          </div>
        )}
      </section>
      <AddSearchModal
        addSavedSearch={addSavedSearch}
        modal={addSearchModal}
        newSearch={newSearch}
        setNewSearch={setNewSearch}
        toggleModal={toggleAddSearchModal}
      />
      <DeleteSearchModal
        deleteSavedSearch={deleteSavedSearch}
        disable={disableDeleteSearchButton}
        modal={deleteSearchModal}
        setDisable={setDisableDeleteSearchButton}
        toggleModal={toggleDeleteSearchModal}
        userSearches={userSearches}
      />
    </div>
  );
};

export default Search;
