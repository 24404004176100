const inputs = [
  {
    field: {
      id: 'occupation',
      label: "Victim's Job Title or Occupation",
      maxLength: '35',
      size: 'large',
      type: 'text',
    },
  },
  {
    field: {
      id: 'businessType',
      label: 'Kind of Business',
      maxLength: '35',
      size: 'large',
      type: 'text',
    },
  },
  {
    field: {
      id: 'company',
      label: 'Name of Company or Employer',
      maxLength: '35',
      size: 'large',
      type: 'text',
    },
  },
  {
    field: {
      id: 'employedCity',
      label: 'City Where Employed',
      maxLength: '25',
      size: 'large',
      type: 'text',
    },
  },
  {
    field: {
      id: 'employedState',
      label: 'State Where Employed',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'employmentStatus',
      label: 'Worker Employment Status',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'employmentStatusSpecify',
      label: 'Please Specify Employment Status',
      maxLength: '35',
      size: 'large',
      type: 'text',
    },
  },
];

export default inputs;
