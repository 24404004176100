const errorMsg = {
  age: 'Must enter 0, or a whole number between 2-115, or a whole number between 201-223',
  bacLevel:
    'If available, please state BAC level. Otherwise if unknown or not stated, please report as "UNK" or "NS"',
  blank: 'Must be blank',
  bodyPartIncorrectValue: 'Body Part value does not match diagnosis',
  char: 'May be letters, numbers, or special characters but limited to ASCII (must translate into sybase)',
  commentDX: "Please put 'DX' in comment in front of doctor's diagnosis",
  commentShort:
    'Short comment, make sure you have enough detail based on verbatim notes from chart',
  currentDate: 'Must be before the current date',
  diagnosisDuplicate:
    '(Diagnosis 2, Body Part 2) combination cannot equal (Diagnosis 1, Body Part 1) combination',
  dispositionWarning: 'Confirm user intent - placeholder',
  disposition8Warning: 'Check that disposition is coded correctly',
  firearmIntent:
    'Injury intent coded for the NEISS case screen seems inconsistent with who caused the firearm injury.  Please verify that both of these values are coded accurately.',
  fire1313: 'Confirm user intent - placeholder',
  fire1866: 'Value must be either 1, 2, or 3',
  fireComment: 'In comment, please note whether fire department attended fire',
  intentAgeBelow: 'Must be 0, 1, or 2',
  intentAgeAbove: 'Must be 0',
  invalidDateFormat: 'Must be MM/DD/YYYY format',
  invalidTimeFormat: 'Must be HH:MM format',
  symptomsQuestions: 'At least one symptom must be selected',
  treatmentQuestions: 'At least one treatment must be selected',
  list: {
    abdominalPain: 'Invalid code for abdonminal pain, please enter 0-2',
    adverseEvent: 'Must be a value from the adverse drug event list',
    alcohol: 'Must be a value from the alcohol list',
    alcoholDrugs: 'Must be a value from the alcohol/drugs list',
    anySymptoms: 'Invalid Code for any symptoms, please enter 0-2',
    artRespiration:
      'Invalid code for inducing artificial respiration, please enter 0-2',
    basic: 'Must be a value from the list',
    bloodTransfusion: 'Invalid code for blood tranfusion, please enter 0-2',
    bodyPart: 'Must be a value from the body part list',
    bodyType: 'Must be a value from the body type list',
    causedBy: 'Must be a value from the caused by list',
    charAdmin: 'Invalid code for administereing charcoal, please enter 0-2',
    contactedDuringED: 'Invalid code for PCC contact by ED, please enter 0-2',
    contactedOther: 'Invalid code for other medical care, please enter 0-2',
    contactedPriorED:
      'Invalid code for PCC contact by victim, Please enter 0-2',
    containerDisposition: 'Please specify what happened to the container',
    crcPresent: 'Invalid code for child resistant closure, please enter 0-2',
    deliveryMethod: 'Must be a value from the drug delivery method list',
    diagnosis: 'Must be a value from the diagnosis list',
    difficultyBreathing:
      'Invalid code for difficulty breathing, please enter 0-2',
    disposition: 'Must be a value from the disposition list',
    dizziness: 'Invalid code for dizziness, please enter 0-2',
    drowsiness: 'Invalid code for drowsiness, please enter 0-2',
    drugsMedication: 'Must be a value from the drugs/medication list',
    drugProvided:
      'Please specify whether Drug was administered for Therapeutic Use',
    edTreatments: 'Invalid code for any treatment, please enter 0-2',
    employedState: 'Must be a value from the state list',
    emplymentStatus: 'Must be a value from the employment status list',
    enterExit: 'Must be a value from the in motion list',
    facility: 'Must be a value from the facility list',
    fire: 'Must be a value from the fire list',
    firearmType: 'Must be a value from the firearm type list',
    hazardPattern: 'Must be a value from the hazard pattern list',
    headaches: 'Invalid code for headache, please enter 0-2',
    injuryEvent: 'Must be a value from the injury event list',
    intent: 'Must be a value from the intent list',
    location: 'Must be a value from the location list',
    maritalStatus: 'Must be a value from the marital status list',
    nauseaVomiting: 'Invalid code for nausea/vomiting, please enter 0-2',
    otherSymptoms: 'Invalid code for other symptoms, please enter 0-2',
    otherTreatment: 'Invalid code for other treatment, please enter 0-2',
    patientAdmitted: 'Must be a value form the patient admitted list',
    patientIntent: 'Must be a value from the patient intent list',
    position: 'Must be a value from the position list',
    product: 'Product not valid for treatment date',
    raceHispanic: 'Must be a value from the hispanic list',
    race: 'Must be a value from the race list',
    sex: 'Must be a value from the sex list',
    sexInvolved: 'Must be a value from the person(s) involved list',
    stomachPump: 'Invalid code for stomach pumped, please enter 0-2',
    transportedToER: 'Must be a value from the transported to ER list',
    vomitingInduced: 'Invalid code for inducing vomiting, please enter 0-2',
    workRelated: 'Must be a value from the work related list',
  },
  nonTrauma:
    'Product code must not be 1913, 1914, 1915, 1916, 1923, 1927, 1928, 1929, 1930, 1931, 1932, 1940, or 2640 if hospital type is non-trauma, age is < 5 and diagnosis 1 or diagnosis 2 are 49 or 68',
  outOfWindow:
    'Must be >= window date unless out-of-window password has been entered',
  beyondOOW:
    'Date is beyond the last updateable date',
  product1519:
    'Do not use both 1901 and 1519. Code 1901 if car seat in a licensed motor vehicle, otherwise code 1519',
  product0878:
    'Product code 0878 is for levels. Check that the product is coded correctly.',
  product1535Warning:
    "Product code 1535 is for a child's potty chair. Is this correct?",
  product1270Warning:
    'Home locale suggests that this may be horseplay or fighting rather than the sport of wrestling. Would you like to modify the case before saving?',
  product1866Warning:
    'Product code 1866 for home fires when no other CPSC products are described. Would you like to modify the case before saving?',
  product3282Warning:
    'Product code 3282 is for the sport of curling. Is this correct?',
  product3260Warning:
    'Product code 3260 is for the sport of fencing. Is this correct?',
  productUse5555:
    'Use Product code 5555 for poisoning or chemical burn of patient less than 5 years old',
  productDontUse5555:
    'Product code 5555 is valid only for poisonings/chemical burns, age < 5',
  productDuplicate: 'Product codes cannot be the same',
  required: {
    abdominalPain: 'Please specify whether victim had abdominal pain',
    artRespiration:
      'Please specify whether artificial respiration was administered',
    basic: 'required',
    bloodTransfusion:
      'Please specify whether artificial respiration was administered',
    charAdmin: 'Please specify whether charcoal was administered',
    contactedOtherSpecify:
      'Please specify other medical person/facility contacted',
    dizziness: 'Please specify whether victim had dizziness',
    difficultyBreathing:
      'Please specify whether victim had difficulty breathing',
    drowsiness: 'Please specify whether victim had drowsiness',
    headaches: 'Please specify whether victim had headache',
    nauseaVomiting: 'Please specify whether victim had nausea or vomiting',
    otherSymptoms: 'Please specify whether there were any other symptoms',
    otherSymptomsSpecify: 'Please specify other symptom(s)',
    otherTreatment: 'Please specify whether any other treatment was given',
    otherTreatmentSpecify: 'Please specify other treatment(s)',
    stomachPump: 'Please specify whether stomach was pumped',
    vomitingInduced: 'Please specify whether vomiting was induced',
  },
  riskOtherSpecify: 'Please specify other type of risk',
  substanceName1: 'Enter "UNKNOWN" if no information',
  substanceName:
    'Please enter the name of the substance(s) involved in the poisoning. Enter "UNKNOWN" if no information',
  substanceAmount:
    'Please enter the amount taken. Enter "UNKNOWN" if no information',
  treatmentDate: 'Must be before the treatment date',
  userIntent: {
    disposition: 'Confirm user intent - placeholder',
    product: 'Confirm user intent - placeholder',
  },
  workRelatedNonTrauma: 'Must be 0 or 2 from the list',
  invalidDate: 'Invalid date',
};

export default errorMsg;
