import React, { useState } from 'react';
import { firearmInjuryInput } from './utils';
import { renderWrapper } from '../../../../Components/CustomComponents';
import { isValidDate, dateFromArgs } from '../../../../utils';

import { dropdownCodes } from '../../../../dropdownCodes';

const { maritalOther, firearmOther, causedOther, caused4, transported8, alcohol1 } = dropdownCodes;

const FirearmInjury = (props) => {
  const {
    currentForm,
    formData,
    formErrors,
    formWarnings,
    setButtonType,
    setFormData,
    setFormErrors,
    setUnsavedChanges,
  } = props;
  const [touched, setTouched] = useState(false);

  const onChange = (e, name) => {
    setTouched(true);
    setUnsavedChanges(true);
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    switch (name) {
      case 'dateInjured':
        if (value && isValidDate(value)) {
          const injuredDate = dateFromArgs(value)
          // getMonth returns 0 for January
          const month = injuredDate.getMonth()
          const day = injuredDate.getDate()
          if (day !== 1 && month !== 0) {
            setFormData((prevState) => ({ ...prevState, precision: 'D' }));
          }
        }
        break;
      case 'maritalStatus':
        if (value !== maritalOther)
          setFormData((prevState) => ({ ...prevState, maritalOther: '' }));
        break;
      case 'firearmType':
        if (value !== firearmOther)
          setFormData((prevState) => ({ ...prevState, firearmOther: '' }));
        break;
      case 'causedBy':
        if (value !== causedOther && value !== caused4)
          setFormData((prevState) => ({ ...prevState, causedByOther: '' }));
        break;
      case 'transportedToER':
        if (value !== transported8)
          setFormData((prevState) => ({ ...prevState, transportedOther: '' }));
        break;
      case 'alcoholDrugTests':
        if (value !== alcohol1)
          setFormData((prevState) => ({ ...prevState, testResults: '' }));
        break;
      default:
        break;
    }

    value = value.replace(/[^\x00-\x7F]/g, '');
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  return (
    <div
      className="list-tab-contents"
      aria-live="polite"
      aria-hidden={currentForm === 'firearmInjury' ? false : true}
    >
      <h1>Firearm Injury Special Study</h1>
      <form className="ut-max-width-900">
        {firearmInjuryInput.map((input) =>
          renderWrapper({
            input,
            formData,
            formErrors,
            formWarnings,
            setFormErrors,
            onChange,
          })
        )}
      </form>
    </div>
  );
};

export default FirearmInjury;
