import React from 'react';

const TableHeader = ({ onClick, sort, value, name }) => (
  <th className="table__heading">
    <button
      className="button button--table-sort"
      value={value}
      onClick={() => {
        let direction;
        if (sort.sortOrder === 'asc') direction = 'desc';
        if (sort.sortOrder === 'desc') direction = 'asc';
        onClick(direction, value);
      }}
    >
      {sort.sortBy !== value && (
        <span aria-live="polite">
          <span className="button-sort-icon"></span>
          <span className="ut-only-sr">Sort table by</span>
        </span>
      )}
      {sort.sortBy === value && sort.sortOrder === 'asc' && (
        <span aria-live="polite">
          <span className="button-sort-icon button-sort-icon--ascending"></span>
          <span className="ut-only-sr">Table is sorted ascending by</span>
        </span>
      )}
      {sort.sortBy === value && sort.sortOrder === 'desc' && (
        <span aria-live="polite">
          <span className="button-sort-icon button-sort-icon--descending"></span>
          <span className="ut-only-sr">Table is sorted descending by</span>
        </span>
      )}
      {name}
    </button>
  </th>
);

export default TableHeader;
