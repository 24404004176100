import { isValidDate, dateFromArgs } from '../../../utils';
import errorMsg from './errorMsg';

const createList = (list) => {
  let string;
  let newList = [];
  list.forEach((item) => {
    string = `${item.code} ${item.name}`;
    newList.push(string);
  });
  return newList;
};

const dateErrors = (colDateString, key, treatDateString, value, oowValid, lastUpdateableDate) => {
  if((value && !isValidDate(value)) || (colDateString && !isValidDate(colDateString)) || (treatDateString && !isValidDate(treatDateString))){
    return errorMsg.invalidDate;
  }
  const date = dateFromArgs(value);

  if (key === 'treatmentDate') {
    const collectionDate = dateFromArgs(colDateString);
    if (!value) return errorMsg.required.basic;
    if (date > collectionDate) return errorMsg.currentDate;
    const windowStart = new Date(
      collectionDate.getFullYear(),
      collectionDate.getMonth() - 1,
      1
    );
    if (date < windowStart && !oowValid) return errorMsg.outOfWindow;
    if(lastUpdateableDate){
      lastUpdateableDate = dateFromArgs(lastUpdateableDate)
      if (date < lastUpdateableDate) return errorMsg.beyondOOW;
    }
  }
  if (key === 'dateInjured' && !value) return errorMsg.required.basic;
  if ((key === 'dateOfBirth' || key === 'dateInjured') && value) {
    const treatmentDate = dateFromArgs(treatDateString);
    if (date > treatmentDate) return errorMsg.treatmentDate;
  }
  return false;
};

const invalidCharErrors = (value) => {
  const commentArr = value.split('');
  const notASCII = commentArr.some((char, index) => {
    const code = value.charCodeAt(index);
    if (code < 0 || code > 127) {
      return true;
    }
    return false;
  });
  return notASCII ? errorMsg.char : false;
};

const isNumber = (value) => {
  if (isNaN(value) || value === ' ') {
    return false;
  }
  return true;
};

const listErrors = (errType, listOptions, value) => {
  if (!value) return errorMsg.required.basic;
  const option = listOptions.find((el) => el === value);
  if (option) {
    return false;
  }
  return errorMsg.list[errType];
};

const setErrorMsg = (key, message, setFormErrors) => {
  if (message) {
    return setFormErrors((prevState) => ({
      ...prevState,
      [key]: message,
    }));
  }
  return false;
};

const setWarningMsg = (key, message, setFormWarnings) => {
  if (message) {
    return setFormWarnings((prevState) => ({
      ...prevState,
      [key]: message,
    }));
  }
  return false;
};

const timeErrors = (value) => {
  const timeFormatFull = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
  const timeFormatMin = /^([0-1][0-9]|2[0-3]):([0-9][0-9])$/;
  const timeFormatHour = /^([0-9][0-9]):([0-5][0-9])$/;
  if (!value) return errorMsg.required.basic;
  if (value) {
    if (value.indexOf('99') === 0) {
      if (!timeFormatHour.test(value)) return errorMsg.invalidTimeFormat;
      return;
    }
    if (value.indexOf('99') === 3) {
      if (!timeFormatMin.test(value)) return errorMsg.invalidTimeFormat;
      return;
    }
    if (!timeFormatFull.test(value)) return errorMsg.invalidTimeFormat;
  }
};

export {
  createList,
  dateErrors,
  invalidCharErrors,
  isNumber,
  listErrors,
  setErrorMsg,
  setWarningMsg,
  timeErrors,
};
