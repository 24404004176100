import React, { useContext, useState } from 'react';
import { CaseDataContext } from '../../../../Context';
import { DEFAULT_FORM_DATA, inputs } from './utils';
import { renderWrapper } from '../../../../Components/CustomComponents';
import { dropdownCodes } from '../../../../dropdownCodes';
import { ClearFormWarningModal } from '../../../../Components/CustomComponents/Modals';

const { basicYes, basicNo, vehiclePos3 } = dropdownCodes;

const MotorVehicle = (props) => {
  const {
    currentForm,
    formData,
    formErrors,
    setButtonType,
    setFormData,
    setFormErrors,
    setUnsavedChanges,
  } = props;
  const caseData = useContext(CaseDataContext);
  const [modal, setModal] = useState(false);
  const [touched, setTouched] = useState(false);
  const [id, setId] = useState('');
  const { product1, product2, product3 } = caseData;

  const onChange = (e, name) => {
    setTouched(true);
    setUnsavedChanges(true);
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    if (name === 'carSeat') {
      formData.collision = value
    } else
    if (name === 'collision') {
      formData.carSeat = value
    }
    if (
      (name === 'carSeat' || name === 'collision') &&
      value === basicYes &&
      touched
    ) {
      setId(name);
      if ((formData.localeInfo || formData.hazardPattern || formData.inMotion || formData.enterExitVehicle || 
          formData.vehiclePosition || formData.positionCode || formData.bodyType || formData.vehicleERRecord) !== '') {
        setModal(true);
      }
    }
    if (name === 'inMotion' && (value === basicYes || value === vehiclePos3)) {
      setFormData((prevState) => ({ ...prevState, enterExitVehicle: '' }));
    }
    if (
      name === 'vehiclePosition' &&
      (value === basicNo || value === vehiclePos3)
    ) {
      setFormData((prevState) => ({ ...prevState, positionCode: '' }));
    }
    value = value.replace(/[^\x00-\x7F]/g, '');
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  return (
    <div
      className="list-tab-contents"
      aria-live="polite"
      aria-hidden={currentForm === 'motorVehicle' ? false : true}
    >
      <h1>NHTSA Motor Vehicle Non-Crash Study</h1>
      <form className="ut-max-width-900">
        {inputs.map((input) => {
          if (
            input.field.id === 'collision' &&
            !product1.startsWith('1901') &&
            !product2.startsWith('1901') &&
            !product3.startsWith('1901')
          ) {
            return null;
          }
          if (
            input.field.id === 'carSeat' &&
            !product1.startsWith('1519') &&
            !product2.startsWith('1519') &&
            !product3.startsWith('1519')
          ) {
            return null;
          }
          return renderWrapper({
            input,
            formData,
            formErrors,
            setFormErrors,
            onChange,
          });
        })}
      </form>
      <ClearFormWarningModal
        defaultFormData={DEFAULT_FORM_DATA}
        id={id}
        modal={modal}
        setModal={setModal}
        setFormData={setFormData}
        setTouched={setTouched}
      />
    </div>
  );
};
export default MotorVehicle;
