import React, { useState } from 'react';
import { DEFAULT_FORM_DATA, inputs } from './utils';
import {
  renderInput,
  renderWrapper,
} from '../../../../Components/CustomComponents';
import { dropdownCodes } from '../../../../dropdownCodes';
import { ClearFormWarningModal } from '../../../../Components/CustomComponents/Modals';

const { eventType6 } = dropdownCodes;

const AdverseDrug = (props) => {
  const {
    formData,
    currentForm,
    formErrors,
    setButtonType,
    setFormData,
    setFormErrors,
    setUnsavedChanges,
  } = props;
  const [showTooltipsAde, setShowTooltipsAde] = useState(false);
  const [modal, setModal] = useState(false);
  const [touched, setTouched] = useState(false);

  const toggleTooltips = (e) => {
    e.preventDefault();
    setShowTooltipsAde(!showTooltipsAde);
  };

  const onChange = (e, name) => {
    setUnsavedChanges(true);
    setTouched(true);
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    switch (name) {
      case 'medList0':
      case 'medList1':
      case 'medList2':
      case 'medList3':
      case 'medList4':
      case 'medList5':
      case 'medList6':
      case 'medList7':
      case 'medList8':
      case 'eventType':
        if (e === eventType6 && touched) setModal(true);
        break;
      case 'drug1Name':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug1Amount: '',
            drug1DeliveryMethod: '',
            drug1DeliveryMethodOtherSpecify: '',
            drug1DeliveryMethodSpecify: '',
            drug1Specify: '',
            drug2Name: '',
            drug2Amount: '',
            drug2DeliveryMethod: '',
            drug2DeliveryMethodOtherSpecify: '',
            drug2DeliveryMethodSpecify: '',
            drug2Specify: '',
            drug3Name: '',
            drug3Amount: '',
            drug3DeliveryMethod: '',
            drug3DeliveryMethodOtherSpecify: '',
            drug3DeliveryMethodSpecify: '',
            drug3Specify: '',
            drug4Name: '',
            drug4Amount: '',
            drug4DeliveryMethod: '',
            drug4DeliveryMethodOtherSpecify: '',
            drug4DeliveryMethodSpecify: '',
            drug4Specify: '',
          }));
        break;
      case 'drug1DeliveryMethod':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug1DeliveryMethodOtherSpecify: '',
            drug1DeliveryMethodSpecify: '',
            drug1Specify: '',
          }));
        break;
      case 'drug1DeliveryMethodSpecify':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug1Specify: '',
          }));
        break;
      case 'drug2Name':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug2Amount: '',
            drug2DeliveryMethod: '',
            drug2DeliveryMethodOtherSpecify: '',
            drug2DeliveryMethodSpecify: '',
            drug2Specify: '',
            drug3Name: '',
            drug3Amount: '',
            drug3DeliveryMethod: '',
            drug3DeliveryMethodOtherSpecify: '',
            drug3DeliveryMethodSpecify: '',
            drug3Specify: '',
            drug4Name: '',
            drug4Amount: '',
            drug4DeliveryMethod: '',
            drug4DeliveryMethodOtherSpecify: '',
            drug4DeliveryMethodSpecify: '',
            drug4Specify: '',
          }));
        break;
      case 'drug2DeliveryMethod':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug2DeliveryMethodOtherSpecify: '',
            drug2DeliveryMethodSpecify: '',
            drug2Specify: '',
          }));
        break;
      case 'drug2DeliveryMethodSpecify':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug2Specify: '',
          }));
        break;
      case 'drug3Name':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug3Amount: '',
            drug3DeliveryMethod: '',
            drug3DeliveryMethodOtherSpecify: '',
            drug3DeliveryMethodSpecify: '',
            drug3Specify: '',
            drug4Name: '',
            drug4Amount: '',
            drug4DeliveryMethod: '',
            drug4DeliveryMethodOtherSpecify: '',
            drug4DeliveryMethodSpecify: '',
            drug4Specify: '',
          }));
        break;
      case 'drug3DeliveryMethod':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug3DeliveryMethodOtherSpecify: '',
            drug3DeliveryMethodSpecify: '',
            drug3Specify: '',
          }));
        break;
      case 'drug3DeliveryMethodSpecify':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug3Specify: '',
          }));
        break;
      case 'drug4Name':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug4Amount: '',
            drug4DeliveryMethod: '',
            drug4DeliveryMethodOtherSpecify: '',
            drug4DeliveryMethodSpecify: '',
            drug4Specify: '',
          }));
        break;
      case 'drug4DeliveryMethod':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug4DeliveryMethodOtherSpecify: '',
            drug4DeliveryMethodSpecify: '',
            drug4Specify: '',
          }));
        break;
      case 'drug4DeliveryMethodSpecify':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            drug4Specify: '',
          }));
        break;
      default:
        break;
    }

    value = value.replace(/[^\x00-\x7F]/g, '');
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const args = {
    currentForm,
    formData,
    formErrors,
    onChange,
    setFormData,
  };

  return (
    <div
      className="list-tab-contents"
      aria-live="polite"
      aria-hidden={currentForm === 'adverseDrug' ? false : true}
    >
      <form
        className={`ut-max-width-900 ut-position-relative ${
          showTooltipsAde === false ? 'tooltips--hidden' : ''
        }`}
      >
        <h1>Adverse Drug Event</h1>
        {/* Tooltip Button */}
        <button
          type="button"
          className={`button button--circle button-icon button--tooltip ${
            showTooltipsAde === true ? 'button--tooltip-show' : ''
          }`}
          onClick={(e) => toggleTooltips(e)}
        >
          <span className="ut-only-sr button--tooltip__not-showing">
            Toggle tooltips off
          </span>
          <span className="button--tooltip__not-showing">i</span>
          <span className="ut-only-sr button--tooltip__showing">
            Toggle tooltips on
          </span>
          <span className="button--tooltip__showing">i</span>
        </button>

        <p className="text-helper text-helper--no-italics">
          An <b>Adverse Drug Event</b> is an injury or undesirable condition
          from a Pharmaceutical Product.
        </p>
        <ul className="text-helper text-helper--no-italics ut-margin-top-0">
          <li>
            Drugs <b>INCLUDE:</b> Prescription Medicines, Over-the-Counter
            Medicines, Medicate Creams/Ointments, Vaccines/Shots,
            Vitamin/HerbalNutritional Supplements, and Unspecified/Unknown
            Medicines.
          </li>
          <li>
            Drugs do <b>NOT</b> include: illegal drugs only and
            non-pharmaceutical products (e.g., rubbing alcohol, lip balm).
          </li>
        </ul>
        {inputs.map((input) => {
          if (input.fieldset) {
            return (
              <fieldset key={input.id}>
                <legend>
                  {input.fieldset}
                  {input.helper && (
                    <span className="text-helper"> {input.helper}</span>
                  )}
                </legend>
                {renderWrapper({
                  input,
                  ...args,
                })}
              </fieldset>
            );
          }
          if (input.flexFieldset) {
            return (
              <div key={input.id}>
                <legend>
                  Record the following information about the drug/medications(s)
                  linked to the adverse event
                </legend>
                <div className="flex flex--two-col-wrap form-ade__four-drugs">
                  {input.flexFieldset.map((el) => {
                    return (
                      <fieldset
                        className="flex-child ut-max-width-445"
                        key={el.id}
                      >
                        <legend>
                          {el.fieldset}
                          {el.helper && (
                            <span className="text-helper"> {el.helper}</span>
                          )}
                        </legend>
                        {renderInput({
                          input: el.flex,
                          ...args,
                        })}
                      </fieldset>
                    );
                  })}
                </div>
              </div>
            );
          }
          return renderWrapper({
            input,
            ...args,
          });
        })}
      </form>
      <ClearFormWarningModal
        defaultFormData={DEFAULT_FORM_DATA}
        id="eventType"
        modal={modal}
        setModal={setModal}
        setFormData={setFormData}
        setTouched={setTouched}
      />
    </div>
  );
};
export default AdverseDrug;
