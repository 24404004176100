import React, { useContext } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
// import { useHistory } from 'react-router-dom';
import { db } from '../../db';
import { trimCases } from './utils';
import { UserDataContext, OfflineModeContext } from '../../Context';

const NeedsAttentionCard = ({
  numberOfNeedsAttention,
  numberOfNeedsCaseData,
  numberOfHasErrors,
  numberOfNeedsStudyData,
  numberOfHasProductCode9999Errors,
  numberOfMissingVictimId,
  handleCardPreset,
}) => (
  <section className="card card--third card--border-red dashboard-card flex flex--column flex--space-between">
    <div className="flex__child">
      <h2 className="header-2 dashboard-card__header ut-color-red">
        {numberOfNeedsAttention ? numberOfNeedsAttention : 0} Needs Attention
      </h2>
      <ul className=" dashboard-card__list flex flex--two-col-wrap">
        <li>
          <b className="ut-color-red">
            {numberOfNeedsCaseData ? numberOfNeedsCaseData : 0}{' '}
          </b>
          Needs Case Data
        </li>
        <li>
          <b className="ut-color-red">
            {numberOfHasErrors ? numberOfHasErrors : 0}{' '}
          </b>{' '}
          Has Errors
        </li>
        <li>
          <b className="ut-color-red">
            {numberOfNeedsStudyData ? numberOfNeedsStudyData : 0}{' '}
          </b>
          Needs Study Data
        </li>
        <li>
          <b className="ut-color-red">
            {numberOfHasProductCode9999Errors
              ? numberOfHasProductCode9999Errors
              : 0}{' '}
          </b>
          With Product Code 9999
        </li>
        <li>
          <b className="ut-color-red">
            {numberOfMissingVictimId ? numberOfMissingVictimId : 0}{' '}
          </b>
          Missing Victim Id
        </li>
      </ul>
    </div>
    <div className="flex__child ut-align-center">
      <button
        type="button"
        className="button button--primary button--small"
        disabled={!numberOfNeedsAttention}
        value="needs attention"
        onClick={handleCardPreset}
      >
        View All <span className="ut-only-sr">Needs Attention</span>
      </button>
    </div>
  </section>
);

const ReadyToSubmitCard = ({
  numberOfReadyToSubmit,
  handleCardPreset,
  disable,
  setSubmitAllModal,
}) => (
  <section className="card card--third card--border-green dashboard-card flex flex--column flex--space-between">
    <div className="flex__child ut-align-center">
      <h2 className="header-2 dashboard-card__header ut-color-green">
        {numberOfReadyToSubmit ? numberOfReadyToSubmit : 0} Ready to Submit
      </h2>
      <p className="dashboard-card__p">
        {numberOfReadyToSubmit
          ? `You have ${numberOfReadyToSubmit} cases ready to be submitted`
          : `You have no cases ready to be submitted.`}
      </p>
    </div>
    <div className="flex__child ut-align-center">
      <div className=" ut-margin-bottom-half-em">
        <button
          type="button"
          className="button button--primary button--small"
          disabled={!numberOfReadyToSubmit}
          value="ready to submit"
          onClick={handleCardPreset}
        >
          View All <span className="ut-only-sr">Ready to Submit</span>
        </button>
      </div>
      <button
        type="button"
        className="button button--secondary  button--secondary-success button--small"
        disabled={disable}
        onClick={() => setSubmitAllModal(true)}
      >
        Submit All
      </button>
    </div>
  </section>
);

const SubmittedCasesCard = ({
  numberOfSubmitted,
  hospital,
  handleCardPreset,
}) => (
  <section className="card card--third card--border-gray dashboard-card flex flex--column flex--space-between">
    <div className="flex__child ut-align-center">
      <h2 className="header-2 dashboard-card__header ut-color-gray">
        {numberOfSubmitted ? numberOfSubmitted : 0} Submitted Cases
      </h2>
      <p className="dashboard-card__p">
        Total cases submitted this month.
        <br />
        Last submission date:
        <br />
        {hospital && hospital.lastSubmittedDate}
      </p>
    </div>
    <div className="flex__child ut-align-center">
      <button
        type="button"
        className="button button--primary button--small"
        disabled={!numberOfSubmitted}
        value="submitted"
        onClick={handleCardPreset}
      >
        View All <span className="ut-only-sr">Submitted</span>
      </button>
    </div>
  </section>
);

const WarningOfflineCard = () => (
  <section className="card card--third card--border-red dashboard-card flex flex--column flex--space-between">
    <div className="flex__child ut-align-center">
      <h2 className="header-2 dashboard-card__header ut-color-red">
        App in Offline Mode
      </h2>
      <p className="dashboard-card__p">
        Functionaly throughout the app has been disabled.
        <br />
        <br />
        Be sure to save all cases before refreshing the page or returning to
        online mode.
      </p>
    </div>
  </section>
);

const ViewAllOfflineCard = ({ setCases }) => {
  const savedCount = useLiveQuery(() => db.cases.count());
  return (
    <section className="card card--third card--border-green dashboard-card flex flex--column flex--space-between">
      <div className="flex__child ut-align-center">
        <h2 className="header-2 dashboard-card__header ut-color-gray">
          {savedCount} Case(s) created this session
        </h2>
      </div>
      <div className="flex__child ut-align-center">
        <button
          type="button"
          className="button button--primary button--small"
          value="submitted"
          onClick={async () => {
            const data = await db.cases.toArray();
            const caseData = trimCases(data);
            setCases(caseData.cases);
          }}
        >
          View All
        </button>
      </div>
    </section>
  );
};

const SaveAllOfflineCard = ({ handleSaveOfflineModal }) => {
  // let history = useHistory();
  return (
    <section className="card card--third card--border-gray dashboard-card flex flex--column flex--space-between">
      <div className="flex__child ut-align-center">
        <h2 className="header-2 dashboard-card__header ut-color-gray">
          Save All Cases
        </h2>
      </div>
      <div className="flex__child ut-align-center">
        <p className="dashboard-card__p">Save all cases created this session</p>
      </div>
      <div className="flex__child ut-align-center">
        <button
          type="button"
          className="button button--primary button--small"
          value="submitted"
          onClick={() => {
            handleSaveOfflineModal();
            //history.go(0) ;
          }}
        >
          Save All
        </button>
      </div>
    </section>
  );
};

const StatusCards = ({
  disable,
  handleCardPreset,
  handleSaveOfflineModal,
  setCases,
  setSubmitAllModal,
}) => {
  const { userData } = useContext(UserDataContext);
  const { hospital } = userData;
  const { offlineMode } = useContext(OfflineModeContext);
  const {
    numberOfHasErrors,
    numberOfHasProductCode9999Errors,
    numberOfMissingVictimId,
    numberOfNeedsAttention,
    numberOfNeedsCaseData,
    numberOfNeedsStudyData,
    numberOfReadyToSubmit,
    numberOfSubmitted,
  } = hospital;
  return (
    <div>
      <div className="flex flex--space-between">
        {offlineMode ? (
          <>
            <WarningOfflineCard />
            <ViewAllOfflineCard setCases={setCases} />
            <SaveAllOfflineCard
              handleSaveOfflineModal={handleSaveOfflineModal}
            />
          </>
        ) : (
          <>
            <NeedsAttentionCard
              numberOfHasErrors={numberOfHasErrors}
              numberOfHasProductCode9999Errors={
                numberOfHasProductCode9999Errors
              }
              numberOfMissingVictimId={numberOfMissingVictimId}
              numberOfNeedsAttention={numberOfNeedsAttention}
              numberOfNeedsCaseData={numberOfNeedsCaseData}
              numberOfNeedsStudyData={numberOfNeedsStudyData}
              handleCardPreset={handleCardPreset}
            />
            <ReadyToSubmitCard
              numberOfReadyToSubmit={numberOfReadyToSubmit}
              handleCardPreset={handleCardPreset}
              disable={disable}
              setSubmitAllModal={setSubmitAllModal}
            />
            <SubmittedCasesCard
              numberOfSubmitted={numberOfSubmitted}
              hospital={hospital}
              handleCardPreset={handleCardPreset}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default StatusCards;
