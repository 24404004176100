import React, { useContext, useEffect, useState } from 'react';
import {
  renderError,
  renderWarning,
} from '../../../../Components/CustomComponents';
import { useParams } from 'react-router-dom';
import { UserDataContext } from '../../../../Context';
import { calculateOOW } from '../../../Dashboard/utils';

const CommentSection = ({
  formData,
  formErrors,
  formWarnings,
  onChange,
  wordWarnings,
}) => {
  const { existingCaseId } = useParams();
  const { userData } = useContext(UserDataContext);
  const { hospital, oowValid } = userData;
  const { lastUpdateableDate } = hospital;
  const [isOow, setIsOowW] = useState(false);
  const [OowChecked, setoowChecked] = useState(false);

  let formatTreatmentDate; 

  // format treatmentDate to yyyy-mm-dd to compare against lastUpdateableDate.
  if (formData.treatmentDate) {
    formatTreatmentDate = new Date(formData.treatmentDate).toISOString().split('T')[0];
  }

  useEffect(() => {
    if (formData.treatmentDate && existingCaseId && !OowChecked) {
      setIsOowW(calculateOOW(formData.treatmentDate));
      setoowChecked(true);
    }
  }, [existingCaseId, formData.treatmentDate, isOow, OowChecked]);

  const disableInputCheck = () => {
    if (existingCaseId && !oowValid && isOow) {
      return calculateOOW(formData.treatmentDate);
    }
    if (existingCaseId && isOow && (formatTreatmentDate < lastUpdateableDate)) return true;
  };

  return (
    <div className="fieldset">
      <label className="input-wrapper input-wrapper--new-case flex--align-items-flex-start">
        <span className="ut-indent-large label">Comments:</span>
        <textarea
          disabled={disableInputCheck()}
          className={`input input--textarea-new-case ${
            formErrors.comment ? 'input--error' : ''
          }`}
          id="comment"
          maxLength="400"
          onChange={(e) => onChange(e, 'comment')}
          value={formData.comment}
        />
      </label>
      {formErrors.comment && renderError(formErrors, 'comment')}
      {formWarnings.comment && renderWarning(formWarnings, 'comment')}
      {wordWarnings && wordWarnings.length > 0 && (
        <div className="comments-word-warnings-list">
          {wordWarnings.map((warning) => (
            <li
              className="comments-word-warnings-list__item"
              key={warning.message}
            >
              <span className="input-warning-message">
                Warning: {warning.message}
              </span>
            </li>
          ))}
        </div>
      )}
    </div>
  );
};

export default CommentSection;
