const adverseDrugFormData = {
  eventType: '',
  primaryReason: '',
  facility: '',
  drug1Name: '',
  drug2Name: '',
  drug3Name: '',
  drug4Name: '',
  drug1Amount: '',
  drug2Amount: '',
  drug3Amount: '',
  drug4Amount: '',
  drug1DeliveryMethod: '',
  drug2DeliveryMethod: '',
  drug3DeliveryMethod: '',
  drug4DeliveryMethod: '',
  drug1DeliveryMethodOtherSpecify: '',
  drug2DeliveryMethodOtherSpecify: '',
  drug3DeliveryMethodOtherSpecify: '',
  drug4DeliveryMethodOtherSpecify: '',
  drug1DeliveryMethodSpecify: '',
  drug2DeliveryMethodSpecify: '',
  drug3DeliveryMethodSpecify: '',
  drug4DeliveryMethodSpecify: '',
  drug1Specify: '',
  drug2Specify: '',
  drug3Specify: '',
  drug4Specify: '',
  finalDiagnosis: '',
  treatmentsGiven: '',
  drugTestResults: '',
  adverseEventDetails: '',
  medList0: '',
  medList1: '',
  medList2: '',
  medList3: '',
  medList4: '',
  medList5: '',
  medList6: '',
  medList7: '',
  medList8: '',
  medList9: '',
};

const adverseDrugFormErrors = {
  eventType: '',
  primaryReason: '',
  facility: '',
  drug1Name: '',
  drug2Name: '',
  drug3Name: '',
  drug4Name: '',
  drug1Amount: '',
  drug2Amount: '',
  drug3Amount: '',
  drug4Amount: '',
  drug1DeliveryMethod: '',
  drug2DeliveryMethod: '',
  drug3DeliveryMethod: '',
  drug4DeliveryMethod: '',
  drug1DeliveryMethodOtherSpecify: '',
  drug2DeliveryMethodOtherSpecify: '',
  drug3DeliveryMethodOtherSpecify: '',
  drug4DeliveryMethodOtherSpecify: '',
  drug1DeliveryMethodSpecify: '',
  drug2DeliveryMethodSpecify: '',
  drug3DeliveryMethodSpecify: '',
  drug4DeliveryMethodSpecify: '',
  drug1Specify: '',
  drug2Specify: '',
  drug3Specify: '',
  drug4Specify: '',
  finalDiagnosis: '',
  treatmentsGiven: '',
  drugTestResults: '',
  adverseEventDetails: '',
  medList0: '',
  medList1: '',
  medList2: '',
  medList3: '',
  medList4: '',
  medList5: '',
  medList6: '',
  medList7: '',
  medList8: '',
  medList9: '',
};

const assaultFormData = {
  arrivalTimeAS: '',
  patientInitiated: '',
  patientIntervened: '',
  patientBystander: '',
  amountInvolved: '',
  sexInvolved: '',
  patientPeer: '',
  cps: '',
  policeReport: '',
  referral: '',
  sexuallyAssaulted: '',
  examination: '',
  examinationSpecify: '',
  prophylactic: '',
  prophylacticSpecify: '',
  contraception: '',
  contraceptionSpecify: '',
};

const assaultFormErrors = {
  arrivalTimeAS: '',
  patientInitiated: '',
  patientIntervened: '',
  patientBystander: '',
  amountInvolved: '',
  sexInvolved: '',
  patientPeer: '',
  cps: '',
  policeReport: '',
  referral: '',
  sexuallyAssaulted: '',
  examination: '',
  examinationSpecify: '',
  prophylactic: '',
  prophylacticSpecify: '',
  contraception: '',
  contraceptionSpecify: '',
};

const childPoisoningFormData = {
  contactedPriorED: '',
  contactedDuringED: '',
  contactedOther: '',
  contactedOtherSpecify: '',
  anySymptoms: '',
  nauseaVomiting: '',
  dizziness: '',
  drowsiness: '',
  difficultyBreathing: '',
  headaches: '',
  abdominalPain: '',
  otherSymptoms: '',
  otherSymptomsSpecify: '',
  edTreatments: '',
  vomitingInduced: '',
  stomachPump: '',
  charAdmin: '',
  artRespiration: '',
  bloodTransfusion: '',
  otherTreatment: '',
  otherTreatmentSpecify: '',
  containerDisposition: '',
  crcPresent: '',
  drugProvided: '',
};

const childPoisoningFormErrors = {
  contactedPriorED: '',
  contactedDuringED: '',
  contactedOther: '',
  contactedOtherSpecify: '',
  anySymptoms: '',
  nauseaVomiting: '',
  dizziness: '',
  drowsiness: '',
  difficultyBreathing: '',
  headaches: '',
  abdominalPain: '',
  otherSymptoms: '',
  otherSymptomsSpecify: '',
  edTreatments: '',
  vomitingInduced: '',
  stomachPump: '',
  charAdmin: '',
  artRespiration: '',
  bloodTransfusion: '',
  otherTreatment: '',
  otherTreatmentSpecify: '',
  containerDisposition: '',
  crcPresent: '',
  drugProvided: '',
};

const firearmInjuryFormData = {
  dateInjured: '',
  precision: '',
  maritalStatus: '',
  maritalOther: '',
  firearmType: '',
  firearmOther: '',
  model: '',
  caliber: '',
  causedBy: '',
  causedByOther: '',
  verbalArgument: '',
  physicalFight: '',
  illicitDrugs: '',
  otherCrime: '',
  alcoholDrugTests: '',
  testResults: '',
  transportedToER: '',
  transportedOther: '',
};

const firearmInjuryFormErrors = {
  dateInjured: '',
  precision: '',
  maritalStatus: '',
  maritalOther: '',
  firearmType: '',
  firearmOther: '',
  model: '',
  caliber: '',
  causedBy: '',
  causedByOther: '',
  verbalArgument: '',
  physicalFight: '',
  illicitDrugs: '',
  otherCrime: '',
  alcoholDrugTests: '',
  testResults: '',
  transportedToER: '',
  transportedOther: '',
};

const firearmInjuryFormWarnings = {
  dateInjured: '',
  precision: '',
  maritalStatus: '',
  maritalOther: '',
  firearmType: '',
  firearmOther: '',
  model: '',
  caliber: '',
  causedBy: '',
  causedByOther: '',
  verbalArgument: '',
  physicalFight: '',
  illicitDrugs: '',
  otherCrime: '',
  alcoholDrugTests: '',
  testResults: '',
  transportedToER: '',
  transportedOther: '',
};

const motorVehicleFormData = {
  collision: '',
  carSeat: '',
  localeInfo: '',
  hazardPattern: '',
  inMotion: '',
  enterExitVehicle: '',
  vehiclePosition: '',
  positionCode: '',
  bodyType: '',
  vehicleERRecord: '',
};

const motorVehicleFormErrors = {
  collision: '',
  carSeat: '',
  localeInfo: '',
  hazardPattern: '',
  inMotion: '',
  enterExitVehicle: '',
  vehiclePosition: '',
  positionCode: '',
  bodyType: '',
  vehicleERRecord: '',
};

const mainCaseFormData = (treatmentDate, collectionDate) => ({
  hospitalId: '',
  treatmentDate: treatmentDate || '',
  caseNumber: '',
  collectionDate: collectionDate || '',
  comment: '',
  dateOfBirth: '',
  age: '',
  sex: '',
  diagnosis1: '',
  diagnosisOther1: '',
  bodyPart1: '',
  diagnosis2: '',
  diagnosisOther2: '',
  bodyPart2: '',
  disposition: '',
  product1: '',
  product2: '',
  product3: '',
  alcohol: '',
  drugsMedication: '',
  intent: '',
  location: '',
  fire: '',
  workRelated: '',
  raceHispanic: '',
  race: '',
  raceOther: '',
  needsCaseData: false,
  needsStudyData: false,
  hasErrors: false,
  hasProductCode9999Errors: false,
  isVictimIdRequired: false,
  hasVictimIdBeenEntered: false,
  alwaysRequireVictimId: false,
  status: '',
});

const mainCaseFormErrors = {
  treatmentDate: '',
  caseNumber: '',
  collectionDate: '',
  comment: '',
  dateOfBirth: '',
  age: '',
  sex: '',
  diagnosis1: '',
  diagnosisOther1: '',
  bodyPart1: '',
  diagnosis2: '',
  diagnosisOther2: '',
  bodyPart2: '',
  disposition: '',
  product1: '',
  product2: '',
  product3: '',
  alcohol: '',
  drugsMedication: '',
  intent: '',
  location: '',
  fire: '',
  workRelated: '',
  raceHispanic: '',
  race: '',
  raceOther: '',
};

const mainCaseFormWarnings = {
  treatmentDate: '',
  caseNumber: '',
  collectionDate: '',
  comment: '',
  dateOfBirth: '',
  age: '',
  sex: '',
  diagnosis1: '',
  diagnosisOther1: '',
  bodyPart1: '',
  diagnosis2: '',
  diagnosisOther2: '',
  bodyPart2: '',
  disposition: '',
  product1: '',
  product2: '',
  product3: '',
  alcohol: '',
  drugsMedication: '',
  intent: '',
  location: '',
  fire: '',
  workRelated: '',
  raceHispanic: '',
  race: '',
  raceOther: '',
};

const selfInflictedFormData = {
  arrivalTimeSI: '',
  patientIntent: '',
  intentSpecify: '',
  injuryEvent: '',
  eventSpecify: '',
  selfHarm: '',
  depression: '',
  bipolarDisorder: '',
  anxiety: '',
  personalityDisorder: '',
  alcoholAbuse: '',
  substanceAbuse: '',
  psychologicalProblems: '',
  riskFactorsOther: '',
  riskOtherSpecify: '',
  alcoholSI: '',
  bacLevel: '',
  drugs: '',
  substanceName1: '',
  substanceAmount1: '',
  substanceName2: '',
  substanceAmount2: '',
  substanceName3: '',
  substanceAmount3: '',
  substanceName4: '',
  substanceAmount4: '',
  patientAdmitted: '',
};

const selfInflictedFormErrors = {
  arrivalTimeSI: '',
  patientIntent: '',
  intentSpecify: '',
  injuryEvent: '',
  eventSpecify: '',
  selfHarm: '',
  depression: '',
  bipolarDisorder: '',
  anxiety: '',
  personalityDisorder: '',
  alcoholAbuse: '',
  psychologicalProblems: '',
  riskFactorsOther: '',
  riskOtherSpecify: '',
  alcoholSI: '',
  bacLevel: '',
  drugs: '',
  substanceName1: '',
  substanceAmount1: '',
  substanceName2: '',
  substanceAmount2: '',
  substanceName3: '',
  substanceAmount3: '',
  substanceName4: '',
  substanceAmount4: '',
  patientAdmitted: '',
};

const workRelatedFormData = {
  occupation: '',
  businessType: '',
  company: '',
  employedCity: '',
  employedState: '',
  employmentStatus: '',
  employmentStatusSpecify: '',
};

const workRelatedFormErrors = {
  occupation: '',
  businessType: '',
  company: '',
  employedCity: '',
  employedState: '',
  employmentStatus: '',
  employmentStatusSpecify: '',
};

export {
  adverseDrugFormData,
  adverseDrugFormErrors,
  assaultFormData,
  assaultFormErrors,
  childPoisoningFormData,
  childPoisoningFormErrors,
  firearmInjuryFormData,
  firearmInjuryFormErrors,
  firearmInjuryFormWarnings,
  motorVehicleFormData,
  motorVehicleFormErrors,
  mainCaseFormData,
  mainCaseFormErrors,
  mainCaseFormWarnings,
  selfInflictedFormData,
  selfInflictedFormErrors,
  workRelatedFormData,
  workRelatedFormErrors,
};
