import React, { useMemo, useState, useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { renderError, renderWarning } from './render';
import { matchSorter } from 'match-sorter';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { UserDataContext, DropdownOptionsContext } from '../../Context';
import { calculateOOW } from '../../Pages/Dashboard/utils';
import { filterActiveProducts } from '../../utils';
import { dropdownCodes } from '../../dropdownCodes';
const { diag68 } = dropdownCodes;

const ComboBoxMainCase = ({
  formData,
  formErrors,
  formWarnings,
  input,
  onChange,
  dateChanged,
  setDateChanged,
}) => {
  const { userData } = useContext(UserDataContext);
  const options = useContext(DropdownOptionsContext);
  const { hospital } = userData;
  const { lastUpdateableDate } = hospital;
  const { existingCaseId } = useParams();
  const { oowValid } = userData;
  const containerRef = useRef(null);
  const [option, setOption] = useState('');
  const [allOptions, setAllOptions] = useState([]);
  const [redirected, setRedirected] = useState(false);
  const [touched, setTouched] = useState(false);
  const [tabIndex, setTabIndex] = useState('');
  const [isOow, setIsOowW] = useState(false);
  const [OowChecked, setoowChecked] = useState(false);
  const {
    id,
    indent,
    label,
    placeholder,
    size,
    tooltip,
    tooltipApi,
    tooltipSize,
    tooltipType,
  } = input;

  const {
    caseBodyPart,
    caseDiagnosis,
    caseDisposition,
    caseFire,
    caseGender,
    caseAlcoholDrugsMedicationYesNoNotRecorded,
    caseIntent,
    caseLocale,
    caseRace,
    caseWorkRelated,
    caseProduct,
  } = options.mainCaseOptions;

  let formatTreatmentDate; 

  // format treatmentDate to yyyy-mm-dd to compare against lastUpdateableDate.
  if (formData.treatmentDate) {
    formatTreatmentDate = new Date(formData.treatmentDate).toISOString().split('T')[0];
  }

  const useFilter = (term, allOptions) => {
    return useMemo(() => {
      if (!term || !term.length) {
        return allOptions;
      }
      const terms = term.split(' ');
      if (!terms) {
        return allOptions;
      }
      return terms.reduceRight(
        (results, term) =>
          matchSorter(results, term, { keys: ['code', 'name', 'aliases'] }),
        allOptions
      );
    }, [term, allOptions]);
  };

  useEffect(() => {
    if (formData.diagnosis1.startsWith('68') || formData.diagnosis2.startsWith('68')) {
      if (id === 'bodyPart1' || id === 'bodyPart2') {
        setTabIndex(-1);
      }
    }
    if (!formData.diagnosis1.startsWith('68') || !formData.diagnosis2.startsWith('68')) {
      if (id === 'bodyPart1' || id === 'bodyPart2') {
        setTabIndex('');
      }
    }
  }, [formData, id]);

  const filteredOptions = useFilter(formData[id], allOptions);

  useEffect(() => {
    if (existingCaseId && !redirected && !touched) {
      if (filteredOptions.length > 0 && formData[id]) {
        onChange(`${filteredOptions[0].code} ${filteredOptions[0].name}`, id);
        setRedirected(true);
      }
    }
  }, [
    existingCaseId,
    formData,
    redirected,
    filteredOptions,
    id,
    onChange,
    touched,
  ]);

  useEffect(() => {
    if (allOptions.length <= 0) {
      switch (id) {
        case 'sex':
          setAllOptions(caseGender);
          break;
        case 'diagnosis1':
        case 'diagnosis2':
          setAllOptions(caseDiagnosis);
          break;
        case 'bodyPart1':
        case 'bodyPart2':
          setAllOptions(caseBodyPart);
          break;
        case 'disposition':
          setAllOptions(caseDisposition);
          break;
        case 'alcohol':
        case 'drugsMedication':
        case 'raceHispanic':
          setAllOptions(caseAlcoholDrugsMedicationYesNoNotRecorded);
          break;
        case 'intent':
          setAllOptions(caseIntent);
          break;
        case 'location':
          setAllOptions(caseLocale);
          break;
        case 'fire':
          setAllOptions(caseFire);
          break;
        case 'workRelated':
          setAllOptions(caseWorkRelated);
          break;
        case 'race':
          setAllOptions(caseRace);
          break;
        default:
          break;
      }
    }
  }, [
    allOptions,
    id,
    caseBodyPart,
    caseDiagnosis,
    caseDisposition,
    caseFire,
    caseGender,
    caseAlcoholDrugsMedicationYesNoNotRecorded,
    caseIntent,
    caseLocale,
    caseRace,
    caseWorkRelated,
    caseProduct,
    options,
  ]);

  useEffect(() => {
    if (allOptions.length <= 0 || (dateChanged && formData.treatmentDate[0] !== '0')) {
      switch (id) {
        case 'product1':
        case 'product2':
        case 'product3':
          let minDate = ''
            if(formData.treatmentDate !== ''){
              minDate = formData.treatmentDate
            }else{
              const lastYear = new Date().getFullYear() - 1;
              minDate = `${lastYear}-01-01`;
            }
          const prodList = filterActiveProducts(
            caseProduct,
            minDate,
            hospital.hospitalTypeCurrent
          );
          setAllOptions(prodList);
          setDateChanged(false);
          break;
        default:
          break;
      }
    }
  }, [allOptions, dateChanged, caseProduct, hospital.hospitalTypeCurrent, id, setDateChanged, formData.treatmentDate]);

  const onKeyUp = (e) => {
    const { key } = e;
    const { value } = e.target;
    setTouched(true);
    switch (key) {
      case 'Up':
      case 'ArrowUp':
      case 'Down':
      case 'ArrowDown':
        setOption(value);
        break;
      default:
        break;
    }
  };

  const onKeyDown = (e) => {
    const { key } = e;
    setTouched(true);
    switch (key) {
      case 'Up':
      case 'ArrowUp':
      case 'Down':
      case 'ArrowDown':
        if (!e.isDefaultPrevented()) {
          const container = containerRef.current;
          if (!container) return;
          window.requestAnimationFrame(() => {
            const element = container.querySelector('[aria-selected=true]');
            if (element) {
              const top = element.offsetTop - container.scrollTop;
              const bottom =
                container.scrollTop +
                container.clientHeight -
                (element.offsetTop + element.clientHeight);

              if (bottom < 0) container.scrollTop -= bottom;
              if (top < 0) container.scrollTop += top;
            }
          });
        }
        break;
      case 'Tab':
        setTouched(false);
        if (!option && filteredOptions.length > 0 && formData[id]) {
          if (id === 'product1' && formData[id] === '0') {
            return;
          }
          onChange(`${filteredOptions[0].code} ${filteredOptions[0].name}`, id);
          setOption('');
        }
        if (option) {
          onChange(option, id);
          setOption('');
        }
        break;
      default:
        break;
    }
  };

  const onBlur = (e) => {
    const { value } = e.target;

    // if the target has the 'data-reach-combobox-option' then an option in the comboBox triggered the blur and we want the event to proceed to onSelect
    if((e.relatedTarget && e.relatedTarget.hasAttribute('data-reach-combobox-option')) || !value) return

    if(filteredOptions.length > 0 && formData[id]) {
      onChange(`${filteredOptions[0].code} ${filteredOptions[0].name}`, id);
      setTouched(false);
      setOption('');
    }
  }

  const disableInputCheck = () => {
    if (existingCaseId && isOow && (formatTreatmentDate < lastUpdateableDate)) return true;
    if (existingCaseId && !oowValid && isOow) {
      return calculateOOW(formData.treatmentDate);
    } else {
      switch (id) {
        case 'product2':
          if (formData.product1.startsWith('0000') || formData.product1.startsWith('7878'))
            return true;
          return false;
        case 'product3':
          if (
            formData.product1.startsWith('0000') ||
            formData.product1.startsWith('7878') ||
            formData.product2.startsWith('0000')
          )
            return true;
          return false;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (formData.treatmentDate && existingCaseId && !OowChecked) {
      setIsOowW(calculateOOW(formData.treatmentDate));
      setoowChecked(true);
      setDateChanged(true);
    }
  }, [existingCaseId, formData.treatmentDate, isOow, OowChecked]);

  return (
    <div className="input-error-wrapper">
      <Combobox
        aria-label={label}
        className="combobox"
        openOnFocus
        onSelect={(e) => {
          setTouched(false);
          onChange(e, id);
        }}
      >
        <label className="input-wrapper input-wrapper--new-case tooltip__wrapper">
          <span className={`ut-indent-${indent} label`}>{label}</span>
          <ComboboxInput
            tabIndex={tabIndex}
            disabled={disableInputCheck()}
            maxLength={60}
            placeholder={placeholder}
            selectOnClick
            className={`input input--text input--${size} ${
              formErrors[id] ? 'input--error' : ''
            }`}
            onChange={(e) => {
              setTouched(true);
              onChange(e, id);
            }}
            onClick={(e) => {
              setTouched(true)
            }}
            value={formData[id]}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onBlur={onBlur}
          />
          {/* if non-trauma show this */}
          {tooltip &&
            hospital &&
            hospital.hospitalTypeCurrent !== 'NEISS-AIP' && (
              <div
                className={`tooltip__content  tooltip__content--${tooltipSize} tooltip__content--${tooltipType}`}
                dangerouslySetInnerHTML={{ __html: tooltip }}
              />
            )}
          {/* if trauma show this */}
          {tooltip &&
            hospital &&
            hospital.hospitalTypeCurrent === 'NEISS-AIP' && (
              <div
                className={`tooltip__content  tooltip__content--${tooltipSize} tooltip__content--${tooltipType}`}
                dangerouslySetInnerHTML={{ __html: tooltipApi }}
              />
            )}
          {filteredOptions && touched && (
            <ComboboxPopover className="shadow-popup" id={id}>
              {filteredOptions.length > 0 && (
                <ComboboxList ref={containerRef} persistSelection>
                  {filteredOptions.slice(0, 100).map((result, index) => (
                    <ComboboxOption
                      key={index}
                      value={`${result.code} ${result.name}`}
                    />
                  ))}
                </ComboboxList>
              )}
              {filteredOptions.length === 0 && !formData[id] && (
                <ComboboxList ref={containerRef} persistSelection>
                  {allOptions.slice(0, 100).map((result, index) => (
                    <ComboboxOption
                      key={index}
                      value={`${result.code} ${result.name}`}
                    />
                  ))}
                </ComboboxList>
              )}
              {filteredOptions.length === 0 && formData[id] && (
                <span style={{ display: 'block', margin: 8 }}>
                  No results found
                </span>
              )}
              {allOptions.length === 0 && (
                <span style={{ display: 'block', margin: 8 }}>
                  No results found
                </span>
              )}
            </ComboboxPopover>
          )}
        </label>
      </Combobox>
      {formErrors[id] && renderError(formErrors, id)}
      {formWarnings[id] && renderWarning(formWarnings, id)}
    </div>
  );
};

export default ComboBoxMainCase;
