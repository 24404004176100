import React, { useContext, useRef, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { db } from '../../db';
import { OfflineModeContext } from '../../Context';
import { getErvsDay } from '../../Pages/Ervs/utils';
import MaskedInput from 'react-maskedinput';

const modalPresets = (message, redirect = false) => ({
  default: {
    error: false,
    loading: false,
    message,
    open: false,
    redirect,
    warning: false,
  },
  loading: {
    error: false,
    loading: true,
    message,
    open: true,
    redirect,
    warning: false,
  },
  error: {
    error: true,
    loading: false,
    message,
    open: true,
    redirect,
    warning: false,
  },
  success: {
    error: false,
    loading: false,
    message,
    open: true,
    redirect,
    warning: false,
  },
  warning: {
    error: false,
    loading: false,
    message,
    open: true,
    redirect: true,
    warning: true,
  },
});

const MODAL_DEFAULT = {
  error: false,
  loading: false,
  message: '',
  open: false,
  redirect: false,
};

const MODAL_DISCARD = {
  open: false,
  period: '',
};

const AlertModal = ({
  focus,
  closeModal,
  modal,
  resetForm,
  needsAttention = false,
  hasWarnings,
  warningMessages,
}) => {
  const inputElement = useRef(null);
  let history = useHistory();
  const { offlineMode, setOfflineMode } = useContext(OfflineModeContext);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  return (
    <ReactModal
      isOpen={modal.open}
      contentLabel="Status Alert Modal"
      className={`modal ${modal.loading === true ? 'modal--loading' : ''}`}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={() => {
        if (focus) {
          const el = document.getElementById(focus);
          if (el) el.focus();
        }
        closeModal(modalPresets().default);
      }}
    >
      <section>
        <div className="modal__header">
          <button
            className="button modal__header-button"
            onClick={() => closeModal(modalPresets().default)}
            disabled={modal.loading}
          >
            <span className="ut-only-sr">Close Modal</span>
            <span className="fa-icon">&#xf00d;</span>
          </button>
        </div>
        {modal.error && !modal.loading && !modal.warning && (
          <h2 className="header-1 modal__h2 ut-color-red">Error</h2>
        )}
        {modal.warning && (
          <h2 className="header-1 modal__h2 ut-color-red">Warning</h2>
        )}
        {!modal.error && !modal.loading && needsAttention && (
          <h2 className="header-1 modal__h2 ut-color-red">
            Warning: Needs Attention
          </h2>
        )}
        {!modal.error && !modal.loading && !needsAttention && hasWarnings && (
          <h2 className="header-1 modal__h2 ut-color-yellow">Warning:</h2>
        )}
        {!modal.error && !modal.loading && !needsAttention && !hasWarnings && (
          <h2 className="header-1 modal__h2 ut-color-green">Success!</h2>
        )}
        {!modal.error && modal.loading && !modal.warning && (
          <h2 className="header-1 modal__h2 ut-color-gray">Loading...</h2>
        )}
        <div className="modal__body">
          {!modal.loading && needsAttention && (
            <div>
              {modal.message
                ? modal.message
                : 'The case contains form errors. Please review before continuing to the next case.'}
            </div>
          )}
          {!modal.loading && !needsAttention && hasWarnings && (
            <>
              <div>
                The case is{' '}
                <span className="ut-color-green">Ready to Submit</span> but has
                the following warnings:
              </div>
              <div className="comments-word-warnings-list ut-align-left">
                {warningMessages.map((warning) => (
                  <li
                    className="comments-word-warnings-list__item"
                    key={warning.message}
                  >
                    <span className="input-warning-message ut-align-left">
                      Warning: {warning.message}
                    </span>
                  </li>
                ))}
              </div>
            </>
          )}
          {!modal.error && !modal.loading && needsAttention && (
            <div className="input-warning-block-message">
              <b>Note:</b> There are items that need to be addressed before the
              case is Ready to Submit. Please review the case and any special
              studies that may need additional attention.
            </div>
          )}
          {!needsAttention && !hasWarnings && <p>{modal.message}</p>}
        </div>
        <div className="modal__buttons">
          <button
            className="button button--secondary ut-margin-right-1-em"
            ref={inputElement}
            onClick={() => {
              if (focus) {
                const el = document.getElementById(focus);
                if (el) el.focus();
              }
              closeModal(modalPresets().default);
            }}
            disabled={modal.loading}
          >
            Close
          </button>
          {resetForm && !modal.error && !needsAttention && (
            <button
              type="button"
              className="save-button button button--primary"
              onClick={resetForm}
              disabled={modal.loading}
            >
              <span className="save-button-label">Continue to next case</span>
            </button>
          )}
          {modal.warning && (
            <button
              type="button"
              className="save-button button button--primary"
              onClick={() => {
                closeModal(modalPresets().default);
                setOfflineMode(!offlineMode);
                if (offlineMode) db.cases.clear();
                if (modal.redirect) history.push('/');
              }}
              disabled={modal.loading}
            >
              <span className="save-button-label">Continue</span>
            </button>
          )}
        </div>
      </section>
    </ReactModal>
  );
};

const ClearFormWarningModal = ({
  defaultFormData,
  id,
  modal,
  setModal,
  setFormData,
  setTouched,
}) => {
  return (
    <ReactModal
      isOpen={modal}
      onRequestClose={() => setModal(!modal)}
      contentLabel="Confirm Event Type modal"
      className="modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <section>
        <div className="modal__header">
          <button
            className="button modal__header-button"
            onClick={() => setModal(!modal)}
          >
            <span className="ut-only-sr">Close Modal</span>
            <span className="fa-icon">&#xf00d;</span>
          </button>
        </div>
        <h2 className="header-1 modal__h2">Confirm Clear Form</h2>
        <div className="modal__body">
          <p>
            Warning! Any data that was entered in response to the questions
            below will be cleared - do you wish to continue?
          </p>
        </div>
        <div className="modal__buttons">
          <button
            className="button button--primary ut-margin-right-1-em"
            onClick={() => {
              setFormData((prevState) => ({
                ...prevState,
                ...defaultFormData,
              }));
              setTouched(false);
              setModal(!modal);
            }}
          >
            Yes
          </button>
          <button
            className="button button--secondary ut-margin-right-1-em"
            onClick={() => {
              setFormData((prevState) => ({ ...prevState, [id]: '' }));
              setModal(!modal);
            }}
          >
            No
          </button>
        </div>
      </section>
    </ReactModal>
  );
};

const DeleteCaseOfflineModal = ({
  caseNumber,
  modal,
  closeModal,
  handleAlerts,
}) => {
  let history = useHistory();
  return (
    <ReactModal
      isOpen={modal}
      contentLabel="Delete Case Modal"
      className="modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={closeModal}
    >
      <section>
        <div className="modal__header">
          <button className="button modal__header-button" onClick={closeModal}>
            <span className="ut-only-sr">Close Modal</span>
            <span className="fa-icon">&#xf00d;</span>
          </button>
        </div>
        <h2 className="header-1 modal__h2">Confirm Delete Case</h2>
        <div className="modal__buttons">
          <button
            className="button button--secondary ut-margin-right-1-em"
            onClick={closeModal}
          >
            Close
          </button>
          <button
            type="button"
            className="save-button button button--primary"
            onClick={async () => {
              try {
                await db.cases.delete(caseNumber);
                history.push('/');
              } catch (err) {
                handleAlerts(
                  modalPresets(
                    `There was an error deleting case ${caseNumber}. Please try again.`
                  )
                );
              }
              closeModal();
            }}
          >
            <span className="save-button-label">Delete</span>
          </button>
        </div>
      </section>
    </ReactModal>
  );
};

const DeleteCaseModal = ({
  args,
  cases,
  deleteCase,
  deleteForm,
  disable,
  modal,
  onChange,
  setCases,
  selectedCase,
  setDeleteForm,
  setDeleteModal,
  setModal,
  setSelectedCases,
  setUserData,
  toggleModal,
}) => (
  <ReactModal
    isOpen={modal}
    onRequestClose={toggleModal}
    contentLabel="Confirm delete modal"
    className="modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
  >
    <section>
      <div className="modal__header">
        <button className="button modal__header-button" onClick={toggleModal}>
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>
      <h2 className="header-1 modal__h2">Confirm Delete Case</h2>
      <div className="modal__body">
        <div className="modal__body--delete">
          <b>Note:</b> Proceeding will delete the entire case, including any
          associated special studies.
        </div>
        {selectedCase && selectedCase.deleteRequiresPassword === true && (
          <span>
            <label>
              <span className="label">Password:</span>
              <input
                className={`input input--password ${
                  deleteForm.passwordIsValid === false ? ' input--error' : ''
                }`}
                type="password"
                name="password"
                id="password"
                maxLength="50"
                onChange={(e) => onChange(e, 'password')}
                value={deleteForm.password}
              />
              {deleteForm.passwordIsValid === false && (
                <span className="text-error">
                  <br />
                  Password is invalid. Please check password and try again.
                </span>
              )}
            </label>
            <br />
            <br />
          </span>
        )}
        {selectedCase && selectedCase.deleteRequiresReason && (
          <label>
            <span className="label">
              Reason for deletion:
              <span className="text-helper">(50 characters max)</span>
            </span>
            <br />
            <textarea
              className="input input--textarea"
              id="reason"
              name="reason"
              maxLength="50"
              onChange={(e) => onChange(e, 'reason')}
              value={deleteForm.reason}
            />
          </label>
        )}
      </div>
      <div className="modal__buttons">
        <button
          className="button button--warning ut-margin-right-1-em"
          onClick={() => {
            toggleModal();
            deleteCase({
              args,
              cases,
              reason: deleteForm.reason,
              password: deleteForm.password,
              selectedCase,
              setDeleteForm,
              setDeleteModal,
              setModal,
              setCases,
              setSelectedCases,
              setUserData,
            });
          }}
          disabled={disable}
        >
          Delete Case
        </button>
        <button
          className="button button--secondary ut-margin-right-1-em"
          onClick={() => {
            toggleModal();
            setDeleteForm((prevState) => ({
              ...prevState,
              reason: '',
              password: '',
            }));
          }}
        >
          Cancel
        </button>
      </div>
    </section>
  </ReactModal>
);

const ErvWarningModal = ({
  args,
  modal,
  setModal,
  setMonthDetails,
  setMonthSelected,
  handleAlerts,
}) => (
  <ReactModal
    isOpen={modal.open}
    contentLabel="Confirm Discard Data Modal"
    className="modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
    onRequestClose={() => setModal(MODAL_DISCARD)}
  >
    <section>
      <div className="modal__header">
        <button
          className="button modal__header-button"
          onClick={() => setModal(MODAL_DISCARD)}
          disabled={modal.loading}
        >
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>
      <h2 className="header-1 modal__h2 ut-color-red">Warning</h2>

      <div className="modal__body">
        <p>
          Your unsaved changes will be lost by switching months. Do you want to
          discard your changes?
        </p>
      </div>
      <div className="modal__buttons">
        <button
          className="button button--primary ut-margin-right-1-em"
          onClick={() => setModal(MODAL_DISCARD)}
        >
          Cancel
        </button>
        <button
          className="button button--secondary ut-margin-right-1-em"
          onClick={() => {
            setModal(MODAL_DISCARD);
            getErvsDay({
              args,
              month: modal.period,
              setModal,
              setMonthDetails,
              setMonthSelected,
              handleAlerts,
            });
          }}
        >
          Discard Changes & Switch Month
        </button>
      </div>
    </section>
  </ReactModal>
);

const HospitalSwitchModal = ({
  modal,
  setModal,
  changeHospital,
  selectedHospital,
}) => (
  <ReactModal
    isOpen={modal}
    onRequestClose={() => setModal(!modal)}
    contentLabel="Confirm Submit modal"
    className="modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
  >
    <section>
      <div className="modal__header">
        <button
          className="button modal__header-button"
          onClick={() => setModal(!modal)}
        >
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>
      <h2 className="header-1 modal__h2">Confirm Change Hospital</h2>
      <div className="modal__body">
        <p>
          You have unsaved changes that will be lost if you change hospitals. Do
          you wish to contiue?
        </p>
      </div>
      <div className="modal__buttons">
        <button
          className="button button--primary ut-margin-right-1-em"
          onClick={() => changeHospital(selectedHospital)}
        >
          Change Hospital
        </button>
        <button
          className="button button--secondary ut-margin-right-1-em"
          onClick={() => setModal(!modal)}
        >
          Cancel
        </button>
      </div>
    </section>
  </ReactModal>
);

const PaperWorkModal = ({ loading, modal, setModal }) => {
  return (
    <ReactModal
      isOpen={modal}
      contentLabel="Paperwork Modal"
      className="modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <section>
        <div className="modal__header">
          <button
            className="button modal__header-button"
            onClick={() => setModal(!modal)}
            disabled={loading}
          >
            <span className="ut-only-sr">Close Modal</span>
            <span className="fa-icon">&#xf00d;</span>
          </button>
        </div>
        <h2 className="header-1 modal__h2">Paperwork Reduction Act Notice</h2>
        <div className="modal__body">
          <p>
            This collection of information is approved under the Paperwork
            Reduction Act of 1995, OMB Control No. 3041-0029, expiring
            05/31/2025. We ask for this information to obtain timely data on
            consumer product-related injuries treated in a statistically valid
            sample of hospital emergency departments. Such information is made
            available to the public. We do not disclose personally identifiable
            information. Participation in the National Electronic Injury
            Surveillance System is voluntary. you are not required to respond to
            this collection of information unless it displays a valid OMB
            control number. The average response time is approximately 2.5
            minutes per record, including time for reviewing and entering the
            information.
          </p>
        </div>
        <div className="modal__buttons">
          <button
            className="button button--secondary ut-margin-right-1-em"
            onClick={() => setModal(!modal)}
            disabled={loading}
          >
            {loading ? 'loading...' : 'Close'}
          </button>
        </div>
      </section>
    </ReactModal>
  );
};

const SubmitSelectedModal = ({ modal, setModal, submit }) => (
  <ReactModal
    isOpen={modal}
    onRequestClose={() => setModal(!modal)}
    contentLabel="Confirm Submit modal"
    className="modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
  >
    <section>
      <div className="modal__header">
        <button
          className="button modal__header-button"
          onClick={() => setModal(!modal)}
        >
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>
      <h2 className="header-1 modal__h2">Confirm Submit Cases</h2>
      <div className="modal__body">
        <p>
          Are you sure you want to submit selected case(s)? This cannot be
          undone.
        </p>
      </div>
      <div className="modal__buttons">
        <button
          className="button button--primary ut-margin-right-1-em"
          onClick={() => {
            setModal(!modal);
            submit(false);
          }}
        >
          Submit Case(s)
        </button>
        <button
          className="button button--secondary ut-margin-right-1-em"
          onClick={() => setModal(!modal)}
        >
          Cancel
        </button>
      </div>
    </section>
  </ReactModal>
);

const SaveAllOfflineModal = ({ modal, handleModal, saveAll }) => {
  return (
    <ReactModal
      isOpen={modal}
      onRequestClose={handleModal}
      contentLabel="Confirm Submit modal"
      className="modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <section>
        <div className="modal__header">
          <button className="button modal__header-button" onClick={handleModal}>
            <span className="ut-only-sr">Close Modal</span>
            <span className="fa-icon">&#xf00d;</span>
          </button>
        </div>
        <h2 className="header-1 modal__h2">Confirm Submit Cases</h2>
        <div className="modal__body">
          <p>
            Are you sure you want to save all cases created this seesion? This
            cannot be undone.
          </p>
          <p></p>
        </div>
        <div className="modal__buttons">
          <button
            className="button button--primary ut-margin-right-1-em"
            onClick={() => {
              handleModal();
              saveAll();
            }}
          >
            Save All Cases
          </button>
          <button
            className="button button--secondary ut-margin-right-1-em"
            onClick={handleModal}
          >
            Cancel
          </button>
        </div>
      </section>
    </ReactModal>
  );
};

const SubmitAllModal = ({ modal, setModal, submit }) => (
  <ReactModal
    isOpen={modal}
    onRequestClose={() => setModal(!modal)}
    contentLabel="Confirm Submit modal"
    className="modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
  >
    <section>
      <div className="modal__header">
        <button
          className="button modal__header-button"
          onClick={() => setModal(!modal)}
        >
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>
      <h2 className="header-1 modal__h2">Confirm Submit Cases</h2>
      <div className="modal__body">
        <p>
          Are you sure you want to submit all ready to submit cases from the
          results below? This cannot be undone.
        </p>
        <p>
          Out of window cases will not be submitted unless the out of window
          password is entered.
        </p>
      </div>
      <div className="modal__buttons">
        <button
          className="button button--primary ut-margin-right-1-em"
          onClick={() => {
            setModal(!modal);
            submit(true);
          }}
        >
          Submit All Ready Cases
        </button>
        <button
          className="button button--secondary ut-margin-right-1-em"
          onClick={() => setModal(!modal)}
        >
          Cancel
        </button>
      </div>
    </section>
  </ReactModal>
);

const VictimIdAlert = ({ modal, setModal, redirect = false }) => {
  let history = useHistory();
  const closeModal = (e) => {
    setModal((prev) => ({
      ...prev,
      open: false,
    }));
    if (redirect || modal.redirect) history.push('/');
  };
  return (
    <ReactModal
      isOpen={modal.open}
      contentLabel="Status Alert Modal"
      className={`modal ${modal.loading === true ? 'modal--loading' : ''}`}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={closeModal}
    >
      <section>
        <div className="modal__header">
          <button
            className="button modal__header-button"
            onClick={closeModal}
            disabled={modal.loading}
          >
            <span className="ut-only-sr">Close Modal</span>
            <span className="fa-icon">&#xf00d;</span>
          </button>
        </div>
        {modal.error && !modal.loading && (
          <h2 className="header-1 modal__h2 ut-color-red">Error</h2>
        )}
        {!modal.error && !modal.loading && (
          <h2 className="header-1 modal__h2 ut-color-green">Success!</h2>
        )}
        {!modal.error && modal.loading && (
          <h2 className="header-1 modal__h2 ut-color-gray">Loading...</h2>
        )}
        <div className="modal__body">
          <p>{modal.message}</p>
        </div>
        <div className="modal__buttons">
          <button
            className="button button--secondary ut-margin-right-1-em"
            onClick={closeModal}
            disabled={modal.loading}
          >
            Close
          </button>
        </div>
      </section>
    </ReactModal>
  );
};

const VictimIdModal = ({
  args,
  caseId,
  closeModal,
  modal,
  onChange,
  saveVictimId,
  setMainCaseData,
  setModal,
  setUserData,
  toggleModal,
  victimIdData,
}) => {
  return (
    <ReactModal
      isOpen={modal}
      onRequestClose={(e) => toggleModal(e)}
      contentLabel="Confirm delete modal"
      className="modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <section>
        <div className="modal__header">
          <button
            className="button modal__header-button"
            onClick={(e) => toggleModal(e)}
          >
            <span className="ut-only-sr">Close Modal</span>
            <span className="fa-icon">&#xf00d;</span>
          </button>
        </div>
        <h2 className="header-1 modal__h2">Enter Victim ID</h2>
        <form autoComplete="off">
          <div className="modal__body">
            <p className="text-helper">
              Victim, Address, City St., Zip, and a phone number (home or work)
              required.
            </p>
            <label>
              <span className="label ut-indent-large">Victim:</span>
              <input
                type="text"
                name="victim"
                id="victim"
                maxLength={30}
                className="input input--text"
                onChange={(e) => onChange(e)}
                value={victimIdData.victim}
              />
              <span className="text-error">&nbsp;*</span>
            </label>
            <br />
            <label>
              <span className="label ut-indent-large">Parent/Guardian:</span>
              <input
                type="text"
                name="guardian"
                id="guardian"
                maxLength={30}
                className="input input--text"
                onChange={(e) => onChange(e)}
                value={victimIdData.guardian}
              />
            </label>
            <br />
            <label>
              <span className="label ut-indent-large">Address:</span>
              <input
                type="text"
                name="address"
                id="address"
                maxLength={30}
                className="input input--text"
                onChange={(e) => onChange(e)}
                value={victimIdData.address}
              />
              <span className="text-error">&nbsp;*</span>
            </label>
            <br />
            <label>
              <span className="label ut-indent-large">City St:</span>
              <input
                type="text"
                name="city"
                id="city"
                maxLength={32}
                className="input input--text"
                onChange={(e) => onChange(e)}
                value={victimIdData.city}
              />
              <span className="text-error">&nbsp;*</span>
            </label>
            <br />
            <label>
              <span className="label ut-indent-large">Zip:</span>
              <input
                type="text"
                name="zip"
                id="zip"
                maxLength={5}
                className="input input--number ut-align-left"
                onChange={(e) => onChange(e)}
                value={victimIdData.zip}
              />
              <span className="text-error">&nbsp;*</span>
            </label>
            <br />
            <label>
              <span className="label ut-indent-large">Home Phone:</span>
              <MaskedInput
                mask="(111) 111-1111"
                country="US"
                name="homePhone"
                id="homePhone"
                className="input input--number"
                onChange={(e) => onChange(e)}
                value={victimIdData.homePhone}
              />
              <span className="text-error">&nbsp;*</span>
            </label>
            <br />
            <label>
              <span className="label ut-indent-large">Work Phone:</span>
              <MaskedInput
                mask="(111) 111-1111"
                name="workPhone"
                id="workPhone"
                className="input input--number ut-align-left"
                onChange={(e) => onChange(e)}
                value={victimIdData.workPhone}
              />
              <span className="text-error">&nbsp;*</span>
            </label>
            <br />
          </div>
          <div className="modal__buttons">
            <button
              className="button button--primary button--narrow ut-margin-right-1-em"
              type="button"
              onClick={() =>
                saveVictimId({
                  args,
                  caseId,
                  closeModal,
                  isUnobtainable: false,
                  setMainCaseData,
                  setModal,
                  setUserData,
                  toggleModal,
                  victimIdData,
                })
              }
              disabled={victimIdData.submitDisabled}
            >
              Save
            </button>
            <button
              className="button button--secondary button--narrow ut-margin-right-1-em"
              type="button"
              onClick={() =>
                saveVictimId({
                  args,
                  caseId,
                  closeModal,
                  isUnobtainable: true,
                  setMainCaseData,
                  setModal,
                  setUserData,
                  toggleModal,
                  victimIdData,
                })
              }
            >
              Victim Data Unobtainable
            </button>
            <button
              className="button button--secondary  button--narrow ut-margin-right-1-em"
              type="button"
              onClick={(e) => toggleModal(e)}
            >
              Cancel
            </button>
          </div>
        </form>
      </section>
    </ReactModal>
  );
};

const AddSearchModal = ({
  addSavedSearch,
  modal,
  newSearch,
  setNewSearch,
  toggleModal,
}) => (
  <ReactModal
    isOpen={modal}
    onRequestClose={(e) => toggleModal(e)}
    contentLabel="Confirm delete modal"
    className="modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
  >
    <section>
      <div className="modal__header">
        <button
          className="button modal__header-button"
          onClick={(e) => toggleModal(e)}
        >
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>
      <h2 className="header-1 modal__h2">Add Saved Search</h2>
      <form onSubmit={addSavedSearch}>
        <div className="modal__body">
          <p>
            Enter name for saved search
            <i className="text-helper"> (maximum length of 13 characters)</i>.
          </p>
          <label>
            <span className="label">Search Name:</span>
            <input
              type="text"
              name="searchname"
              id="searchname"
              maxLength="13"
              onChange={(e) => setNewSearch(e.target.value)}
            />
          </label>
        </div>
        <div className="modal__buttons">
          <button
            className="button button--primary ut-margin-right-1-em"
            type="submit"
            disabled={newSearch ? false : true}
          >
            Add
          </button>
          <button
            className="button button--secondary ut-margin-right-1-em"
            onClick={(e) => toggleModal(e)}
          >
            Cancel
          </button>
        </div>
      </form>
    </section>
  </ReactModal>
);

const DeleteSearchModal = ({
  deleteSavedSearch,
  disable,
  modal,
  setDisable,
  toggleModal,
  userSearches,
}) => (
  <ReactModal
    isOpen={modal}
    onRequestClose={toggleModal}
    contentLabel="Confirm delete modal"
    className="modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
  >
    <section>
      <div className="modal__header">
        <button className="button modal__header-button" onClick={toggleModal}>
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>
      <h2 className="header-1 modal__h2">Delete Saved Search</h2>
      <form onSubmit={(e) => deleteSavedSearch(e)}>
        <div className="modal__body">
          <p>Select search to delete:</p>
          <ul className="dashboard-delete-search-list">
            {userSearches && userSearches.length > 0 && (
              <>
                {userSearches.map((search) => (
                  <li
                    className="dashboard-delete-search-list__item"
                    key={search.id}
                  >
                    <label>
                      <input
                        type="radio"
                        className="input--radio"
                        value={search.id}
                        name="searches"
                        onClick={() => setDisable(false)}
                      />{' '}
                      <span className="label label--radio">{search.name}</span>
                    </label>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
        <div className="modal__buttons">
          <button
            className="button button--warning ut-margin-right-1-em"
            disabled={disable}
          >
            Delete Selected Search
          </button>
          <button
            className="button button--secondary ut-margin-right-1-em"
            onClick={toggleModal}
          >
            Cancel
          </button>
        </div>
      </form>
    </section>
  </ReactModal>
);

const NewMessageModal = ({
  modal,
  msg,
  handleMessage,
  submit,
  toggleModal,
}) => (
  <ReactModal
    isOpen={modal}
    messageLabel="New Message modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
    onRequestClose={toggleModal}
    className="modal"
  >
    <section>
      <div className="modal__header">
        <button className="button modal__header-button" onClick={toggleModal}>
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>

      <h2 className="header-1 modal__h2 ">Enter New Message</h2>

      <div className="modal__body">
        <label className="label">Message:</label>
        <p className="text-helper">Message limited to 700 characters</p>
        <textarea
          autoFocus
          className="input input--textarea"
          maxLength="700"
          value={msg}
          onChange={(e) => handleMessage(e.target.value)}
        ></textarea>
      </div>
      <div className="modal__buttons">
        <button
          className="button button--primary ut-margin-right-1-em"
          onClick={submit}
          disabled={msg ? false : true}
        >
          Send Message
        </button>
        <button
          className="button button--secondary ut-margin-right-1-em"
          onClick={toggleModal}
        >
          Cancel
        </button>
      </div>
    </section>
  </ReactModal>
);

export {
  MODAL_DEFAULT,
  MODAL_DISCARD,
  modalPresets,
  AddSearchModal,
  AlertModal,
  ClearFormWarningModal,
  DeleteCaseModal,
  DeleteCaseOfflineModal,
  DeleteSearchModal,
  ErvWarningModal,
  HospitalSwitchModal,
  NewMessageModal,
  PaperWorkModal,
  SaveAllOfflineModal,
  SubmitAllModal,
  SubmitSelectedModal,
  VictimIdAlert,
  VictimIdModal,
};
