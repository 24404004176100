import {
  errorMsg,
  setErrorMsg,
  listErrors,
  createList,
} from '../../../Validations';

const validateWorkRelated = ({
  formErrors,
  formData,
  setFormErrors,
  options,
}) => {
  const { employmentStatus } = formData;
  let err;
  let errArr = [];
  const stateList = createList(
    options.specialStudiesNioshWorkerEmploymentState
  );
  const employmentStatusList = createList(
    options.specialStudiesNioshWorkerEmploymentStatus
  );
  Object.keys(formErrors).forEach((key) => {
    switch (key) {
      case 'occupation':
      case 'businessType':
      case 'company':
      case 'employedCity':
      case 'employedState':
      case 'employmentStatus':
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (key === 'employedState' && formData[key]) {
          err = listErrors('employedState', stateList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'employmentStatus' && formData[key]) {
          err = listErrors(
            'employmentStatus',
            employmentStatusList,
            formData[key]
          );
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'employmentStatusSpecify':
        if (employmentStatus === '8 Other' && !formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      default:
        return errArr;
    }
  });
  const filtered = [...new Set(errArr)].filter(
    (err) => err !== undefined && err !== false
  );
  return filtered;
};

export default validateWorkRelated;
