import React from 'react';
import { renderWrapperMainCase } from '../../../../Components/CustomComponents';

const TopSection = ({
  caseId,
  formData,
  formErrors,
  onChange,
  topLeft,
  topRight,
  setFormData,
  setFormErrors,
  showTooltipsCase,
  toggleCaseTooltips,
}) => {
  const args = { caseId, formData, formErrors, onChange, setFormData, setFormErrors };
  return (
    <div className="fieldset flex flex--space-between">
      <div className="ut-relative">
        <button
          type="button"
          className={`button button--circle button-icon button--tooltip button--tooltip-case ${
            showTooltipsCase === true ? 'button--tooltip-show' : ''
          }`}
          onClick={(e) => toggleCaseTooltips(e)}
        >
          <span className="ut-only-sr button--tooltip__not-showing">
            Toggle tooltips off
          </span>
          <span className="button--tooltip__not-showing">i</span>
          <span className="ut-only-sr button--tooltip__showing">
            Toggle tooltips on
          </span>
          <span className="button--tooltip__showing">i</span>
        </button>
      </div>
      <div className="flex flex__child flex__child--fg-1">
        {topLeft.map((inputs) => renderWrapperMainCase({ ...args, inputs }))}
      </div>
      <div className="flex__child flex__child--fg-1 flex__child--flex-end">
        {topRight.map((inputs) => renderWrapperMainCase({ ...args, inputs }))}
      </div>
    </div>
  );
};

export default TopSection;
