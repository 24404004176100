import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CaseDataContext, UserDataContext } from '../../Context';
import { calculateOOW } from '../../Pages/Dashboard/utils';
import { renderError } from './index';

const inputs = [
  {
    className: '',
    id: 'selfHarm',
    label: 'One or more previous episodes of self-harm',
  },
  { className: 'table__row--zebra', id: 'depression', label: 'Depression' },
  { className: '', id: 'bipolarDisorder', label: 'Bipolar disorder' },
  {
    className: 'table__row--zebra',
    id: 'anxiety',
    label: 'Anxiety, panic attacks, post traumatic stress disorder',
  },
  {
    className: '',
    id: 'personalityDisorder',
    label: 'Borderline personality disorder',
  },
  {
    className: 'table__row--zebra',
    id: 'alcoholAbuse',
    label: 'History of alcohol abuse',
  },
  {
    className: '',
    id: 'substanceAbuse',
    label: 'History of other substance(s) abuse',
  },
  {
    className: 'table__row--zebra',
    id: 'psychologicalProblems',
    label: 'Other psychological/psychiatric problems e.g. schizophrenia',
  },
  {
    className: '',
    id: 'riskFactorsOther',
    label:
      'Other specific risk factor(s) (e.g. argument with loved one, abuse or negelct, death of a loved one, illness, money or legal problems, etc.).',
  },
];

const CheckboxRow = ({
  className,
  id,
  formData,
  label,
  onToggle,
  existingCaseId,
  oowValid,
  caseData,
}) => {
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (existingCaseId && !redirected) {
      if (formData[id] !== '') {
        setRedirected(true);
        onToggle(formData[id], id);
      }
    }
  }, [existingCaseId, formData, id, onToggle, redirected]);

  return (
    <tr className={`table__row ${className}`}>
      <td className="table__cell ut-align-left">
        <label htmlFor={id}>{label}</label>
      </td>
      <td className="table__cell">
        <input
          checked={
            formData[id] === 'true' || formData[id] === true ? true : false
          }
          className="input"
          disabled={
            existingCaseId && !oowValid && calculateOOW(caseData.treatmentDate)
              ? true
              : false
          }
          type="checkbox"
          onChange={(e) => onToggle(e, id)}
          id={id}
        />
      </td>
    </tr>
  );
};

const CheckboxTable = ({ formData, formErrors, onChange, onToggle }) => {
  const { existingCaseId } = useParams();
  const [isOow, setIsOowW] = useState(false);
  const [OowChecked, setoowChecked] = useState(false);
  const caseData = useContext(CaseDataContext);
  const { userData } = useContext(UserDataContext);
  const { oowValid } = userData;

  useEffect(() => {
    if (caseData.treatmentDate && existingCaseId && !OowChecked) {
      setIsOowW(calculateOOW(caseData.treatmentDate));
      setoowChecked(true);
    }
  }, [existingCaseId, caseData.treatmentDate, isOow, OowChecked]);

  useEffect(() => {
    if (
      ((formData.psychologicalProblems !== 'true' && formData.riskFactorsOther !== 'true') && 
       (formData.psychologicalProblems !== true && formData.riskFactorsOther !== true)) &&
      formData.riskOtherSpecify
    )
      onChange('', 'riskOtherSpecify');
  }, [formData, onChange]);

  const disableInputCheck = (e) => {
    if (existingCaseId && !oowValid && isOow) {
      return calculateOOW(caseData.treatmentDate);
    }
    if ((formData.riskFactorsOther === 'true' || formData.psychologicalProblems === 'true') || 
        (formData.riskFactorsOther === true || formData.psychologicalProblems === true))
      return false;

    return true;
  };

  return (
    <table className="table table--compact ut-indent-1em ut-max-width-800">
      <thead>
        <tr className="table__head">
          <th className="table__heading  ut-align-left">Risk Factor</th>
          <th className="table__heading">Yes</th>
        </tr>
      </thead>
      <tbody>
        {inputs.map(({ id, className, label }) => (
          <CheckboxRow
            className={className}
            formData={formData}
            key={id}
            id={id}
            label={label}
            onToggle={onToggle}
            existingCaseId={existingCaseId}
            oowValid={oowValid}
            caseData={caseData}
          />
        ))}
        <tr className="table__row" key="riskOtherSpecify">
          <td
            className="input-wrapper input-wrapper--left input-wrapper--risk-other"
            colSpan="2"
          >
            <label className="label" htmlFor="riskOtherSpecify">
              Please specify other risk factor(s):
              <input
                disabled={disableInputCheck()}
                className="input input--large"
                id="riskOtherSpecify"
                maxLength="45"
                onChange={(e) => onChange(e, 'riskOtherSpecify')}
                type="text"
                value={formData.riskOtherSpecify}
              />
              {formErrors.riskOtherSpecify
                ? renderError(formErrors, 'riskOtherSpecify')
                : null}
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CheckboxTable;
