import inputs from './inputs';
import validateMotorVehicle from './validateMotorVehicle';

const DEFAULT_FORM_DATA = {
  localeInfo: '',
  hazardPattern: '',
  inMotion: '',
  enterExitVehicle: '',
  vehiclePosition: '',
  positionCode: '',
  bodyType: '',
  vehicleERRecord: '',
};

export { DEFAULT_FORM_DATA, inputs, validateMotorVehicle };
