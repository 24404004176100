import { NHTSAKeywords } from './keywords';
import { dropdownCodes } from '../../../dropdownCodes';
import { checkActiveDatesHospital } from '../../../utils';
import {
  adverseDrugFormData,
  adverseDrugFormErrors,
  assaultFormData,
  assaultFormErrors,
  childPoisoningFormData,
  childPoisoningFormErrors,
  firearmInjuryFormData,
  firearmInjuryFormErrors,
  motorVehicleFormData,
  motorVehicleFormErrors,
  selfInflictedFormData,
  selfInflictedFormErrors,
  workRelatedFormData,
  workRelatedFormErrors,
} from '../formData';
const { diag49, diag68, dis8, intent0, intent1, intent2, work1 } =
  dropdownCodes;

const addRemoveTabs = (
  check,
  formData,
  formErrors,
  formList,
  id,
  label,
  setFormData,
  setFormErrors
) => {
  let list = formList;
  if (formList) {
    if (check) {
      if (!findFormList(formList, id)) {
        list = [...formList, { label, id }];
        return list;
      }
    }
    if (!check) {
      if (findFormList(formList, id)) {
        list = removeFormList(formList, id);
        setFormData(formData);
        setFormErrors(formErrors);
        return list;
      }
    }
  }
  return list;
};

const findFormList = (list, query) => {
  return list.find(({ id }) => id === query);
};

const removeFormList = (list, query) => {
  return list.filter(({ id }) => id !== query);
};

const adverseDrugCheck = (
  intent,
  product1,
  product2,
  product3,
  activeDates,
  treatmentDate
) => {
  if (!checkActiveDatesHospital(activeDates, treatmentDate)) return false;
  if (intent && (product1 || product2 || product3)) {
    if (
      (product1.startsWith('1915') ||
        product1.startsWith('1916') ||
        product1.startsWith('1923') ||
        product1.startsWith('1927') ||
        product1.startsWith('1928') ||
        product1.startsWith('1929') ||
        product1.startsWith('1930') ||
        product1.startsWith('1931') ||
        product1.startsWith('1932') ||
        product2.startsWith('1915') ||
        product2.startsWith('1916') ||
        product2.startsWith('1923') ||
        product2.startsWith('1927') ||
        product2.startsWith('1928') ||
        product2.startsWith('1929') ||
        product2.startsWith('1930') ||
        product2.startsWith('1931') ||
        product2.startsWith('1932') ||
        product3.startsWith('1915') ||
        product3.startsWith('1916') ||
        product3.startsWith('1923') ||
        product3.startsWith('1927') ||
        product3.startsWith('1928') ||
        product3.startsWith('1929') ||
        product3.startsWith('1930') ||
        product3.startsWith('1931') ||
        product3.startsWith('1932')) &&
      (intent === intent0 || intent === intent1 || intent === intent2)
    ) {
      return true;
    }
  }
  return false;
};

const assaultCheck = (intent, activeDates, treatmentDate) => {
  if (!checkActiveDatesHospital(activeDates, treatmentDate)) return false;
  return intent === intent1 ? true : false;
};

const childPoisoningCheck = (
  age,
  diagnosis1,
  diagnosis2,
  activeDates,
  treatmentDate
) => {
  if (!checkActiveDatesHospital(activeDates, treatmentDate)) return false;
  if (parseInt(age) === 0) return false;
  if (age && (diagnosis1 || diagnosis2)) {
    if (age < 5 || (age >= 201 && age <= 223)) {
      if (
        diagnosis1 === diag68 ||
        diagnosis2 === diag68 ||
        diagnosis1 === diag49 ||
        diagnosis2 === diag49
      ) {
        return true;
      }
    }
  }
  return false;
};

const firearmCheck = (
  product1,
  product2,
  product3,
  activeDates,
  treatmentDate
) => {
  if (!checkActiveDatesHospital(activeDates, treatmentDate)) return false;
  if (product1 || product2 || product3) {
    if (
      product1.startsWith('1619') ||
      product1.startsWith('1237') ||
      product1.startsWith('1935') ||
      product1.startsWith('1936') ||
      product1.startsWith('3224') ||
      product1.startsWith('3253') ||
      product2.startsWith('1619') ||
      product2.startsWith('1237') ||
      product2.startsWith('1935') ||
      product2.startsWith('1936') ||
      product2.startsWith('3224') ||
      product2.startsWith('3253') ||
      product3.startsWith('1619') ||
      product3.startsWith('1237') ||
      product3.startsWith('1935') ||
      product3.startsWith('1936') ||
      product3.startsWith('3224') ||
      product3.startsWith('3253')
    ) {
      return true;
    }
  }
  return false;
};

const motorVehicleCheck = (
  comment,
  disposition,
  intent,
  product1,
  product2,
  product3,
  activeDates,
  treatmentDate
) => {
  if (!checkActiveDatesHospital(activeDates, treatmentDate)) return false;
  const check = NHTSAKeywords.some((char) => {
    if (comment.toLowerCase().includes(char.toLowerCase())) {
      if (comment[0] === '^') {
        return false;
      }
      return true;
    }
    return false;
  });
  if (
    (product1.startsWith('1901') ||
      product2.startsWith('1901') ||
      product3.startsWith('1901') ||
      product1.startsWith('1519') ||
      product2.startsWith('1519') ||
      product3.startsWith('1519')) &&
    !product1.startsWith('3224') &&
    !product2.startsWith('3224') &&
    !product3.startsWith('3224') &&
    !product1.startsWith('3253') &&
    !product2.startsWith('3253') &&
    !product3.startsWith('3253') &&
    intent === intent0 &&
    disposition !== dis8 &&
    !check
  ) {
    return true;
  }
  return false;
};

const selfInflictedCheck = (intent, activeDates, treatmentDate) => {
  if (!checkActiveDatesHospital(activeDates, treatmentDate)) return false;
  return intent === intent2 ? true : false;
};

const workRelatedCheck = (workRelated, activeDates, treatmentDate) => {
  if (!checkActiveDatesHospital(activeDates, treatmentDate)) return false;
  const treatDateString = new Date(treatmentDate).toISOString();
  const NIOSHExpiredDateString = new Date('2024-01-01').toISOString();

  if (treatDateString < NIOSHExpiredDateString) {
    return workRelated === work1 ? true : false;
  }
};

const runAllChecks = ({
  formList,
  hospital,
  mainCaseData,
  setFormData,
  setFormErrors,
  setFormList,
  treatmentDate,
}) => {
  const { specialStudiesActiveDates } = hospital;
  const {
    setAdverseDrugData,
    setAssaultData,
    setChildPoisoningData,
    setFirearmInjuryData,
    setMotorVehicleData,
    setSelfInflictedData,
    setWorkRelatedData,
  } = setFormData;
  const {
    setAdverseDrugErrors,
    setAssaultErrors,
    setChildPoisoningErrors,
    setFirearmInjuryErrors,
    setMotorVehicleErrors,
    setSelfInflictedErrors,
    setWorkRelatedErrors,
  } = setFormErrors;
  const {
    age,
    comment,
    diagnosis1,
    diagnosis2,
    disposition,
    intent,
    product1,
    product2,
    product3,
    workRelated,
  } = mainCaseData;
  let list = formList;
  let listDiff = [];
  const resetIds = {};
  let check;
  specialStudiesActiveDates.forEach(({ specialStudyType, activeDates }) => {
    switch (specialStudyType) {
      case 'Firearms Injury':
        check = firearmCheck(
          product1,
          product2,
          product3,
          activeDates,
          treatmentDate
        );
        list = addRemoveTabs(
          check,
          firearmInjuryFormData,
          firearmInjuryFormErrors,
          list,
          'firearmInjury',
          'Firearm Injury',
          setFirearmInjuryData,
          setFirearmInjuryErrors
        );
        if (!check) {
          resetIds.firearmInjury = '';
          listDiff.push('firearm injuries');
        }
        break;
      case 'NIOSH Work-Related Injury':
        check = workRelatedCheck(workRelated, activeDates, treatmentDate);
        list = addRemoveTabs(
          check,
          workRelatedFormData,
          workRelatedFormErrors,
          list,
          'workRelated',
          'Work Related',
          setWorkRelatedData,
          setWorkRelatedErrors
        );
        if (!check) {
          resetIds.workRelated = '';
          listDiff.push('NIOSH work injuries');
        }
        break;
      case "Children's Poisoning":
        check = childPoisoningCheck(
          age,
          diagnosis1,
          diagnosis2,
          activeDates,
          treatmentDate
        );
        list = addRemoveTabs(
          check,
          childPoisoningFormData,
          childPoisoningFormErrors,
          list,
          'childPoisoning',
          'Child Poisoning',
          setChildPoisoningData,
          setChildPoisoningErrors
        );
        if (!check) {
          resetIds.childPoisoning = '';
          listDiff.push("children's poisoning");
        }
        break;
      case 'Self-Inflicted Injury':
        check = selfInflictedCheck(intent, activeDates, treatmentDate);
        list = addRemoveTabs(
          check,
          selfInflictedFormData,
          selfInflictedFormErrors,
          list,
          'selfInflicted',
          'Self Inflicted',
          setSelfInflictedData,
          setSelfInflictedErrors
        );
        if (!check) {
          resetIds.selfInflicted = '';
          listDiff.push('self-inflicted injuries');
        }
        break;
      case 'Adverse Drug Events':
        check = adverseDrugCheck(
          intent,
          product1,
          product2,
          product3,
          activeDates,
          treatmentDate
        );
        list = addRemoveTabs(
          check,
          adverseDrugFormData,
          adverseDrugFormErrors,
          list,
          'adverseDrug',
          'Adverse Drug Event',
          setAdverseDrugData,
          setAdverseDrugErrors
        );
        if (!check) {
          resetIds.adverseDrug = '';
          listDiff.push('adverse drug event');
        }
        break;
      case 'Assault Injury':
        check = assaultCheck(intent, activeDates, treatmentDate);
        list = addRemoveTabs(
          check,
          assaultFormData,
          assaultFormErrors,
          list,
          'assault',
          'Assault',
          setAssaultData,
          setAssaultErrors
        );
        if (!check) {
          resetIds.assault = '';
          listDiff.push('assaults');
        }
        break;
      case 'NHTSA MV Non Crash Injury':
        check = motorVehicleCheck(
          comment,
          disposition,
          intent,
          product1,
          product2,
          product3,
          activeDates,
          treatmentDate
        );
        list = addRemoveTabs(
          check,
          motorVehicleFormData,
          motorVehicleFormErrors,
          list,
          'motorVehicle',
          'NHTSA Motor Vehicle',
          setMotorVehicleData,
          setMotorVehicleErrors
        );
        if (!check) {
          resetIds.motorVehicle = '';
          listDiff.push('NHTSA non-crash motor vehicle injuries');
        }
        break;
      default:
        return list;
    }
  });
  setFormList([...list]);
  return { list, listDiff, resetIds };
};

export { runAllChecks };
