import Dexie from 'dexie';
import { modalPresets } from './Components/CustomComponents/Modals';

export const db = new Dexie('myDatabase');
db.version(1).stores({
  cases: 'caseNumber',
});

export const saveToDb = async ({
  caseNumber,
  caseData,
  setButtonType,
  setUnsavedChanges,
  handleAlerts,
}) => {
  try {
    await db.cases.put(caseData, caseNumber);
    handleAlerts(modalPresets('The case was saved.').success);
    setUnsavedChanges(false);
    setButtonType('continue');
  } catch (err) {
    handleAlerts(
      modalPresets(`Failed to save case ${caseNumber}. ${err}`).error
    );
  }
};
