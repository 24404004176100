import {
  errorMsg,
  createList,
  dateErrors,
  invalidCharErrors,
  listErrors,
  setErrorMsg,
  setWarningMsg,
} from '../../../Validations';
import { fireDepKeyWords, fireKeyWords } from '../../../Validations/keywords';
import { dropdownCodes } from '../../../../../dropdownCodes';

const {
  diag41,
  diag42,
  diag49,
  diag52,
  diag65,
  diag67,
  diag68,
  diag69,
  diag71,
  body0,
  body75,
  body85,
  dis8,
  dis9,
  fire1,
  fire2,
  fire3,
  fire0,
  intent0,
  intent3,
  loc1,
  neiss,
  race3,
  work0,
  work2,
} = dropdownCodes;

const ageErrors = (value) => {
  if (!value) return errorMsg.required.basic;
  const age = parseInt(value);
  if (age === 0) return false;
  if (age >= 2 && age <= 115) return false;
  if (age >= 201 && age <= 223) return false;
  return errorMsg.age;
};

const bodyPartErrors = (bodyPart, diagnosis) => {
  if ((diagnosis === diag41 || diagnosis === diag42) && bodyPart !== body0)
    return errorMsg.bodyPartIncorrectValue;
  if (diagnosis === diag52 && bodyPart !== body75)
    return errorMsg.bodyPartIncorrectValue;
  if (
    (diagnosis === diag65 ||
      diagnosis === diag67 ||
      diagnosis === diag68 ||
      diagnosis === diag69) &&
    bodyPart !== body85
  )
    return errorMsg.bodyPartIncorrectValue;
};

const caseNumberErrors = (value) => {
  if (!value) {
    return errorMsg.required.basic;
  }
  return false;
};

const commentErrors = (value) => {
  if (!value) return errorMsg.required.basic;
  const str = value.toLowerCase();
  const err = invalidCharErrors(str);
  if (err) return err;
  if (!str.includes('dx')) return errorMsg.commentDX;
  return false;
};

const commentWarnings = (value) => {
  const str = value.toLowerCase();
  if (str.length < 25) return errorMsg.commentShort;
  return false;
};

const diagnosisErrors = (
  bodyPart1,
  bodyPart2,
  diagnosis1,
  diagnosis2,
  diagnosisOther2,
  key,
  list
) => {
  let err;
  if (key === 'diagnosis1') {
    err = listErrors('diagnosis', list, diagnosis1);
    if (err) return err;
  }
  if (key === 'diagnosis2' && diagnosis2) {
    err = listErrors('diagnosis', list, diagnosis2);
    if (err) return err;
  }
  if (key === 'bodyPart1') {
    err = listErrors('bodyPart', list, bodyPart1);
    if (err) return err;
    err = bodyPartErrors(bodyPart1, diagnosis1);
    if (err) return err;
  }
  if (key === 'bodyPart2') {
    if (!bodyPart2 && (diagnosis2 || diagnosisOther2)) {
      return errorMsg.required.basic;
    }
    if (bodyPart2) {
      err = listErrors('bodyPart', list, bodyPart2);
      if (err) return err;
      err = bodyPartErrors(bodyPart2, diagnosis2);
      if (err) return err;
    }
  }
  if (diagnosis1 && diagnosis2 && bodyPart1 && bodyPart2) {
    if (diagnosis1 === diagnosis2 && bodyPart1 === bodyPart2) {
      return errorMsg.diagnosisDuplicate;
    } else {
      return false;
    }
  }
};

const diagnosisOtherErrors = (diagnosis, value) => {
  if (!value && diagnosis === diag71) return errorMsg.required.basic;
  const err = invalidCharErrors(value);
  if (err) return err;
  return false;
};

const dispositionErrors = (list, value) => {
  const err = listErrors('disposition', list, value);
  if (err) return err;
  return false;
};

const dispositionWarnings = (value) => {
  if (value === dis9) return errorMsg.disposition8Warning;
  if (value === dis8) return errorMsg.disposition8Warning;
  return false;
};

const fireErrors = (
  commentStr,
  fireKeyWords,
  list,
  product1,
  product2,
  product3,
  value
) => {
  let check;
  const comment = commentStr ? commentStr.toLowerCase() : '';
  const err = listErrors('fire', list, value);
  if (err) return err;
  if (value === fire0) {
    if (
      product1.startsWith('1866') ||
      product2.startsWith('1866') ||
      product3.startsWith('1866')
    ) {
      return errorMsg.fire1866;
    }
    if (
      product1.startsWith('1313') ||
      product2.startsWith('1313') ||
      product3.startsWith('1313')
    ) {
      check = fireKeyWords.some((char) => {
        if (comment.includes(char)) return true;
        return false;
      });
      return check ? errorMsg.fire1313 : false;
    }
  }
};

const fireWarnings = (value, comment, fireDepKeyWords) => {
  let check;
  if (value === fire1 || value === fire2 || value === fire3) {
    check = fireDepKeyWords.some((char) => {
      if (comment.includes(char)) return true;
      return false;
    });
    return !check ? errorMsg.fireComment : false;
  }
};

const intentErrors = (
  age,
  hospitalType,
  key,
  list,
  product1,
  product2,
  product3,
  value
) => {
  const err = listErrors(key, list, value);
  if (err) return err;
  if (
    hospitalType === neiss &&
    age < 13 &&
    value === intent3 &&
    !product1.startsWith('1237') &&
    !product1.startsWith('1935') &&
    !product1.startsWith('1936') &&
    !product1.startsWith('3224') &&
    !product1.startsWith('3253') &&
    !product2.startsWith('1237') &&
    !product2.startsWith('1935') &&
    !product2.startsWith('1936') &&
    !product2.startsWith('3224') &&
    !product2.startsWith('3253') &&
    !product3.startsWith('1237') &&
    !product3.startsWith('1935') &&
    !product3.startsWith('1936') &&
    !product3.startsWith('3224') &&
    !product3.startsWith('3253')
  ) {
    return errorMsg.intentAgeBelow;
  }
  if (
    hospitalType === neiss &&
    age >= 13 &&
    value !== intent0 &&
    !product1.startsWith('1237') &&
    !product1.startsWith('1935') &&
    !product1.startsWith('1936') &&
    !product1.startsWith('3224') &&
    !product1.startsWith('3253') &&
    !product2.startsWith('1237') &&
    !product2.startsWith('1935') &&
    !product2.startsWith('1936') &&
    !product2.startsWith('3224') &&
    !product2.startsWith('3253') &&
    !product3.startsWith('1237') &&
    !product3.startsWith('1935') &&
    !product3.startsWith('1936') &&
    !product3.startsWith('3224') &&
    !product3.startsWith('3253')
  ) {
    return errorMsg.intentAgeAbove;
  }
};

const productErrors = (
  age,
  diagnosis1,
  diagnosis2,
  hospitalType,
  key,
  list,
  productOther1,
  productOther2,
  value
) => {
  if (key === 'product1') {
    const err = listErrors('product', list, value);
    if (err) return err;
    if (value === productOther1 || value === productOther2) {
      return errorMsg.productDuplicate;
    }
  } else if (
    (key === 'product2' && !value.startsWith('0000')) ||
    (key === 'product3' && !value.startsWith('0000'))
  ) {
    if (value) {
      if (value.startsWith('7878')) {
        return 'This is an invalid product code. Please re-enter.';
      }
      const err = listErrors('product', list, value);
      if (err) return err;
      if (!productOther2.startsWith('0000')) {
        if (value === productOther1 || value === productOther2) {
          return errorMsg.productDuplicate;
        }
      }
    }
    if (
      key === 'product3' &&
      !value.startsWith('0000') &&
      productOther2.startsWith('0000')
    ) {
      return 'Must be product code 0000';
    }
  }
  if (
    (age < 5 || (age >= 201 && age <= 223)) &&
    value.startsWith('7878') &&
    (diagnosis1 === diag49 ||
      diagnosis2 === diag49 ||
      diagnosis1 === diag68 ||
      diagnosis2 === diag68)
  ) {
    return errorMsg.productUse5555;
  }
  if (
    value.startsWith('1519') &&
    (productOther1.startsWith('1901') || productOther2.startsWith('1901'))
  ) {
    return errorMsg.product1519;
  }
  if (
    value.startsWith('1901') &&
    (productOther1.startsWith('1519') || productOther2.startsWith('1519'))
  ) {
    return errorMsg.product1519;
  }
  if (
    (age <= 201 || age >= 223) &&
    age >= 6 &&
    (diagnosis1 !== diag49 ||
      diagnosis1 !== diag68 ||
      diagnosis2 !== diag49 ||
      diagnosis2 !== diag68) &&
    value.startsWith('5555')
  ) {
    return errorMsg.productDontUse5555;
  }
  if (
    diagnosis1 !== diag49 &&
    diagnosis1 !== diag68 &&
    diagnosis2 !== diag49 &&
    diagnosis2 !== diag68 &&
    value.startsWith('5555')
  ) {
    return 'diagnosis must be 49 or 68';
  }
  if (
    age < 5 &&
    hospitalType === neiss &&
    (diagnosis1 === diag49 ||
      diagnosis1 === diag68 ||
      diagnosis2 === diag49 ||
      diagnosis2 === diag68) &&
    (value.startsWith('1913') ||
      value.startsWith('1914') ||
      value.startsWith('1915') ||
      value.startsWith('1916') ||
      value.startsWith('1923') ||
      value.startsWith('1927') ||
      value.startsWith('1928') ||
      value.startsWith('1929') ||
      value.startsWith('1930') ||
      value.startsWith('1931') ||
      value.startsWith('1932') ||
      value.startsWith('2640'))
  ) {
    return errorMsg.nonTrauma;
  }
  return false;
};

const productWarnings = (
  age,
  value,
  productOther1,
  productOther2,
  location,
  inScope
) => {
  if (value.startsWith('0878')) return errorMsg.product0878;
  if (value.startsWith('1866') && inScope) return errorMsg.product1866Warning;
  if (value.startsWith('1270') && location === loc1)
    return errorMsg.product1270Warning;
  if (value.startsWith('3282')) return errorMsg.product3282Warning;
  if (value.startsWith('3260')) return errorMsg.product3260Warning;
  if (age > 10 && age < 116 && value.startsWith('1535')) {
    return errorMsg.product1535Warning;
  }
  return false;
};

const raceErrors = (race, value) => {
  if (value && race !== race3) return errorMsg.blank;
  if (!value && race === race3) return errorMsg.required.basic;
  const err = invalidCharErrors(value);
  if (err) return err;
};

const workRelatedErrors = (
  hospitalType,
  key,
  list,
  product1,
  product2,
  product3,
  value
) => {
  const err = listErrors(key, list, value);
  if (err) return err;
  if (
    hospitalType === neiss &&
    value !== work0 &&
    value !== work2 &&
    !product1.startsWith('1237') &&
    !product1.startsWith('1935') &&
    !product1.startsWith('1936') &&
    !product1.startsWith('3224') &&
    !product1.startsWith('3253') &&
    !product2.startsWith('1237') &&
    !product2.startsWith('1935') &&
    !product2.startsWith('1936') &&
    !product2.startsWith('3224') &&
    !product2.startsWith('3253') &&
    !product3.startsWith('1237') &&
    !product3.startsWith('1935') &&
    !product3.startsWith('1936') &&
    !product3.startsWith('3224') &&
    !product3.startsWith('3253')
  ) {
    return errorMsg.workRelatedNonTrauma;
  }
  return false;
};

const productsInScope = (products, options) => {
  return products.some((product) => {
    return options.find((option) => {
      const code = product.substring(0, 4);
      if (code !== '1866' && code === option.code) {
        return option.inScope;
      }
      return false;
    });
  });
};

const validateMainCase = ({
  formErrors,
  formData,
  hospitalType,
  hospital,
  oowValid,
  setFormErrors,
  setFormWarnings,
  options,
}) => {
  const {
    caseAlcoholDrugsMedicationYesNoNotRecorded,
    caseBodyPart,
    caseDiagnosis,
    caseDisposition,
    caseFire,
    caseGender,
    caseIntent,
    caseLocale,
    caseProduct,
    caseRace,
    caseWorkRelated,
  } = options;

  const {
    treatmentDate,
    caseNumber,
    collectionDate,
    comment,
    dateOfBirth,
    age,
    sex,
    diagnosis1,
    diagnosisOther1,
    bodyPart1,
    diagnosis2,
    diagnosisOther2,
    bodyPart2,
    disposition,
    product1,
    product2,
    product3,
    alcohol,
    drugsMedication,
    intent,
    location,
    fire,
    workRelated,
    raceHispanic,
    race,
    raceOther,
  } = formData;
  let err;
  let errArr = [];
  let warn;
  const yesNoList = createList(caseAlcoholDrugsMedicationYesNoNotRecorded);
  const bodyPartList = createList(caseBodyPart);
  const diagnosisList = createList(caseDiagnosis);
  const dispositionList = createList(caseDisposition);
  const fireList = createList(caseFire);
  const genderList = createList(caseGender);
  const intentList = createList(caseIntent);
  const locationList = createList(caseLocale);
  const productList = createList(caseProduct);
  const raceList = createList(caseRace);
  const workRelatedList = createList(caseWorkRelated);
  const inScope = productsInScope([product1, product2, product3], caseProduct);
  Object.keys(formErrors).forEach((key) => {
    switch (key) {
      case 'treatmentDate':
        err = dateErrors(collectionDate, key, null, treatmentDate, oowValid, hospital?.lastUpdateableDate);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'caseNumber':
        err = caseNumberErrors(caseNumber);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'collectionDate':
        return false;
      case 'comment':
        warn = commentWarnings(comment);
        setWarningMsg(key, warn, setFormWarnings);
        err = commentErrors(comment);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'dateOfBirth':
        err = dateErrors(
          collectionDate,
          key,
          treatmentDate,
          dateOfBirth,
          false
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'age':
        err = ageErrors(age);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'sex':
        err = listErrors('sex', genderList, sex);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'diagnosis1':
      case 'diagnosis2':
        err = diagnosisErrors(
          bodyPart1,
          bodyPart2,
          diagnosis1,
          diagnosis2,
          null,
          key,
          diagnosisList
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'diagnosisOther1':
        err = diagnosisOtherErrors(diagnosis1, diagnosisOther1);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'bodyPart1':
        err = diagnosisErrors(
          bodyPart1,
          bodyPart2,
          diagnosis1,
          diagnosis2,
          null,
          key,
          bodyPartList
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'diagnosisOther2':
        err = diagnosisOtherErrors(diagnosis2, diagnosisOther2);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'bodyPart2':
        err = diagnosisErrors(
          bodyPart1,
          bodyPart2,
          diagnosis1,
          diagnosis2,
          diagnosisOther2,
          key,
          bodyPartList
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'disposition':
        err = dispositionErrors(dispositionList, disposition);
        warn = dispositionWarnings(disposition);
        errArr.push(err);
        setWarningMsg(key, warn, setFormWarnings);
        return setErrorMsg(key, err, setFormErrors);
      case 'product1':
        err = productErrors(
          age,
          diagnosis1,
          diagnosis2,
          hospitalType,
          key,
          productList,
          product2,
          product3,
          product1
        );
        warn = productWarnings(
          age,
          product1,
          product2,
          product3,
          location,
          inScope
        );
        errArr.push(err);
        setWarningMsg(key, warn, setFormWarnings);
        return setErrorMsg(key, err, setFormErrors);
      case 'product2':
        err = productErrors(
          age,
          diagnosis1,
          diagnosis2,
          hospitalType,
          key,
          productList,
          location,
          product1,
          product3,
          product2
        );
        warn = productWarnings(
          age,
          product2,
          product1,
          product3,
          location,
          inScope
        );
        errArr.push(err);
        setWarningMsg(key, warn, setFormWarnings);
        return setErrorMsg(key, err, setFormErrors);
      case 'product3':
        err = productErrors(
          age,
          diagnosis1,
          diagnosis2,
          hospitalType,
          key,
          productList,
          product1,
          product2,
          product3
        );
        warn = productWarnings(
          age,
          product3,
          product2,
          product1,
          location,
          inScope
        );
        errArr.push(err);
        setWarningMsg(key, warn, setFormWarnings);
        return setErrorMsg(key, err, setFormErrors);
      case 'alcohol':
        if (hospitalType === 'NEISS-AIP') {
          return false;
        }
        err = listErrors('alcohol', yesNoList, alcohol);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'drugsMedication':
        if (hospitalType === 'NEISS-AIP') {
          return false;
        }
        err = listErrors('drugsMedication', yesNoList, drugsMedication);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'intent':
        err = intentErrors(
          age,
          hospitalType,
          'intent',
          intentList,
          product1,
          product2,
          product3,
          intent
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'location':
        err = listErrors('location', locationList, location);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'fire':
        err = fireErrors(
          comment,
          fireKeyWords,
          fireList,
          product1,
          product2,
          product3,
          fire
        );
        warn = fireWarnings(fire, comment, fireDepKeyWords);
        setWarningMsg(key, warn, setFormWarnings);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'workRelated':
        err = workRelatedErrors(
          hospitalType,
          'workRelated',
          workRelatedList,
          product1,
          product2,
          product3,
          workRelated
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'raceHispanic':
        err = listErrors('raceHispanic', yesNoList, raceHispanic);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'race':
        err = listErrors('race', raceList, race);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'raceOther':
        err = raceErrors(race, raceOther);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      default:
        return errArr;
    }
  });
  const filtered = [...new Set(errArr)].filter(
    (err) => err !== undefined && err !== false
  );
  return filtered;
};

export default validateMainCase;
