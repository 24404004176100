import React, { useState, useEffect, useContext } from 'react';
import { Prompt } from 'react-router';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { DropdownOptionsContext, UserDataContext } from './Context';
import { Footer, Header } from './Components';
import { Accounting, CaseForm, Dashboard, Ervs, Messages } from './Pages';
import { PaperWorkModal } from './Components/CustomComponents/Modals';
import { request } from './api';
import './App.css';

const TAB_LIST = [
  {
    id: 'mainCase',
    label: 'NEISS Case',
  },
];

const Pages = () => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [paperworkActModal, setPaperworkActModal] = useState(false);
  const [versionNumber, setVersionNumber] = useState('');
  const { userData } = useContext(UserDataContext);
  const dropdownOptions = useContext(DropdownOptionsContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const inLocalStorage = window.localStorage.getItem('paperworkModal');
      if (inLocalStorage === null || inLocalStorage === false) {
        window.localStorage.setItem('paperworkModal', true);
        setPaperworkActModal(true);
      }
    }
  }, []);

  const caseProps = {
    current: 'mainCase',
    setUnsavedChanges,
    tabList: TAB_LIST,
  };

  useEffect(() => {
    request({ def: 'config' }).then((config) =>
      setVersionNumber(config.RELEASE_NUMBER)
    );
  }, []);

  return (
    <>
      {paperworkActModal ? (
        <PaperWorkModal
          loading={!userData && !dropdownOptions}
          modal={paperworkActModal}
          setModal={setPaperworkActModal}
        />
      ) : userData && dropdownOptions ? (
        <>
          <Prompt
            message={() => {
              setUnsavedChanges(false);
              return 'You have unsaved changes that will be lost if you change pages. Do you wish to continue?';
            }}
            when={unsavedChanges}
          />
          <Header
            unsavedChanges={unsavedChanges}
            displayVersionNumber={versionNumber}
          />
          <main id="main" className="main">
            <Switch>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route exact path="/newCase">
                <CaseForm {...caseProps} />
              </Route>
              <Route exact path="/newCase/:existingCaseId">
                <CaseForm {...caseProps} />
              </Route>
              <Route exact path="/messages">
                <Messages />
              </Route>
              <Route exact path="/accounting">
                <Accounting />
              </Route>
              <Route exact path="/ervs">
                <Ervs setUnsavedChanges={setUnsavedChanges} />
              </Route>
            </Switch>
          </main>
          <Footer displayVersionNumber={versionNumber} />
        </>
      ) : null}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <div className="app">
        <Pages />
      </div>
    </Router>
  );
};

export default App;
