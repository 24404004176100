import {
  createList,
  listErrors,
  errorMsg,
  setErrorMsg,
} from '../../../Validations';
import { dropdownCodes } from '../../../../../dropdownCodes';
const { childYes, basicNo } = dropdownCodes;

const childPoisoningErrors = (key, listOptions, preReq, value) => {
  if (preReq == null && listOptions) {
    if (!value) return errorMsg.required.basic;
    return listErrors(key, listOptions, value);
  }
  if (preReq === childYes && !value) return errorMsg.required[key];
  if (preReq === childYes && value && listOptions)
    return listErrors(key, listOptions, value);
  return false;
};

const validateChildPoisoning = ({
  formErrors,
  formData,
  setFormErrors,
  options,
}) => {
  const {
    contactedOther,
    anySymptoms,
    otherSymptoms,
    edTreatments,
    otherTreatment,
  } = formData;
  let err;
  let errArr = [];
  const yesNoList = createList(
    options.specialStudiesChildrensPoisoningYesNoNotStated
  );
  const containerList = createList(
    options.specialStudiesChildrensPoisoningContainer
  );
  Object.keys(formErrors).forEach((key) => {
    switch (key) {
      case 'contactedPriorED':
      case 'contactedDuringED':
      case 'contactedOther':
      case 'anySymptoms':
      case 'edTreatments':
      case 'crcPresent':
      case 'drugProvided':
        err = childPoisoningErrors(key, yesNoList, null, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'contactedOtherSpecify':
        err = childPoisoningErrors(key, null, contactedOther, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'nauseaVomiting':
      case 'dizziness':
      case 'drowsiness':
      case 'difficultyBreathing':
      case 'headaches':
      case 'abdominalPain':
      case 'otherSymptoms':
        err = childPoisoningErrors(key, yesNoList, anySymptoms, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'otherSymptomsSpecify':
        err = childPoisoningErrors(key, null, otherSymptoms, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'vomitingInduced':
      case 'stomachPump':
      case 'charAdmin':
      case 'artRespiration':
      case 'bloodTransfusion':
      case 'otherTreatment':
        err = childPoisoningErrors(key, yesNoList, edTreatments, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'otherTreatmentSpecify':
        err = childPoisoningErrors(key, null, otherTreatment, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'containerDisposition':
        err = childPoisoningErrors(key, containerList, null, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      default:
        return errArr;
    }
  });

if(errorMsg.symptomsQuestions) {
  if(formData.dizziness === basicNo && 
      formData.nauseaVomiting === basicNo && 
      formData.drowsiness === basicNo && 
      formData.difficultyBreathing === basicNo && 
      formData.headaches === basicNo && 
      formData.abdominalPain === basicNo &&
      formData.otherSymptoms === basicNo) {
        return errorMsg.symptomsQuestions
    }
  }
  if(errorMsg.treatmentQuestions) {
    if(formData.vomitingInduced === basicNo && 
      formData.stomachPump === basicNo && 
      formData.charAdmin === basicNo && 
      formData.artRespiration === basicNo && 
      formData.bloodTransfusion === basicNo && 
      formData.otherTreatment === basicNo) {
          return errorMsg.treatmentQuestions
    }
  }
  const filtered = [...new Set(errArr)].filter(
    (err) => err !== undefined && err !== false
  );
  return filtered;
};

export default validateChildPoisoning;
