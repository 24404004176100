import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import {
  AlertModal,
  modalPresets,
} from '../../Components/CustomComponents/Modals';
import { request } from '../../api';

const ReturnedErrorMessages = ({
  args,
  existingCaseId,
  handleReturnedErrors,
  returnedErrorMsg,
}) => {
  const [alertModal, setAlertModal] = useState(modalPresets().default);
  const [errorId, setErrorId] = useState('');
  const [replyMsg, setReplyMsg] = useState('');
  const [replyModal, setReplyModal] = useState(false);

  const handleAlerts = (modalPreset) => {
    setAlertModal(modalPreset);
  };

  const openReplyModal = (id) => {
    setReplyModal(true);
    setErrorId(id);
    setReplyMsg('');
  };

  const closeReplyModal = () => {
    setReplyModal(false);
    setErrorId('');
    setReplyMsg('');
  };

  /*   useEffect(() => {
    if (!existingCaseId && returnedErrorMsg.length >= 1) {
      setReturnedErrorMsg([]);
    }
  }, [existingCaseId, returnedErrorMsg]);
 */

  const sendReply = async () => {
    setReplyModal(false);
    try {
      await request({
        ...args,
        body: { reply: replyMsg },
        caseId: existingCaseId,
        def: 'returnedErrorMsgs',
        handleAlerts,
        method: 'PUT',
        queryString: errorId,
      });
      setReplyMsg('');
      const returnedErrors = await request({
        ...args,
        caseId: existingCaseId,
        def: 'returnedErrorMsgs',
        handleAlerts,
        method: 'GET',
      });
      handleReturnedErrors(returnedErrors);
      handleAlerts(modalPresets('Your reply has been sent.').success);
    } catch (err) {
      console.log('ERROR:', err.message);
    }
  };

  return (
    <>
      {returnedErrorMsg && returnedErrorMsg.length > 0 && existingCaseId && (
        <section className="error-messages">
          <h2 className="ut-color-red">Error Messages</h2>
          <ul className="error-messsages__list">
            {returnedErrorMsg.map((error) => (
              <li className="flex flex--flex-start" key={error.id}>
                <div className="flex__child error-messsages__date">
                  {error.enteredDate}
                </div>
                <div className="flex__child">
                  <div className="error-messsages__type ut-color-red">
                    Error Message
                  </div>
                  {error.analystInitials && <div className="error-messsages__email">{error.analystInitials}</div>}
                  <div className="error-messsages__message">{error.error}</div>
                  {error.status === 'Sent' && (
                    <button
                      className="button button--secondary button--small error-messsages__button"
                      onClick={() => openReplyModal(error.id)}
                    >
                      <span className="fa-icon"></span> Reply
                    </button>
                  )}
                  {error.reply && error.reply.length > 0 && (
                    <div className="error-messsages__message">
                      <b>Reply:</b> {error.reply}
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
      {/* The modal for replying to error */}
      <ReactModal
        isOpen={replyModal}
        contentLabel="Error reply modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        onRequestClose={closeReplyModal}
        className="modal"
      >
        <section>
          <div className="modal__header">
            <button
              className="button modal__header-button"
              onClick={closeReplyModal}
              disabled={replyModal.loading}
            >
              <span className="ut-only-sr">Close Modal</span>
              <span className="fa-icon">&#xf00d;</span>
            </button>
          </div>

          <h2 className="header-1 modal__h2 ">Enter Error Reply</h2>

          <div className="modal__body">
            <label className="label">Reply:</label>
            <p className="text-helper">Reply limited to 200 characters</p>
            <textarea
              autoFocus
              className="input input--textarea"
              maxLength="200"
              value={replyMsg}
              onChange={(e) => setReplyMsg(e.target.value)}
            ></textarea>
          </div>
          <div className="modal__buttons">
            <button
              className="button button--primary ut-margin-right-1-em"
              onClick={sendReply}
              disabled={replyMsg ? false : true}
            >
              Send Reply
            </button>
            <button
              className="button button--secondary ut-margin-right-1-em"
              onClick={closeReplyModal}
              disabled={replyModal.loading}
            >
              Cancel
            </button>
          </div>
        </section>
      </ReactModal>
      <AlertModal
        focus="victimIdBtn"
        closeModal={setAlertModal}
        modal={alertModal}
      />
    </>
  );
};

export default ReturnedErrorMessages;
