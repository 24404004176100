const renderError = (formErrors, key) => (
  <span
    className="input-error-message"
    aria-live="polite"
    data-testid={`${key}-err`}
  >
    {formErrors[key]}
  </span>
);

const renderWarning = (formWarnings, key) => (
  <span className="input-warning-message" data-testid={`${key}-warn`}>
    Warning: {formWarnings[key]}
  </span>
);

export { renderError, renderWarning };
