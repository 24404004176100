import React, { useState } from 'react';
import { inputs } from './utils';
import { renderWrapper } from '../../../../Components/CustomComponents';
import { dropdownCodes } from '../../../../dropdownCodes';
import { errorMsg } from '../../Validations';

const { basicYes, basicNo } = dropdownCodes;

const ChildPoisoning = (props) => {
  const {
    currentForm,
    formData,
    formErrors,
    setButtonType,
    setFormData,
    setFormErrors,
    setUnsavedChanges,
  } = props;
  const [touched, setTouched] = useState(false);
  const onChange = (e, name) => {
    setTouched(true);
    setUnsavedChanges(true);
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    value = value.replace(/[^\x00-\x7F]/g, '');
    if (touched) {
      switch (name) {
        case 'anySymptoms':
          if (value !== basicYes)
            setFormData((prevState) => ({
              ...prevState,
              nauseaVomiting: '',
              dizziness: '',
              drowsiness: '',
              difficultyBreathing: '',
              headaches: '',
              abdominalPain: '',
              otherSymptoms: '',
              otherSymptomsSpecify: '',
            }));
          break;
        case 'edTreatments':
          if (value !== basicYes)
            setFormData((prevState) => ({
              ...prevState,
              vomitingInduced: '',
              stomachPump: '',
              charAdmin: '',
              artRespiration: '',
              bloodTransfusion: '',
              otherTreatment: '',
              otherTreatmentSpecify: '',
            }));
          break;
        case 'contactedOther':
          if (value !== basicYes)
            setFormData((prevState) => ({
              ...prevState,
              contactedOtherSpecify: '',
            }));
          break;
        case 'otherSymptoms':
          if (value !== basicYes)
            setFormData((prevState) => ({
              ...prevState,
              otherSymptomsSpecify: '',
            }));
          break;
        case 'otherTreatment':
          if (value !== basicYes)
            setFormData((prevState) => ({
              ...prevState,
              otherTreatmentSpecify: '',
            }));
          break;
        default:
          break;
      }
    }
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };
  return (
    <div
      className="list-tab-contents"
      aria-live="polite"
      aria-hidden={currentForm === 'childPoisoning' ? false : true}
    >
      <h1>Children’s Poisoning Study</h1>
      <p className="text-helper text-helper--no-italics">
        1=yes, 2=no, 0=unknown
      </p>
      <form className="ut-max-width-900">
        {inputs.map((input) => {
          if (input.fieldset) {
            return (
              <fieldset key={input.id}>
                <label className='label'>
                  <span className='input-error-message'>
                    {input.fieldset === 'Symptoms' &&
                      formData.dizziness === basicNo && 
                      formData.nauseaVomiting === basicNo && 
                      formData.drowsiness === basicNo && 
                      formData.difficultyBreathing === basicNo && 
                      formData.headaches === basicNo && 
                      formData.abdominalPain === basicNo &&
                      formData.otherSymptoms === basicNo ? 
                    (
                      setFormErrors(errorMsg.symptomsQuestions),
                      errorMsg.symptomsQuestions
                    ) : ('')}
                    {input.fieldset === 'Treatment' &&
                      formData.vomitingInduced === basicNo && 
                      formData.stomachPump === basicNo && 
                      formData.charAdmin === basicNo && 
                      formData.artRespiration === basicNo && 
                      formData.bloodTransfusion === basicNo && 
                      formData.otherTreatment === basicNo ? 
                    (
                      setFormErrors(errorMsg.treatmentQuestions),
                      errorMsg.treatmentQuestions
                    ) : ('')}
                  </span></label>
                <legend>
                  {input.fieldset}
                  {input.helper && (
                    <span className="text-helper"> {input.helper}</span>
                  )}
                </legend>
                {renderWrapper({
                  input,
                  formData,
                  formErrors,
                  setFormErrors,
                  onChange,
                })}
              </fieldset>
            );
          }
          return renderWrapper({
            input,
            formData,
            formErrors,
            onChange,
          });
        })}
      </form>
    </div>
  );
};
export default ChildPoisoning;
