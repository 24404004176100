import { request } from '../../../api';

const saveVictimId = async ({
  args,
  caseId,
  closeModal,
  isUnobtainable,
  setMainCaseData,
  setModal,
  setUserData,
  toggleModal,
  victimIdData,
}) => {
  const victimData = {
    name: victimIdData.victim,
    address: victimIdData.address,
    cityState: victimIdData.city,
    zip: victimIdData.zip,
    phone: victimIdData.homePhone,
    workPhone: victimIdData.workPhone,
    parentOrGuardianName: victimIdData.guardian,
    victimIdCannotBeObtained: isUnobtainable,
  };
  try {
    toggleModal();
    setModal({
      open: true,
      error: false,
      loading: true,
      message: 'Saving Victim ID...',
      onClose: closeModal,
    });
    const data = await request({
      ...args,
      body: victimData,
      caseId,
      def: 'sendVictimId',
      method: 'POST',
    });
    if (data.errorMessage || data.status === 500) {
      const errorMsg = data.errorMessage
        ? data.errorMessage
        : `${data.status} ${data.statusText}`;
      setModal({
        open: true,
        error: true,
        loading: false,
        message: `Victim ID failed to save. ${errorMsg}`,
        onClose: toggleModal,
      });
    } else {
      setMainCaseData((prevState) => ({
        ...prevState,
        isVictimIdRequired: false,
        hasVictimIdBeenEntered: true,
      }));
      setUserData((prevState) => ({
        ...prevState,
        hospital: { ...prevState.hospital },
      }));
      setModal({
        open: true,
        error: false,
        loading: false,
        message: 'Victim ID has been saved.',
        onClose: closeModal,
      });
    }
  } catch (err) {
    setModal({
      open: true,
      error: true,
      loading: false,
      message: `Victim ID failed to save. ${err}`,
      onClose: toggleModal,
    });
    console.log('ERROR:', err.message);
  }
};

export { saveVictimId };
