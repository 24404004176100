import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useIdleTimer } from 'react-idle-timer';
import {
  DropdownOptionsContext,
  OfflineModeContext,
  UserDataContext,
} from './Context';
import { db } from './db';
import { request, acquireToken } from './api';
import { checkActiveDatesHospital, formatLookups } from './utils';
import { AlertModal, modalPresets } from './Components/CustomComponents/Modals';
import App from './App';

const AppLoader = () => {
  const { instance, accounts } = useMsal();
  const [modal, setModal] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const [userData, setUserData] = useState(null);
  const [offlineMode, setOfflineMode] = useState(false);
  const [account, setAccount] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const handleAlerts = (modalPreset) => {
    setModal(modalPreset);
  };

  // timers
  const handleOnIdle = async () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('paperworkModal', 'false');
    }
    await acquireToken(instance);
    instance.logoutRedirect({
      account: instance.getAccountByHomeId(account.homeAccountId),
      postLogoutRedirectUri: '/',
    });
  };

  // logout when idle for 15 minutes
  useIdleTimer({
    timeout: 1000 * 60 * 15,
    debounce: 500,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    async function auth() {
      await acquireToken(instance);
      setAccount(accounts[0]);
    }

    if (accounts.length > 0 && instance) {
      auth();
    }
  }, [accounts, instance]);

  // get initial user data and hospital data
  useEffect(() => {
    let isSubscribed = true;
    const fetchInitialData = async () => {
      const user = {};
      const args = { method: 'GET' };

      const regex = /9188040d-6c67-4c5b-b112-36a304b66dad/;
      if (regex.test(account.idTokenClaims.idp)) {
        user.guid = true;
      } else {
        user.guid = false;
      }

      user.name = account.name;

      // get hospital data
      let hasHospitalData;
      let hospitalData;
      const hospitalIds = account.idTokenClaims.groups || [];

      hasHospitalData = await request({
        ...args,
        def: 'hospitalData',
      });

      if (hasHospitalData) {
        hospitalData = hasHospitalData.filter((hospital) =>
          hospitalIds.some((id) => hospital.groupId === id)
        );
        hospitalData.sort((a, b) => a.groupId.localeCompare(b.groupId));
        hospitalData.map((hospital, index) => {
          hospitalData[index] = {
            ...hospitalData[index],
            hospitalTypeCurrent: checkActiveDatesHospital(
              hospitalData[index].neissAIPActiveDates,
              new Date()
            )
              ? 'NEISS-AIP'
              : 'NEISS',
          };
          return hospitalData;
        });
        const selectedHospital = JSON.parse(
          localStorage.getItem('selectedHospital')
        );

        const hospitalExists = hospitalData.findIndex((hospital) => {
          if (selectedHospital) {
            return selectedHospital.groupId === hospital.groupId;
          }
          return false;
        });
        if (isSubscribed) {
          setUserData({
            ...user,
            hospitalData,
            hospitalType: '',
            oowValid: false,
            hospital:
              hospitalExists >= 0
                ? hospitalData[hospitalExists]
                : hospitalData[0],
          });
        }
      }
      return true;
    };

    const fetchDropdowns = async () => {
      const args = { method: 'GET' };
      const res = await request({ ...args, def: 'lookups' });
      const options = await formatLookups(res);
      if (isSubscribed) setDropdownOptions(options);
      return true;
    };

    const loadData = async () => {
      handleAlerts(modalPresets().loading);
      const initialData = fetchInitialData();
      const dropdowns = fetchDropdowns();
      Promise.all([initialData, dropdowns]).then(
        async ([dataRes, dropdownRes]) => {
          if (dataRes && dropdownRes) {
            handleAlerts(modalPresets().default);
            setIsDataLoaded(true);
          }
        }
      );
    };

    if (account && !isDataLoaded && !offlineMode) {
      db.cases.clear();
      loadData();
    }
    return () => (isSubscribed = false);
  }, [account, isDataLoaded, offlineMode]);

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      <DropdownOptionsContext.Provider value={dropdownOptions}>
        <OfflineModeContext.Provider value={{ offlineMode, setOfflineMode }}>
          {isDataLoaded && <App />}
          <AlertModal modal={modal} closeModal={handleAlerts} />
        </OfflineModeContext.Provider>
      </DropdownOptionsContext.Provider>
    </UserDataContext.Provider>
  );
};

export default AppLoader;
