import { drugEventInput, inputs } from './inputs';
import validateAdverseDrug from './validateAdverseDrug';

const DEFAULT_FORM_DATA = {
  primaryReason: '',
  facility: '',
  drug1Name: '',
  drug2Name: '',
  drug3Name: '',
  drug4Name: '',
  drug1Amount: '',
  drug2Amount: '',
  drug3Amount: '',
  drug4Amount: '',
  drug1DeliveryMethod: '',
  drug2DeliveryMethod: '',
  drug3DeliveryMethod: '',
  drug4DeliveryMethod: '',
  drug1DeliveryMethodOtherSpecify: '',
  drug2DeliveryMethodOtherSpecify: '',
  drug3DeliveryMethodOtherSpecify: '',
  drug4DeliveryMethodOtherSpecify: '',
  drug1DeliveryMethodSpecify: '',
  drug2DeliveryMethodSpecify: '',
  drug3DeliveryMethodSpecify: '',
  drug4DeliveryMethodSpecify: '',
  drug1Specify: '',
  drug2Specify: '',
  drug3Specify: '',
  drug4Specify: '',
  finalDiagnosis: '',
  treatmentsGiven: '',
  drugTestResults: '',
  adverseEventDetails: '',
  medList0: '',
  medList1: '',
  medList2: '',
  medList3: '',
  medList4: '',
  medList5: '',
  medList6: '',
  medList7: '',
  medList8: '',
  medList9: '',
};

export { DEFAULT_FORM_DATA, drugEventInput, inputs, validateAdverseDrug };
