import {
  errorMsg,
  setErrorMsg,
  invalidCharErrors,
  listErrors,
  createList,
} from '../../../Validations';
import { dropdownCodes } from '../../../../../dropdownCodes';

const {
  eventType1,
  eventType2,
  eventType3,
  eventType4,
  eventType5,
  deliveryMethod1,
  deliveryMethod2,
  deliveryMethod3,
  deliveryMethod88,
  otherInh,
  otherInj,
  otherOra,
} = dropdownCodes;

const adverseDrugErrors = (drugName, value) => {
  if (drugName && drugName !== 'unknown' && !value) {
    return errorMsg.required.basic;
  }
  const err = invalidCharErrors(value);
  if (err) return err;
  return false;
};

const validateAdverseDrug = ({
  formErrors,
  formData,
  setFormErrors,
  options,
}) => {
  const yesNoList = createList(
    options.specialStudiesAdverseDrugEventsPatientFromFacilityGaveMedicine
  );
  const deliveryMethodList = createList(
    options.specialStudiesAdverseDrugEventsDeliveryRoute
  );
  const deliveryMethodSpecifyList = createList(
    options.specialStudiesAdverseDrugEventsDeliveryRouteSpecification
  );
  const adverseEventList = createList(options.specialStudiesAdverseType);
  const {
    eventType,
    facility,
    drug2Name,
    drug3Name,
    drug4Name,
    drug1DeliveryMethod,
    drug2DeliveryMethod,
    drug3DeliveryMethod,
    drug4DeliveryMethod,
    drug1DeliveryMethodSpecify,
    drug2DeliveryMethodSpecify,
    drug3DeliveryMethodSpecify,
    drug4DeliveryMethodSpecify,
  } = formData;
  let err;
  let errArr = [];

  Object.keys(formErrors).forEach((key) => {
    switch (key) {
      case 'eventType':
        if (!eventType) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        err = listErrors('adverseEvent', adverseEventList, eventType);
        return setErrorMsg(key, err, setFormErrors);
      case 'primaryReason':
      case 'facility':
      case 'drug1Name':
      case 'drug1Amount':
      case 'drug1DeliveryMethod':
      case 'finalDiagnosis':
      case 'treatmentsGiven':
      case 'drugTestResults':
        if (
          (eventType === eventType1 ||
            eventType === eventType2 ||
            eventType === eventType3 ||
            eventType === eventType4 ||
            eventType === eventType5) &&
          !formData[key]
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (key === 'drug1DeliveryMethod' && formData[key]) {
          err = listErrors(
            'deliveryMethod',
            deliveryMethodList,
            drug1DeliveryMethod
          );
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'facility' && formData[key]) {
          err = listErrors('facility', yesNoList, facility);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (formData[key]) {
          err = invalidCharErrors(formData[key]);
          errArr.push(err);
          return setErrorMsg(key, err, setFormErrors);
        }
        errArr.push(err);
        return err;
      case 'adverseEventDetails':
      case 'medList0':
      case 'medList1':
      case 'medList2':
      case 'medList3':
      case 'medList4':
      case 'medList5':
      case 'medList6':
      case 'medList7':
      case 'medList8':
      case 'medList9':
        err = invalidCharErrors(formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'drug2Amount':
      case 'drug3Amount':
      case 'drug4Amount':
      case 'drug2DeliveryMethod':
      case 'drug3DeliveryMethod':
      case 'drug4DeliveryMethod':
        if (
          (key === 'drug2DeliveryMethod' ||
            key === 'drug3DeliveryMethod' ||
            key === 'drug4DeliveryMethod') &&
          formData[key]
        ) {
          err = listErrors('deliveryMethod', deliveryMethodList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'drug2Amount' || key === 'drug2DeliveryMethod') {
          err = adverseDrugErrors(drug2Name, formData[key]);
          errArr.push(err);
          return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'drug3Amount' || key === 'drug3DeliveryMethod') {
          err = adverseDrugErrors(drug3Name, formData[key]);
          errArr.push(err);
          return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'drug4Amount' || key === 'drug4DeliveryMethod') {
          err = adverseDrugErrors(drug4Name, formData[key]);
          errArr.push(err);
          return setErrorMsg(key, err, setFormErrors);
        }
        return err;
      case 'drug1Specify':
        if (drug1DeliveryMethod === deliveryMethod88 && !formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'drug2Specify':
        if (drug2DeliveryMethod === deliveryMethod88 && !formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'drug3Specify':
        if (drug3DeliveryMethod === deliveryMethod88 && !formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'drug4Specify':
        if (drug4DeliveryMethod === deliveryMethod88 && !formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'drug1DeliveryMethodOtherSpecify':
        if (
          (drug1DeliveryMethodSpecify === otherOra ||
            drug1DeliveryMethodSpecify === otherInj ||
            drug1DeliveryMethodSpecify === otherInh) &&
          !formData[key]
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'drug2DeliveryMethodOtherSpecify':
        if (
          (drug2DeliveryMethodSpecify === otherOra ||
            drug2DeliveryMethodSpecify === otherInj ||
            drug2DeliveryMethodSpecify === otherInh) &&
          !formData[key]
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'drug3DeliveryMethodOtherSpecify':
        if (
          (drug3DeliveryMethodSpecify === otherOra ||
            drug3DeliveryMethodSpecify === otherInj ||
            drug3DeliveryMethodSpecify === otherInh) &&
          !formData[key]
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'drug4DeliveryMethodOtherSpecify':
        if (
          (drug4DeliveryMethodSpecify === otherOra ||
            drug4DeliveryMethodSpecify === otherInj ||
            drug4DeliveryMethodSpecify === otherInh) &&
          !formData[key]
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'drug1DeliveryMethodSpecify':
        if (
          !formData[key] &&
          (drug1DeliveryMethod === deliveryMethod1 ||
            drug1DeliveryMethod === deliveryMethod3 ||
            drug1DeliveryMethod === deliveryMethod2)
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (formData[key]) {
          err = listErrors(
            'deliveryMethod',
            deliveryMethodSpecifyList,
            formData[key]
          );
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'drug2DeliveryMethodSpecify':
        if (
          !formData[key] &&
          (drug2DeliveryMethod === deliveryMethod1 ||
            drug2DeliveryMethod === deliveryMethod3 ||
            drug2DeliveryMethod === deliveryMethod2)
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (formData[key]) {
          err = listErrors(
            'deliveryMethod',
            deliveryMethodSpecifyList,
            formData[key]
          );
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'drug3DeliveryMethodSpecify':
        if (
          !formData[key] &&
          (drug3DeliveryMethod === deliveryMethod1 ||
            drug3DeliveryMethod === deliveryMethod3 ||
            drug3DeliveryMethod === deliveryMethod2)
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (formData[key]) {
          err = listErrors(
            'deliveryMethod',
            deliveryMethodSpecifyList,
            formData[key]
          );
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'drug4DeliveryMethodSpecify':
        if (
          !formData[key] &&
          (drug4DeliveryMethod === deliveryMethod1 ||
            drug4DeliveryMethod === deliveryMethod3 ||
            drug4DeliveryMethod === deliveryMethod2)
        ) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (formData[key]) {
          err = listErrors(
            'deliveryMethod',
            deliveryMethodSpecifyList,
            formData[key]
          );
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      default:
        return errArr;
    }
  });
  const filtered = [...new Set(errArr)].filter(
    (err) => err !== undefined && err !== false
  );
  return filtered;
};

export default validateAdverseDrug;
