import React, { useState } from 'react';
import { inputs } from './utils';
import { renderWrapper } from '../../../../Components/CustomComponents';

const WorkRelated = (props) => {
  const {
    currentForm,
    formData,
    formErrors,
    setButtonType,
    setFormData,
    setFormErrors,
    setUnsavedChanges,
  } = props;
  const [touched, setTouched] = useState(false);

  const onChange = (e, name) => {
    setTouched(true);
    setUnsavedChanges(true);
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    switch (name) {
      case 'employmentStatus':
        if (touched && !value)
          setFormData((prevState) => ({
            ...prevState,
            employmentStatusSpecify: '',
          }));
        break;
      default:
        break;
    }
    value = value.replace(/[^\x00-\x7F]/g, '');
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  return (
    <div
      className="list-tab-contents"
      aria-live="polite"
      aria-hidden={currentForm === 'workRelated' ? false : true}
    >
      <h1>NIOSH Work Related Study</h1>
      <form className="ut-max-width-900">
        {inputs.map((input) => {
          return renderWrapper({
            input,
            formData,
            formErrors,
            setFormErrors,
            onChange,
          });
        })}
      </form>
    </div>
  );
};

export default WorkRelated;
