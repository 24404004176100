export const dropdownCodes = {
  alcohol1: '1 Yes',
  admitted2:
    '2 Admitted/transferred to a psychiatric ward or psychiatric inpatient facility',
  admitted4: '4 Not recorded',
  basicYes: '1 Yes',
  basicNo: '2 No',
  body0: '0 Internal',
  body75: '75 Head',
  body85: '85 All of Body',
  capsYes: '1 YES',
  capsNo: '2 NO',
  assaultNo: '2 No',
  caused1: '1 Self',
  caused4: '4 Other relative',
  causedOther: '8 Other',
  deliveryMethod1: '1 Orally',
  deliveryMethod2: '2 Injected',
  deliveryMethod3: '3 Inhaled',
  deliveryMethod88: '88 Other',
  diag41: '41 Ingested Object',
  diag42: '42 Aspirated Object',
  diag49: '49 Burns, Chemical',
  diag52: '52 Concussion',
  diag65: '65 Anoxia',
  diag67: '67 Electric Shock',
  diag68: '68 Poisoning',
  diag69: '69 Submersion',
  diag71: '71 Other or N.S.',
  diag74: '74 Dermat or Conj',
  childYes: '1 Yes',
  dis2: '2 Treated & Transferred',
  dis4: '4 Treated & Admitted For Hospitalization, Hospitalized',
  dis8: '8 Fatalities, Including Doa, Died In ED, Expired In Hosp.',
  dis9: '9 Unknown, Not Stated, Etc.',
  drugTest1: '1 Yes',
  eventType1: '1 Therapeutic Use/Unintentional Ingestion',
  eventType2: '2 Abuse/Recreational Use (of legal pharmaceutical)',
  eventType3: '3 Self-harm/Suicide Attempt',
  eventType4: '4 Assault',
  eventType5: '5 Unknown Purpose',
  eventType6: '6 Not an Adverse Drug Event',
  fire0: '0 No Fire Or No Flame/Smoke Spread',
  fire1: '1 Fire or Smoke; FD',
  fire2: '2 Fire or Smoke; No FD',
  fire3: '3 Fire or Smoke; FD?',
  firearmOther: '5 Other',
  injuryEvent4: '4 Other intent described (e.g. intent to attract attention)',
  injuryEventOther: '4 Other',
  intent0: '0 Unintentional, Unknown, or N.S.',
  intent1: '1 Intentional or Assault',
  intent2: '2 Self-Inflicted or Suicide',
  intent3: '3 Legal Intervention',
  loc1: '1 Home',
  maritalOther: '6 Other',
  neissAIP: 'NEISS-AIP',
  neiss: 'NEISS',
  otherOra: '88 Other oral',
  otherInj: '88 Other injected',
  otherInh: '88 Other inhaled',
  patientIntent4: '4 Other intent described (e.g. intent to attract attention)',
  prod0000: '0000 No Second Product Involvement',
  race3: '3 Other',
  transported8: '8 Other',
  vehiclePos3: '3 Unknown',
  work0: '0 Unknown, Not Recorded',
  work1: '1 Work-Related; Occurred On The Job (Excl. Active Duty Military)',
  work2: '2 Injury Not Occupational or Work-Related',
  work3: '3 Work-Related - Active Duty Military',
};
