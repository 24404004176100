const drugEventInput = [];

const inputs = [
  {
    field: {
      id: 'eventType',
      display: 'hidden',
      label: 'What type of Adverse Drug Event occurred?',
      size: 'large',
      type: 'select',
      tooltipSize: 'large',
      tooltip: 
        '<p> <b>Therapeutic Use/Unintentional Ingestion:</b> Side effects, Allergic reactions, Medication errors, Accidental child ingestions, Overdose from excess dose or high levels of medicines, Secondary effects related to how drug was given (e.g., choking)  </p>  <p> <b>Abuse/Recreational Use:</b> Clinician documented abuse at current ED visit or taking a legal pharmaceutical to ‘get high’/‘party’, even if alcohol and/or illegal substances also involved. Also includes cases in which the purpose of taking the pharmaceutical is not documented, but the chart indicates that it was taken by a non-approved route (e.g., crushed and snorted) or bought on the street/from someone else, or described using a street/slang term  </p>  <p> <b>Unknown Purpose:</b> Reason for taking pharmaceutical cannot be determined based on available information  </p>  <p> <b>Not an Adverse Drug Event:</b> Only illegal drugs or alcohol are mentioned; Only past history of abuse; Occupational injury; Seeking detox; Drug withdrawal; or Drug was given during current ED visit </p>',
    },
  },
  {
    field: {
      id: 'primaryReason',
      label: 'What was the primary reason the patient came to the ED?',
      maxLength: '100',
      size: 'full',
      tooltipWrapper: 'full',
      type: 'text',
      wrapper: 'full',
    },
  },
  {
    field: {
      id: 'facility',
      label:
        'Did the patient come from a nursing home or residential facility that gives medicines to the patient?',
      size: 'large',
      type: 'select',
      tooltip:
        '<p> These <b>INCLUDE</b> any patient residential facility with supervised medication administration, such as nursing homes, extended care facilities, rehabilitation facilities, or group homes.  </p>  <p> These <b>DO NOT INCLUDE</b> infusion centers, doctors&#39; or dentists&#39; offices, clinics, visiting nurses, or jails. </p> ',
    },
  },
  {
    id: 'drugFields',
    flexFieldset: [
      {
        fieldset: 'Drug #1',
        id: 'drug1Fields',
        flex: [
          {
            helper: 'If no information, type "Unknown".',
            id: 'drug1Name',
            label: 'What was the name of the drug?',
            labelledby: 'Drug #1',
            maxLength: '40',
            row: true,
            size: '200',
            type: 'text',
            tooltip:
              '<p>Be as specific as possible when reporting the drug/medication name, including:</p> <ul> 	<li>Brand name (preferred) or generic name</li> 	<li>Type of drug/reason for taking (e.g., blood pressure pill, antibiotic) if only limited information available </li> 	<li>&#39;unknown&#39; if no information at all</li> </ul>  ',
          },
          {
            helper: '(# pills/mg/mL/units)',
            id: 'drug1Amount',
            label: 'How much was taken?',
            labelledby: 'Drug #1',
            maxLength: '60',
            row: true,
            size: '200',
            type: 'text',
            tooltip:
              '<p> Indicate the amount of medication that was taken (#pills, #mg, #mL, #tsp, #units). Examples include: </p> <ul> 	<li>5 pills that were 325mg each</li> 	<li>Ingested a full bottle (120 mL)</li> 	<li>2 tsp of 160mg/5mL suspension</li> 	<li>Took a handful of 500mg tablets</li> 	<li>Injected 10 units (instead of 5 units)</li> </ul> ',
          },
          {
            id: 'drug1DeliveryMethod',
            label: 'How did the patient take the drug?',
            labelledby: 'Drug #1',
            row: true,
            size: '200',
            type: 'select',
            tooltip:
              '<p>Please indicate how the drug/medication was administered. If the route of administration is not documented, please select "UNKNOWN".</p><p> Infusion devices <b>INCLUDE</b> automated medication infusion (pumps), but do <b>NOT</b> include manual delivery (injection pens).</p>',
          },
          {
            id: 'drug1Specify',
            label: 'If other, specify how the patient took the drug',
            labelledby: 'Drug #1',
            maxLength: '50',
            indent: 'xlarge',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            id: 'drug1DeliveryMethodSpecify',
            label: 'Specify delivery method',
            labelledby: 'Drug #1',
            row: true,
            size: '200',
            type: 'select',
          },
          {
            id: 'drug1DeliveryMethodOtherSpecify',
            label: 'If other, specify the delivery method',
            labelledby: 'Drug #1',
            screenReader: ' (how patient took drug)',
            maxLength: '50',
            row: true,
            size: '200',
            type: 'text',
          },
        ],
      },
      {
        fieldset: 'Drug #2',
        id: 'drug2Fields',
        flex: [
          {
            helper: 'If no information, type "Unknown".',
            id: 'drug2Name',
            label: 'What was the name of the drug?',
            labelledby: 'Drug #2',
            maxLength: '40',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            helper: '(# pills/mg/mL/units)',
            id: 'drug2Amount',
            label: 'How much was taken?',
            labelledby: 'Drug #2',
            maxLength: '60',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            id: 'drug2DeliveryMethod',
            label: 'How did the patient take the drug?',
            labelledby: 'Drug #2',
            row: true,
            size: '200',
            type: 'select',
          },
          {
            id: 'drug2Specify',
            label: 'If other, specify how the patient took the drug',
            labelledby: 'Drug #2',
            maxLength: '50',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            id: 'drug2DeliveryMethodSpecify',
            label: 'Specify delivery method',
            labelledby: 'Drug #2',
            row: true,
            size: '200',
            type: 'select',
          },
          {
            id: 'drug2DeliveryMethodOtherSpecify',
            label: 'If other, specify the delivery method',
            labelledby: 'Drug #2',
            screenReader: ' (how patient took drug)',
            maxLength: '50',
            row: true,
            size: '200',
            type: 'text',
          },
        ],
      },
      {
        fieldset: 'Drug #3',
        id: 'drug3Fields',
        flex: [
          {
            helper: 'If no information, type "Unknown".',
            id: 'drug3Name',
            label: 'What was the name of the drug?',
            labelledby: 'Drug #3',
            maxLength: '40',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            helper: '(# pills/mg/mL/units)',
            id: 'drug3Amount',
            label: 'How much was taken?',
            labelledby: 'Drug #3',
            maxLength: '60',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            id: 'drug3DeliveryMethod',
            label: 'How did the patient take the drug?',
            labelledby: 'Drug #3',
            row: true,
            size: '200',
            type: 'select',
          },
          {
            id: 'drug3Specify',
            label: 'If other, specify how the patient took the drug',
            labelledby: 'Drug #3',
            maxLength: '50',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            id: 'drug3DeliveryMethodSpecify',
            label: 'Specify delivery method',
            labelledby: 'Drug #3',
            row: true,
            size: '200',
            type: 'select',
          },
          {
            id: 'drug3DeliveryMethodOtherSpecify',
            label: 'If other, specify the delivery method',
            labelledby: 'Drug #3',
            screenReader: ' (how patient took drug)',
            maxLength: '50',
            row: true,
            size: '200',
            type: 'text',
          },
        ],
      },
      {
        fieldset: 'Drug #4',
        id: 'drug4Fields',
        flex: [
          {
            helper: 'If no information, type "Unknown".',
            id: 'drug4Name',
            label: 'What was the name of the drug?',
            labelledby: 'Drug #4',
            maxLength: '40',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            helper: '(# pills/mg/mL/units)',
            id: 'drug4Amount',
            label: 'How much was taken?',
            labelledby: 'Drug #4',
            maxLength: '60',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            id: 'drug4DeliveryMethod',
            label: 'How did the patient take the drug?',
            labelledby: 'Drug #4',
            row: true,
            size: '200',
            type: 'select',
          },
          {
            id: 'drug4Specify',
            label: 'If other, specify how the patient took the drug',
            labelledby: 'Drug #4',
            maxLength: '50',
            row: true,
            size: '200',
            type: 'text',
          },
          {
            id: 'drug4DeliveryMethodSpecify',
            label: 'Specify delivery method',
            labelledby: 'Drug #4',
            row: true,
            size: '200',
            type: 'select',
          },
          {
            id: 'drug4DeliveryMethodOtherSpecify',
            label: 'If other, specify the delivery method',
            labelledby: 'Drug #4',
            screenReader: ' (how patient took drug)',
            maxLength: '50',
            row: true,
            size: '200',
            type: 'text',
          },
        ],
      },
    ],
  },
  {
    field: {
      id: 'finalDiagnosis',
      label:
        'What was the final clinician diagnosis (Dx) or clinical impression?',
      maxLength: '100',
      size: 'full',
      tooltipWrapper: 'full',
      type: 'text',
      tooltip:
        "<p>Please record the clinician's diagnosis or impression word-for-word <b>EXACTLY</b> as it appears in the medical record.</p>",
      wrapper: 'full',
    },
  },
  {
    field: {
      id: 'treatmentsGiven',
      label: 'What treatments were given in the ED or by EMS?',
      maxLength: '100',
      size: 'full',
      tooltipWrapper: 'full',
      type: 'text',
      tooltip:
        '<p> Enter interventions given in the ED or by EMS. Examples include: </p> <ul> <li>Reversal Agents (Narcan/naloxone, flumazenil, N-acetylcysteine/NAC/Mucomyst)</li> <li>Activated charcoal / Gastric lavage</li> <li>Blood transfusion (PRBCs)</li> <li>Other medications (D50, Benadryl, Epinephrine, steroids, vitamin K)</li> <li>Patient told to stop medication or change dose Do not repeat patient disposition and do not note &#39;per protocol&#39; treatments</li> </ul> ',
      wrapper: 'full',
    },
  },
  {
    field: {
      id: 'drugTestResults',
      label:
        'What were the RESULTS of key tests performed for the adverse drug event?',
      maxLength: '100',
      size: 'full',
      tooltipWrapper: 'full',
      type: 'text',
      tooltip:
        '<p> Enter <b>ONLY</b> results of key tests that are directly related to the ADE. Examples include: </p> <ul> <li>Lab results (e.g., INR/PT value, blood glucose, specific drug levels, drug screen/alcohol level)</li> <li>EKGs</li> <li>X-rays (CT scan) </li> </ul> <p> Do not note &#39;per protocol&#39; testing </p> ',
      wrapper: 'full',
    },
  },
  {
    field: {
      id: 'adverseEventDetails',
      label: 'Please record on other information regarding the event:',
      maxLength: '355',
      placeHolder: '',
      type: 'textArea',
      tooltip:
        '<p> Enter additional information that describes the ADE, such as: </p> <ul> <li>Symptoms</li> <li>Purpose for taking</li> <li> Situational details (Who? What? Where? When? Why How?) </li> <li>Specific administration errors</li> <li>Alcohol and illegal drugs also involved</li> <li>Manipulating the medication for administration</li> <li>Using medicine prescribed to another person</li> <li>Discharge instructions </li> </ul> <p>Do not repeat information that is entered elsewhere.</p> ',
    },
  },
  {
    fieldset:
      'Please list any other medications NOT ALREADY LISTED that the patient is currently taking.',
    id: 'medicationList',
    flex: [
      {
        id: 'medList0',
        indent: 'large',
        label: 'Medication 1:',
        maxLength: '40',
        size: 'large',
        type: 'text',
        tooltip:
          '<p><b>ONLY</b> record other medications that a patient takes that were not implicated in the current ADE. If a patient is taking more than 10 medications, record them in the order they appear in the chart.</p><p>Do not repeat the implicated Drug 1, Drug 2, Drug 3, or Drug 4 in the &#39;OTHER DRUGS&#39; fields.</p>',
      },
      {
        id: 'medList1',
        indent: 'large',
        label: 'Medication 2:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
      {
        id: 'medList2',
        indent: 'large',
        label: 'Medication 3:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
      {
        id: 'medList3',
        indent: 'large',
        label: 'Medication 4:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
      {
        id: 'medList4',
        indent: 'large',
        label: 'Medication 5:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
      {
        id: 'medList5',
        indent: 'large',
        label: 'Medication 6:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
      {
        id: 'medList6',
        indent: 'large',
        label: 'Medication 7:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
      {
        id: 'medList7',
        indent: 'large',
        label: 'Medication 8:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
      {
        id: 'medList8',
        indent: 'large',
        label: 'Medication 9:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
      {
        id: 'medList9',
        indent: 'large',
        label: 'Medication 10:',
        maxLength: '40',
        size: 'large',
        type: 'text',
      },
    ],
  },
];

export { drugEventInput, inputs };
