import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { db } from '../../db';
import {
  DropdownOptionsContext,
  OfflineModeContext,
  UserDataContext,
} from '../../Context';
import Search from './Search';
import StatusCards from './StatusCards';
import {
  applyCardPreset,
  calculateOOW,
  deleteCase,
  DELETE_FORM,
  onSearch,
  SEARCH_PARAMS,
  submitCases,
  trimCases,
  parseLocalStorage,
} from './utils';
import { request } from '../../api';
import {
  AlertModal,
  DeleteCaseModal,
  modalPresets,
  SubmitAllModal,
  SaveAllOfflineModal,
  SubmitSelectedModal,
} from '../../Components/CustomComponents/Modals';

const SORT_DEFAULT = { sortOrder: 'desc', sortBy: 'treatmentDate' };

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const Dashboard = () => {
  const { userData, setUserData } = useContext(UserDataContext);
  const dropdownOptions = useContext(DropdownOptionsContext);
  const { offlineMode } = useContext(OfflineModeContext);
  const defaultParams = parseLocalStorage('dashboardSearch') || SEARCH_PARAMS;
  const [cases, setCases] = useState(null);
  const [pages, setPages] = useState(null);
  const [searchParams, setSearchParams] = useState(defaultParams);
  const [userSearches, setUserSearches] = useState();
  const [selectedCases, setSelectedCases] = useState([]);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [alertModal, setAlertModal] = useState(modalPresets().default);
  const [deleteModal, setDeleteModal] = useState(false);
  const [saveOfflineModal, setSaveOfflineModal] = useState(false);
  const [submitAllModal, setSubmitAllModal] = useState(false);
  const [sort, setSort] = useState(SORT_DEFAULT);
  const [submitSelectedModal, setSubmitSelectedModal] = useState(false);
  const [deleteForm, setDeleteForm] = useState(DELETE_FORM);
  const { hospital, oowValid } = userData;
  const productCodes = dropdownOptions.mainCaseOptions.caseProduct;
  const prevHospitalId = useRef(hospital.groupId);
  const prevOffline = usePrevious(offlineMode);

  const handleAlerts = (modalPreset) => {
    setAlertModal(modalPreset);
  };

  const handleSaveOfflineModal = () => setSaveOfflineModal(!saveOfflineModal);

  const args = useMemo(
    () => ({
      handleAlerts,
      hospitalId: hospital.groupId,
    }),
    [hospital.groupId]
  );

  const submit = async (submitAll) => {
    const params = {
      ...searchParams,
      product: searchParams.product.split(' ')[0],
    };
    let submitList;
    submitAll ? (submitList = cases) : (submitList = selectedCases);
    if (!oowValid) {
      submitList = submitList.filter(
        ({ treatmentDate }) => !calculateOOW(treatmentDate)
      );
    }
    const res = await submitCases({
      args,
      cases: submitList,
      setCases,
      setModal: handleAlerts,
      setSelectedCases,
      setUserData,
      userData,
      submitAll,
    });
    if (res) {
      await onSearch({
        args,
        params,
        setCases,
        setModal: handleAlerts,
        setPages,
      });
    }
  };

  const saveAllOffline = async () => {
    try {
      const data = await db.cases.toArray();
      await request({
        ...args,
        def: 'bulkSaveCases',
        method: 'POST',
        body: data,
      });
      handleAlerts(modalPresets('Cases have been successfuly saved').success);
      await db.cases.clear();
      setCases([]);
    } catch (err) {
      console.log('Error', err);
    }
  };

  const handleSelectedCases = (caseData, checked) => {
    if (checked) {
      setSelectedCases((prevState) => [caseData, ...prevState]);
    } else {
      const filteredCases = selectedCases.filter(
        ({ id }) => id !== caseData.id
      );
      setSelectedCases([...filteredCases]);
    }
  };

  const handleCardPreset = (e) => {
    const { value } = e.target;
    const { lastUpdateableDate } = hospital;
    setSelectedCases([]);
    setSort(SORT_DEFAULT);
    applyCardPreset({
      args,
      lastUpdateableDate,
      onSearch,
      preset: value,
      search: true,
      setCases,
      setModal: handleAlerts,
      setPages,
      setSearchParams,
    });
  };

  const handleSearch = (e, setDisableButtons) => {
    e.preventDefault();
    const params = {
      ...searchParams,
      product: searchParams.product.split(' ')[0],
      pageNumber: 1,
      userId: '',
    };
    // save searchParams to local
    localStorage.setItem('dashboardSearch', JSON.stringify(searchParams));

    onSearch({ args, params, setCases, setModal: handleAlerts, setPages });
    setDisableButtons({ search: true, submit: true, delete: true });
    setSelectedCases([]);
  };

  const onChangeDeleteForm = (e, name) => {
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    setDeleteForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (cases && offlineMode !== prevOffline) {
      setCases(null);
    }
  }, [cases, offlineMode, prevOffline]);

  useEffect(() => {
    if (deleteModal) {
      const { deleteRequiresPassword, deleteRequiresReason } = selectedCases[0];
      setDisableDeleteBtn(false);
      if (deleteRequiresPassword && deleteRequiresReason) {
        if (!deleteForm.password || !deleteForm.reason) {
          setDisableDeleteBtn(true);
        } else setDisableDeleteBtn(false);
      }
      if (deleteRequiresPassword && !deleteRequiresReason) {
        if (!deleteForm.password) {
          setDisableDeleteBtn(true);
        } else setDisableDeleteBtn(false);
      }
      if (!deleteRequiresPassword && deleteRequiresReason) {
        if (!deleteForm.reason) {
          setDisableDeleteBtn(true);
        } else setDisableDeleteBtn(false);
      }
    }
  }, [deleteForm, deleteModal, selectedCases]);

  const sortCases = (order, column) => {
    const params = { ...searchParams, orderBy: column, orderDirection: order };
    setSort({ sortBy: column, sortOrder: order });
    if (offlineMode) return;
    setSearchParams(params);
    onSearch({ args, params, setCases, setModal: handleAlerts, setPages });
  };

  const toggleCheckbox = (index) => {
    let toggledCases = cases;
    toggledCases[index].checked = !toggledCases[index].checked;
    handleSelectedCases(toggledCases[index], toggledCases[index].checked);
  };

  const toggleDeleteSelectedModal = () => {
    setDeleteModal(!deleteModal);
    if (
      selectedCases[0].deleteRequiresPassword ||
      selectedCases[0].deleteRequiresReason
    )
      setDisableDeleteBtn(true);
  };

  useEffect(() => {
    let isSubscribed = true;
    const fetchFromDb = async () => {
      if (!cases) {
        try {
          const data = await db.cases.toArray();
          const caseData = trimCases(data);
          if (isSubscribed && caseData) {
            setCases(caseData.cases);
          }
        } catch (err) {
          handleAlerts(
            modalPresets(`Failed to fetch cases from local database. ${err}`)
              .error
          );
        }
      }
    };

    const fetchCaseSummary = async () => {
        // get latest summary numbers from the server
        const summary = await request({
          def: 'casesSummary',
          hospitalId: hospital.groupId,
        });
    
        // update context with new summary numbers
        setUserData((prev) => {
          return {
            ...prev,
            hospital: {
              ...prev.hospital,
              ...summary,
            },
            hospitalData: prev.hospitalData.map((hData) => {
              if (hData.groupId === prev.hospital.groupId) {
                return {
                  ...hData,
                  ...summary,
                };
              }
              return hData;
            }),
          };
        });
    }
    const fetchCases = async () => {
      const params = {
        ...searchParams,
        product: searchParams.product.split(' ')[0],
        pageNumber: 1,
      };
      try {
          prevHospitalId.current = hospital.groupId;
          const data = await request({
            ...args,
            def: 'searchCaseData',
            method: 'GET',
            queryString: params,
          });
          if (isSubscribed && data) {
            const {
              pageNumber,
              pageSize,
              totalNumberOfPages,
              totalNumberOfRecords,
              results,
            } = data;
            const caseData = trimCases(results);
            setSort(SORT_DEFAULT);
            setCases(caseData.cases);
            setPages({
              pageNumber,
              pageSize,
              totalNumberOfPages,
              totalNumberOfRecords,
            });
            handleAlerts(modalPresets().default);
          }
      } catch (err) {
        console.log('ERROR:', err.message);
      }
    };
    if (offlineMode) {
      fetchFromDb();
    } else if (!cases || prevHospitalId.current !== hospital.groupId) {
      fetchCases();
      fetchCaseSummary();
    }
    return () => (isSubscribed = false);
  }, [args, cases, hospital.groupId, offlineMode, searchParams, setUserData]);

  useEffect(() => {
    let isSubscribed = true;
    const fetchSavedSearches = async () => {
      try {
        if (!userSearches) {
          const data = await request({
            ...args,
            def: 'savedSearches',
            method: 'GET',
          });
          if (data && isSubscribed) {
            setUserSearches(data);
          }
        }
      } catch (err) {
        console.log('ERROR:', err.message);
      }
    };
    if (!offlineMode) fetchSavedSearches();
    return () => (isSubscribed = false);
  }, [args, offlineMode, userSearches]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && cases && cases.length >= 1) {
      const enableSubmit = cases.some(
        (data) => data.status !== 'ready to submit'
      );
      setDisableSubmitBtn(enableSubmit);
    }
    if ((isSubscribed && !cases) || cases.length === 0) {
      setDisableSubmitBtn(true);
    }

    return () => (isSubscribed = false);
  }, [cases]);

  return (
    <div>
      <StatusCards
        handleCardPreset={handleCardPreset}
        handleSaveOfflineModal={handleSaveOfflineModal}
        setSubmitAllModal={setSubmitAllModal}
        setCases={setCases}
        disable={disableSubmitBtn}
      />
      <Search
        args={args}
        cases={cases}
        handleSearch={handleSearch}
        onSearch={onSearch}
        pages={pages}
        productCodes={productCodes}
        searchParams={searchParams}
        selectedCases={selectedCases}
        setAlertModal={handleAlerts}
        setCases={setCases}
        setPages={setPages}
        setSearchParams={setSearchParams}
        setSubmitModal={setSubmitSelectedModal}
        setUserSearches={setUserSearches}
        sort={sort}
        sortCases={sortCases}
        submit={submit}
        toggleCheckbox={toggleCheckbox}
        toggleDeleteSelectedModal={toggleDeleteSelectedModal}
        userSearches={userSearches}
      />
      <AlertModal modal={alertModal} closeModal={handleAlerts} />
      <SubmitAllModal
        modal={submitAllModal}
        submit={submit}
        setModal={setSubmitAllModal}
      />
      <SubmitSelectedModal
        modal={submitSelectedModal}
        setModal={setSubmitSelectedModal}
        submit={submit}
      />
      <DeleteCaseModal
        args={args}
        cases={cases}
        deleteCase={deleteCase}
        deleteForm={deleteForm}
        disable={disableDeleteBtn}
        modal={deleteModal}
        onChange={onChangeDeleteForm}
        selectedCase={selectedCases[0]}
        setCases={setCases}
        setDeleteForm={setDeleteForm}
        setDeleteModal={setDeleteModal}
        setModal={handleAlerts}
        setSelectedCases={setSelectedCases}
        setUserData={setUserData}
        toggleModal={toggleDeleteSelectedModal}
      />
      <SaveAllOfflineModal
        modal={saveOfflineModal}
        handleModal={handleSaveOfflineModal}
        saveAll={saveAllOffline}
      />
    </div>
  );
};

export default Dashboard;
