import {
  dateErrors,
  errorMsg,
  invalidCharErrors,
  setErrorMsg,
  listErrors,
  createList,
  setWarningMsg,
} from '../../../Validations';
import { dropdownCodes } from '../../../../../dropdownCodes';

const {
  caused1,
  caused4,
  causedOther,
  drugTest1,
  firearmOther,
  intent2,
  maritalOther,
  transported8,
} = dropdownCodes;

const firearmErrors = (key, preReq, value) => {
  const err = invalidCharErrors(value);
  if (err) return err;
  if (key === 'maritalOther' && preReq === maritalOther && !value)
    return errorMsg.required.basic;
  if (key === 'firearmOther' && preReq === firearmOther && !value)
    return errorMsg.required.basic;
  if (
    key === 'causedByOther' &&
    (preReq === caused4 || preReq === causedOther) &&
    !value
  )
    return errorMsg.required.basic;
  if (key === 'testResults' && preReq === drugTest1 && !value)
    return errorMsg.required.basic;
  if (key === 'transportedOther' && preReq === transported8 && !value)
    return errorMsg.required.basic;
};

const firearmWarnings = (key, preReq, value) => {
  if (key === 'causedBy') {
    if (preReq === intent2 && value !== caused1) return errorMsg.firearmIntent;
    if (preReq !== intent2 && value === caused1) return errorMsg.firearmIntent;
  }
};

const validateFirearm = ({
  formErrors,
  formData,
  mainCaseData,
  setFormErrors,
  setFormWarnings,
  options,
}) => {
  const {
    alcoholDrugTests,
    causedBy,
    dateInjured,
    firearmType,
    maritalStatus,
    transportedToER,
  } = formData;
  const { intent, treatmentDate } = mainCaseData;
  let err;
  let errArr = [];
  let warn;

  const maritalList = createList(
    options.specialStudiesFirearmInjuryMaritalStatus
  );
  const transportedList = createList(
    options.specialStudiesFirearmInjuryTransportedToER
  );
  const firearmTypeList = createList(
    options.specialStudiesFirearmInjuryTypeOfFirearm
  );
  const whoCausedList = createList(
    options.specialStudiesFirearmInjuryWhoCausedInjury
  );
  const yesNoList = createList(
    options.specialStudiesFirearmInjuryYesNoNotStated
  );
  Object.keys(formErrors).forEach((key) => {
    switch (key) {
      case 'dateInjured':
        err = dateErrors(null, 'dateInjured', treatmentDate, dateInjured, true);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'maritalStatus':
      case 'firearmType':
      case 'verbalArgument':
      case 'physicalFight':
      case 'illicitDrugs':
      case 'otherCrime':
      case 'alcoholDrugTests':
      case 'transportedToER':
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (
          (key === 'verbalArgument' ||
            key === 'physicalFight' ||
            key === 'illicitDrugs' ||
            key === 'otherCrime' ||
            key === 'alcoholDrugTests') &&
          formData[key]
        ) {
          err = listErrors('basic', yesNoList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'transportedToER' && formData[key]) {
          err = listErrors('transportedToER', transportedList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'maritalStatus' && formData[key]) {
          err = listErrors('maritalStatus', maritalList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'firearmType' && formData[key]) {
          err = listErrors('firearmType', firearmTypeList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'precision':
        break;
      case 'causedBy':
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (intent) {
          warn = firearmWarnings(key, intent, formData[key]);
          err = firearmErrors(key, intent, formData[key]);
          errArr.push(err);
          setWarningMsg(key, warn, setFormWarnings);
          return setErrorMsg(key, err, setFormErrors);
        }
        if (formData[key]) {
          err = listErrors('causedBy', whoCausedList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'model':
      case 'caliber':
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        err = invalidCharErrors(formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'maritalOther':
        err = firearmErrors(key, maritalStatus, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'firearmOther':
        err = firearmErrors(key, firearmType, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'causedByOther':
        err = firearmErrors(key, causedBy, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'testResults':
        err = firearmErrors(key, alcoholDrugTests, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'transportedOther':
        err = firearmErrors(key, transportedToER, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      default:
        return errArr;
    }
  });
  const filtered = [...new Set(errArr)].filter(
    (err) => err !== undefined && err !== false
  );
  return filtered;
};

export default validateFirearm;
