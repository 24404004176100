const inputs = [
  {
    field: {
      id: 'collision',
      label: 'Was the victim injured in a collision or rollover?',
      size: 'medium',
      type: 'select',
    },
  },
  {
    field: {
      id: 'carSeat',
      label: 'Was the car seat being used away from the vehicle?',
      size: 'medium',
      type: 'select',
    },
  },
  {
    field: {
      id: 'localeInfo',
      label: 'Provide any additional locale information from ER record:',
      maxLength: '255',
      placeHolder: '',
      type: 'textArea',
    },
  },
  {
    field: {
      id: 'hazardPattern',
      label: 'Code the hazard pattern:',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'inMotion',
      label: 'Was the vehicle in motion at the time of the incident?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'enterExitVehicle',
      label:
        'Was victim entering or exiting the vehicle at the time of the incident?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'vehiclePosition',
      label: 'Was the victim in/on the vehicle when the incident occurred?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'positionCode',
      label: "Code the victim's position in the vehicle?",
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'bodyType',
      label: 'Vehicle body type:',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'vehicleERRecord',
      label:
        'All information describing the vehicle from ER record (Type, make, model, year, etc.)',
      maxLength: '255',
      placeHolder: '',
      type: 'textArea',
    },
  },
];

export default inputs;
