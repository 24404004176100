import React from 'react';
import { usePagination, DOTS } from './usePagination';

const Pagination = ({ currentPage, totalCount, pageSize, onPageChange }) => {
  const siblingCount = 2;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className="paginator">
      <li>
        <button
          className={`paginator__button paginator__button--arrow ${
            currentPage === 1 ? 'ut-invisible' : ''
          }`}
          onClick={onPrevious}
        >
          <span className="ut-only-sr">Go to previous list of results</span>
          <span className="fa-icon">&#xf053;</span>
        </button>
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber}>
              <span className="fa-icon paginator__dots">&#xF141;</span>
            </li>
          );
        }

        return (
          <li key={pageNumber}>
            <button
              className={`paginator__button ${
                pageNumber === currentPage ? 'selected' : null
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        );
      })}
      <li>
        <button
          className={`paginator__button paginator__button--arrow ${
            currentPage === lastPage ? 'ut-invisible' : ''
          }`}
          onClick={onNext}
        >
          <span className="ut-only-sr">Go to next list of results</span>
          <span className="fa-icon">&#xf054;</span>
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
