import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from '@reach/combobox';
import { matchSorter } from 'match-sorter';
import { useParams } from 'react-router-dom';
import {
  CaseDataContext,
  UserDataContext,
  DropdownOptionsContext,
} from '../../Context';
import { calculateOOW } from '../../Pages/Dashboard/utils';
import { dropdownCodes } from '../../dropdownCodes';
import '@reach/combobox/styles.css';

const {
  basicYes,
  basicNo,
  capsYes,
  dis2,
  dis4,
  deliveryMethod1,
  deliveryMethod2,
  deliveryMethod3,
  eventType6,
  intent0,
  intent1,
  intent2,
  intent3,
  vehiclePos3,
} = dropdownCodes;

const ComboBox = React.memo((props) => {
  const { currentForm, el, formData, formErrors, onChange } = props
  const [isOow, setIsOowW] = useState(false);
  const [OowChecked, setoowChecked] = useState(false);
  const caseData = useContext(CaseDataContext);
  const { userData } = useContext(UserDataContext);
  const options = useContext(DropdownOptionsContext);
  const { existingCaseId } = useParams();
  const { id, display, label, labelledby, size, tooltip, tooltipSize } = el;
  const { oowValid } = userData;
  const containerRef = useRef(null);
  const [allOptions, setAllOptions] = useState([]);
  const [option, setOption] = useState('');
  const [redirected, setRedirected] = useState(false);
  const [touched, setTouched] = useState(false);

  const {
    adverseDrugOptions,
    assaultOptions,
    childrensPoisoningOptions,
    firearmInjuryOptions,
    motorVehicleOptions,
    selfInflictedOptions,
    workRelatedOptions,
  } = options;

  const useFilter = (term, allOptions) => {
    return useMemo(() => {
      if (allOptions) {
        if (allOptions.length > 0) {
          if (!term || !term.length) return allOptions;
          const terms = term.split(' ');
          if (!terms) return allOptions;
          return terms.reduceRight(
            (results, term) =>
              matchSorter(results, term, { keys: ['code', 'name'] }),
            allOptions
          );
        }
      }
    }, [term, allOptions]);
  };

  const filteredOptions = useFilter(formData[id], allOptions);

  useEffect(() => {
    // check if we only have a code number in formData
    const isNumber = Number.isInteger(parseInt(formData[id]))
    const isOnlyCode = isNumber && formData[id].split(' ').length === 1
    
    if (
      (existingCaseId && !redirected && !touched) || 
      (existingCaseId && isOnlyCode && !touched)) // if a code number has replaced option text, then switch it back again
    {
      if (filteredOptions) {
        if (filteredOptions.length > 0 && formData[id]) {
          onChange(`${filteredOptions[0].code} ${filteredOptions[0].name}`, id);
          setRedirected(true);
        }
      }
    }
  }, [
    existingCaseId,
    formData,
    redirected,
    filteredOptions,
    id,
    touched,
    onChange,
  ]);

  useEffect(() => {
    if (caseData) {
      if (caseData.treatmentDate && existingCaseId && !OowChecked) {
        setIsOowW(calculateOOW(caseData.treatmentDate));
        setoowChecked(true);
      }
    }
  }, [existingCaseId, caseData, isOow, OowChecked]);

  const onKeyUp = (e) => {
    const { key } = e;
    const { value } = e.target;
    switch (key) {
      case 'Up':
      case 'ArrowUp':
      case 'Down':
      case 'ArrowDown':
        setOption(value);
        break;
      case 'Tab':
        setTouched(true);
        break;
      default:
        break;
    }
  };

  const onKeyDown = (e) => {
    const { key } = e;
    switch (key) {
      case 'Up':
      case 'ArrowUp':
      case 'Down':
      case 'ArrowDown':
        if (!e.isDefaultPrevented()) {
          const container = containerRef.current;
          if (!container) return;
          window.requestAnimationFrame(() => {
            const element = container.querySelector('[aria-selected=true]');
            if (element) {
              const top = element.offsetTop - container.scrollTop;
              const bottom =
                container.scrollTop +
                container.clientHeight -
                (element.offsetTop + element.clientHeight);

              if (bottom < 0) container.scrollTop -= bottom;
              if (top < 0) container.scrollTop += top;
            }
          });
        }
        break;
      case 'Tab':
        if (!option && filteredOptions.length > 0 && formData[id]) {
          onChange(`${filteredOptions[0].code} ${filteredOptions[0].name}`, id);
          setOption('');
        }
        if (option) {
          onChange(option, id);
          setOption('');
        }
        break;
      default:
        break;
    }
  };

  const onBlur = (e) => {
    const { value } = e.target;

    // if the target has the 'data-reach-combobox-option' then an option in the comboBox triggered the blur and we want the event to proceed to onSelect
    if((e.relatedTarget && e.relatedTarget.hasAttribute('data-reach-combobox-option')) || !value) return

    if(filteredOptions.length > 0 && formData[id]) {
      onChange(`${filteredOptions[0].code} ${filteredOptions[0].name}`, id);
      setTouched(false);
      setOption('');
    }
  }

  useEffect(() => {
    if (allOptions?.length <= 0) {
      switch (id) {
        // Adverse Drug
        case 'eventType':
          setAllOptions(adverseDrugOptions.specialStudiesAdverseType);
          break;
        case 'facility':
          setAllOptions(
            adverseDrugOptions.specialStudiesAdverseDrugEventsPatientFromFacilityGaveMedicine
          );
          break;
        case 'drug1DeliveryMethod':
        case 'drug2DeliveryMethod':
        case 'drug3DeliveryMethod':
        case 'drug4DeliveryMethod':
          setAllOptions(
            adverseDrugOptions.specialStudiesAdverseDrugEventsDeliveryRoute
          );
            break;
            case 'drug1DeliveryMethodSpecify':
            if(formData.drug1DeliveryMethod) {
              const code = formData.drug1DeliveryMethod.split(' ')[0];
              const filteredByParent =
              adverseDrugOptions.specialStudiesAdverseDrugEventsDeliveryRouteSpecification.filter(
                (option) => option.parentCode === code
              );
              setAllOptions(filteredByParent);
            }
            break;
            case 'drug2DeliveryMethodSpecify':
              if(formData.drug2DeliveryMethod) {
                const code = formData.drug2DeliveryMethod.split(' ')[0];
                const filteredByParent =
                adverseDrugOptions.specialStudiesAdverseDrugEventsDeliveryRouteSpecification.filter(
                  (option) => option.parentCode === code
                );
                setAllOptions(filteredByParent);
            }
            break;
            case 'drug3DeliveryMethodSpecify':
              if(formData.drug3DeliveryMethod) {
                const code = formData.drug3DeliveryMethod.split(' ')[0];
                const filteredByParent =
                adverseDrugOptions.specialStudiesAdverseDrugEventsDeliveryRouteSpecification.filter(
                  (option) => option.parentCode === code
                );
                setAllOptions(filteredByParent);
            }
            break;
            case 'drug4DeliveryMethodSpecify':
              if(formData.drug4DeliveryMethod) {
                const code = formData.drug4DeliveryMethod.split(' ')[0];
                const filteredByParent =
                adverseDrugOptions.specialStudiesAdverseDrugEventsDeliveryRouteSpecification.filter(
                  (option) => option.parentCode === code
                );
                setAllOptions(filteredByParent);
            }
            break;
        // Assault
        case 'patientInitiated':
        case 'patientIntervened':
        case 'patientBystander':
        case 'patientPeer':
        case 'cps':
        case 'policeReport':
        case 'referral':
        case 'sexuallyAssaulted':
        case 'examination':
        case 'prophylactic':
        case 'contraception':
          setAllOptions(assaultOptions.specialStudiesAssaultYesNoUnknown);
          break;
        case 'amountInvolved':
          setAllOptions(
            assaultOptions.specialStudiesAssaultNumberOfPerpetrators
          );
          break;
        case 'sexInvolved':
          setAllOptions(assaultOptions.specialStudiesAssaultSex);
          break;
        // Child Poisoning
        case 'contactedPriorED':
        case 'contactedDuringED':
        case 'contactedOther':
        case 'anySymptoms':
        case 'nauseaVomiting':
        case 'dizziness':
        case 'drowsiness':
        case 'difficultyBreathing':
        case 'headaches':
        case 'abdominalPain':
        case 'otherSymptoms':
        case 'edTreatments':
        case 'vomitingInduced':
        case 'stomachPump':
        case 'charAdmin':
        case 'artRespiration':
        case 'bloodTransfusion':
        case 'otherTreatment':
        case 'crcPresent':
        case 'drugProvided':
          setAllOptions(
            childrensPoisoningOptions.specialStudiesChildrensPoisoningYesNoNotStated
          );
          break;
        case 'containerDisposition':
          setAllOptions(
            childrensPoisoningOptions.specialStudiesChildrensPoisoningContainer
          );
          break;
        // Firearm Injury
        case 'verbalArgument':
        case 'physicalFight':
        case 'illicitDrugs':
        case 'otherCrime':
        case 'alcoholDrugTests':
          setAllOptions(
            firearmInjuryOptions.specialStudiesFirearmInjuryYesNoNotStated
          );
          break;
        case 'maritalStatus':
          setAllOptions(
            firearmInjuryOptions.specialStudiesFirearmInjuryMaritalStatus
          );
          break;
        case 'firearmType':
          setAllOptions(
            firearmInjuryOptions.specialStudiesFirearmInjuryTypeOfFirearm
          );
          break;
        case 'causedBy':
          setAllOptions(
            firearmInjuryOptions.specialStudiesFirearmInjuryWhoCausedInjury
          );
          break;
        case 'transportedToER':
          setAllOptions(
            firearmInjuryOptions.specialStudiesFirearmInjuryTransportedToER
          );
          break;
        // Motor Vehicle
        case 'collision':
        case 'carSeat':
          setAllOptions(motorVehicleOptions.specialStudiesNhtsaYesNo);
          break;
        case 'inMotion':
        case 'vehiclePosition':
          setAllOptions(motorVehicleOptions.specialStudiesNhtsaYesNoUnknown);
          break;
        case 'hazardPattern':
          setAllOptions(motorVehicleOptions.specialStudiesNhtsaHazardPattern);
          break;
        case 'enterExitVehicle':
          setAllOptions(motorVehicleOptions.specialStudiesNhtsaVictimMotion);
          break;
        case 'positionCode':
          setAllOptions(motorVehicleOptions.specialStudiesNhtsaVictimPosition);
          break;
        case 'bodyType':
          setAllOptions(motorVehicleOptions.specialStudiesNhtsaVehicleBodyType);
          break;
        /* Self Inflicted */
        case 'patientIntent':
          setAllOptions(selfInflictedOptions.specialStudiesSelfInflictedIntent);
          break;
        case 'injuryEvent':
          setAllOptions(
            selfInflictedOptions.specialStudiesSelfInflictedDiagnosis
          );
          break;
        case 'alcoholSI':
        case 'drugs':
          setAllOptions(
            selfInflictedOptions.specialStudiesSelfInflictedAlcoholDrugsUsed
          );
          break;
        case 'patientAdmitted':
          setAllOptions(
            selfInflictedOptions.specialStudiesSelfInflictedDisposition
          );
          break;
        /* Work Related */
        case 'employedState':
          setAllOptions(
            workRelatedOptions.specialStudiesNioshWorkerEmploymentState
          );
          break;
        case 'employmentStatus':
          setAllOptions(
            workRelatedOptions.specialStudiesNioshWorkerEmploymentStatus
          );
          break;
        default:
          break;
      }
    }
  }, [
    allOptions,
    id,
    adverseDrugOptions,
    assaultOptions,
    childrensPoisoningOptions,
    firearmInjuryOptions,
    motorVehicleOptions,
    selfInflictedOptions,
    workRelatedOptions,
    formData.drug1DeliveryMethodSpecify,
    formData.drug2DeliveryMethodSpecify,
    formData.drug3DeliveryMethodSpecify,
    formData.drug4DeliveryMethodSpecify
  ]);

  const disableInputs = (inputData) => {
    if (
      inputData === deliveryMethod1 ||
      inputData === deliveryMethod2 ||
      inputData === deliveryMethod3
    )
      return false;
    return true;
  };

  const disableInputCheck = (e) => {
    if (existingCaseId && !oowValid && isOow) {
      return calculateOOW(caseData.treatmentDate);
    } else {
      switch (id) {
        case 'facility':
        case 'drug1DeliveryMethod':
          if (formData.eventType === eventType6) return true;
          return false;
        case 'drug2DeliveryMethod':
          if (formData.eventType === eventType6) return true;
          return formData.drug2Name ? false : true;
        case 'drug3DeliveryMethod':
          if (formData.eventType === eventType6) return true;
          return formData.drug3Name ? false : true;
        case 'drug4DeliveryMethod':
          if (formData.eventType === eventType6) return true;
          return formData.drug4Name ? false : true;
        case 'drug1DeliveryMethodSpecify':
          if (formData.eventType === eventType6) return true;
          if (formData.drug1DeliveryMethod)
            return disableInputs(formData.drug1DeliveryMethod);
          return true;
        case 'drug2DeliveryMethodSpecify':
          if (formData.eventType === eventType6) return true;
          if (formData.drug2DeliveryMethod)
            return disableInputs(formData.drug2DeliveryMethod);
          return true;
        case 'drug3DeliveryMethodSpecify':
          if (formData.eventType === eventType6) return true;
          if (formData.drug3DeliveryMethod)
            return disableInputs(formData.drug3DeliveryMethod);
          return true;
        case 'drug4DeliveryMethodSpecify':
          if (formData.eventType === eventType6) return true;
          if (formData.drug4DeliveryMethod)
            return disableInputs(formData.drug4DeliveryMethod);
          return true;
        case 'hazardPattern':
        case 'inMotion':
        case 'vehiclePosition':
        case 'bodyType':
          if (formData.collision === basicYes || formData.carSeat === basicYes)
            return true;
          return false;
        case 'enterExitVehicle':
          if (
            formData.collision === basicYes ||
            formData.carSeat === basicYes ||
            formData.inMotion === basicYes ||
            formData.inMotion === vehiclePos3
          )
            return true;
          return false;
        case 'positionCode':
          if (
            formData.collision === basicYes ||
            formData.carSeat === basicYes ||
            formData.vehiclePosition === basicNo ||
            formData.vehiclePosition === vehiclePos3
          )
            return true;
          return false;
        case 'patientPeer':
        case 'cps':
          if ((caseData.age >= 2 && caseData.age <= 17) || (caseData.age >= 201 && caseData.age <= 223)) return false;
          return true;
        case 'examination':
        case 'prophylactic':
        case 'contraception':
          if (formData.sexuallyAssaulted === capsYes) return false;
          return true;
        case 'patientAdmitted':
          if (caseData.disposition === dis2 || caseData.disposition === dis4)
            return false;
          return true;
        case 'nauseaVomiting':
        case 'dizziness':
        case 'drowsiness':
        case 'difficultyBreathing':
        case 'headaches':
        case 'abdominalPain':
        case 'otherSymptoms':
          if (formData.anySymptoms !== basicYes) return true;
          return false;
        case 'vomitingInduced':
        case 'stomachPump':
        case 'charAdmin':
        case 'artRespiration':
        case 'bloodTransfusion':
        case 'otherTreatment':
          if (formData.edTreatments !== basicYes) return true;
          return false;
        default:
          return false;
      }
    }
  };

  const filterDeliveryOptions = (deliveryMethod) => {
    if (deliveryMethod) {
      const code = deliveryMethod.split(' ')[0];
      const filteredByParent =
        adverseDrugOptions.specialStudiesAdverseDrugEventsDeliveryRouteSpecification.filter(
          (option) => option.parentCode === code
        );
      setOption('');
      setAllOptions(filteredByParent);
    }
  };

  const filterEventType = (intent) => {
    if (intent === intent0 || intent === intent3) {
      const eventTypeOptions =
        adverseDrugOptions.specialStudiesAdverseType.filter((val, i) => {
          return i !== 2 && i !== 3;
        });
      setOption('');
      setAllOptions(eventTypeOptions);
    }
    if (intent === intent1) {
      setAllOptions([adverseDrugOptions.specialStudiesAdverseType[3]]);
      setOption('');
    }
    if (intent === intent2) {
      setAllOptions([adverseDrugOptions.specialStudiesAdverseType[2]]);
      setOption('');
    }
  };

  const onFocus = () => {
    switch (id) {
      case 'eventType':
        filterEventType(caseData.intent);
        return;
      case 'drug1DeliveryMethodSpecify':
        filterDeliveryOptions(formData.drug1DeliveryMethod);
        break;
      case 'drug2DeliveryMethodSpecify':
        filterDeliveryOptions(formData.drug2DeliveryMethod);
        break;
      case 'drug3DeliveryMethodSpecify':
        filterDeliveryOptions(formData.drug3DeliveryMethod);
        break;
      case 'drug4DeliveryMethodSpecify':
        filterDeliveryOptions(formData.drug4DeliveryMethod);
        break;
      default:
        break;
    }
  };

  return (
    <Combobox
      aria-label={labelledby ? `${labelledby} ${label}` : label}
      className="combobox"
      id={id}
      openOnFocus
      onSelect={(e) => {
        setTouched(false);
        onChange(e, id);
      }}
    >
      <span className="tooltip__wrapper">
        <ComboboxInput
          className={`input input--text input--${size} ${
            formErrors[id] ? 'input--error' : ''
          }`}
          disabled={disableInputCheck()}
          onChange={(e) => {
            setTouched(true);
            onChange(e, id);
          }}
          onClick={() => setTouched(true)}
          selectOnClick
          value={formData[id]}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {tooltip && (caseData.intent === intent0 || !display) && (
          <div
            className={`tooltip__content tooltip__content--${tooltipSize}`}
            dangerouslySetInnerHTML={{ __html: tooltip }}
          ></div>
        )}
        {filteredOptions && touched && currentForm !== 'mainCase' && (
          <ComboboxPopover className="shadow-popup">
            {filteredOptions.length > 0 && (
              <ComboboxList ref={containerRef} persistSelection>
                {filteredOptions.slice(0, 100).map((result, index) => (
                  <ComboboxOption
                    key={index}
                    value={`${result.code} ${result.name}`}
                  >
                    <ComboboxOptionText />
                  </ComboboxOption>
                ))}
              </ComboboxList>
            )}
            {filteredOptions.length === 0 &&
              !formData[id] &&
              allOptions.length > 0 && (
                <ComboboxList ref={containerRef} persistSelection>
                  {allOptions.slice(0, 100).map((result, index) => (
                    <ComboboxOption
                      key={index}
                      value={`${result.code} ${result.name}`}
                    >
                      <ComboboxOptionText />
                    </ComboboxOption>
                  ))}
                </ComboboxList>
              )}
            {filteredOptions.length === 0 && formData[id] && (
              <span style={{ display: 'block', margin: 8 }}>
                No results found
              </span>
            )}
            {allOptions.length === 0 && (
              <span style={{ display: 'block', margin: 8 }}>
                No results found
              </span>
            )}
          </ComboboxPopover>
        )}
      </span>
    </Combobox>
  );
});

export default ComboBox;
