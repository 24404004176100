import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MaskedInput from 'react-maskedinput';
import ReactModal from 'react-modal';
import { CaseDataContext, UserDataContext } from '../../Context';
import { calculateOOW } from '../../Pages/Dashboard/utils';
import { dropdownCodes } from '../../dropdownCodes';
import { isValidDate, allowedDateKeys, dateFromArgs } from '../../utils';

const {
  basicYes,
  capsNo,
  caused4,
  causedOther,
  deliveryMethod88,
  eventType6,
  firearmOther,
  injuryEvent4,
  injuryEventOther,
  maritalOther,
  otherInh,
  otherInj,
  otherOra,
} = dropdownCodes;

const PrecisionModalMonth = ({
  modal,
  toggleModal,
  onChange,
  setMonthModal,
}) => {
  const handleClose = () => {
    toggleModal(setMonthModal, modal)
  }
  return (
  <ReactModal
    isOpen={modal}
    onRequestClose={handleClose}
    contentLabel="Confirm Month"
    className="modal"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
  >
    <section>
      <div className="modal__header">
        <button className="button modal__header-button" onClick={handleClose}>
          <span className="ut-only-sr">Close Modal</span>
          <span className="fa-icon">&#xf00d;</span>
        </button>
      </div>
      <h2 className="header-1 modal__h2">Confirm Month</h2>
      <div className="modal__body">
        <p>Do you know that the injury occured in January?</p>
      </div>
      <div className="modal__buttons">
        <button
          className="button button--primary ut-margin-right-1-em"
          onClick={() => {
            onChange('M', 'precision');
            handleClose()
          }}
        >
          Yes
        </button>
        <button
          className="button button--secondary ut-margin-right-1-em"
          onClick={() => {
            onChange('Y', 'precision');
            handleClose()
          }}
        >
          No
        </button>
      </div>
    </section>
  </ReactModal>
)};

const PrecisionModalDay = ({
  dateString,
  modal,
  toggleModal,
  onChange,
  setDayModal,
  setMonthModal,
}) => {
  let month = '';
  if (isValidDate(dateString)) {
    const date = dateFromArgs(dateString)
    month = date.getMonth() + 1
  }
  const handleClose = () => {
    toggleModal(setDayModal, modal)
  }
  return (
    <ReactModal
      isOpen={modal}
      onRequestClose={handleClose}
      contentLabel="Confirm Day"
      className="modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <section>
        <div className="modal__header">
          <button className="button modal__header-button" onClick={handleClose}>
            <span className="ut-only-sr">Close Modal</span>
            <span className="fa-icon">&#xf00d;</span>
          </button>
        </div>
        <h2 className="header-1 modal__h2">Confirm Day</h2>
        <div className="modal__body">
          <p>Do you know that the injury occured on the 1st of the month?</p>
        </div>
        <div className="modal__buttons">
          <button
            className="button button--primary ut-margin-right-1-em"
            onClick={() => {
              onChange('D', 'precision');
              handleClose()
            }}
          >
            Yes
          </button>
          <button
            className="button button--secondary ut-margin-right-1-em"
            onClick={() => {
              if (month !== 1) {
                onChange('M', 'precision');
              }
              if (month === 1) {
                setMonthModal(true);
              }
              handleClose()
            }}
          >
            No
          </button>
        </div>
      </section>
    </ReactModal>
  );
};

const TextInput = ({ el, formData, formErrors, setFormErrors, onChange }) => {
  const [isOow, setIsOowW] = useState(false);
  const [OowChecked, setoowChecked] = useState(false);
  const { existingCaseId } = useParams();
  const { userData } = useContext(UserDataContext);
  const caseData = useContext(CaseDataContext);
  const [dayModal, setDayModal] = useState(false);
  const [monthModal, setMonthModal] = useState(false);
  const {
    id,
    label,
    labelledby,
    maxLength,
    placeholder,
    type,
    size,
    tooltip,
    tooltipSize,
    tooltipType,
    tooltipWrapper,
  } = el;
  const { oowValid } = userData;

  const precisionCheck = (dateString) => {
    const date = dateString.split(/[\/-]/);
    const month = parseInt(date[0]);
    const day = parseInt(date[1]);
    if (day === 1) {
      setDayModal(true);
    }
    if (month === 1 && day !== 1) {
      setMonthModal(true);
    }
  };

  const onBlur = (e) => {
    if (id === 'dateInjured') {
      precisionCheck(e.target.value);
    }
  };

  const onBlurDate = (e) => {
    const value = e.target.value
    if(value && !isValidDate(value)){
      setFormErrors((prevState) => ({ ...prevState, [id]: 'invalid date' }));
      return
    }
    // if blur was triggered while clicking on the 
    // if(e.relatedTarget === document.querySelector('.save-button')){
    //   return
    // }
    if (id === 'dateInjured') {
      precisionCheck(e.target.value);
    }
  };

  const onKeyDownDate = (e) => {
    // handle date inputs
    if(!allowedDateKeys.includes(e.key)){
      e.preventDefault()
    }
  }

  const props = {
    'aria-label': labelledby ? `${labelledby} ${label}` : label,
    className: `input input--${type} input--${size} ${
      formErrors[id] ? 'input--error' : ''
    }`,
    disabled:
      existingCaseId && !oowValid && calculateOOW(caseData.treatmentDate)
        ? true
        : false,
    id,
    maxLength: maxLength || '',
    onChange: (e) => onChange(e, id),
    onBlur,
    placeholder: placeholder || '',
    value: formData[id],
    type: type,
  };

  if(props.type === 'date') {
    // props.type = 'text'
    // props.onBlur = onBlurDate
    // props.onKeyDown = onKeyDownDate
    if(formData[id]){
      props.value = new Date(formData[id]).toISOString().split('T')[0]
    }
  }

  const toggleModal = (setter, modal) => {
    setter(!modal);
    const el = document.querySelector('[aria-label="Marital Status:"]');
    el.focus();
  };


  const disableInputs = (inputData) => {
    if (
      inputData === deliveryMethod88 ||
      inputData === otherInh ||
      inputData === otherInj ||
      inputData === otherOra
    ) {
      return false;
    }
    return true;
  };

  const medListDrugNameCheck = (eventType, value) => {
    if (eventType === eventType6) return true;
    return value ? false : true;
  };
  const medListNameCheck = (eventType, firstMedValue, nextMedValue) => {
    if (eventType === eventType6) return true;
    if (!firstMedValue && !nextMedValue) return true;
      if (!firstMedValue && nextMedValue) 
        return false;
    return false;
  };
  const substanceNameCheck = (value) => {
    return value ? false : true;
  };

  const disableInputCheck = (e) => {
    if (existingCaseId && !oowValid && isOow) {
      return calculateOOW(caseData.treatmentDate);
    }
    switch (id) {
      case 'primaryReason':
      case 'drug1Name':
      case 'drug1Amount':
      case 'finalDiagnosis':
      case 'treatmentsGiven':
      case 'drugTestResults':
      case 'medList0':
        if (formData.eventType === eventType6) return true;
        return false;
      case 'medList1':
        return medListNameCheck(formData.eventType, formData.medList0, formData.medList1);
      case 'medList2':
        return medListNameCheck(formData.eventType, formData.medList1, formData.medList2);
      case 'medList3':
        return medListNameCheck(formData.eventType, formData.medList2, formData.medList3);
      case 'medList4':
        return medListNameCheck(formData.eventType, formData.medList3, formData.medList4);
      case 'medList5':
        return medListNameCheck(formData.eventType, formData.medList4, formData.medList5);
      case 'medList6':
        return medListNameCheck(formData.eventType, formData.medList5, formData.medList6);
      case 'medList7':
        return medListNameCheck(formData.eventType, formData.medList6, formData.medList7);
      case 'medList8':
        return medListNameCheck(formData.eventType, formData.medList7, formData.medList8);
      case 'medList9':
        return medListNameCheck(formData.eventType, formData.medList8, formData.medList9);
      case 'drug2Name':
        return medListDrugNameCheck(formData.eventType, formData.drug1Name);
      case 'drug3Name':
        return medListDrugNameCheck(formData.eventType, formData.drug2Name);
      case 'drug4Name':
        return medListDrugNameCheck(formData.eventType, formData.drug3Name);
      case 'drug2Amount':
        return medListDrugNameCheck(formData.eventType, formData.drug2Name);
      case 'drug3Amount':
        return medListDrugNameCheck(formData.eventType, formData.drug3Name);
      case 'drug4Amount':
        return medListDrugNameCheck(formData.eventType, formData.drug4Name);
      case 'substanceAmount1':
      case 'substanceName2':
        return substanceNameCheck(formData.substanceName1);
      case 'substanceAmount2':
      case 'substanceName3':
        return substanceNameCheck(formData.substanceName2);
      case 'substanceAmount3':
      case 'substanceName4':
        return substanceNameCheck(formData.substanceName3);
      case 'substanceAmount4':
        return substanceNameCheck(formData.substanceName4);
      case 'drug1Specify':
        if (formData.drug1DeliveryMethod) {
          return disableInputs(formData.drug1DeliveryMethod);
        }
        return true;
      case 'drug2Specify':
        if (formData.drug2DeliveryMethod) {
          return disableInputs(formData.drug2DeliveryMethod);
        }
        return true;
      case 'drug3Specify':
        if (formData.drug3DeliveryMethod) {
          return disableInputs(formData.drug3DeliveryMethod);
        }
        return true;
      case 'drug4Specify':
        if (formData.drug4DeliveryMethod) {
          return disableInputs(formData.drug4DeliveryMethod);
        }
        return true;
      case 'drug1DeliveryMethodOtherSpecify':
        if (formData.eventType === eventType6) return true;
        if (formData.drug1DeliveryMethodSpecify)
          return disableInputs(formData.drug1DeliveryMethodSpecify);
        return true;
      case 'drug2DeliveryMethodOtherSpecify':
        if (formData.eventType === eventType6) return true;
        if (formData.drug2DeliveryMethodSpecify)
          return disableInputs(formData.drug2DeliveryMethodSpecify);
        return true;
      case 'drug3DeliveryMethodOtherSpecify':
        if (formData.eventType === eventType6) return true;
        if (formData.drug3DeliveryMethodSpecify)
          return disableInputs(formData.drug3DeliveryMethodSpecify);
        return true;
      case 'drug4DeliveryMethodOtherSpecify':
        if (formData.eventType === eventType6) return true;
        if (formData.drug4DeliveryMethodSpecify)
          return disableInputs(formData.drug4DeliveryMethodSpecify);
        return true;
      case 'precision':
        return true;
      case 'testResults':
        if (formData.alcoholDrugTests === basicYes) return false;
        return true;
      case 'examinationSpecify':
        if (formData.examination === capsNo) return false;
        return true;
      case 'prophylacticSpecify':
        if (formData.prophylactic === capsNo) return false;
        return true;
      case 'contraceptionSpecify':
        if (formData.contraception === capsNo) return false;
        return true;
      case 'contactedOtherSpecify':
        if (formData.contactedOther === basicYes) return false;
        return true;
      case 'otherSymptomsSpecify':
        if (formData.otherSymptoms === basicYes) return false;
        return true;
      case 'otherTreatmentSpecify':
        if (formData.otherTreatment === basicYes) return false;
        return true;
      case 'intentSpecify':
        if (formData.patientIntent === injuryEvent4) return false;
        return true;
      case 'eventSpecify':
        if (formData.injuryEvent === injuryEventOther) return false;
        return true;
      case 'maritalOther':
        if (formData.maritalStatus === maritalOther) return false;
        return true;
      case 'bacLevel':
        if (formData.alcoholSI === basicYes) return false;
        return true;
      case 'firearmOther':
        if (formData.firearmType === firearmOther) return false;
        return true;
      case 'causedByOther':
        if (formData.causedBy === causedOther || formData.causedBy === caused4)
          return false;
        return true;
      case 'transportedOther':
        if (formData.transportedToER === causedOther) return false;
        return true;
      case 'employmentStatusSpecify':
        if (formData.employmentStatus === causedOther) return false;
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (caseData.treatmentDate && existingCaseId && !OowChecked) {
      setIsOowW(calculateOOW(caseData.treatmentDate));
      setoowChecked(true);
    }
  }, [existingCaseId, caseData.treatmentDate, isOow, OowChecked]);

  return (
    <>
      {id === 'dateInjured' && (
        <>
          <input {...props}  />
          {/* <input {...props} type='text' onBlur={onBlur} /> */}
          <PrecisionModalDay
            modal={dayModal}
            dateString={formData.dateInjured}
            toggleModal={toggleModal}
            setDayModal={setDayModal}
            setMonthModal={setMonthModal}
            onChange={onChange}
          />
          <PrecisionModalMonth
            modal={monthModal}
            toggleModal={toggleModal}
            setMonthModal={setMonthModal}
            onChange={onChange}
          />
        </>
      )}
      {(id === 'arrivalTimeSI' || id === 'arrivalTimeAS') && (
        <>
          <span
            className={`tooltip__wrapper tooltip__wrapper--${tooltipWrapper}`}
          >
            <MaskedInput
              {...props}
              mask="11:11"
              disabled={disableInputCheck()}
            />
            {tooltip && (
              <div
                className={`tooltip__content  tooltip__content--${tooltipSize} tooltip__content--${tooltipType}`}
                dangerouslySetInnerHTML={{ __html: tooltip }}
              ></div>
            )}
          </span>
        </>
      )}
      {id !== 'dateInjured' &&
        id !== 'arrivalTimeSI' &&
        id !== 'arrivalTimeAS' && (
          <>
            <span
              className={`tooltip__wrapper tooltip__wrapper--${tooltipWrapper}`}
            >
              <input {...props} disabled={disableInputCheck()} />
              {tooltip && (
                <div
                  className={`tooltip__content  tooltip__content--${tooltipSize} tooltip__content--${tooltipType}`}
                  dangerouslySetInnerHTML={{ __html: tooltip }}
                ></div>
              )}
            </span>
          </>
        )}
    </>
  );
};

export default TextInput;
