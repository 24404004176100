import React, { useState } from 'react';
import { inputs } from './utils';
import { renderWrapper } from '../../../../Components/CustomComponents';
import { dropdownCodes } from '../../../../dropdownCodes';

const { capsYes, capsNo } = dropdownCodes;

const Assault = (props) => {
  const {
    currentForm,
    formData,
    formErrors,
    setButtonType,
    setFormData,
    setFormErrors,
    setUnsavedChanges,
  } = props;
  const [touched, setTouched] = useState(false);
  const onChange = (e, name) => {
    setTouched(true);
    setUnsavedChanges(true);
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }

    switch (name) {
      case 'sexuallyAssaulted':
        if (value !== capsYes)
          setFormData((prevState) => ({
            ...prevState,
            examination: '',
            examinationSpecify: '',
            prophylactic: '',
            prophylacticSpecify: '',
            contraception: '',
            contraceptionSpecify: '',
          }));
        break;
      case 'examination':
        if (value !== capsNo)
          setFormData((prevState) => ({
            ...prevState,
            examinationSpecify: '',
          }));
        break;
      case 'prophylactic':
        if (value !== capsNo)
          setFormData((prevState) => ({
            ...prevState,
            prophylacticSpecify: '',
          }));
        break;
      case 'contraception':
        if (value !== capsNo)
          setFormData((prevState) => ({
            ...prevState,
            contraceptionSpecify: '',
          }));
        break;
      default:
        break;
    }

    value = value.replace(/[^\x00-\x7F]/g, '');
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };
  return (
    <div
      className="list-tab-contents"
      aria-live="polite"
      aria-hidden={currentForm === 'assault' ? false : true}
    >
      <h1>Assault Study</h1>
      <form className="ut-max-width-900">
        {inputs.map((input) => {
          return renderWrapper({
            input,
            formData,
            formErrors,
            setFormErrors,
            onChange,
          });
        })}
      </form>
    </div>
  );
};
export default Assault;
