import {
  errorMsg,
  setErrorMsg,
  listErrors,
  createList,
} from '../../../Validations';
import { dropdownCodes } from '../../../../../dropdownCodes';

const { basicNo, basicYes } = dropdownCodes;

const conditionErrors = (condition, option, value) => {
  if (condition !== option && value) {
    return errorMsg.blank;
  } else if (condition === option && !value) {
    return errorMsg.required.basic;
  }
  return false;
};

const motorVehicleErrors = (
  condition1,
  condition2,
  product1,
  product2,
  product3,
  value
) => {
  if (
    (product1 === condition1 ||
      product2 === condition1 ||
      product3 === condition1) &&
    product1 !== condition2 &&
    product2 !== condition2 &&
    product3 !== condition2 &&
    !value
  ) {
    return errorMsg.required.basic;
  }
  if (
    (product1 === condition2 ||
      product2 === condition2 ||
      product3 === condition2) &&
    product1 !== condition1 &&
    product2 !== condition1 &&
    product3 !== condition1 &&
    value
  ) {
    return errorMsg.blank;
  }
};

const validateMotorVehicle = ({
  formErrors,
  formData,
  mainCaseData,
  setFormErrors,
  options,
}) => {
  const { product1, product2, product3 } = mainCaseData;
  const { carSeat, collision, inMotion, vehiclePosition } = formData;
  let err;
  let errArr = [];
  const yesNoList = createList(options.specialStudiesNhtsaYesNo);
  const yesNoUnknownList = createList(options.specialStudiesNhtsaYesNoUnknown);
  const hazardPatternList = createList(
    options.specialStudiesNhtsaHazardPattern
  );
  const positionList = createList(options.specialStudiesNhtsaVictimPosition);
  const inMotionList = createList(options.specialStudiesNhtsaVictimMotion);
  const bodyTypeList = createList(options.specialStudiesNhtsaVehicleBodyType);
  Object.keys(formErrors).forEach((key) => {
    switch (key) {
      case 'collision':
        if (formData[key]) {
          err = listErrors('basic', yesNoList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        err = motorVehicleErrors(
          product1,
          product2,
          product3,
          formData[key]
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'carSeat':
        if (formData[key]) {
          err = listErrors('basic', yesNoList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        err = motorVehicleErrors(
          product1,
          product2,
          product3,
          formData[key]
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'localeInfo':
      case 'hazardPattern':
      case 'inMotion':
      case 'vehiclePosition':
      case 'bodyType':
      case 'vehicleERRecord':
        if ((collision === basicNo || carSeat === basicNo) && !formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (
          (key === 'inMotion' || key === 'vehiclePosition') &&
          formData[key]
        ) {
          err = listErrors('basic', yesNoUnknownList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'hazardPattern' && formData[key]) {
          err = listErrors('hazardPattern', hazardPatternList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'bodyType' && formData[key]) {
          err = listErrors('bodyType', bodyTypeList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'enterExitVehicle':
        if (formData[key]) {
          err = listErrors('enterExit', inMotionList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        err = conditionErrors(basicNo, inMotion, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'positionCode':
        if (formData[key]) {
          err = listErrors('position', positionList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        err = conditionErrors(basicYes, vehiclePosition, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      default:
        return errArr;
    }
  });
  const filtered = [...new Set(errArr)].filter(
    (err) => err !== undefined && err !== false
  );
  return filtered;
};

export default validateMotorVehicle;
