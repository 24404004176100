import React, { useContext } from 'react';
import { UserDataContext } from '../../../../Context';
import { renderWrapperMainCase } from '../../../../Components/CustomComponents';

const BottomSection = ({
  bottomLeft,
  bottomRight,
  formData,
  formErrors,
  formWarnings,
  hospitalType,
  onChange,
  dateChanged,
  setDateChanged,
  setFormData,
  setFormErrors,
}) => {
  const args = {
    formData,
    formErrors,
    formWarnings,
    onChange,
    dateChanged,
    setDateChanged,
    setFormData,
    setFormErrors,
  };
  const { userData } = useContext(UserDataContext);
  const { hospital } = userData;
  return (
    <div className="flex flex--two-col-divider">
      <div className="flex__child">
        {bottomLeft.map((inputs) => {
          return renderWrapperMainCase({
            inputs,
            ...args,
          });
        })}
      </div>
      <div className="flex__child">
        {bottomRight.map((inputs) => {
          if (
            hospital &&
            hospital.hospitalTypeCurrent === 'NEISS-AIP' &&
            inputs.id === 'drugsAlcohol'
          ) {
            return false;
          } else {
            return renderWrapperMainCase({
              inputs,
              ...args,
            });
          }
        })}
      </div>
    </div>
  );
};

export default BottomSection;
