const inputs = [
  {
    helper: '(24 hour clock)',
    field: {
      id: 'arrivalTimeSI',
      label: 'Time of arrival at ED',
      maxLength: '5',
      placeholder: '00:00',
      size: 'medium',
      type: 'text',
    },
  },
  {
    helper:
      '(Please record the most severe intent, e.g. intent to die before intent to harm oneself.)',
    field: {
      id: 'patientIntent',
      label:
        'How did the patient describe his/her intent to the staff, other people, or in a (suicide) note?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'intentSpecify',
      label: 'Specify patient intent:',
      maxLength: '60',
      size: 'large',
      type: 'text',
    },
  },
  {
    helper:
      '(Please record most severe diagnosis/description, e.g. attempt before gesture.)',
    field: {
      id: 'injuryEvent',
      label: 'How did the staff describe or diagnose the injury event?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'eventSpecify',
      label: 'Specify injury event:',
      maxLength: '45',
      size: 'large',
      type: 'text',
    },
  },
  {
    helper: '(Please check all risk factors mentioned.)',
    field: {
      id: 'riskFactors',
      label: 'Were any of the listed risk factors mentioned in the ED notes?',
      size: 'large',
      type: 'table',
    },
  },
  {
    field: {
      id: 'alcoholSI',
      label: 'Was alcohol used by the patient at the time of the injury event?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'bacLevel',
      label: 'If available: BAC level:',
      maxLength: '45',
      size: 'large',
      type: 'text',
    },
  },
  {
    field: {
      id: 'drugs',
      label:
        'Were recreational drugs (e.g. cocaine, heroin, marijuana, ecstasy) used by the patient at the time of the injury event?',
      size: 'large',
      type: 'select',
    },
  },
  {
    fieldset:
      'If the self-harm method was poisoning, please record up to four medications, drugs, or substances taken by the patient.',
    id: 'medications',
    flexField: [
      {
        id: 'substanceName1',
        label: 'Name of substance 1:',
        maxLength: '45',
        size: 'large',
        type: 'text',
      },
      {
        id: 'substanceAmount1',
        label: 'Amount of substance 1:',
        maxLength: '45',
        size: 'large',
        type: 'text',
      },
      {
        id: 'substanceName2',
        label: 'Name of substance 2:',
        maxLength: '45',
        size: 'large',
        type: 'text',
      },
      {
        id: 'substanceAmount2',
        label: 'Amount of substance 2:',
        maxLength: '45',
        size: 'large',
        type: 'text',
      },
      {
        id: 'substanceName3',
        label: 'Name of substance 3:',
        maxLength: '45',
        size: 'large',
        type: 'text',
      },
      {
        id: 'substanceAmount3',
        label: 'Amount of substance 3:',
        maxLength: '45',
        size: 'large',
        type: 'text',
      },
      {
        id: 'substanceName4',
        label: 'Name of substance 4:',
        maxLength: '45',
        size: 'large',
        type: 'text',
      },
      {
        id: 'substanceAmount4',
        label: 'Amount of substance 4:',
        maxLength: '45',
        size: 'large',
        type: 'text',
      },
    ],
  },
  {
    field: {
      id: 'patientAdmitted',
      label:
        'If the patient was admitted or transferred, please specify where s/he went.',
      size: 'large',
      type: 'select',
    },
  },
];

export default inputs;
