import React from 'react';
import ComboBoxMainCase from './ComboBoxMainCase';
import TextInputMainCase from './TextInputMainCase';
import CheckboxTable from './CheckboxTable';
import ComboBox from './ComboBox';
import TextArea from './TextArea';
import TextInput from './TextInput';
import { renderError, renderWarning } from './render';

const renderInput = ({
  currentForm,
  input,
  formData,
  formErrors,
  formWarnings,
  onChange,
  onToggle,
  setFormData,
  setFormErrors,
}) => {
  if (input.field) {
    const { id, indent, label, type, helper, screenReader, wrapper } = input.field;
    const props = {
      currentForm,
      el: input.field,
      formData,
      formErrors,
      onChange,
      onToggle,
      setFormData,
      setFormErrors,
    };
    if (type === 'table') return <CheckboxTable {...props} />;

    if (type === 'textArea')
      return (
        <div className="input-wrapper" key={id}>
          {id === 'localeInfo' && (
            <p className="text-helper">
              Please review the entire case record and provide the following
              information. Include all information from the ambulance run,
              nurse, physician, and any other source, even if some information
              is redundant or contradictory.
            </p>
          )}
          <label className={`${type === 'table' ? '' : 'flex'}`}>
            <span
              className={`ut-indent-${indent} label  ${
                formErrors[id] ? 'input--error' : ''
              }`}
            >
              {label}
              {helper && <span className="text-helper"> {helper}</span>}
              {screenReader && (
                <span className="ut-only-sr"> {screenReader}</span>
              )}
            </span>
            <TextArea {...props} />
          </label>
          {formErrors[id] && renderError(formErrors, id)}
          {formWarnings && formWarnings[id] && renderWarning(formWarnings, id)}
        </div>
      );

    return (
      <div
        className={`input-wrapper input-wrapper--left input-wrapper--${wrapper}`}
        key={id}
      >
        <label className={`${type === 'table' ? '' : 'flex'}`}>
          <span
            className={`ut-indent-${indent} label  ${
              formErrors[id] ? 'input--error' : ''
            }`}
          >
            {label}
            {helper && <span className="text-helper"> {helper}</span>}
            {screenReader && (
              <span className="ut-only-sr"> {screenReader}</span>
            )}
          </span>

          {type === 'select' && <ComboBox {...props} />}
          {type === 'text' && !input.options && <TextInput {...props} />}
          {type === 'date' && <TextInput {...props} />}
        </label>
        {formErrors[id] && renderError(formErrors, id)}
        {formWarnings && formWarnings[id] && renderWarning(formWarnings, id)}
      </div>
    );
  }
  return input.map((el) => {
    const { id, indent, label, type } = el;
    const props = { el, formData, formErrors, onChange, setFormErrors, };
    if (el.row) {
      return (
        <div className="input-wrapper" key={el.id}>
          <label className="flex">
            <span className={`ut-indent-${indent} label`}>
              {label}
              {el.helper && <span className="text-helper"> {el.helper}</span>}
              {el.screenReader && (
                <span className="ut-only-sr"> {el.screenReader}</span>
              )}
            </span>

            {type === 'select' && <ComboBox {...props} />}
            {type === 'text' && <TextInput {...props} />}
            {type === 'date' && <TextInput {...props} />}
          </label>
          {formErrors[id] && renderError(formErrors, id)}{' '}
          {formWarnings && formWarnings[id] && renderWarning(formWarnings, id)}
        </div>
      );
    }
    return (
      <div className="flex__child flex__child--flex-end input-wrapper" key={id}>
        <label className="flex">
          <span className={`ut-indent-${indent} label`}>{label}</span>
          {type === 'select' && <ComboBox {...props} />}
          {type === 'text' && <TextInput {...props} />}
          {type === 'date' && <TextInput {...props} />}
        </label>
        {formErrors[id] && renderError(formErrors, id)}{' '}
        {formWarnings && formWarnings[id] && renderWarning(formWarnings, id)}
      </div>
    );
  });
};

const renderInputMainCase = (props) => {
  const { input } = props
  if (input.type === 'combobox') {
    return <ComboBoxMainCase {...props} key={input.id} />;
  } else {
    return <TextInputMainCase {...props} key={input.id} />;
  }
};

const renderOptions = (list) =>
  list.map((option) => <option key={option} value={option} />);

const renderWrapper = ({
  input,
  ...args
}) => {
  if (input.flex)
    return (
      <div className="flex flex--two-col-wrap" key={input.id}>
        {renderInput({
          input: input.flex,
          ...args,
        })}
      </div>
    );
  if (input.flexField)
    return (
      <div className="fieldset flex flex--two-col-wrap" key={input.id}>
        {renderInput({
          input: input.flexField,
          ...args,
        })}
      </div>
    );
  if (input.field) {
    return (
      <div className="fieldset" key={input.field.id}>
        {renderInput({
          input,
          ...args,
        })}
      </div>
    );
  }
};

const renderWrapperMainCase = ({
  inputs,
  ...args
}) => {
  if (inputs.row) {
    return (
      <div className="fieldset flex flex--space-between" key={inputs.id}>
        {inputs.row.map((input) => renderInputMainCase({ ...args, input }))}
      </div>
    );
  }
  if (inputs.field) {
    return (
      <div className="fieldset" key={inputs.id}>
        {inputs.field.map((field) => {
          if (field.row) {
            return (
              <div className="flex flex--space-between" key={field.id}>
                {field.row.map((input) =>
                  renderInputMainCase({ ...args, input })
                )}
              </div>
            );
          }
          return renderInputMainCase({ ...args, input: field });
        })}
      </div>
    );
  }
  return (
    <div className="fieldset" key={inputs.id}>
      {renderInputMainCase({ ...args, input: inputs })}
    </div>
  );
};

export {
  renderError,
  renderWarning,
  renderInput,
  renderInputMainCase,
  renderOptions,
  renderWrapper,
  renderWrapperMainCase,
};
