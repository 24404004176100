import {
  errorMsg,
  setErrorMsg,
  timeErrors,
  listErrors,
  createList,
} from '../../../Validations';
import { dropdownCodes } from '../../../../../dropdownCodes';
const { assaultNo, capsYes } = dropdownCodes;

const assaultErrors = (preReq, value) => {
  if (preReq === assaultNo && !value) return errorMsg.required.basic;
};

const validateAssault = ({
  formErrors,
  formData,
  mainCaseData,
  setFormErrors,
  options,
}) => {
  const { age } = mainCaseData;
  const { examination, prophylactic, contraception, sexuallyAssaulted } =
    formData;
  const yesNoList = createList(options.specialStudiesAssaultYesNoUnknown);
  const sexInvolvedList = createList(options.specialStudiesAssaultSex);
  const perpetratorList = createList(
    options.specialStudiesAssaultNumberOfPerpetrators
  );
  let err;
  let errArr = [];
  Object.keys(formErrors).forEach((key) => {
    switch (key) {
      case 'arrivalTimeAS':
        if (formData[key] !== '99:99') {
          err = timeErrors(formData[key]);
          errArr.push(err);
          return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'sexInvolved':
        if (formData[key]) {
          err = listErrors('sexInvolved', sexInvolvedList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'amountInvolved':
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (key === 'amountInvolved' && formData[key]) {
          err = listErrors('amountInvolved', perpetratorList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'patientInitiated':
      case 'patientIntervened':
      case 'patientBystander':
      case 'policeReport':
      case 'referral':
      case 'sexuallyAssaulted':
        if (formData[key]) {
          err = listErrors('basic', yesNoList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if (key === 'amountInvolved' && formData[key]) {
          err = listErrors('amountInvolved', perpetratorList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'patientPeer':
      case 'cps':
        if (!formData[key] && ((age >= 2 && age <= 17) || (age >= 201 && age <=223))) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'examination':
      case 'prophylactic':
      case 'contraception':
        if (!formData[key] && sexuallyAssaulted === capsYes) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        break;
      case 'examinationSpecify':
        err = assaultErrors(examination, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'prophylacticSpecify':
        err = assaultErrors(prophylactic, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'contraceptionSpecify':
        err = assaultErrors(contraception, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      default:
        return errArr;
    }
  });
  const filtered = [...new Set(errArr)].filter(
    (err) => err !== undefined && err !== false
  );
  return filtered;
};

export default validateAssault;
