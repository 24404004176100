import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams, useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { db } from '../../db';
import {
  DeleteCaseOfflineModal,
  VictimIdAlert,
  VictimIdModal,
} from '../../Components/CustomComponents/Modals';
/* Default Form values */
import {
  adverseDrugFormData,
  adverseDrugFormErrors,
  assaultFormData,
  assaultFormErrors,
  childPoisoningFormData,
  childPoisoningFormErrors,
  firearmInjuryFormData,
  firearmInjuryFormErrors,
  firearmInjuryFormWarnings,
  motorVehicleFormData,
  motorVehicleFormErrors,
  mainCaseFormData,
  mainCaseFormErrors,
  mainCaseFormWarnings,
  selfInflictedFormData,
  selfInflictedFormErrors,
  workRelatedFormData,
  workRelatedFormErrors,
} from './formData';
import {
  CaseDataContext,
  UserDataContext,
  DropdownOptionsContext,
  OfflineModeContext,
} from '../../Context';
/* Utility Functions */
import {
  formatSpecialStudy,
  bodyPartCheck,
  getCurrentDate,
  resetErrors,
  resetData,
  SPECIAL_CASE_IDS,
  VICTIM_ID_DEFAULT,
  runAllChecks,
  saveAndValidate,
} from './Validations';
import { deleteCase } from '../Dashboard/utils';
import { saveVictimId } from './Forms/utils';
import { isValidDate, dateFromArgs } from '../../utils';
import { request } from '../../api';
/* Components */
import {
  SaveButton,
  ContinueButton,
} from '../../Components/CustomComponents/Buttons';
import {
  AdverseDrug,
  Assault,
  ChildPoisoning,
  FirearmInjury,
  MotorVehicle,
  MainCase,
  SelfInflicted,
  WorkRelated,
} from './Forms';
import {
  MODAL_DEFAULT,
  AlertModal,
  DeleteCaseModal,
  modalPresets,
} from '../../Components/CustomComponents/Modals';
import ReturnedErrorMessages from './ReturnedErrorMessages';

const CaseForm = ({
  current = 'mainCase',
  tabList,
  setUnsavedChanges,
  validateOnly,
}) => {
  const { instance, accounts } = useMsal();
  const { existingCaseId } = useParams();
  let history = useHistory();
  const { userData, setUserData } = useContext(UserDataContext);
  const { offlineMode } = useContext(OfflineModeContext);
  const options = useContext(DropdownOptionsContext);

  const { hospital, oowValid } = userData;
  const prevoowValid = useRef(oowValid);

  const [currentForm, setCurrentForm] = useState(current);
  const [caseId, setCaseId] = useState('');
  const [specialCaseIds, setSpecialCaseIds] = useState(SPECIAL_CASE_IDS);
  const [formList, setFormList] = useState(tabList);
  const [prodList, setProdList] = useState();
  const [wordWarnings, setWordWarnings] = useState([]);
  const [needsValidation, setNeedsValidation] = useState(false);
  const [buttonType, setButtonType] = useState('submit');
  /* Modals */
  const [alertModal, setAlertModal] = useState(modalPresets().default);
  const [needsAttention, setNeedsAttention] = useState(false);
  const [hasWarnings, setHasWarnings] = useState(false);
  const [warningMessages, setWarningMessages] = useState(false);
  const [victimIdModal, setVictimIdModal] = useState(false);
  const [victimIdAlert, setVictimIdAlert] = useState(MODAL_DEFAULT);
  const [victimIdButton, setVictimIdButton] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  /* Victim Id */
  const [victimIdCategories, setVictimIdCategories] = useState(null);
  const [victimIdData, setVictimIdData] = useState(VICTIM_ID_DEFAULT);
  /* Delete Case */
  const [deleteForm, setDeleteForm] = useState({
    password: '',
    reason: '',
    passwordIsValid: null,
    deleteRequiresPassword: false,
  });
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  /* Form Data */
  const [adverseDrugData, setAdverseDrugData] = useState(adverseDrugFormData);
  const [adverseDrugErrors, setAdverseDrugErrors] = useState(
    adverseDrugFormErrors
  );
  /* Main Case */
  const [mainCaseData, setMainCaseData] = useState(mainCaseFormData('', ''));
  const [mainCaseErrors, setMainCaseErrors] = useState(mainCaseFormErrors);
  const [mainCaseWarnings, setMainCaseWarnings] =
    useState(mainCaseFormWarnings);
  const [selfInflictedData, setSelfInflictedData] = useState(
    selfInflictedFormData
  );
  const [returnedErrorMsg, setReturnedErrorMsg] = useState([]);
  /* Special Studies */
  const [assaultData, setAssaultData] = useState(assaultFormData);
  const [assaultErrors, setAssaultErrors] = useState(assaultFormErrors);
  const [childPoisoningData, setChildPoisoningData] = useState(
    childPoisoningFormData
  );
  const [childPoisoningErrors, setChildPoisoningErrors] = useState(
    childPoisoningFormErrors
  );
  const [firearmInjuryData, setFirearmInjuryData] = useState(
    firearmInjuryFormData
  );
  const [firearmInjuryErrors, setFirearmInjuryErrors] = useState(
    firearmInjuryFormErrors
  );
  const [firearmInjuryWarnings, setFirearmInjuryWarnings] = useState(
    firearmInjuryFormWarnings
  );
  const [motorVehicleData, setMotorVehicleData] =
    useState(motorVehicleFormData);
  const [motorVehicleErrors, setMotorVehicleErrors] = useState(
    motorVehicleFormErrors
  );
  const [selfInflictedErrors, setSelfInflictedErrors] = useState(
    selfInflictedFormErrors
  );
  const [workRelatedData, setWorkRelatedData] = useState(workRelatedFormData);
  const [workRelatedErrors, setWorkRelatedErrors] = useState(
    workRelatedFormErrors
  );

  const alertFocus = document.getElementById('victimIdBtn')
    ? 'victimIdBtn'
    : 'treatmentDate';

  const handleAlerts = useCallback((modalPreset) => {
    setAlertModal(modalPreset);
  }, []);

  const handleDelete = () => setDeleteModal(!deleteModal);

  /* Props and args */
  const args = useMemo(
    () => ({
      hospitalId: hospital.groupId,
    }),
    [hospital.groupId]
  );

  const { dateOfBirth, diagnosis1, diagnosis2, treatmentDate } = mainCaseData;
  const { lastUpdateableDate } = hospital;

  const formData = useMemo(
    () => ({
      mainCaseData,
      adverseDrugData,
      assaultData,
      childPoisoningData,
      firearmInjuryData,
      motorVehicleData,
      selfInflictedData,
      workRelatedData,
    }),
    [
      mainCaseData,
      adverseDrugData,
      assaultData,
      childPoisoningData,
      firearmInjuryData,
      motorVehicleData,
      selfInflictedData,
      workRelatedData,
    ]
  );

  const formErrors = useMemo(
    () => ({
      mainCaseErrors,
      adverseDrugErrors,
      assaultErrors,
      childPoisoningErrors,
      firearmInjuryErrors,
      motorVehicleErrors,
      selfInflictedErrors,
      workRelatedErrors,
    }),
    [
      mainCaseErrors,
      adverseDrugErrors,
      assaultErrors,
      childPoisoningErrors,
      firearmInjuryErrors,
      motorVehicleErrors,
      selfInflictedErrors,
      workRelatedErrors,
    ]
  );

  const formWarnings = useMemo(
    () => ({
      mainCaseWarnings,
      firearmInjuryWarnings,
    }),
    [mainCaseWarnings, firearmInjuryWarnings]
  );
  const setFormData = useMemo(
    () => ({
      setAdverseDrugData,
      setAssaultData,
      setChildPoisoningData,
      setFirearmInjuryData,
      setMotorVehicleData,
      setMainCaseData,
      setSelfInflictedData,
      setWorkRelatedData,
    }),
    []
  );

  const setFormErrors = useMemo(
    () => ({
      setMainCaseErrors,
      setAdverseDrugErrors,
      setAssaultErrors,
      setChildPoisoningErrors,
      setFirearmInjuryErrors,
      setMotorVehicleErrors,
      setSelfInflictedErrors,
      setWorkRelatedErrors,
    }),
    []
  );

  const setFormWarnings = useMemo(
    () => ({
      setMainCaseWarnings,
      setFirearmInjuryWarnings,
    }),
    [setMainCaseWarnings, setFirearmInjuryWarnings]
  );
  const saveArgs = useMemo(
    () => ({
      account: accounts[0],
      caseId,
      formData,
      formErrors,
      formList: formList.length === 0 ? tabList : formList,
      formWarnings,
      hospitalType: hospital.hospitalTypeCurrent,
      hospital,
      instance,
      options,
      oowValid,
      returnedErrorMsg,
      specialCaseIds,
      setButtonType,
      setCaseId,
      setFormData,
      setFormErrors,
      setFormList,
      setSpecialCaseIds,
      handleAlerts,
      setUnsavedChanges,
      setFormWarnings,
      setWordWarnings,
      validateOnly,
      victimIdCategories,
      setMainCaseData,
    }),
    [
      accounts,
      caseId,
      formData,
      formErrors,
      formList,
      formWarnings,
      instance,
      hospital,
      options,
      oowValid,
      returnedErrorMsg,
      handleAlerts,
      setFormData,
      setFormErrors,
      setUnsavedChanges,
      specialCaseIds,
      setFormWarnings,
      tabList,
      validateOnly,
      victimIdCategories,
    ]
  );

  const validateArgs = useMemo(
    () => ({
      formList,
      hospital,
      mainCaseData,
      setFormData,
      setFormErrors,
      setFormList,
      treatmentDate,
    }),
    [
      formList,
      hospital,
      mainCaseData,
      setFormData,
      setFormErrors,
      setFormList,
      treatmentDate,
    ]
  );

  const formProps = {
    currentForm,
    mainCaseData,
    setButtonType,
    setUnsavedChanges,
  };

  const adverseDrugProps = {
    formData: adverseDrugData,
    formErrors: adverseDrugErrors,
    setFormData: setAdverseDrugData,
    setFormErrors: setAdverseDrugErrors,
  };

  const assaultProps = {
    formData: assaultData,
    formErrors: assaultErrors,
    setFormData: setAssaultData,
    setFormErrors: setAssaultErrors,
  };

  const childPoisoningProps = {
    formData: childPoisoningData,
    formErrors: childPoisoningErrors,
    setFormData: setChildPoisoningData,
    setFormErrors: setChildPoisoningErrors,
  };

  const firearmInjuryProps = {
    formData: firearmInjuryData,
    formErrors: firearmInjuryErrors,
    setFormData: setFirearmInjuryData,
    setFormErrors: setFirearmInjuryErrors,
    formWarnings: firearmInjuryWarnings,
    setFormWarnings: setFirearmInjuryWarnings,
  };

  const motorVehicleProps = {
    formData: motorVehicleData,
    formErrors: motorVehicleErrors,
    setFormData: setMotorVehicleData,
    setFormErrors: setMotorVehicleErrors,
  };

  const mainCaseProps = {
    caseId,
    formData: mainCaseData,
    formErrors: mainCaseErrors,
    setFormData: setMainCaseData,
    setFormErrors: setMainCaseErrors,
    setAdverseDrugData: setAdverseDrugData,
    setAssaultData: setAssaultData,
    setMotorVehicleData: setMotorVehicleData,
    prodList,
    formWarnings: mainCaseWarnings,
    setFormWarnings: setMainCaseWarnings,
    wordWarnings,
    setWordWarnings,
    hospitalType: hospital.hospitalType,
    setProdList,
  };

  const selfInflictedProps = {
    formData: selfInflictedData,
    formErrors: selfInflictedErrors,
    setFormData: setSelfInflictedData,
    setFormErrors: setSelfInflictedErrors,
  };

  const workRelatedProps = {
    formData: workRelatedData,
    formErrors: workRelatedErrors,
    setFormData: setWorkRelatedData,
    setFormErrors: setWorkRelatedErrors,
  };

  useEffect(() => {
    let hasErrors = false;
    const keys = Object.keys(formErrors);
    // loop through the formErrors object - which is a collection of objects - break as soon as an error is found
    for (let i = 0; i < keys.length; i++) {
      if (hasErrors) break;
      const values = Object.values(formErrors[keys[i]]);
      // loop through the individual formError values
      for (let j = 0; j < values.length; j++) {
        if (values[j] !== '') {
          hasErrors = true;
          break;
        }
      }
    }

    if (hasErrors || (mainCaseData.isVictimIdRequired && !mainCaseData.hasVictimIdBeenEntered)) {
      setNeedsAttention(true);
    } else {
      setNeedsAttention(false);
    }
  }, [formErrors, mainCaseData.isVictimIdRequired, mainCaseData.hasVictimIdBeenEntered]);

  useEffect(() => {
    let hasWarnings = false;
    const keys = Object.keys(wordWarnings);
    // loop through the wordWarnings object - which is a collection of objects - break as soon as an error is found
    for (let i = 0; i < keys.length; i++) {
      if (hasWarnings) break;
      const values = Object.values(wordWarnings[keys[i]]);
      // loop through the individual wordWarnings values
      for (let j = 0; j < values.length; j++) {
        if (values[j] !== '') {
          hasWarnings = true;
          break;
        }
      }
    }
    if (hasWarnings) {
      setHasWarnings(true);
      setWarningMessages(wordWarnings);
    } else {
      setHasWarnings(false);
      setWarningMessages(null);
    }
  }, [wordWarnings]);

  const handleDeleteCase = (e, name) => {
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    setDeleteForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value) {
      setDisableDeleteBtn(false);
    } else {
      setDisableDeleteBtn(true);
    }
  };

  const toggleDeleteModal = () => {
    setDeleteForm((prevState) => ({ ...prevState, reason: '', password: '' }));
    setDeleteModal(!deleteModal);
    if (
      selectedCase.deleteRequiresPassword ||
      selectedCase.deleteRequiresReason
    )
      setDisableDeleteBtn(true);
  };

  const toggleVictimIdModal = () => {
    if (victimIdAlert) setVictimIdAlert(MODAL_DEFAULT);
    setVictimIdModal((value) => !value);
  };

  const toggleVictimIdButtonErrors = () => {
    if (alertModal.error === false) {
      setVictimIdButton(true);
    } 
  }

  const handleVictimIdInput = (e) => {
    const fieldName = e.target.name;
    let value = e.target.value;
    if (fieldName === 'zip') value = e.target.value.replace(/\D/g, '');
    setVictimIdData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const closeModal = () => {
    if (!victimIdAlert.loading) {
      setVictimIdAlert(MODAL_DEFAULT);
    }
  };

  const handleReturnedErrors = (messages) => {
    setReturnedErrorMsg(messages);
  };

  const resetForm = () => {
    history.push('/newCase');
    setButtonType('submit');
    setCurrentForm('mainCase');
    setFormList(tabList);
    handleAlerts(modalPresets().default);
    resetData({ mainCaseData, setFormData, setCaseId, setSpecialCaseIds });
    resetErrors({ setFormErrors, setFormWarnings });
    setVictimIdData(VICTIM_ID_DEFAULT);
    setWordWarnings([]);
    setUnsavedChanges(false);
    const el = document.getElementById('treatmentDate');
    if (el) el.focus();
  };

  // reset save button on OOW pw success
  useEffect(() => {
    if (oowValid !== prevoowValid.current) {
      prevoowValid.current = oowValid;
      setButtonType('submit');
    }
  }, [buttonType, oowValid]);

  useEffect(() => {
    if (hospital && !mainCaseData.hospitalId) {
      setMainCaseData((prevState) => ({
        ...prevState,
        hospitalId: hospital.groupId,
      }));
    }
  }, [hospital, mainCaseData.hospitalId]);

  // set collectionDate on mount
  useEffect(() => {
    if (!existingCaseId) {
      setMainCaseData((prevState) => ({
        ...prevState,
        collectionDate: getCurrentDate(),
      }));
    }
  }, [existingCaseId]);

  // calculate age in years and months from treatmentDate and dateOfBirth
  useEffect(() => {
    if (isValidDate(treatmentDate) && isValidDate(dateOfBirth)) {
      const treatDate = dateFromArgs(treatmentDate);
      const birthDate = dateFromArgs(dateOfBirth);
      if (birthDate <= treatDate) {
        let monthCalc;
        if (birthDate.getMonth() <= treatDate.getMonth()) {
          monthCalc = Math.abs(birthDate.getMonth() - treatDate.getMonth());
        }
        if (birthDate.getMonth() > treatDate.getMonth()) {
          monthCalc = treatDate.getMonth() - birthDate.getMonth();
        }
        let monthDiff =
          monthCalc +
          Math.abs(12 * (treatDate.getFullYear() - birthDate.getFullYear()));
        if (birthDate.getDate() > treatDate.getDate()) {
          monthDiff -= 1;
        }
        if (monthDiff < 24) {
          if (monthDiff < 0) {
            setMainCaseData((prevState) => ({ ...prevState, age: '' }));
            return;
          }
          if (monthDiff === 0) {
            setMainCaseData((prevState) => ({ ...prevState, age: '201' }));
            return;
          }
          if (monthDiff < 10) {
            setMainCaseData((prevState) => ({
              ...prevState,
              age: `20${monthDiff}`,
            }));
            return;
          }
          setMainCaseData((prevState) => ({
            ...prevState,
            age: `2${monthDiff}`,
          }));
        } else {
          setMainCaseData((prevState) => ({
            ...prevState,
            age: Math.floor(monthDiff / 12),
          }));
          return;
        }
      }
    }
  }, [dateOfBirth, treatmentDate]);
  // update bodypart1 based on diagnosis1
  useEffect(() => {
    if (diagnosis1) {
      bodyPartCheck('bodyPart1', diagnosis1, setMainCaseData);
    }
  }, [diagnosis1]);

  // update bodyPart2 based on diagnosis2
  useEffect(() => {
    if (diagnosis2) {
      bodyPartCheck('bodyPart2', diagnosis2, setMainCaseData);
    }
  }, [diagnosis2]);

  // hide victim ID button on errors
  useEffect(() => {  
    if (alertModal.error) {
      setVictimIdButton(false);
    } 
  }, [alertModal.error]);

  // fetch & set victim ID categories
  useEffect(() => {
    let isSubscribed = true;
    const fetchVictimIdCategories = async () => {
      try {
        if (victimIdCategories === null) {
          const data = await request({
            ...args,
            def: 'victimIdCategories',
            // handleAlerts,
            method: 'GET',
          });
          if (data && isSubscribed) {
            setVictimIdCategories(data);
          }
          // handleAlerts(modalPresets().default);
        }
      } catch (err) {
        console.log('ERROR:', err.message);
      }
    };
    if (!offlineMode) fetchVictimIdCategories();
    return () => (isSubscribed = false);
  }, [args, offlineMode, victimIdCategories]);

  // if they landed on this page with an existingCaseId param in the URL, load and populate it.
  useEffect(() => {
    let isSubscribed = true;
    const fetchFromDb = async () => {
      if (existingCaseId) {
        try {
          const data = await db.cases
            .where('caseNumber')
            .equals(existingCaseId)
            .toArray();
          setMainCaseData(data[0]);
          if (data[0].specialStudies) {
            formatSpecialStudy({
              setFormData,
              setFormList,
              setSpecialCaseIds,
              specialStudy: data[0].specialStudies,
            });
          }
          setNeedsValidation(true);
        } catch (err) {
          handleAlerts(
            modalPresets(`Failed to fetch case from local database. ${err}`)
              .error
          );
        }
      }
    };
    const fetchCase = async () => {
      try {
        if (existingCaseId) {
          handleAlerts(modalPresets('Loading case data...').loading);

          setCaseId(existingCaseId);
          let data;
          let specialStudy;
          // Promise.All these 4 requests, they can run together and speed up the case fetching
          data = request({
            ...args,
            caseId: existingCaseId,
            def: 'caseData',
            method: 'GET',
          });

          Promise.all([data]).then(async ([dataRes]) => {
            if (dataRes && isSubscribed) {
              setMainCaseData((prevState) => ({
                ...prevState,
                ...dataRes,
                // normalize dates
                collectionDate: dateFromArgs(dataRes.collectionDate),
                dateOfBirth: dateFromArgs(dataRes.dateOfBirth),
                treatmentDate: dateFromArgs(dataRes.treatmentDate),
              }));
              setSelectedCase({
                deleteRequiresPassword: dataRes.deleteRequiresPassword,
                deleteRequiresReason: dataRes.deleteRequiresReason,
                id: dataRes.id,
              });
            }
            if (dataRes.specialStudies.length > 0 && isSubscribed) {
              formatSpecialStudy({
                setFormData,
                setFormList,
                setSpecialCaseIds,
                specialStudy: dataRes.specialStudies,
              });
            }
            if (dataRes.caseErrors) {
              setReturnedErrorMsg(dataRes.caseErrors)
            }
            if (dataRes.warnings) {
              setWordWarnings(dataRes.warnings);
            }
            setUnsavedChanges(false);
            setNeedsValidation(true);

            handleAlerts(modalPresets().default);
          });
        } else {
          setUnsavedChanges(false);
        }
      } catch (err) {
        console.log('ERROR:', err.message);
      }
    };
    if (offlineMode) {
      fetchFromDb();
    } else {
      fetchCase();
    }
    return () => (isSubscribed = false);
  }, [
    args,
    existingCaseId,
    setFormData,
    instance,
    accounts,
    offlineMode,
    handleAlerts,
    setUnsavedChanges,
  ]);

  useEffect(() => {
    if (existingCaseId && needsValidation) {
      saveAndValidate({ ...saveArgs, validateOnly: true });
      runAllChecks({
        ...validateArgs,
      });
      setNeedsValidation(false);
      setVictimIdButton(true);
    }
  }, [
    existingCaseId,
    needsValidation,
    setNeedsValidation,
    saveArgs,
    validateArgs,
  ]);

  useEffect(() => {
    if (victimIdData) {
      if (
        victimIdData.submitDisabled &&
        victimIdData.victim.length >= 1 &&
        victimIdData.address.length >= 1 &&
        victimIdData.city.length >= 1 &&
        victimIdData.zip.length === 5 &&
        (victimIdData.homePhone.replace(/\D/g, '').length === 10 ||
          victimIdData.workPhone.replace(/\D/g, '').length === 10)
      ) {
        setVictimIdData((prevState) => ({
          ...prevState,
          submitDisabled: false,
        }));
      }
    }
  }, [victimIdData]);

  useEffect(() => {
    if (victimIdData) {
      if (
        !victimIdData.submitDisabled &&
        victimIdData.victim.length >= 1 &&
        victimIdData.address.length >= 1 &&
        victimIdData.city.length >= 1 &&
        victimIdData.zip.length === 5 &&
        victimIdData.homePhone.replace(/\D/g, '').length < 10 &&
        victimIdData.workPhone.replace(/\D/g, '').length < 10
      ) {
        setVictimIdData((prevState) => ({
          ...prevState,
          submitDisabled: true,
        }));
      }
    }
  }, [victimIdData]);
  useHotkeys(
    'shift + ctrl + 1, shift + ctrl + 2, shift + ctrl + 3, shift + ctrl + 4, shift + ctrl + 5, shift + ctrl + 6, shift + ctrl + 7, alt + ctrl + =, alt + ctrl + -, cmd + ctrl + =, cmd + ctrl + -',
    (event, handler) => {
      let element;
      let length;
      let index;
      switch (handler.key) {
        case 'shift+ctrl+1':
          element = document.querySelector('[aria-label="Diagnosis 1:"]');
          element.focus();
          break;
        case 'shift+ctrl+2':
          element = document.querySelector('[aria-label="Body Part 1:"]');
          element.focus();
          break;
        case 'shift+ctrl+3':
          element = document.querySelector('[aria-label="Diagnosis 2:"]');
          element.focus();
          break;
        case 'shift+ctrl+4':
          element = document.querySelector('[aria-label="Body Part 2:"]');
          element.focus();
          break;
        case 'shift+ctrl+5':
          element = document.querySelector('[aria-label="Product 1:"]');
          element.focus();
          break;
        case 'shift+ctrl+6':
          element = document.querySelector('[aria-label="Product 2:"]');
          element.focus();
          break;
        case 'shift+ctrl+7':
          element = document.querySelector('[aria-label="Product 3:"]');
          element.focus();
          break;
        case 'alt+ctrl+=':
        case 'cmd+ctrl+=':
          length = formList.length;
          index = formList.findIndex(({ id }) => {
            if (id === currentForm) return true;
            return false;
          });
          if (length === 1) return;
          if (index + 1 < length) {
            setCurrentForm(formList[index + 1].id);
            element = document.querySelector(
              `[aria-label="${formList[index + 1].label}"]`
            );
            element.focus();
          }
          if (index + 1 === length) {
            setCurrentForm(formList[0].id);
            element = document.querySelector(
              `[aria-label="${formList[0].label}"]`
            );
            element.focus();
          }
          break;
        case 'alt+ctrl+-':
        case 'cmd+ctrl+-':
          length = formList.length;
          index = formList.findIndex(({ id }) => {
            if (id === currentForm) return true;
            return false;
          });
          if (length === 1) return;
          if (index === 0) {
            setCurrentForm(formList[length - 1].id);
            element = document.querySelector(
              `[aria-label="${formList[length - 1].label}"]`
            );
            element.focus();
          }
          if (index !== 0 && index + 1 <= length) {
            setCurrentForm(formList[index - 1].id);
            element = document.querySelector(
              `[aria-label="${formList[index - 1].label}"]`
            );
            element.focus();
          }
          break;
        default:
          break;
      }
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] }
  );

  return (
    <div className="ut-relative">
      <nav>
        <ul className="list-tab-buttons">
          {formList.map(({ label, id }) => (
            <li key={id}>
              <button
                aria-selected={currentForm === id ? 'true' : 'false'}
                aria-label={label}
                className="button button--tab"
                onClick={() => setCurrentForm(id)}
                role="tab"
              >
                {label}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      {mainCaseData.isVictimIdRequired && victimIdButton &&
        !mainCaseData.hasVictimIdBeenEntered && (
          <button
            aria-label="Enter Victim Id"
            id="victimIdBtn"
            className="button button--warning button-victim-id"
            onClick={toggleVictimIdModal}
          >
            Enter Victim ID
          </button>
        )}
      <CaseDataContext.Provider value={mainCaseData}>
        <section className="card">
          <AdverseDrug {...adverseDrugProps} {...formProps} />
          <Assault {...assaultProps} {...formProps} />
          <ChildPoisoning {...childPoisoningProps} {...formProps} />
          <FirearmInjury {...firearmInjuryProps} {...formProps} />
          <MotorVehicle {...formProps} {...motorVehicleProps} />
          <MainCase {...formProps} {...mainCaseProps} />
          <SelfInflicted {...selfInflictedProps} {...formProps} />
          <WorkRelated {...workRelatedProps} {...formProps} />
          {buttonType === 'submit' && (
            <SaveButton
              saveArgs={saveArgs}
              prodList={prodList}
              lastUpdateableDate={lastUpdateableDate}
              toggleVictimIdButtonErrors={toggleVictimIdButtonErrors}
              toggleDeleteModal={offlineMode ? handleDelete : toggleDeleteModal}
            />
          )}
          {buttonType === 'continue' && (
            <ContinueButton resetForm={resetForm} />
          )}
          <ReturnedErrorMessages
            args={args}
            existingCaseId={existingCaseId}
            handleReturnedErrors={handleReturnedErrors}
            returnedErrorMsg={returnedErrorMsg}
          />
        </section>
      </CaseDataContext.Provider>
      <VictimIdModal
        args={args}
        caseId={caseId}
        closeModal={closeModal}
        modal={victimIdModal}
        onChange={handleVictimIdInput}
        saveVictimId={saveVictimId}
        setMainCaseData={setMainCaseData}
        setModal={handleAlerts}
        setUserData={setUserData}
        toggleModal={toggleVictimIdModal}
        victimIdData={victimIdData}
      />
      {offlineMode ? (
        <DeleteCaseOfflineModal
          caseNumber={mainCaseData.caseNumber}
          modal={deleteModal}
          closeModal={handleDelete}
          handleAlerts={handleAlerts}
        />
      ) : (
        <DeleteCaseModal
          args={args}
          cases={false}
          deleteCase={deleteCase}
          deleteForm={deleteForm}
          disable={disableDeleteBtn}
          modal={deleteModal}
          onChange={handleDeleteCase}
          selectedCase={selectedCase}
          setCases={false}
          setDeleteForm={setDeleteForm}
          setDeleteModal={setDeleteModal}
          setModal={setVictimIdAlert}
          setSelectedCases={false}
          setUserData={setUserData}
          toggleModal={toggleDeleteModal}
        />
      )}
      <VictimIdAlert
        modal={victimIdAlert}
        setModal={setVictimIdAlert}
        redirect={true}
      />
      <AlertModal
        focus={alertFocus}
        closeModal={handleAlerts}
        modal={alertModal}
        resetForm={resetForm}
        needsAttention={needsAttention}
        hasWarnings={hasWarnings}
        warningMessages={warningMessages}
      />
    </div>
  );
};

export default CaseForm;
