import React, { useState, useEffect, useContext, useMemo } from 'react';
import { UserDataContext } from '../../Context';
import {
  AlertModal,
  modalPresets,
  NewMessageModal,
} from '../../Components/CustomComponents/Modals';
import { request } from '../../api';

const Messages = () => {
  const { userData } = useContext(UserDataContext);
  const { hospital } = userData;
  const [messageData, setMessageData] = useState(null);
  const [alertModal, setAlertModal] = useState(modalPresets().default);
  const [newMsg, setNewMsg] = useState('');
  const [newMsgModal, setNewMsgModal] = useState(false);

  const { groupId } = hospital;

  const toggleNewMsgModal = () => {
    setNewMsgModal(!newMsgModal);
    setNewMsg('');
  };

  const handleAlerts = (modalPreset) => {
    setAlertModal(modalPreset);
  };

  const handleMessage = (msg) => {
    setNewMsg(msg);
  };

  const args = useMemo(
    () => ({
      handleAlerts,
      hospitalId: groupId,
    }),
    [groupId]
  );

  // adds new message to local state
  const appendMessages = (data) => {
    const tempObj = {
      id: data.id,
      fromHospitalId: data.fromHospitalId,
      fromHospitalName: data.fromHospitalName,
      message: newMsg,
      messageDate: data.messageDate,
      isRead: false,
    };
    let tempArray = messageData.slice(0);
    tempArray.unshift(tempObj);
    setMessageData(tempArray);
  };

  const sendNewMsg = async () => {
    setNewMsgModal(!newMsgModal);
    try {
      const msgToSend = {
        fromHospitalId: hospital.groupId,
        message: newMsg,
      };
      const res = await request({
        ...args,
        def: 'sendMessage',
        method: 'POST',
        body: msgToSend,
      });
      appendMessages(res);
      handleAlerts(modalPresets('Your message has been sent').success);
    } catch (err) {
      console.log('ERROR:', err.message);
    }
  };

  const sendMessageRead = async (messageId) => {
    try {
      await request({
        ...args,
        def: 'sendReadMessage',
        method: 'POST',
        queryString: messageId,
      });
      handleAlerts(modalPresets().default);
    } catch (err) {
      console.log('ERROR:', err.message);
    }
  };

  const handleReadMessage = (messageId) => {
    let whichMessageId = -1;
    for (let i = 0; i < messageData.length; i++) {
      if (messageId === messageData[i].id) {
        whichMessageId = i;
      }
    }
    let tempArray = messageData.slice(0);
    tempArray[whichMessageId].hasBeenRead = true;
    setMessageData(tempArray);
    sendMessageRead(messageId);
  };
  const HospitalIdToName = (idIn) => {
    if (idIn === 'Headquarters') {
      return 'Headquarters';
    }
    const whichHospitalId = userData.hospitalData.findIndex(
      (x) => x.groupId === idIn
    );
    return userData.hospitalData[whichHospitalId].name;
  };

  useEffect(() => {
    let isSubscribed = true;
    const fetchMessages = async () => {
      try {
        if (!messageData) {
          const messages = await request({
            ...args,
            def: 'messages',
            method: 'GET',
          });
          if (isSubscribed && messages) {
            setMessageData(messages);
          }
          handleAlerts(modalPresets().default);
        }
      } catch (err) {
        console.log('ERROR:', err.message);
      }
    };
    fetchMessages();
    return () => (isSubscribed = false);
  }, [args, messageData]);

  // TODO: explore 508 solutions for onclick "is read" function. Not sure how to make that work yet. - Fitz
  return (
    <>
      <section className="card">
        <div className="flex flex--space-between flex--align-items-flex-end ut-margin-bottom-1-em ">
          <div className="flex__child">
            <h2 className="ut-margin-bottom-0 ut-ellipsis-overflow ut-max-width-700">
              Messages for {hospital.name}
            </h2>
          </div>
          <div className="flex__child">
            <button
              className="button button--primary"
              onClick={toggleNewMsgModal}
            >
              <span className="fa-icon ut-margin-right-half-em">&#xf067;</span>
              New Message
            </button>
          </div>
        </div>
        <table className="table Messages-table">
          <thead>
            <tr className="table__head">
              <th className="table__heading messages-table__read-col">
                <span className="ut-only-sr">Message Read</span>
              </th>
              <th className="table__heading  messages-table__date-col">
                <button className="button button--table-sort">Date</button>
              </th>
              <th className="table__heading  messages-table__date-col">
                <button className="button button--table-sort">From</button>
              </th>
              <th className="table__heading  messages-table__message-col">
                Message
              </th>
            </tr>
          </thead>
          <tbody>
            {messageData &&
              messageData.length > 0 &&
              messageData.map((message) => {
                return (
                  <tr
                    key={message.id}
                    className={`table__row messages-table__row ${
                      message.hasBeenRead ? 'messages-table__row--is-read' : ''
                    }`}
                    onClick={() => handleReadMessage(message.id)}
                    tabIndex="0"
                  >
                    <td className="table__cell messages-table__cell-is-read">
                      <span className="ut-only-sr messages-table__is-read">
                        This message has been read
                      </span>
                      <span className="ut-only-sr messages-table__not-read">
                        This message is unread
                      </span>
                      <span className="messages-table__not-read ut-color-red fa-icon">
                        &#xf111;
                      </span>
                    </td>
                    <td className="table__cell messages-table__cell-date">
                      {message.messageDate}
                    </td>
                    <td className="table__cell messages-table__cell-date">
                      <span className=" ut-ellipsis-overflow ut-max-width-100">
                        {HospitalIdToName(message.fromHospitalId)}
                      </span>
                    </td>
                    <td className="table__cell">{message.message}</td>
                  </tr>
                );
              })}

            {(messageData === null || messageData.length < 1) && (
              <tr className="table__row messages-table__row messages-table__none">
                <td colSpan="4" className="table__cell">
                  You have no messages
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <NewMessageModal
        modal={newMsgModal}
        msg={newMsg}
        handleMessage={handleMessage}
        submit={sendNewMsg}
        toggleModal={toggleNewMsgModal}
      />
      <AlertModal modal={alertModal} closeModal={setAlertModal} />
    </>
  );
};

export default Messages;
