const topLeft = [
  {
    id: 'treatmentDate',
    indent: 'large',
    label: 'Treatment Date:',
    placeholder: 'mm/dd/yyyy',
    size: '85',
    type: 'date',
  },
  {
    id: 'caseNumber',
    indent: 'xlarge',
    label: 'Case Number:',
    maxLength: '8',
    placeholder: '',
    size: 'medium',
    type: 'text',
  },
];

const topRight = [
  {
    id: 'collectionDate',
    indent: 'large',
    label: 'Collection Date:',
    maxLength: '10',
    placeholder: 'mm/dd/yyyy',
    readOnly: true,
    size: '85',
    type: 'date',
  },
];

const bottomLeft = [
  {
    id: 'dateAgeSex',
    field: [
      {
        id: 'dateAge',
        row: [
          {
            id: 'dateOfBirth',
            indent: 'large',
            label: 'Date of Birth:',
            maxLength: '10',
            placeholder: 'mm/dd/yyyy',
            size: '85',
            type: 'date',
          },
          {
            id: 'age',
            indent: 'tiny',
            label: 'Age:',
            maxLength: '3',
            placeholder: '',
            size: 'medium-age',
            type: 'text',
          },
        ],
      },
      {
        id: 'sex',
        indent: 'large',
        label: 'Gender:',
        placeholder: '',
        size: 'large',
        type: 'combobox',
      },
    ],
  },
  {
    id: 'diagnosis1Field',
    field: [
      {
        id: 'diagnosis1',
        indent: 'large',
        label: 'Diagnosis 1:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaDiagnosis.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma diagnosis</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaDiagnosis.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
      {
        id: 'diagnosisOther1',
        indent: 'large',
        label: 'Diag Other 1:',
        maxLength: '50',
        placeholder: '',
        size: 'large',
        type: 'text',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaDiagnosis.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma diagnosis</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaDiagnosis.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
      {
        id: 'bodyPart1',
        indent: 'large',
        label: 'Body Part 1:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaBodyPart.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma body parts</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaBodyPart.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
    ],
  },
  {
    id: 'diagnosis2Field',
    field: [
      {
        id: 'diagnosis2',
        indent: 'large',
        label: 'Diagnosis 2:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaDiagnosis.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma diagnosis</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaDiagnosis.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
      {
        id: 'diagnosisOther2',
        indent: 'large',
        label: 'Diag Other 2:',
        maxLength: '50',
        placeholder: '',
        size: 'large',
        type: 'text',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaDiagnosis.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma diagnosis</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaDiagnosis.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
      {
        id: 'bodyPart2',
        indent: 'large',
        label: 'Body Part 2:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaBodyPart.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma body parts</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaBodyPart.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
    ],
  },
  {
    id: 'disposition',
    indent: 'large',
    label: 'Disposition:',
    maxLength: '',
    placeholder: '',
    size: 'large',
    type: 'combobox',
  },
];

const bottomRight = [
  {
    id: 'productField',
    field: [
      {
        id: 'product1',
        indent: 'large',
        label: 'Product 1:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaProductCode.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaProductCode.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
      {
        id: 'product2',
        indent: 'large',
        label: 'Product 2:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaProductCode.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaProductCode.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
      {
        id: 'product3',
        indent: 'large',
        label: 'Product 3:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
        tooltipType: 'pdf-link',
        tooltip:
          '<a href="/pdfs/HelpNonTraumaProductCode.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
        tooltipApi:
          '<a href="/pdfs/HelpTraumaProductCode.pdf" target="_blank" rel="noopener noreferrer" className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"><span class="fa-icon"> &#xf045;</span><span class="ut-only-sr">PDF of non-trauma product codes</span></a>',
      },
    ],
  },
  {
    id: 'drugsAlcohol',
    row: [
      {
        id: 'alcohol',
        indent: 'large',
        label: 'Alcohol?',
        maxLength: '',
        placeholder: '',
        size: 'medium',
        type: 'combobox',
      },
      {
        id: 'drugsMedication',
        indent: 'large-drugs',
        label: 'Drugs/Medication?',
        maxLength: '',
        placeholder: '',
        size: 'medium-drugs',
        type: 'combobox',
      },
    ],
  },
  {
    id: 'intentField',
    field: [
      {
        id: 'intent',
        indent: 'large',
        label: 'Intent:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
      },
      {
        id: 'location',
        indent: 'large',
        label: 'Location:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
      },
      {
        id: 'fire',
        indent: 'large',
        label: 'Fire:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
      },
      {
        id: 'workRelated',
        indent: 'large',
        label: 'Work Related:',
        maxLength: '',
        placeholder: '',
        size: 'large',
        type: 'combobox',
      },
    ],
  },
  {
    id: 'raceField',
    field: [
      {
        id: 'race',
        row: [
          {
            id: 'raceHispanic',
            indent: 'large',
            label: 'Hispanic:',
            maxLength: '',
            placeholder: '',
            size: 'medium',
            type: 'combobox',
          },
          {
            id: 'race',
            indent: '',
            label: 'Race:',
            maxLength: '',
            placeholder: '',
            size: 'medium-race',
            type: 'combobox',
          },
        ],
      },
      {
        id: 'raceOther',
        indent: 'large',
        label: 'Race Other:',
        maxLength: '15',
        placeholder: '',
        size: 'large',
        type: 'text',
      },
    ],
  },
];

export { bottomLeft, bottomRight, topLeft, topRight };
