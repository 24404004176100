const firearmInjuryInput = [
  {
    id: 'date',
    flex: [
      {
        id: 'dateInjured',
        indent: 'xlarge',
        label: 'Date Injured:',
        maxLength: '10',
        placeholder: 'mm/dd/yyyy',
        size: '240',
        type: 'date',
      },
      {
        id: 'precision',
        indent: 'xlarge',
        label: 'Precision:',
        maxLength: '',
        size: '240',
        type: 'text',
      },
    ],
  },
  {
    id: 'marital',
    flex: [
      {
        id: 'maritalStatus',
        indent: 'xlarge',
        label: 'Marital Status:',
        size: '240',
        type: 'select',
      },
      {
        id: 'maritalOther',
        indent: 'xlarge',
        label: 'Marital Status Other:',
        maxLength: '18',
        size: '240',
        type: 'text',
      },
    ],
  },
  {
    id: 'firearm',
    flex: [
      {
        id: 'firearmType',
        indent: 'xlarge',
        label: 'Type of Firearm:',
        size: '240',
        type: 'select',
      },
      {
        id: 'firearmOther',
        indent: 'xlarge',
        label: 'Type of Firearm Other:',
        maxLength: '15',
        size: '240',
        type: 'text',
      },
    ],
  },
  {
    id: 'makemodel',
    flex: [
      {
        id: 'model',
        indent: 'xlarge',
        label: 'Manufacturer/Model:',
        maxLength: '27',
        size: '240',
        type: 'text',
      },
      {
        id: 'caliber',
        indent: 'xlarge',
        label: 'Caliber of Firearm:',
        maxLength: '7',
        size: '240',
        type: 'text',
      },
    ],
  },
  {
    id: 'cause',
    flex: [
      {
        id: 'causedBy',
        indent: 'xlarge',
        label: 'Who Caused the Injury:',
        maxLength: '27',
        size: '240',
        type: 'select',
      },
      {
        id: 'causedByOther',
        indent: 'xlarge',
        label: 'Who Caused Other:',
        maxLength: '15',
        size: '240',
        type: 'text',
      },
    ],
  },
  {
    id: 'argument',
    flex: [
      {
        id: 'verbalArgument',
        indent: 'xlarge',
        label: 'Verbal Argument:',
        size: '240',
        type: 'select',
      },
      {
        id: 'physicalFight',
        indent: 'xlarge',
        label: 'Physical Fight:',
        size: '240',
        type: 'select',
      },
    ],
  },
  {
    id: 'drugs',
    flex: [
      {
        id: 'illicitDrugs',
        indent: 'xlarge',
        label: 'Illicit Drugs:',
        size: '240',
        type: 'select',
      },
      {
        id: 'otherCrime',
        indent: 'xlarge',
        label: 'Other Crime:',
        size: '240',
        type: 'select',
      },
    ],
  },
  {
    id: 'tests',
    flex: [
      {
        id: 'alcoholDrugTests',
        indent: 'xlarge',
        label: 'Any Alcohol/Drug Tests:',
        size: '240',
        type: 'select',
      },
      {
        id: 'testResults',
        indent: 'xlarge',
        label: 'Results (Alcohol/Drug test):',
        maxLength: '21',
        size: '240',
        type: 'text',
      },
    ],
  },
  {
    id: 'transported',
    flex: [
      {
        id: 'transportedToER',
        indent: 'xlarge',
        label: 'Transported to ER:',
        size: '240',
        type: 'select',
      },
      {
        id: 'transportedOther',
        indent: 'xlarge',
        label: 'Transported Other:',
        maxLength: '20',
        size: '240',
        type: 'text',
      },
    ],
  },
];

export default firearmInjuryInput;
