import { request } from '../../api';
import { modalPresets } from '../../Components/CustomComponents/Modals';

const formatQuarter = (dateIn, isLongFormat) => {
  const month = parseInt(dateIn.substring(0, 2));
  const year = dateIn.substring(6, 10);
  const quarterRange = ['', 'Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];
  let quarter = Math.floor((month + 2) / 3);
  if (isLongFormat) {
    return `${year} Quarter ${quarter} (${quarterRange[quarter]})`;
  } else {
    return 'Q' + quarter;
  }
};

const getErvsDay = async ({
  args,
  month,
  handleAlerts,
  setMonthDetails,
  setMonthSelected,
}) => {
  try {
    const data = await request({
      ...args,
      handleAlerts,
      def: 'ervsByDay',
      method: 'GET',
      queryString: month,
    });
    let ervs = [];
    for (let i = 0; i < data.length; i++) {
      let erv = {};
      erv.treatmentDate = data[i].treatmentDate;
      erv.numberOfCases = Number(data[i].numberOfCases);
      erv.numberOfErvs = data[i].numberOfErvs;
      erv.zeroCasesConfirmed = data[i].zeroCasesConfirmed;
      // add in extras for FX
      erv.errorMessage = null;
      if (erv.numberOfCases === 0) {
        erv.showZeroCases = true;
        erv.isValid = false;
      } else {
        erv.showZeroCases = false;
        erv.isValid = true;
      }
      ervs.push(erv);
    }
    setMonthDetails([...ervs]);
    setMonthSelected(month);
    handleAlerts(modalPresets().default);
  } catch (err) {
    console.log('ERROR:', err.message);
  }
};

const saveErvs = async ({
  args,
  monthDetails,
  setDisableSubmitQuarterTotalsButton,
  handleAlerts,
  setmonthDetailsHaveChanged,
  setQuarters,
}) => {
  try {
    // reconstruct BE data without extra FE fields
    let monthData = [];
    for (let i = 0; i < monthDetails.length; i++) {
      let tempObj = {};
      tempObj.treatmentDate = monthDetails[i].treatmentDate;
      tempObj.numberOfErvs = parseInt(monthDetails[i].numberOfErvs);
      tempObj.zeroCasesConfirmed = monthDetails[i].zeroCasesConfirmed;
      tempObj.numberOfCases = parseInt(monthDetails[i].numberOfCases) || 0;
      monthData.push(tempObj);
    }
    await request({
      ...args,
      body: monthData,
      def: 'saveErvsByDay',
      handleAlerts,
      method: 'PUT',
    });
    setDisableSubmitQuarterTotalsButton(true);
    setmonthDetailsHaveChanged(false);
    setQuarters(null);
    handleAlerts(modalPresets('ERV Quarterly Totals saved sada.').success);
  } catch (err) {
    console.log('ERROR:', err.message);
  }
};

const saveErvsQuarter = async ({
  args,
  quarters,
  handleAlerts,
  setquartersHaveChanged,
}) => {
  try {
    // reconstruct BE data without extra FE fields
    let quartersData = [];
    for (let i = 0; i < quarters.length; i++) {
      let tempObj = {};
      tempObj.quarterEnd = quarters[i].quarterEnd;
      tempObj.ervUserTotal = quarters[i].ervUserTotal;
      quartersData.push(tempObj);
    }
    await request({
      ...args,
      body: quartersData,
      def: 'saveErvsByQuarter',
      handleAlerts,
      method: 'PUT',
    });
    handleAlerts(modalPresets('ERV Quarterly Totals saved.').success);
    setquartersHaveChanged(false);
  } catch (err) {
    console.log('ERROR:', err.message);
  }
};

const validateMonthlyForm = (monthDetails, setMonthDetails) => {
  let tempArray = monthDetails.slice(0);
  for (let i = 0; i < tempArray.length; i++) {
    //reset all warnings
    tempArray[i].isValid = true;
    tempArray[i].showZeroCases = false;
    tempArray[i].errorMessage = '';
    // if ERVs not filled in throw required
    if (tempArray[i].numberOfErvs === undefined) {
      tempArray[i].isValid = false;
      tempArray[i].errorMessage = 'Required';
    }
    // if ERVs less than cases throw message
    if (
      parseInt(tempArray[i].numberOfErvs) < parseInt(tempArray[i].numberOfCases)
    ) {
      tempArray[i].isValid = false;
      tempArray[i].errorMessage = 'ERVs Less than Cases';
    }
    // if ERVs = 0 show form and input field not empty
    if (parseInt(tempArray[i].ervs) && parseInt(tempArray[i].cases) === 0) {
      tempArray[i].showZeroCases = true;
    }
    // if ERVs = 0 & form not checked, set as invalid
    if (
      tempArray[i].showZeroCases &&
      tempArray[i].zeroCasesConfirmed === false
    ) {
      tempArray[i].isValid = false;
    }
  }
  setMonthDetails([...tempArray]);
};

export {
  formatQuarter,
  getErvsDay,
  saveErvs,
  saveErvsQuarter,
  validateMonthlyForm,
};
