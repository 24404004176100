const inputs = [
  {
    fieldset: 'Was poison Control Center contacted:',
    id: 'contactedPCC',
    flex: [
      {
        id: 'contactedPriorED',
        label: 'prior to the ED Visit?',
        size: 'large',
        type: 'select',
      },
      {
        id: 'contactedDuringED',
        label: 'during the visit?',
        size: 'large',
        type: 'select',
      },
    ],
  },
  {
    fieldset: 'Prior to ED Visit',
    id: 'contactedOtherPCC',
    flex: [
      {
        id: 'contactedOther',
        label: 'Was any other medical person or facility contacted?',
        size: 'large',
        type: 'select',
      },
      {
        id: 'contactedOtherSpecify',
        label: 'Specify:',
        maxLength: '35',
        placeholder: '',
        size: 'large',
        type: 'text',
      },
    ],
  },
  {
    fieldset: 'Symptoms',
    id: 'symptoms',
    flexField: [
      {
        id: 'anySymptoms',
        label: 'Any Symptom(s)?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'nauseaVomiting',
        label: 'Nausea/Vomiting?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'dizziness',
        label: 'Dizziness?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'drowsiness',
        label: 'Drowsiness?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'difficultyBreathing',
        label: 'Difficulty Breathing?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'headaches',
        label: 'Headaches?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'abdominalPain',
        label: 'Abdominal Pain?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'otherSymptoms',
        label: 'Other Symptoms?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'otherSymptomsSpecify',
        label: 'Specify Other Symptoms:',
        indent: 'xlarge',
        maxLength: '35',
        placeholder: '',
        size: 'large',
        type: 'text',
      },
    ],
  },
  {
    fieldset: 'Treatment',
    helper: '-No = observation only',
    id: 'treatment',
    flexField: [
      {
        id: 'edTreatments',
        label: 'Any ED Treatment(s)?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'vomitingInduced',
        label: 'Vomiting Induced?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'stomachPump',
        label: 'Stomach Pump?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'charAdmin',
        label: 'Char. Admin?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'artRespiration',
        label: 'Art. Respiration?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'bloodTransfusion',
        label: 'Blood Transfusion?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'otherTreatment',
        label: 'Other Treatment?',
        indent: 'xlarge',
        size: 'large',
        type: 'select',
      },
      {
        id: 'otherTreatmentSpecify',
        label: 'Specify Other Treatment:',
        indent: 'xlarge',
        maxLength: '35',
        placeholder: '',
        size: 'large',
        type: 'text',
      },
    ],
  },
  {
    field: {
      id: 'containerDisposition',
      label: 'What happened to the container?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'crcPresent',
      label: 'CRC Present?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'drugProvided',
      label:
        'Did the poisoning involve a drug (e.g. medications, vitamins, immunizations, etc.) given to the child to treat or prevent a medical condition or to provide other health benefit?',
      size: 'large',
      type: 'select',
    },
  },
];

export default inputs;
