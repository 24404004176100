const inputs = [
  {
    helper: '(24 hour clock)',
    field: {
      id: 'arrivalTimeAS',
      label: 'Time of arrival at ED',
      maxLength: '5',
      placeholder: '00:00',
      size: 'medium',
      type: 'text',
    },
  },
  {
    field: {
      id: 'patientInitiated',
      label:
        'Is there evidence that the patient initiated the violent incident during which s/he was injured, e.g. started a fight, patient tried to rob someone?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'patientIntervened',
      label:
        'Was the patient injured while trying to intervene in a violent incident, e.g. trying to stop a fight, apprehending a purse snatcher?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'patientBystander',
      label:
        'Was the patient a bystander or incidental victim in the assault, e.g. a child was hit while being held by his mother who was being assaulted by her boyfriend?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'amountInvolved',
      label:
        'How many individuals were involved in the assault (excluding the patient)?',
      size: 'large',
      type: 'select',
    },
  },
  {
    helper:
      "Code Male when terms such as 'he', 'man', 'boy', 'father', 'boyfriend' where used to describe the assailant. Code Female when terms such as 'she', 'woman', 'girl', 'mother', 'girlfriend' where used to describe the assailant.",
    field: {
      id: 'sexInvolved',
      label:
        'What was the sex of the other person(s) involved in the violent incident?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'patientPeer',
      label:
        'Was the patient a peer of the other person(s) involved in the assault, e.g. a classmate, friend about the same age as the patient?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'cps',
      label:
        'Was the case referred to Child Protective Services (CPS), or a similar agency?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'policeReport',
      label:
        'Was a police report made or were the police at the scene of the incident or at the  hospital?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'referral',
      label:
        'Was a referral made to a community organization or a similar agency for some type of assistance or intervention (excluding CPS)?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'sexuallyAssaulted',
      label: 'Was the patient sexually assaulted?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'examination',
      label:
        'Was an examination done by a person specifically trained for such a task, e.g. a Sexual Assault Nurse Examiner (SANE)?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'examinationSpecify',
      label: 'If no examination done, please provide a reason',
      maxLength: '60',
      size: 'large',
      type: 'text',
    },
  },
  {
    field: {
      id: 'prophylactic',
      label: 'Were prophylactic antibiotics provided to the patient?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'prophylacticSpecify',
      label: 'If no prophylactic antibiotics provided, please provide a reason',
      maxLength: '60',
      size: 'large',
      type: 'text',
    },
  },
  {
    field: {
      id: 'contraception',
      label: 'Was emergency contraception provided to the patient?',
      size: 'large',
      type: 'select',
    },
  },
  {
    field: {
      id: 'contraceptionSpecify',
      label: 'If no contraception provided, please provide a reason',
      maxLength: '60',
      size: 'large',
      type: 'text',
    },
  },
];

export default inputs;
