import React, { useState } from 'react';
import { inputs } from './utils';
import { renderWrapper } from '../../../../Components/CustomComponents';
import { dropdownCodes } from '../../../../dropdownCodes';

const SelfInflicted = (props) => {
  const {
    currentForm,
    formData,
    formErrors,
    setButtonType,
    setFormData,
    setFormErrors,
    setUnsavedChanges,
  } = props;
  const [touched, setTouched] = useState(false);

  const onChange = (e, name) => {
    setTouched(true);
    setUnsavedChanges(true);
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }

    switch (name) {
      case 'patientIntent':
        if ((touched && !value) || (value.length > 2 && value !== dropdownCodes.patientIntent4))
          setFormData((prevState) => ({ ...prevState, intentSpecify: '' }));
        break;
      case 'injuryEvent':
        if ((touched && !value) || (value.length > 2 && value !== dropdownCodes.injuryEventOther))
          setFormData((prevState) => ({ ...prevState, eventSpecify: '' }));
        break;
      case 'alcoholSI':
        if ((touched && !value) || (value.length > 2 && value !== dropdownCodes.basicYes))
          setFormData((prevState) => ({ ...prevState, bacLevel: '' }));
        break;
      default:
        break;
    }

    value = value.replace(/[^\x00-\x7F]/g, '');
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onToggle = (e, name) => {
    let checked;
    if (e.target) {
      checked = e.target.checked;
    } else {
      checked = e;
    }
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: checked }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  return (
    <div
      className="list-tab-contents"
      aria-live="polite"
      aria-hidden={currentForm === 'selfInflicted' ? false : true}
    >
      <h1>Self Inflicted Study</h1>
      <form className="ut-max-width-900">
        {inputs.map((input) => {
           if (input.fieldset) {
            return (
              <fieldset key={input.id}>
                <legend>
                  <label className='label'>{input.fieldset}</label>
                </legend><br />
                {renderWrapper({
                  input,
                  formData,
                  formErrors,
                  setFormErrors,
                  onChange,
                  onToggle,
                })}
              </fieldset>
            )
           }
          return renderWrapper({
            input,
            formData,
            formErrors,
            setFormErrors,
            onChange,
            onToggle,
          });
        })}
      </form>
    </div>
  );
};

export default SelfInflicted;
