import React from 'react';
const CaseDataContext = React.createContext(null);
const UserDataContext = React.createContext(null);
const DropdownOptionsContext = React.createContext(null);
const OfflineModeContext = React.createContext(null);

export {
  CaseDataContext,
  DropdownOptionsContext,
  OfflineModeContext,
  UserDataContext,
};
