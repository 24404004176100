import { InteractionRequiredAuthError, BrowserAuthError } from "@azure/msal-browser";
import { modalPresets } from "./Components/CustomComponents/Modals";

const formatModalMessage = ({ method, queryString }) => ({
  accountingMonthlyDetails: `${method === "GET" ? "Loading" : "Saving"} monthly details for ${queryString}...`,
  accountingMonthlyTotals: `${method === "GET" ? "Loading" : "Saving"} monthly totals...`,
  addSearch: `${method === "GET" ? "Loading" : "Saving"} search...`,
  bulkCases: "Bulk Loading cases...",
  bulkSaveCases: "Bulk Saving cases...",
  caseData: `${method === "GET" ? "Loading" : "Saving"} case data...`,
  casesSummary: `${method === "GET" ? "Loading" : "Saving"} case summary...`,
  deleteCase: `Deleting case...`,
  deleteSearch: `Deleting search...`,
  ervsByDay: `${method === "GET" ? "Loading" : "Saving"} ERVs details for ${queryString}...`,
  ervsByQuarter: `${method === "GET" ? "Loading" : "Saving"} ERVs...`,
  ervsAll: `${method === "GET" ? "Loading" : "Saving"} ERVs...`,
  hospitalData: `${method === "GET" ? "Loading" : "Saving"} hospital data...`,
  lookups: `${method === "GET" ? "Loading" : "Saving"} lookups...`,
  messages: `${method === "GET" ? "Loading" : "Saving"} messages...`,
  oowPassword: `${method === "GET" ? "Loading" : "Saving"} ...`,
  products: `${method === "GET" ? "Loading" : "Saving"} ...`,
  readMessages: `${method === "GET" ? "Loading" : "Saving"} ...`,
  returnedErrorMsgs: `${method === "GET" ? "Loading error messages" : "Sending reply..."}`,
  returnedWarningMsgs: `${method === "GET" ? "Loading warning messages" : "Sending reply..."}`,
  saveCase: `Saving case data...`,
  saveErvsByDay: `Submitting ERV daily log changes...`,
  saveErvsByQuarter: `Submitting ERV quarterly totals...`,
  savedSearches: `${method === "GET" ? "Loading" : "Saving"} saved searches...`,
  searchCaseData: `Searching for Case(s)...`,
  sendErrorMsgReply: `${method === "GET" ? "Loading" : "Saving"} ...`,
  sendMessage: `Sending message...`,
  sendReadMessage: `Sending read message.`,
  sendVictimId: `${method === "GET" ? "Loading" : "Saving"} ...`,
  specialStudy: () => {
    if (method === 'GET') return `Loading special study data.`;
    if (method === 'POST' || method === 'PUT')
      return `Saving special study data.`;
    if (method === 'DELETE') return `Deleting unused special study data.`;
  },
  submitCases: `Submitting cases...`,
  unreadMessages: `${method === "GET" ? "Loading" : "Saving"} ...`,
  unreadForHospitals: `${method === "GET" ? "Loading" : "Saving"} messages...`,
  victimIdCategories: `${method === "GET" ? "Loading" : "Saving"} victim Id categories...`,
});

const formatErrorMessage = ({ method, message = "", status = "" }) => ({
  accountingMonthlyDetails: `${status} Failed to load accounting monthly details. ${message}`,
  accountingMonthlyTotals: `${status} Failed to load accounting monthly totals. ${message}`,
  addSearch: `${status} Search failed to save. ${message}`,
  bulkCases: `${status} Failed to bulk load case data. ${message}`,
  bulkSaveCases: `${status} Failed to bulk save case data. ${message}`,
  caseData: `${status} Failed to load case data. ${message}`,
  casesSummary: `${status} Failed to load case summary. ${message}`,
  deleteCase: `${status} Failed to delete case. Please try again. ${message}`,
  deleteSearch: `${status} Search failed to delete. Please try again. ${message}`,
  ervsByDay: `${status} Failed to load ERVs. Please try again. ${message}`,
  ervsByQuarter: `${status} Failed to load ERVs. Please try again. ${message}`,
  ervsAll: `${status} Failed to load ERV totals. Please try again. ${message}`,
  hospitalData: `${status} Failed to load hospital data. Please try again. ${message}`,
  lookups: `${status} Failed to load lookups. Please try again. ${message}`,
  messages: `${status} There was an error loading messages. Please try again. ${message}`,
  noConnection: "Failed to fetch. Check your internet connection and refresh the page.",
  oowPassword: ``,
  products: ``,
  readMessages: `${
    method === "GET"
      ? `${status} Failed to load error messages. ${message}`
      : `${status} Reply failed to send. Please try again. ${message}`
  }`,
  returnedErrorMsgs: `${
    method === "GET"
      ? `${status} Failed to load error messages. ${message}`
      : `${status} Reply failed to send. Please try again. ${message}`
  }`,
  saveCase: `${
    message === "duplicate"
      ? "The Case # already exists for the entered Treatment Date and Hospital. Please review the entered data and try again."
      : `${status} There was an error saving the case. Please review the entered data and try again. ${message}`
  }`,
  saveErvsByDay: `${status} Failed to submit ERV Daily Log changes. Please try again. ${message}`,
  saveErvsByQuarter: `${status} Failed to submit ERV Quarterly Totals. Please try again. ${message}`,
  savedSearches: `${status} Failed to load saved searches. Please try again. ${message}`,
  searchCaseData: `${status} Failed to retrieve case(s). Please try again. ${message}`,
  sendErrorMsgReply: ``,
  sendMessage: `${status} Message failed to send. Please try again. ${message}`,
  sendReadMessage: `${status} Failed to send read  message.${message}`,
  sendVictimId: ``,
  specialStudy: () => {
    if (method === "GET") return `${status} Failed to load special study data. ${message}`;
    if (method === "POST" || method === "PUT") return `${status} Failed to save special study data. ${message}`;
    if (method === "DELETE") return `${status} Failed to delete special study data. ${message}`;
  },
  submitCases: `${status} Failed to submit case(s). Please try again. ${message}`,
  unreadMessages: ``,
  unreadForHospitals: `${status} Failed to messages. Please try again. ${message}`,
  victimIdCategories: `${status} Failed to load victim id categories. Please try again. ${message}`,
});

const formatUrls = ({ caseId, hospitalId, params, specialCaseId, url }) => ({
  accountingMonthlyDetails: `${url}/cases/accountingSummary/byDay?hospitalId=${hospitalId}&month=${params}`,
  accountingMonthlyTotals: `${url}/cases/accountingSummary/byMonth?hospitalId=${hospitalId}`,
  addSearch: `${url}/savedSearches`,
  bulkCases: `${url}/casesAll`,
  bulkSaveCases: `${url}/cases/save`,
  caseData: `${url}/cases/${caseId}`,
  casesSummary: `${url}/casesSummary?hospitalId=${hospitalId}`,
  deleteCase: `${url}/cases/${caseId}`,
  deleteSearch: `${url}/savedSearches/${params}`,
  ervsByDay: `${url}/ervs/byDay?hospitalId=${hospitalId}&month=${params}`,
  ervsByQuarter: `${url}/ervs/byQuarter?hospitalId=${hospitalId}`,
  ervsAll: `${url}/ervs/existByQuarter`,
  hospitalData: `${url}/hospitals`,
  lookups: `${url}/lookups`,
  messages: `${url}/messages?hospitalId=${hospitalId}`,
  oowPassword: `${url}/user/oowPassword?oowPassword=${params}`,
  products: `${url}/typeAheads`,
  readMessages: `${url}/user/messages?hospitalId=${hospitalId}`,
  returnedErrorMsgs: `${url}/cases/${caseId}/errors${params ? `/${params}` : ""}`,
  returnedWarningMsgs: `${url}/cases/${caseId}/warnings${params ? `/${params}` : ""}`,
  saveCase: `${url}/cases`,
  saveErvsByDay: `${url}/ervs/byDay?hospitalId=${hospitalId}`,
  saveErvsByQuarter: `${url}/ervs/byQuarter?hospitalId=${hospitalId}`,
  savedSearches: `${url}/savedSearches`,
  searchCaseData: `${url}/cases?hospitalId=${hospitalId}${params}`,
  sendErrorMsgReply: `${url}/cases/${caseId}/errors/${params}`,
  sendMessage: `${url}/messages`,
  sendReadMessage: `${url}/user/messages/${params}?hospitalId=${hospitalId}`,
  sendVictimId: `${url}/cases/${caseId}/victim`,
  specialStudy: `${url}/cases/${caseId}/specialStudies${specialCaseId ? `/${specialCaseId}` : ""}`,
  submitCases: `${url}/cases/submit/${hospitalId}`,
  unreadMessages: `${url}/user/messages/unread?hospitalId=${hospitalId}`,
  unreadForHospitals: `${url}/messages/unreadForHospitals`,
  victimIdCategories: `${url}/categories`,
});

const noConnection = "Failed to fetch";

const fetchWithTimeout = async (url, options) => {
  const timeout = 30000;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  } catch (err) {
    return err;
  }
};

let config;
const fetchConfig = async () => {
  if (!config) {
    await fetch("/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        config = res;
      })
      .catch((error) => {
        console.log("Could not load application configuration.");
        console.log(error);
      });
  }
};

let msalInstance;
let tokenInfo; // this could probably be retrieved using msalInstance, but MicroSoft does not make it easy
const acquireToken = async (instance = msalInstance) => {
  if (!msalInstance && !instance) {
    throw new Error("No MSAL instance available. Try refreshing your page.");
  }

  // acquireToken will first be called by AppLoader.
  // cache the instance for later use within api.js
  if (instance && !msalInstance) {
    msalInstance = instance;
  }

  const account = instance.getAllAccounts()[0];

  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }
  
  if (instance === "logout") {
    const tokenCache = instance.getTokenCache();
    tokenCache.storage.clear();
    // remove the account
    tokenCache.storage.removeAccount(account.homeAccountId + account.environment + account.tenantId);
    msalInstance = null;
    tokenInfo = null;
    return
  }

  // Thanks MicroSoft
  // tokenInfo.expiresOn: Date Object = access token expiration
  // tokenInfo.idTokenClaims.exp: int = id token experation in seconds

  // issue - idToken expires before the accessToken - sometimes up to 30 minutes apart
  // issue - acquireTokenSilent will grant a new access token but may not renew idToken
  // resolution - check the exiration of both accessToken and idToken. If either are beyond the expiration window, then aquire new tokens with the `forceRefresh` flag set

// fetch a new token a few minutes before expiration in case the server's clock does not exactly match the user's clock
  const minutesBeforeExpiration = 6

  if (
    !tokenInfo || // first time login
    Date.now() > tokenInfo.expiresOn.valueOf() - minutesBeforeExpiration * 60 * 1000 || // check if accessToken is near expiration
    Date.now() > tokenInfo.idTokenClaims.exp * 1000 - minutesBeforeExpiration * 60 * 1000 // check if idToken is near expiration - this timestamp does not include milliseconds because why would it?
  ) {
    console.info("Refreshing tokens at: ", new Date());

    const request = {
      // scopes: ["User.Read"],
      authority: "https://login.microsoftonline.com/4d44ecc4-de99-4c21-ad8f-a22f31240751",
      redirectUri: "/",
      account,
      forceRefresh: true, // this flag should force a call to the server thereby creating new accessToken and idToken
    };

    // cache tokenInfo
    tokenInfo = await instance.acquireTokenSilent(request).catch(async (err) => {
      if (err instanceof InteractionRequiredAuthError || err instanceof BrowserAuthError) {
        console.warn(err);
        return await instance.acquireTokenRedirect(request).catch((err) => console.log(err.message));
      }
    });

    console.info("Access Token expires at: ", tokenInfo.expiresOn);
    console.info("Id Token expires at: ", new Date(tokenInfo.idTokenClaims.exp * 1000));
  }

  return tokenInfo;
};

const request = async ({ body, caseId, def, handleAlerts, hospitalId, method, specialCaseId, queryString }) => {
  if (!config) {
    await fetchConfig();
  }
  if (def === "config") {
    return config;
  }
  const { REACT_APP_API_URL, REACT_APP_SUBSCRIPTION_KEY } = config;
  let params = "";
  const message = formatModalMessage({ method, queryString });
  let modalPreset;
  if (def === "specialStudy") {
    modalPreset = modalPresets(message[def]());
  } else {
    modalPreset = modalPresets(message[def]);
  }
  if (handleAlerts) handleAlerts(modalPreset.loading);

  // verify the token
  const { idToken } = await acquireToken();

  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  headers.append("Ocp-Apim-Subscription-Key", REACT_APP_SUBSCRIPTION_KEY);
  const options = {
    method,
    headers,
    ...(body && { body: JSON.stringify(body) }),
  };
  if (def === "searchCaseData") {
    for (const [key, value] of Object.entries(queryString)) {
      params += `&${key}=${value}`;
    }
  } else {
    params = queryString;
  }
  const url = formatUrls({
    caseId,
    hospitalId,
    params,
    specialCaseId,
    url: REACT_APP_API_URL,
  });
  try {
    const response = await fetchWithTimeout(url[def], options);
    if (
      (def === "deleteCase" ||
        def === "deleteSearch" ||
        def === "submitCases" ||
        def === "sendReadMessage" ||
        (def === "specialStudy" && method !== "GET")) &&
      response.status === 200
    ) {
      return response;
    }
    if (response.status > 400) throw response;
    if (response.message && response.message === noConnection) throw response;

    let res = response;

    try {
      res = await response.json();
    } catch (error) {
      // console.info('No data to process from response')
    }

    if (res.errorList) {
      const { errorList } = res;
      if (errorList[0].badValue) {
        let errMsg = "";
        errorList.forEach((error) => {
          errMsg = `${errMsg} Invalid value: ${error.badValue} for field: ${error.field}.`;
        });
        throw new Error(errMsg);
      }
    }
    if (res.errorMessage) throw new Error(res.errorMessage);
    return res;
  } catch (err) {
    let errPreset;
    const res = await fetchWithTimeout(url[def], options);
    const errorMessage = formatErrorMessage({
      message: err.message,
      status: err.status,
    });
    if (method === "GET" && res.status >= 500) {
      alert('Unable to fetch hospitals.')
    }
    if (def === "specialStudy") {
      errPreset = modalPresets(message[def]());
    } else {
      errPreset = modalPresets(errorMessage[def]).error;
    }
    if (handleAlerts) handleAlerts(errPreset);
    throw err;
  }
};

export { request, acquireToken };
