import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CaseDataContext, UserDataContext } from '../../Context';
import { calculateOOW } from '../../Pages/Dashboard/utils';
import { dropdownCodes } from '../../dropdownCodes';

const { eventType6, basicYes } = dropdownCodes;

const TextArea = ({ el, formData, formErrors, onChange }) => {
  const { existingCaseId } = useParams();
  const { userData } = useContext(UserDataContext);
  const caseData = useContext(CaseDataContext);
  const {
    id,
    label,
    labelledby,
    maxLength,
    placeholder,
    tooltip,
    tooltipSize,
  } = el;
  const { oowValid } = userData;
  const [isOow, setIsOowW] = useState(false);
  const [OowChecked, setoowChecked] = useState(false);

  const props = {
    'aria-label': labelledby ? `${labelledby} ${label}` : label,
    className: `input input--textarea ${formErrors[id] ? 'input--error' : ''}`,
    id,
    maxLength: maxLength || '',
    onChange: (e) => onChange(e, id),
    placeholder: placeholder || '',
    value: formData[id],
  };
  const disableInputCheck = (e) => {
    if (existingCaseId && !oowValid && isOow) {
      return calculateOOW(caseData.treatmentDate);
    } else {
      switch (id) {
        case 'adverseEventDetails':
          if (formData.eventType === eventType6) return true;
          return false;
        case 'localeInfo':
        case 'vehicleERRecord':
          if (formData.collision === basicYes || formData.carSeat === basicYes)
            return true;
          return false;
        default:
          return false;
      }
    }
  };

  useEffect(() => {
    if (caseData.treatmentDate && existingCaseId && !OowChecked) {
      setIsOowW(calculateOOW(caseData.treatmentDate));
      setoowChecked(true);
    }
  }, [existingCaseId, caseData.treatmentDate, isOow, OowChecked]);

  return (
    <>
      <span className="tooltip__wrapper ut-width-100-percent">
        <textarea {...props} disabled={disableInputCheck()} />
        {tooltip && (
          <div
            className={`tooltip__content tooltip__content--${tooltipSize}`}
            dangerouslySetInnerHTML={{ __html: tooltip }}
          ></div>
        )}
      </span>
    </>
  );
};

export default TextArea;
