import { resetErrors } from './utils';
import { validateMainCase } from '../Forms/MainCase/utils';
import { validateSelfInflicted } from '../Forms/SelfInflicted/utils';
import { validateWorkRelated } from '../Forms/WorkRelated/utils';
import { validateAdverseDrug } from '../Forms/AdverseDrug/utils';
import { validateAssault } from '../Forms/Assault/utils';
import { validateChildPoisoning } from '../Forms/ChildPoisoning/utils';
import { validateFirearm } from '../Forms/FirearmInjury/utils';
import { validateMotorVehicle } from '../Forms/MotorVehicle/utils';
import { formatData, setCaseStatus } from './utils';
import { runAllChecks } from './addRemoveTabs';
import { request } from '../../../api';
import { modalPresets } from '../../../Components/CustomComponents/Modals';

const validateErrorMsg = (errMsgs) => {
  return errMsgs.some(({ reply }) => reply === null);
};

const onSave = ({
  accounts,
  caseId,
  formData,
  formErrors,
  formWarnings,
  formList,
  hospitalType,
  hospital,
  options,
  oowValid,
  returnedErrorMsg,
  specialCaseIds,
  handleAlerts,
  setButtonType,
  setCaseId,
  setFormData,
  setFormErrors,
  setFormList,
  setSpecialCaseIds,
  setFormWarnings,
  setWordWarnings,
  validateOnly,
  victimIdCategories,
}) => {
  const { mainCaseData } = formData;
  const { list, listDiff, resetIds } = runAllChecks({
    formList,
    hospitalType,
    hospital,
    mainCaseData,
    setFormData,
    setFormErrors,
    setFormList,
    treatmentDate: mainCaseData.treatmentDate,
  });

  const args = {
    accounts,
    caseId,
    formData,
    formErrors,
    formWarnings,
    formList: list,
    hospitalType,
    hospital,
    listDiff,
    resetIds,
    options,
    oowValid,
    returnedErrorMsg,
    specialCaseIds,
    handleAlerts,
    setButtonType,
    setCaseId,
    setFormData,
    setFormErrors,
    setSpecialCaseIds,
    setFormWarnings,
    setWordWarnings,
    validateOnly,
    victimIdCategories,
  };
  resetErrors({ setFormErrors, setFormWarnings });

  // if save is successful, will return the latest case summary numbers
  // the new case summary numbers are then applied to the UserData Context
  return saveAndValidate({ ...args });
};

const saveAndValidate = ({
  accounts,
  caseId,
  formData,
  formErrors,
  formWarnings,
  formList,
  hospital,
  hospitalType,
  listDiff,
  options,
  oowValid,
  resetIds,
  returnedErrorMsg,
  specialCaseIds,
  handleAlerts,
  setButtonType,
  setCaseId,
  setErrorMsg,
  setFormData,
  setFormErrors,
  setSpecialCaseIds,
  setFormWarnings,
  setWordWarnings,
  validateOnly = false,
  victimIdCategories,
}) => {
  let validateArgs = {};
  let errs = { mainCase: [], specialStudy: [] };
  let specialStudyErrs = [];

  const { setMainCaseData } = setFormData;
  const {
    mainCaseOptions,
    adverseDrugOptions,
    assaultOptions,
    childrensPoisoningOptions,
    firearmInjuryOptions,
    motorVehicleOptions,
    workRelatedOptions,
    selfInflictedOptions,
  } = options;
  const {
    mainCaseData,
    adverseDrugData,
    assaultData,
    childPoisoningData,
    firearmInjuryData,
    motorVehicleData,
    selfInflictedData,
    workRelatedData,
  } = formData;
  const {
    mainCaseErrors,
    adverseDrugErrors,
    assaultErrors,
    childPoisoningErrors,
    firearmInjuryErrors,
    motorVehicleErrors,
    selfInflictedErrors,
    workRelatedErrors,
  } = formErrors;
  const { mainCaseWarnings } = formWarnings;
  const {
    adverseDrug,
    assault,
    childPoisoning,
    firearmInjury,
    motorVehicle,
    selfInflicted,
    workRelated,
  } = specialCaseIds;
  const {
    setMainCaseErrors,
    setAdverseDrugErrors,
    setAssaultErrors,
    setChildPoisoningErrors,
    setFirearmInjuryErrors,
    setMotorVehicleErrors,
    setSelfInflictedErrors,
    setWorkRelatedErrors,
  } = setFormErrors;
  const { setMainCaseWarnings, setFirearmInjuryWarnings } = setFormWarnings;

  /** set tab index to first input field for special study on save click **/
  const target = document.querySelector(
    '.list-tab-contents[aria-hidden=false] input:not([id=dateInjured]):not([aria-controls=listbox--collision]):not([aria-controls=listbox--carSeat])'
  );
  target.focus();

  // run validations
  formList.map(({ id }) => {
    switch (id) {
      case 'mainCase':
        validateArgs = {
          formErrors: mainCaseErrors,
          formWarnings: mainCaseWarnings,
          formData: mainCaseData,
          hospitalType,
          hospital,
          setFormErrors: setMainCaseErrors,
          setFormWarnings: setMainCaseWarnings,
          options: mainCaseOptions,
          oowValid,
        };
        errs.mainCase = validateMainCase({ ...validateArgs });
        break;
      case 'adverseDrug':
        validateArgs = {
          formErrors: adverseDrugErrors,
          formData: adverseDrugData,
          setFormErrors: setAdverseDrugErrors,
          options: adverseDrugOptions,
        };
        specialStudyErrs = validateAdverseDrug({ ...validateArgs });
        errs.specialStudy = [...errs.specialStudy, ...specialStudyErrs];
        break;
      case 'assault':
        validateArgs = {
          formErrors: assaultErrors,
          formData: assaultData,
          mainCaseData,
          setFormErrors: setAssaultErrors,
          options: assaultOptions,
          assaultOptions,
        };
        specialStudyErrs = validateAssault({ ...validateArgs });
        errs.specialStudy = [...errs.specialStudy, ...specialStudyErrs];
        break;
      case 'childPoisoning':
        validateArgs = {
          formErrors: childPoisoningErrors,
          formData: childPoisoningData,
          setFormErrors: setChildPoisoningErrors,
          options: childrensPoisoningOptions,
        };
        specialStudyErrs = validateChildPoisoning({ ...validateArgs });
        errs.specialStudy = [...errs.specialStudy, ...specialStudyErrs];
        break;
      case 'firearmInjury':
        validateArgs = {
          formErrors: firearmInjuryErrors,
          formData: firearmInjuryData,
          mainCaseData,
          setFormErrors: setFirearmInjuryErrors,
          setFormWarnings: setFirearmInjuryWarnings,
          options: firearmInjuryOptions,
        };
        specialStudyErrs = validateFirearm({ ...validateArgs });
        errs.specialStudy = [...errs.specialStudy, ...specialStudyErrs];
        break;
      case 'motorVehicle':
        validateArgs = {
          formErrors: motorVehicleErrors,
          formData: motorVehicleData,
          mainCaseData,
          setFormErrors: setMotorVehicleErrors,
          options: motorVehicleOptions,
        };
        specialStudyErrs = validateMotorVehicle({ ...validateArgs });
        errs.specialStudy = [...errs.specialStudy, ...specialStudyErrs];
        break;
      case 'selfInflicted':
        validateArgs = {
          formErrors: selfInflictedErrors,
          formData: selfInflictedData,
          mainCaseData,
          setFormErrors: setSelfInflictedErrors,
          options: selfInflictedOptions,
        };
        specialStudyErrs = validateSelfInflicted({ ...validateArgs });
        errs.specialStudy = [...errs.specialStudy, ...specialStudyErrs];
        break;
      case 'workRelated':
        validateArgs = {
          formErrors: workRelatedErrors,
          formData: workRelatedData,
          setFormErrors: setWorkRelatedErrors,
          options: workRelatedOptions,
        };
        specialStudyErrs = validateWorkRelated({ ...validateArgs });
        errs.specialStudy = [...errs.specialStudy, ...specialStudyErrs];
        break;
      default:
        break;
    }
    return errs;
  });

  const caseData = setCaseStatus(
    mainCaseData,
    setMainCaseData,
    errs,
    validateErrorMsg(returnedErrorMsg),
    victimIdCategories
  );

  if (validateOnly) {
    return caseData;
  }

  const submitAll = formList.map(({ id }) => {
    let returnVal = {};

    switch (id) {
      case 'mainCase':
        returnVal = {
          accounts,
          caseData: formatData(caseData),
          caseId,
          caseName: id,
          handleAlerts,
          key: id,
          listDiff,
          resetIds,
          setCaseId,
          setErrorMsg,
          specialCaseId: '',
          setSpecialCaseIds,
          setMainCaseWarnings,
          setWordWarnings,
        };
        break;
      case 'adverseDrug':
        returnVal = {
          type: 'adverse drug event',
          content: formatData(adverseDrugData),
        };
        break;
      case 'assault':
        returnVal = {
          type: 'assaults',
          content: formatData(assaultData),
        };
        break;
      case 'childPoisoning':
        returnVal = {
          type: "children's poisoning",
          content: formatData(childPoisoningData),
        };
        break;
      case 'firearmInjury':
        returnVal = {
          type: 'firearm injuries',
          content: formatData(firearmInjuryData),
        };
        break;
      case 'motorVehicle':
        returnVal = {
          type: 'NHTSA non-crash motor vehicle injuries',
          content: formatData(motorVehicleData),
        };
        break;
      case 'selfInflicted':
        returnVal = {
          type: 'self-inflicted injuries',
          content: formatData(selfInflictedData),
        };
        break;
      case 'workRelated':
        returnVal = {
          type: 'NIOSH work injuries',
          content: formatData(workRelatedData),
        };
        break;
      default:
        break;
    }
    return returnVal;
  });

  return new Promise((resolve, reject) => {
    // split off the mainCase so that it may be submitted first, for new cases we will need the caseId from the response
    const [mainCase, ...specialStudies] = submitAll;
    // submit the mainCase
    mainCase.caseData.specialStudies = [...specialStudies];
    onSubmit(mainCase)
      .then(async (res) => {
        // submit the special studies
        // success!
        setButtonType('continue');
        handleAlerts(modalPresets('The case was saved.').success);
      })
      .catch((err) => reject(err));
  }).catch((err) => {
    console.error(err);
  });
};

const handleMainCaseWarnings = (setWarnings, res) => {
  if (res.diagnosisUnacceptableResponseWarnings) {
    if (res.diagnosisUnacceptableResponseWarnings.length === 1) {
      setWarnings((prevState) => ({
        ...prevState,
        [res.diagnosisUnacceptableResponseWarnings[0].field]:
          res.diagnosisUnacceptableResponseWarnings[0].message,
      }));
    }
    if (res.diagnosisUnacceptableResponseWarnings.length === 2) {
      setWarnings((prevState) => ({
        ...prevState,
        [res.diagnosisUnacceptableResponseWarnings[0].field]:
          res.diagnosisUnacceptableResponseWarnings[0].message,
        [res.diagnosisUnacceptableResponseWarnings[1].field]:
          res.diagnosisUnacceptableResponseWarnings[1].message,
      }));
    }
  }
};

const onSubmit = async ({
  accounts,
  caseData,
  caseId,
  caseName,
  handleAlerts,
  key,
  listDiff,
  resetIds,
  setCaseId,
  specialCaseId,
  setSpecialCaseIds,
  setMainCaseWarnings,
  setWordWarnings,
}) => {
  // submit main case without ids - new case record
  if (caseName === 'mainCase' && !caseId) {
    return request({
      accounts,
      body: caseData,
      def: 'saveCase',
      handleAlerts,
      method: 'POST',
    }).then((res) => {
      // NOTE: this does not set the CaseId for special studies that are running in the Promise.all()
      setCaseId(res.id);
      // set the word warnings below the comments fields
      setWordWarnings(res.warnings);
      handleMainCaseWarnings(setMainCaseWarnings, res);
      return res;
    });
  }
  // submit main case with id - update main case record
  if (caseName === 'mainCase' && caseId) {
    const saveCase = request({
      accounts,
      body: caseData,
      caseId,
      def: 'caseData',
      handleAlerts,
      method: 'PUT',
    }).then((res) => {
      // set the word warnings below the comments fields
      setWordWarnings(res.warnings);
      handleMainCaseWarnings(setMainCaseWarnings, res);
      return res;
    });

    return Promise.all([saveCase]).then((res) => res[0]);
  }
};

export { onSave, onSubmit, saveAndValidate };
