import React, { useState, useEffect, useContext, useMemo } from 'react';
import { UserDataContext } from '../../Context';
import { AlertModal, modalPresets } from '../../Components/CustomComponents/Modals';
import { formatMonth } from '../../utils';
import { request } from '../../api';

const Accounting = () => {
  const { userData } = useContext(UserDataContext);

  const [modal, setModal] = useState(modalPresets().default);
  const [monthlyAccounting, setMonthlyAccounting] = useState(null);

  const { hospital } = userData;
  const { name, groupId } = hospital;

  const handleAlerts = (modalPreset) => {
    setModal(modalPreset);
  };

  const args = useMemo(
    () => ({
      handleAlerts,
      hospitalId: groupId,
      method: 'GET',
    }),
    [groupId]
  );

  useEffect(() => {
    let isSubscribed = true;
    const fetchMonthlyAccounting = async () => {
      try {
        if (!monthlyAccounting) {
          const monthlyTotals = await request({
            ...args,
            def: 'accountingMonthlyTotals',
          });
          if (isSubscribed && monthlyTotals) {
            setMonthlyAccounting(monthlyTotals);
          }
          handleAlerts(modalPresets().default);
        }
      } catch (err) {
        console.log('ERROR:', err.message);
      }
    };
    fetchMonthlyAccounting();
    return () => (isSubscribed = false);
  }, [
    args,
    monthlyAccounting,
    setModal,
  ]);


  return (
    <>
      <section className="card">
        <h2>Accounting for {name}</h2>
        <div className="flex flex--space-around accounting-flex-container">
          <div className="flex__child accounting-flex-container__center">
            {/*  ** main left col ** */}
            <table className="table table--compact accounting-select-table">
              <caption className="table__caption">Monthly Totals</caption>
              <thead>
                <tr className="table__head">
                  <th className="table__heading">Month</th>
                  <th className="table__heading">#Cases</th>
                  <th className="table__heading">#ADE</th>
                  <th className="table__heading">#Studies</th>
                </tr>
              </thead>
              <tbody>
                {monthlyAccounting && monthlyAccounting.length > 0 && (
                  <>
                    {monthlyAccounting.map((period) => (
                      <tr key={`ma${period.month}`}
                        className="table__row"
                        tabIndex="0"
                      >
                        <td className="table__cell">
                          {formatMonth(period.month, true)}
                        </td>
                        <td className="table__cell">{period.numberOfCases}</td>
                        <td className="table__cell">{period.numberOfAde}</td>
                        <td className="table__cell">
                          {period.numberOfStudies}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* ** end main two-column flex grid ** */}
      </section>
      <AlertModal modal={modal} closeModal={setModal} />
    </>
  );
};

export default Accounting;
