const fireDepKeyWords = [
  'fd',
  'f.d.',
  'fire dep',
  'firefight',
  'fire fighter',
  'fireman',
  'firemen',
  'fire man',
  'firewom',
  'fire woman',
];

const fireKeyWords = ['smoke', 'flame', 'smolder'];

const NHTSAKeywords = [
  'MVA',
  'MV A',
  'M VA',
  'M.V.A',
  'MVC',
  'CRASH',
  'COLLISION',
  'REAREND',
  'REAR END',
  'T-BONE',
  'TBONE',
  'LOST CONTROL',
  'BROADSIDE',
  'RESTRAIN',
  'DRIVER',
  'PASS',
  'CYCLIST',
  'BIKE',
  'CROSSING STREET',
  'CROSSING THE STREET',
  'PEDEST',
  'TROLLEY',
  'RUN OVER',
  'RAN OVER',
  'CAR ACCIDENT',
  'CAR CRASH',
  'CAR WRECK',
  'VEHICLE ACC',
  'CYCLE',
  'MCA',
  'MCC',
  'SLOW MOVING',
  'GUARDRAIL',
  'GUARD RAIL',
  'HIT TREE',
  'HIT A TREE',
  'STRUCK TREE',
  'STRUCK A TREE',
  'FLIPPED OVER',
  'FLIPPED OFF',
  'ROLLED OFF',
  'ROLLED OVER',
  'ROLLED CAR',
  'ROLLOVER',
  'SIDESWIP',
  'ACCIDENT ',
  'ACCID.',
  'STRUCK BY',
  'HIT BY',
  'ACCIDENT.',
  'ACCIDENT,',
  'REAR-END',
  'T BONE',
  'BROAD SIDE',
  'SIDE SWIP',
  'TABLE',
  'COUNTER',
  'CART',
  'STROLLER',
  'CHAIR',
  'COUCH',
  ' RV ',
  ' RIG ',
  ' RV',
  '18 WHEEL',
  '18-WHEEL',
  '18WHEEL',
  'AMBULANCE',
  'BIG TRUCK',
  'BOX TRUCK',
  'BRADLEY TANK',
  'BUCKET TRUCK',
  'CABULANCE',
  'CAMPER',
  'CAR CARRIER',
  'CEMENT TRUCK',
  'COAL TRUCK',
  'DELIVERY TRUCK',
  'DUMP TRUCK',
  'FIRE ENGINE',
  'FIRE TRUCK',
  'FIRETRUCK',
  'FLAT BED',
  'FLATBED',
  'GARBAGE TRUCK',
  'LARGE TRUCK',
  'MAILTRUCK',
  'MAIL TRUCK',
  'MOBILE HOME',
  'MOTOR HOME',
  'MOTORHOME',
  'OIL TRUCK',
  'POLICE WAGON',
  'POSTAL TRUCK',
  'SALT TRUCK',
  'SEMI',
  'SINGLE UNIT',
  'STRAIGHT TRUCK',
  'TOW TRUCK',
  'TOWTRUCK',
  'TRAILER',
  'TRAILOR',
  'TRAM',
  'TRASH TRUCK',
  'TRASHTRUCK',
  'TROLLY',
  'U HAUL',
  'UHAUL',
  'U-HAUL',
  'UTILITY TRUCK',
  'BUS',
  'SANITATION TRUCK',
];

export { fireDepKeyWords, fireKeyWords, NHTSAKeywords };
