import {
  errorMsg,
  invalidCharErrors,
  setErrorMsg,
  timeErrors,
  listErrors,
  createList,
} from '../../../Validations';
import { dropdownCodes } from '../../../../../dropdownCodes';

const { basicYes, admitted2, admitted4, alcohol1, diag68, injuryEvent4, injuryEventOther } =
  dropdownCodes;

const selfInflictedErrors = (key, preReq, value) => {
  let err;
  switch (key) {
    case 'substanceName1':
    case 'substanceName2':
    case 'substanceName3':
    case 'substanceName4':
      if (preReq && !value) return errorMsg.substanceName;
      err = invalidCharErrors(value);
      if (err) return err;
      break;
    case 'riskOtherSpecify':
      if (((preReq.psychologicalProblems === 'true' || preReq.riskFactorsOther === 'true') || 
           (preReq.psychologicalProblems === true || preReq.riskFactorsOther === true)) && !value)
        return errorMsg.riskOtherSpecify;
      err = invalidCharErrors(value);
      if (err) return err;
      break;
    case 'substanceAmount1':
    case 'substanceAmount2':
    case 'substanceAmount3':
    case 'substanceAmount4':
      if (preReq && !value) return errorMsg.substanceAmount;
      err = invalidCharErrors(value);
      if (err) return err;
      break;
    case 'intentSpecify':
    case 'eventSpecify':
      if ((preReq === injuryEvent4 || preReq === injuryEventOther) && !value)
        return errorMsg.required.basic;
      err = invalidCharErrors(value);
      if (err) return err;
      break;
    case 'bacLevel':
      if (preReq === alcohol1 && !value) return errorMsg.bacLevel;
      err = invalidCharErrors(value);
      if (err) return err;
      break;
    case 'patientAdmitted':
      if ((preReq === admitted2 || preReq === admitted4) && !value)
        return errorMsg.required.basic;
      err = invalidCharErrors(value);
      if (err) return err;
      break;
    default:
      break;
  }
};

const validateSelfInflicted = ({
  formErrors,
  formData,
  mainCaseData,
  setFormErrors,
  options,
}) => {
  const {
    alcoholAbuse,
    alcoholSI,
    anxiety,
    bipolarDisorder,
    depression,
    injuryEvent,
    patientIntent,
    personalityDisorder,
    psychologicalProblems,
    riskFactorsOther,
    selfHarm,
    substanceAbuse,
    substanceName1,
    substanceName2,
    substanceName3,
    substanceName4,
    substanceAmount1,
    substanceAmount2,
    substanceAmount3,
    substanceAmount4,
  } = formData;
  const { diagnosis1, diagnosis2, disposition } = mainCaseData;
  const disposition2 = '2 TREATED & TRANSFERRED';
  const disposition4 = '4 TREATED & ADMITTED FOR HOSPITALIZATION, HOSPITALIZED';
  let err;
  let errArr = [];
  const alcoholDrugsList = createList(
    options.specialStudiesSelfInflictedAlcoholDrugsUsed
  );
  const intentList = createList(options.specialStudiesSelfInflictedIntent);
  const injuryEventList = createList(
    options.specialStudiesSelfInflictedDiagnosis
  );
  const admittedList = createList(
    options.specialStudiesSelfInflictedDisposition
  );
  Object.keys(formErrors).forEach((key) => {
    switch (key) {
      case 'arrivalTimeSI':
        if (formData[key] !== '99:99') {
          err = timeErrors(formData[key]);
          errArr.push(err);
          return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'patientIntent':
      case 'injuryEvent':
      case 'drugs':
      case 'alcoholSI':
        if (!formData[key]) {
          errArr.push('required');
          return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        if ((key === 'alcoholSI' || key === 'drugs') && formData[key]) {
          err = listErrors('alcoholDrugs', alcoholDrugsList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'patientIntent' && formData[key]) {
          err = listErrors('patientIntent', intentList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        if (key === 'injuryEvent' && formData[key]) {
          err = listErrors('injuryEvent', injuryEventList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        break;
      case 'intentSpecify':
        err = selfInflictedErrors(key, patientIntent, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'eventSpecify':
        err = selfInflictedErrors(key, injuryEvent, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'bacLevel':
        err = selfInflictedErrors(key, alcoholSI, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'riskOtherSpecify':
        err = selfInflictedErrors(
          key,
          { riskFactorsOther, psychologicalProblems },
          formData[key]
        );
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'substanceName1':
        if (
          (diagnosis1 === diag68 || diagnosis2 === diag68) && formData.drugs === basicYes && !formData[key]) {
          errArr.push(errorMsg.substanceName1);
          return setErrorMsg(key, errorMsg.substanceName1, setFormErrors);
        }
        if ((diagnosis1 === diag68 || diagnosis2 === diag68) && !formData[key]) {
            errArr.push('required');
            return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
        }
        err = selfInflictedErrors(key, substanceAmount1, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'substanceName2':
        err = selfInflictedErrors(key, substanceAmount2, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'substanceName3':
        err = selfInflictedErrors(key, substanceAmount3, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'substanceName4':
        err = selfInflictedErrors(key, substanceAmount4, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'substanceAmount1':
        err = selfInflictedErrors(key, substanceName1, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'substanceAmount2':
        err = selfInflictedErrors(key, substanceName2, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'substanceAmount3':
        err = selfInflictedErrors(key, substanceName3, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'substanceAmount4':
        err = selfInflictedErrors(key, substanceName4, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      case 'patientAdmitted':
        if (!formData[key]) {
          if (
            mainCaseData.disposition === disposition2 ||
            mainCaseData.disposition === disposition4
          ) {
            errArr.push('required');
            return setErrorMsg(key, errorMsg.required.basic, setFormErrors);
          }
        }
        if (formData[key]) {
          err = listErrors('patientAdmitted', admittedList, formData[key]);
          if (err) return setErrorMsg(key, err, setFormErrors);
        }
        err = selfInflictedErrors(key, disposition, formData[key]);
        errArr.push(err);
        return setErrorMsg(key, err, setFormErrors);
      default:
        return errArr;
    }
  });
  const filtered = [...new Set(errArr)].filter(
    (err) => err !== undefined && err !== false
  );
  return filtered;
};

export default validateSelfInflicted;
