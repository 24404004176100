import React, { useContext, useState, useEffect } from 'react';
import { renderError, renderWarning } from './render';
import { useParams } from 'react-router-dom';
import { UserDataContext } from '../../Context';
import { calculateOOW } from '../../Pages/Dashboard/utils';
import { dropdownCodes } from '../../dropdownCodes';
import { isValidDate, allowedDateKeys } from '../../utils';

const { race3, diag71 } = dropdownCodes;

const TextInputMainCase = function (props) {
  const {
    caseId,
    formData,
    formErrors,
    setFormErrors,
    formWarnings,
    onChange,
    input,
  } = props;

  const { existingCaseId } = useParams();
  const { userData } = useContext(UserDataContext);
  const [tabIndex, setTabIndex] = useState('');
  const [isOow, setIsOowW] = useState(false);
  const [OowChecked, setoowChecked] = useState(false);
  const { hospital, oowValid } = userData;
  const { lastUpdateableDate } = hospital;
  const {
    id,
    indent,
    label,
    maxLength,
    placeholder,
    readOnly,
    size,
    type,
    tooltip,
    tooltipApi,
    tooltipSize,
    tooltipType,
  } = input;

  let formatTreatmentDate; 

  // format treatmentDate to yyyy-mm-dd to compare against lastUpdateableDate.
  if (formData.treatmentDate) {
    formatTreatmentDate = new Date(formData.treatmentDate).toISOString().split('T')[0];
  }

  const handleOnChange = (e) => {
    // trigger callback
    if (onChange) {
      onChange(e, id);
    }
  };

  const handleOnKeyDown = (e) => {
    // handle date inputs
    if (!allowedDateKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleOnBlur = (e) => {
    const value = e.target.value;
    if (value && !isValidDate(value)) {
      setFormErrors((prevState) => ({ ...prevState, [id]: 'invalid date' }));
      return;
    }
  };

  const args = {
    className: `input input--text input--${size} ${
      formErrors[id] ? 'input--error' : ''
    }`,
    id,
    'aria-label': label,
    maxLength,
    onChange: handleOnChange,
    placeholder,
    value: formData[id],
    type,
    ...(id === 'treatmentDate' && {
      max: '9999-12-31',
      autoFocus: true,
    }),
  };

  if (args.type === 'date') {
    //   args.type = 'text'
    //   args.onBlur = handleOnBlur
    //   args.onKeyDown = handleOnKeyDown
    if (formData[id]) {
      args.value = new Date(formData[id]).toISOString().split('T')[0];
    }
  }

  const disableInputs = (inputData) => {
    if (inputData === race3 || inputData === diag71) return false;
    return true;
  };

  const disableInputCheck = () => {
    if (existingCaseId && !oowValid && isOow) {
      return calculateOOW(formData.treatmentDate);
    }
    if (existingCaseId && isOow && (formatTreatmentDate < lastUpdateableDate)) return true;
    switch (id) {
      case 'diagnosisOther1':
        if (formData.diagnosis1) return disableInputs(formData.diagnosis1);
        return true;
      case 'diagnosisOther2':
        if (formData.diagnosis2) return disableInputs(formData.diagnosis2);
        return true;
      case 'raceOther':
        if (formData.race) return disableInputs(formData.race);
        return true;
      case 'age':
        if (formData.dateOfBirth) return true;
        return false;
      default:
        break;
    }
  };

  useEffect(() => {
    if (formData.treatmentDate && existingCaseId && !OowChecked) {
      setIsOowW(calculateOOW(formData.treatmentDate));
      setoowChecked(true);
    }
  }, [existingCaseId, formData.treatmentDate, isOow, OowChecked]);

  return (
    <div className="input-error-wrapper tooltip__wrapper">
      <label className="input-wrapper input-wrapper--new-case">
        <span className={`ut-indent-${indent} label`}>
          {id === 'caseNumber' && caseId && 'Existing '}
          {id === 'caseNumber' && !caseId && 'New '}
          {label}
        </span>
        {readOnly ? (
          <input {...args} readOnly disabled />
        ) : (
          <input tabIndex={tabIndex} {...args} disabled={disableInputCheck()} />
        )}
        {/* if non-trauma show this */}
        {tooltip && hospital && hospital.hospitalType !== 'NEISS-AIP' && (
          <div
            className={`tooltip__content  tooltip__content--${tooltipSize} tooltip__content--${tooltipType}`}
            dangerouslySetInnerHTML={{ __html: tooltip }}
          ></div>
        )}
        {/* if trauma show this */}
        {tooltip && hospital && hospital.hospitalType === 'NEISS-AIP' && (
          <div
            className={`tooltip__content  tooltip__content--${tooltipSize} tooltip__content--${tooltipType}`}
            dangerouslySetInnerHTML={{ __html: tooltipApi }}
          ></div>
        )}
      </label>
      {formErrors[id] && renderError(formErrors, id)}
      {formWarnings && formWarnings[id] && renderWarning(formWarnings, id)}
    </div>
  );
};

export default TextInputMainCase;
