import {
  adverseDrugFormData,
  adverseDrugFormErrors,
  assaultFormData,
  assaultFormErrors,
  childPoisoningFormData,
  childPoisoningFormErrors,
  firearmInjuryFormData,
  firearmInjuryFormErrors,
  firearmInjuryFormWarnings,
  motorVehicleFormData,
  motorVehicleFormErrors,
  mainCaseFormData,
  mainCaseFormErrors,
  mainCaseFormWarnings,
  selfInflictedFormData,
  selfInflictedFormErrors,
  workRelatedFormData,
  workRelatedFormErrors,
} from '../formData';
import {isVictimIdRequired, victimIdCheck} from './victimId';
import { dropdownCodes } from '../../../dropdownCodes';
import { isValidDate, dateFromArgs } from '../../../utils';

const {
  diag41,
  diag42,
  diag52,
  diag65,
  diag67,
  diag68,
  diag69,
  body0,
  body75,
  body85,
} = dropdownCodes;

const bodyPartCheck = (bodyPart, diagnosis, setFormData) => {
  if (diagnosis === diag41 || diagnosis === diag42) {
    setFormData((prevState) => ({
      ...prevState,
      [bodyPart]: body0,
    }));
    return;
  }
  if (diagnosis === diag52) {
    setFormData((prevState) => ({ ...prevState, [bodyPart]: body75 }));
    return;
  }
  if (
    diagnosis === diag65 ||
    diagnosis === diag67 ||
    diagnosis === diag68 ||
    diagnosis === diag69
  ) {
    setFormData((prevState) => ({
      ...prevState,
      [bodyPart]: body85,
    }));
    return;
  }
};

const formatSpecialStudy = ({
  setFormData,
  setFormList,
  setSpecialCaseIds,
  specialStudy,
}) => {
  const {
    setAdverseDrugData,
    setAssaultData,
    setChildPoisoningData,
    setFirearmInjuryData,
    setMotorVehicleData,
    setSelfInflictedData,
    setWorkRelatedData,
  } = setFormData;
  specialStudy.forEach((study) => {
    const { content, type, id } = study;
    switch (type) {
      case 'NIOSH work injuries':
        setWorkRelatedData((prevState) => ({
          ...prevState,
          ...content,
        }));
        setSpecialCaseIds((prevState) => ({
          ...prevState,
          workRelated: id,
        }));
        setFormList((prevState) => [
          ...prevState,
          { label: 'Work Related', id: 'workRelated' },
        ]);
        break;
      case 'assaults':
        setAssaultData((prevState) => ({
          ...prevState,
          ...content,
        }));
        setSpecialCaseIds((prevState) => ({
          ...prevState,
          assault: id,
        }));
        setFormList((prevState) => [
          ...prevState,
          { label: 'Assault', id: 'assault' },
        ]);
        break;
      case 'adverse drug event':
        setAdverseDrugData((prevState) => ({
          ...prevState,
          ...content,
        }));
        setSpecialCaseIds((prevState) => ({
          ...prevState,
          adverseDrug: id,
        }));
        setFormList((prevState) => [
          ...prevState,
          { label: 'Adverse Drug Event', id: 'adverseDrug' },
        ]);
        break;
      case 'self-inflicted injuries':
        setSelfInflictedData((prevState) => ({
          ...prevState,
          ...content,
        }));
        setSpecialCaseIds((prevState) => ({
          ...prevState,
          selfInflicted: id,
        }));
        setFormList((prevState) => [
          ...prevState,
          { label: 'Self Inflicted', id: 'selfInflicted' },
        ]);
        break;
      case 'firearm injuries':
        // normalize dates
        if (content.dateInjured)
          content.dateInjured = dateFromArgs(content.dateInjured);
        setFirearmInjuryData((prevState) => ({
          ...prevState,
          ...content,
        }));
        setSpecialCaseIds((prevState) => ({
          ...prevState,
          firearmInjury: id,
        }));
        setFormList((prevState) => [
          ...prevState,
          { label: 'Firearm Injury', id: 'firearmInjury' },
        ]);
        break;
      case `children's poisoning`:
        setChildPoisoningData((prevState) => ({
          ...prevState,
          ...content,
        }));
        setSpecialCaseIds((prevState) => ({
          ...prevState,
          childPoisoning: id,
        }));
        setFormList((prevState) => [
          ...prevState,
          { label: 'Child Poisoning', id: 'childPoisoning' },
        ]);
        break;
      case 'NHTSA non-crash motor vehicle injuries':
        setMotorVehicleData((prevState) => ({
          ...prevState,
          ...content,
        }));
        setSpecialCaseIds((prevState) => ({
          ...prevState,
          motorVehicle: id,
        }));
        setFormList((prevState) => [
          ...prevState,
          { label: 'NHTSA Motor Vehicle', id: 'motorVehicle' },
        ]);
        break;
      default:
        break;
    }
  });
};

const setCaseStatus = (
  caseData,
  setCaseData,
  errs,
  replyNeeded,
  victimIdCategories
) => {
  let displayVictimIdButton = victimIdCheck(victimIdCategories, caseData);
  let data = {
    ...caseData
  };
  const { mainCase, specialStudy } = errs;

  // if the case has a category that requires a victim id, set the
  // isVictimIdRequired flag on the case accordingly
  data = {
    ...data,
    isVictimIdRequired: isVictimIdRequired(victimIdCategories, caseData),
  };

  if (
    mainCase.length === 0 &&
    specialStudy.length === 0 &&
    !displayVictimIdButton &&
    !replyNeeded
  ) {
    data = {
      ...data,
      status: 'ready to submit',
      needsCaseData: false,
      needsStudyData: false,
      hasProductCode9999Errors: false,
    };
  } else {
    data = {
      ...data,
      status: 'needs attention',
    };
    if (mainCase.length >= 1) {
      data = {
        ...data,
        needsCaseData: true,
      };
    }
    if (specialStudy.length >= 1) {
      data = {
        ...data,
        needsStudyData: true,
      };
    }
  }

  setCaseData(data);
  return data;
};

/* Modify form data to only include codes */
const formatData = (caseData) => {
  const tempData = { ...caseData };
  Object.keys(tempData).map((key) => {
    switch (key) {
      case 'sex':
      case 'diagnosis1':
      case 'diagnosis2':
      case 'bodyPart1':
      case 'bodyPart2':
      case 'disposition':
      case 'product1':
      case 'product2':
      case 'product3':
      case 'alcohol':
      case 'drugsMedication':
      case 'intent':
      case 'location':
      case 'fire':
      case 'workRelated':
      case 'raceHispanic':
      case 'race':
      case 'eventType':
      case 'drug1DeliveryMethod':
      case 'drug2DeliveryMethod':
      case 'drug3DeliveryMethod':
      case 'drug4DeliveryMethod':
      case 'drug1DeliveryMethodSpecify':
      case 'drug2DeliveryMethodSpecify':
      case 'drug3DeliveryMethodSpecify':
      case 'drug4DeliveryMethodSpecify':
      case 'facility':
      case 'patientInitiated':
      case 'patientIntervened':
      case 'patientBystander':
      case 'patientPeer':
      case 'cps':
      case 'policeReport':
      case 'referral':
      case 'sexuallyAssaulted':
      case 'examination':
      case 'prophylactic':
      case 'contraception':
      case 'amountInvolved':
      case 'sexInvolved':
      case 'contactedPriorED':
      case 'contactedDuringED':
      case 'contactedOther':
      case 'anySymptoms':
      case 'nauseaVomiting':
      case 'dizziness':
      case 'drowsiness':
      case 'difficultyBreathing':
      case 'headaches':
      case 'abdominalPain':
      case 'otherSymptoms':
      case 'edTreatments':
      case 'vomitingInduced':
      case 'stomachPump':
      case 'charAdmin':
      case 'artRespiration':
      case 'bloodTransfusion':
      case 'otherTreatment':
      case 'crcPresent':
      case 'drugProvided':
      case 'containerDisposition':
      case 'verbalArgument':
      case 'physicalFight':
      case 'illicitDrugs':
      case 'otherCrime':
      case 'alcoholDrugTests':
      case 'maritalStatus':
      case 'firearmType':
      case 'causedBy':
      case 'transportedToER':
      case 'collision':
      case 'carSeat':
      case 'inMotion':
      case 'vehiclePosition':
      case 'hazardPattern':
      case 'enterExitVehicle':
      case 'positionCode':
      case 'bodyType':
      case 'patientIntent':
      case 'injuryEvent':
      case 'alcoholSI':
      case 'drugs':
      case 'patientAdmitted':
      case 'employedState':
      case 'employmentStatus':
        try {
          tempData[key] = tempData[key].split(' ')[0];
        } catch (error) {
          console.error(key, error);
        }
        break;
      case 'collectionDate':
      case 'treatmentDate':
      case 'dateOfBirth':
      case 'dateInjured':
        if (isValidDate(tempData[key])) {
          const date = dateFromArgs(tempData[key]);
          tempData[key] = `${
            date.getMonth() + 1
          }/${date.getDate()}/${date.getFullYear()}`;
        }
        break;
      default:
        break;
    }
    return tempData;
  });
  return tempData;
};

/* Reset Data and Errors */
const resetData = ({
  mainCaseData,
  setFormData,
  setCaseId,
  setSpecialCaseIds,
}) => {
  const {
    setAdverseDrugData,
    setAssaultData,
    setChildPoisoningData,
    setFirearmInjuryData,
    setMotorVehicleData,
    setMainCaseData,
    setSelfInflictedData,
    setWorkRelatedData,
  } = setFormData;

  setAdverseDrugData(adverseDrugFormData);
  setAssaultData(assaultFormData);
  setChildPoisoningData(childPoisoningFormData);
  setFirearmInjuryData(firearmInjuryFormData);
  setMotorVehicleData(motorVehicleFormData);
  setMainCaseData(
    mainCaseFormData(mainCaseData.treatmentDate, mainCaseData.collectionDate)
  );
  setSelfInflictedData(selfInflictedFormData);
  setWorkRelatedData(workRelatedFormData);
  setCaseId('');
  setSpecialCaseIds(SPECIAL_CASE_IDS);
};

const resetErrors = ({ setFormErrors, setFormWarnings }) => {
  const {
    setAdverseDrugErrors,
    setAssaultErrors,
    setChildPoisoningErrors,
    setFirearmInjuryErrors,
    setMotorVehicleErrors,
    setMainCaseErrors,
    setSelfInflictedErrors,
    setWorkRelatedErrors,
  } = setFormErrors;

  const { setMainCaseWarnings, setFirearmInjuryWarnings } = setFormWarnings;

  setAdverseDrugErrors(adverseDrugFormErrors);
  setAssaultErrors(assaultFormErrors);
  setChildPoisoningErrors(childPoisoningFormErrors);
  setFirearmInjuryErrors(firearmInjuryFormErrors);
  setMotorVehicleErrors(motorVehicleFormErrors);
  setMainCaseErrors(mainCaseFormErrors);
  setSelfInflictedErrors(selfInflictedFormErrors);
  setWorkRelatedErrors(workRelatedFormErrors);

  setMainCaseWarnings(mainCaseFormWarnings);
  setFirearmInjuryWarnings(firearmInjuryFormWarnings);
};

/* Miscellaneous */
const SPECIAL_CASE_IDS = {
  adverseDrug: '',
  assault: '',
  childPoisoning: '',
  firearmInjury: '',
  motorVehicle: '',
  selfInflicted: '',
  workRelated: '',
};

const calcMinDate = (treatmentDate) => {
  const lastYear = new Date().getFullYear() - 1;
  const minDate = new Date(lastYear, '00', '01');
  return treatmentDate <= minDate;
};

const getCurrentDate = () => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${year}-${month}-${day}`;
};

export {
  SPECIAL_CASE_IDS,
  bodyPartCheck,
  calcMinDate,
  formatData,
  formatSpecialStudy,
  getCurrentDate,
  resetData,
  resetErrors,
  setCaseStatus,
};
