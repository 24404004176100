import React from 'react';

const Footer = ({ displayVersionNumber }) => (
  <footer className="footer" id="footer">
    <div>
      U.S. Consumer Product Safety Commission
      <br />
      4330 East West Highway Bethesda, MD 20814
      <br />
      <br />
      CPSC.gov is an official website of the United States government.
    </div>
    <div>
      {displayVersionNumber && <span>1.6.1 ({displayVersionNumber}) </span>}
      <br />
      Contact Us: webNEISShelp@cpsc.gov
    </div>
  </footer>
);

export default Footer;
