const checkActiveDatesHospital = (activeDates, date) => {
  if (Array.isArray(activeDates)) {
    if (!activeDates || !date) return false;
    const treatDate = new Date(date).toISOString();
    const isActive = activeDates.some((activeDate) => {
      const { beginDate, endDate } = activeDate;
      const begin = new Date(beginDate).toISOString();
      const end = new Date(endDate).toISOString();
      if (treatDate >= begin && treatDate <= end) return true;
      return false;
    });
    return isActive;
  } else {
    const { beginDate, endDate } = activeDates;
    if (!beginDate || !endDate || !date) return false;
    const treatDate = new Date(date).toISOString().split('T')[0];
    const begin = new Date(beginDate).toISOString().split('T')[0];
    const end = new Date(endDate).toISOString().split('T')[0];
    if (treatDate >= begin && treatDate <= end) return true;
    return false;
  }
};

const isQuarterStart = (dateIn) => {
  const quarterStartMonths = ['01', '04', '07', '10'];
  const month = dateIn.substring(0, 2);
  if (quarterStartMonths.includes(month)) {
    return true;
  } else {
    return false;
  }
};

const filterActiveProducts = (products, treatmentDate, hospitalType) => {
  const prod1935 = '1935';
  const prod1936 = '1936';
  const prod3224 = '3224';
  const prod3253 = '3253';
  const treatDate = new Date(treatmentDate).toISOString().split('T')[0];
  const prodList = products.filter((product) => {
    let code = product.code;
    return product.activeDates.some(({ neissAIPOnly, beginDate, endDate }) => {
      const begin = new Date(beginDate).toISOString().split('T')[0];
      const end = new Date(endDate).toISOString().split('T')[0];
      if (
        code === prod1935 ||
        code === prod1936 ||
        code === prod3224 ||
        code === prod3253
      ) {
        if (treatDate >= begin && treatDate <= end) return true;
        return false;
      }
      if (neissAIPOnly && hospitalType !== 'NEISS-AIP') return false;
      if (neissAIPOnly && hospitalType === 'NEISS-AIP') {
        if (treatDate >= begin && treatDate <= end) {
          product.inScope = false;
          return true;
        }
        return false;
      }
      if (treatDate >= begin && treatDate <= end) {
        product.inScope = true;
        return true;
      }
      return false;
    });
  });
  return prodList;
};

export const dateFormat_MDY =
  /^(0?[1-9]|1[0-2])[/](0?[1-9]|[1-2][0-9]|3[01])[/]\d{4}$/; // expecting mm/dd/yyyy
export const dateFormat_YMD =
  /^(\d{4})[/-]{1}(0?[1-9]|1[0-2])[/-]{1}(0?[1-9]|[1-2][0-9]|3[01])$/; // expecting yyyy-mm-dd

const allowedDateKeys = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  '/',
  '-',
  'Backspace',
  'Delete',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
];

const isValidDate = (date) => {
  if (typeof date?.getMonth === 'function') {
    return true;
  }
  const listOfDays = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  let datePart, month, day, year;

  // Extract the string into month, date and year
  if (dateFormat_YMD.test(date)) {
    datePart = date.split(/[\/-]/);
    month = parseInt(datePart[1]);
    day = parseInt(datePart[2]);
    year = parseInt(datePart[0]);
  } else if (dateFormat_MDY.test(date)) {
    datePart = date.split(/[\/-]/);
    month = parseInt(datePart[0]);
    day = parseInt(datePart[1]);
    year = parseInt(datePart[2]);
  }
  if (!datePart) {
    return false;
  }
  if (month === 1 || month > 2) {
    if (day > listOfDays[month - 1]) {
      //to check if the date is out of range
      return false;
    }
  } else if (month === 2) {
    let leapYear = false;
    if ((!(year % 4) && year % 100) || !(year % 400)) {
      leapYear = true;
    }
    if (leapYear === false && day >= 29) {
      // Invalid date format
      return false;
    } else {
      if (leapYear === true && day > 29) {
        // Invalid date format
        return false;
      }
    }
  }
  return true;
};

// dateString can be formatted as 'mm/dd/yyyy', 'mm-dd-yyyy', 'yyyy/mm/dd' or 'yyyy-mm-dd'
const dateFromArgs = (dateString) => {
  // console.log('dateString', dateString)

  // catch no dateString
  if (!dateString) return '';

  // dateString is already a date object
  if (typeof dateString.getMonth === 'function') {
    return dateString;
  }

  /*
  Note: Dates formatted as yyyy-mm-dd generate an off by one day problem
  Dates formatted as yyyy/mm/dd do not create this issue
  Therefore, we only need to ensure that string dates are formatted using `/` seperators

  Example:
  new Date('2023-10-19')
  Wed Oct 18 2023 20:00:00 GMT-0400 (Eastern Daylight Time)
  new Date('2023/10/19')
  Thu Oct 19 2023 00:00:00 GMT-0400 (Eastern Daylight Time)
  new Date('10-19-2023')
  Thu Oct 19 2023 00:00:00 GMT-0400 (Eastern Daylight Time)
  new Date('10/19/2023')
  Thu Oct 19 2023 00:00:00 GMT-0400 (Eastern Daylight Time)
  */
  const newDate = dateString.replace('-', '/');

  const date = new Date(newDate);
  return date;
};

const formatActiveDates = (specialStudiesActiveDates) => {
  const activeDates = {
    adverseDrug: false,
    assault: false,
    childPoisoning: false,
    firearm: false,
    motorVehicle: false,
    selfInflicted: false,
    workRelated: false,
  };
  const activeDatesFormatted = [];
  specialStudiesActiveDates.forEach(
    ({ specialStudyType, beginDate, endDate }, index) => {
      switch (specialStudyType) {
        case 'Adverse Drug Events':
          if (activeDates.adverseDrug) {
            activeDatesFormatted[activeDates.adverseDrug].activeDates.push({
              beginDate,
              endDate,
            });
          } else {
            activeDates.adverseDrug = index;
            activeDatesFormatted.push({
              specialStudyType,
              activeDates: [{ beginDate, endDate }],
            });
          }
          break;
        case 'Assault Injury':
          if (activeDates.assault) {
            activeDatesFormatted[activeDates.assault].activeDates.push({
              beginDate,
              endDate,
            });
          } else {
            activeDates.assault = index;
            activeDatesFormatted.push({
              specialStudyType,
              activeDates: [{ beginDate, endDate }],
            });
          }
          break;
        case "Children's Poisoning":
          if (activeDates.childPoisoning) {
            activeDatesFormatted[activeDates.childPoisoning].activeDates.push({
              beginDate,
              endDate,
            });
          } else {
            activeDates.childPoisoning = index;
            activeDatesFormatted.push({
              specialStudyType,
              activeDates: [{ beginDate, endDate }],
            });
          }
          break;
        case 'Firearms Injury':
          if (activeDates.firearm) {
            activeDatesFormatted[activeDates.firearm].activeDates.push({
              beginDate,
              endDate,
            });
          } else {
            activeDates.firearm = index;
            activeDatesFormatted.push({
              specialStudyType,
              activeDates: [{ beginDate, endDate }],
            });
          }
          break;
        case 'Self-Inflicted Injury':
          if (activeDates.selfInflicted) {
            activeDatesFormatted[activeDates.selfInflicted].activeDates.push({
              beginDate,
              endDate,
            });
          } else {
            activeDates.selfInflicted = index;
            activeDatesFormatted.push({
              specialStudyType,
              activeDates: [{ beginDate, endDate }],
            });
          }
          break;
        case 'NHTSA MV Non Crash Injury':
          if (activeDates.motorVehicle) {
            activeDatesFormatted[activeDates.motorVehicle].activeDates.push({
              beginDate,
              endDate,
            });
          } else {
            activeDates.motorVehicle = index;
            activeDatesFormatted.push({
              specialStudyType,
              activeDates: [{ beginDate, endDate }],
            });
          }
          break;
        case 'NIOSH Work-Related Injury':
          if (activeDates.workRelated) {
            activeDatesFormatted[activeDates.workRelated].activeDates.push({
              beginDate,
              endDate,
            });
          } else {
            activeDates.workRelated = index;
            activeDatesFormatted.push({
              specialStudyType,
              activeDates: [{ beginDate, endDate }],
            });
          }
          break;
        default:
          break;
      }
      return activeDatesFormatted;
    }
  );
  return activeDatesFormatted;
};

const formatLookups = async (lookupData) => {
  const mainCaseOptions = {
    caseBodyPart: [],
    caseDiagnosis: [],
    caseDisposition: [],
    caseFire: [],
    caseGender: [],
    caseAlcoholDrugsMedicationYesNoNotRecorded: [],
    caseIntent: [],
    caseLocale: [],
    caseRace: [],
    caseWorkRelated: [],
    caseProduct: [],
  };
  const adverseDrugOptions = {
    specialStudiesAdverseDrugEventsDeliveryRoute: [],
    specialStudiesAdverseDrugEventsDeliveryRouteSpecification: [],
    specialStudiesAdverseDrugEventsDeliveryMethod: [],
    specialStudiesAdverseType: [],
    specialStudiesAdverseDrugEventsPatientFromFacilityGaveMedicine: [],
  };
  const assaultOptions = {
    specialStudiesAssaultNumberOfPerpetrators: [],
    specialStudiesAssaultSex: [],
    specialStudiesAssaultYesNoUnknown: [],
  };
  const childrensPoisoningOptions = {
    specialStudiesChildrensPoisoningContainer: [],
    specialStudiesChildrensPoisoningYesNoNotStated: [],
  };
  const firearmInjuryOptions = {
    specialStudiesFirearmInjuryMaritalStatus: [],
    specialStudiesFirearmInjuryTransportedToER: [],
    specialStudiesFirearmInjuryTypeOfFirearm: [],
    specialStudiesFirearmInjuryWhoCausedInjury: [],
    specialStudiesFirearmInjuryYesNoNotStated: [],
  };
  const motorVehicleOptions = {
    specialStudiesNhtsaHazardPattern: [],
    specialStudiesNhtsaVehicleBodyType: [],
    specialStudiesNhtsaVictimMotion: [],
    specialStudiesNhtsaVictimPosition: [],
    specialStudiesNhtsaYesNo: [],
    specialStudiesNhtsaYesNoUnknown: [],
  };
  const workRelatedOptions = {
    specialStudiesNioshWorkerEmploymentState: [],
    specialStudiesNioshWorkerEmploymentStatus: [],
  };
  const selfInflictedOptions = {
    specialStudiesSelfInflictedAlcoholDrugsUsed: [],
    specialStudiesSelfInflictedDiagnosis: [],
    specialStudiesSelfInflictedDisposition: [],
    specialStudiesSelfInflictedIntent: [],
  };

  lookupData.forEach((option) => {
    const { lookupType } = option;
    switch (lookupType) {
      case 'caseBodyPart':
      case 'caseDiagnosis':
      case 'caseDisposition':
      case 'caseFire':
      case 'caseGender':
      case 'caseAlcoholDrugsMedicationYesNoNotRecorded':
      case 'caseIntent':
      case 'caseLocale':
      case 'caseProduct':
      case 'caseRace':
      case 'caseWorkRelated':
        mainCaseOptions[lookupType] = [...mainCaseOptions[lookupType], option];
        return;
      case 'specialStudiesAdverseDrugEventsDeliveryRoute':
      case 'specialStudiesAdverseDrugEventsDeliveryRouteSpecification':
      case 'specialStudiesAdverseDrugEventsDeliveryMethod':
      case 'specialStudiesAdverseType':
      case 'specialStudiesAdverseDrugEventsPatientFromFacilityGaveMedicine':
        adverseDrugOptions[lookupType] = [
          ...adverseDrugOptions[lookupType],
          option,
        ];
        return;
      case 'specialStudiesAssaultNumberOfPerpetrators':
      case 'specialStudiesAssaultSex':
      case 'specialStudiesAssaultYesNoUnknown':
        assaultOptions[lookupType] = [...assaultOptions[lookupType], option];
        return;
      case 'specialStudiesChildrensPoisoningContainer':
      case 'specialStudiesChildrensPoisoningYesNoNotStated':
        childrensPoisoningOptions[lookupType] = [
          ...childrensPoisoningOptions[lookupType],
          option,
        ];
        return;
      case 'specialStudiesFirearmInjuryMaritalStatus':
      case 'specialStudiesFirearmInjuryTransportedToER':
      case 'specialStudiesFirearmInjuryTypeOfFirearm':
      case 'specialStudiesFirearmInjuryYesNoNotStated':
      case 'specialStudiesFirearmInjuryWhoCausedInjury':
        firearmInjuryOptions[lookupType] = [
          ...firearmInjuryOptions[lookupType],
          option,
        ];
        return;
      case 'specialStudiesNhtsaHazardPattern':
      case 'specialStudiesNhtsaVehicleBodyType':
      case 'specialStudiesNhtsaVictimMotion':
      case 'specialStudiesNhtsaVictimPosition':
      case 'specialStudiesNhtsaYesNo':
      case 'specialStudiesNhtsaYesNoUnknown':
        motorVehicleOptions[lookupType] = [
          ...motorVehicleOptions[lookupType],
          option,
        ];
        return;
      case 'specialStudiesNioshWorkerEmploymentState':
      case 'specialStudiesNioshWorkerEmploymentStatus':
        workRelatedOptions[lookupType] = [
          ...workRelatedOptions[lookupType],
          option,
        ];
        return;
      case 'specialStudiesSelfInflictedAlcoholDrugsUsed':
      case 'specialStudiesSelfInflictedDiagnosis':
      case 'specialStudiesSelfInflictedDisposition':
      case 'specialStudiesSelfInflictedIntent':
        selfInflictedOptions[lookupType] = [
          ...selfInflictedOptions[lookupType],
          option,
        ];
        return;
      default:
        break;
    }
  });
  const options = {
    mainCaseOptions,
    adverseDrugOptions,
    assaultOptions,
    childrensPoisoningOptions,
    firearmInjuryOptions,
    motorVehicleOptions,
    workRelatedOptions,
    selfInflictedOptions,
  };
  return options;
};

const formatDailyErvs = (dailyErvs) => {
  const formattedErvsArray = [];
  dailyErvs.forEach((erv) => {
    const { treatmentDate, numberOfCases, numberOfErvs, zeroCasesConfirmed } =
      erv;
    let formattedErv = {
      errorMessage: null,
      cases: numberOfCases,
      ervs: numberOfErvs,
      treatmentDate,
      zeroCasesConfirmed,
    };
    if (numberOfCases === 0) {
      formattedErv.showZeroCases = true;
      formattedErv.isValid = false;
    } else {
      formattedErv.showZeroCases = false;
      formattedErv.isValid = true;
    }
    formattedErvsArray.push(formattedErv);
  });
  return formattedErvsArray;
};

const formatQuarterlyErvs = (quarterlyErvs) => {
  let quarterlyTotal = 0;
  const formattedQuarterlyArray = [];
  const formattedMonthlyArray = [];
  quarterlyErvs.forEach((erv) => {
    const { ervOthers, ervSum, ervUserTotal, months, quarterEnd } = erv;
    let formattedQuarter = { ervOthers, ervSum, ervUserTotal, quarterEnd };
    formattedQuarterlyArray.push(formattedQuarter);

    months.forEach((monthlyErvs) => {
      const { month, numberOfErvs } = monthlyErvs;
      let formattedMonth = {
        month,
        numberOfErvs,
        isQuarterStart: isQuarterStart(month),
        qtotal: (quarterlyTotal += parseInt(numberOfErvs)),
      };
      formattedMonthlyArray.push(formattedMonth);
      if (isQuarterStart) quarterlyTotal = 0;
    });
  });
  return { formattedMonthlyArray, formattedQuarterlyArray };
};

const formatMonth = (dateIn, isShort) => {
  const monthAbbrev = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];
  const monthIndex = parseInt(dateIn.substring(0, 2)) - 1;
  const year = dateIn.substring(6, 10);
  let monthFormatted = monthAbbrev[monthIndex];
  if (isShort) {
    monthFormatted = monthFormatted.substring(0, 3);
  }
  return monthFormatted + ' ' + year;
};

export {
  checkActiveDatesHospital,
  filterActiveProducts,
  allowedDateKeys,
  isValidDate,
  dateFromArgs,
  formatActiveDates,
  formatLookups,
  formatDailyErvs,
  formatMonth,
  formatQuarterlyErvs,
  isQuarterStart,
};
