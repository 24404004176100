import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { calculateOOW } from './utils';
import { UserDataContext, OfflineModeContext } from '../../Context';

const sexAbbreviation = (sex) => {
  switch (sex) {
    case '0':
      return 'U';
    case '1':
      return 'M';
    case '2':
      return 'F';
    case '3':
      return 'O';
    default:
      return '';
  }
};

const TableRow = ({ caseInfo, comments, index, onChange }) => {
  const { userData } = useContext(UserDataContext);
  const { offlineMode } = useContext(OfflineModeContext);
  const { oowValid } = userData;
  const {
    age,
    caseNumber,
    checked,
    collectionDate,
    comment,
    id,
    product1,
    product2,
    product3,
    treatmentDate,
    sex,
    status,
    hasErrors,
    isVictimIdRequired,
    hasVictimIdBeenEntered,
    needsStudyData,
    hasProductCode9999Errors,
    needsCaseData,
    submittedDate,
  } = caseInfo;

  return (
    <>
      <tr
        className={`table__row ${index % 2 === 1 ? 'table__row--zebra' : ''}`}
      >
        <td>
          {calculateOOW(treatmentDate) === true &&
          oowValid === false ? (
            <>
              <span className="ut-only-sr">Enter an OOW Password</span>
              <span className="fa-icon">&#xf023;</span>
            </>
          ) : (
            <>
              <label htmlFor={id} className="ut-only-sr">
                Select this row
              </label>
              <input
                className="input"
                type="checkbox"
                id={id}
                checked={checked ? checked : false}
                onChange={() => onChange(index)}
              />
            </>
          )}
        </td>
        <td className="table__cell">{treatmentDate}</td>
        <td className="table__cell">{collectionDate}</td>
        <td className="table__cell">
          {offlineMode ? (
            <Link to={`/newCase/${caseNumber}`}>{caseNumber}</Link>
          ) : (
            <Link to={`/newCase/${id}`}>{caseNumber}</Link>
          )}
        </td>
        <td className="table__cell">{age}</td>
        <td className="table__cell">{sexAbbreviation(sex)}</td>
        <td className="table__cell dashboard-table__product-cell">
          <span className="ut-ellipsis-overflow">{product1}</span>
        </td>
        <td className="table__cell dashboard-table__product-cell">
          <span className="ut-ellipsis-overflow">{product2}</span>
        </td>
        <td className="table__cell dashboard-table__product-cell">
          <span className="ut-ellipsis-overflow">{product3}</span>
        </td>
        <td className="table__cell">
          {status === 'needs attention' && (
            <b className="ut-color-red">Needs Attention</b>
          )}
          {status === 'submitted' && <b className="ut-color-gray">Submitted</b>}
          {status === 'ready to submit' && (
            <b className="ut-color-green">Ready to Submit</b>
          )}
        </td>
      </tr>
      {comments && (
        <tr
          className={`table__row table__row--expandable ${
            index % 2 === 1 ? 'table__row--zebra' : ''
          }`}
        >
          <td className="table__cell">&nbsp;</td>
          <td colSpan="9" className="table__cell">
            {status === 'needs attention' && (
              <div className="ut-display-inline">
                <b className="ut-color-red">Needs Attention:</b>
                <ul className="dashboard-table__attention-list">
                  {needsCaseData && <li>Needs Case Data</li>}
                  {hasErrors && <li>Errors</li>}
                  {needsStudyData && <li>Needs Study Data</li>}
                  {hasProductCode9999Errors && (
                    <li>Product Code 9999 Errors</li>
                  )}
                  {(isVictimIdRequired && !hasVictimIdBeenEntered) && <li>Missing Victim Id</li>}
                </ul>
              </div>
            )}
            {status === 'submitted' && submittedDate && (
              <span>
                <b className="ut-color-gray">Submitted: </b>
                {submittedDate}
              </span>
            )}
            {status === 'ready to submit' && (
              <b className="ut-color-green">Ready to Submit</b>
            )}
            <br />
            {comment}
          </td>
        </tr>
      )}
    </>
  );
};
export default TableRow;
