import React from 'react';

const DetailTableRow = ({
  handleErvChange,
  period,
}) => {
  return (
    <>
      <tr className="table__row">
        <td className="table__cell">{period.treatmentDate}</td>
        <td className="table__cell">
          <span
            className={
              !period.isValid && !period.zeroCasesConfirmed
                ? 'ut-color-red'
                : ''
            }
          >
            {period.numberOfCases}
          </span>
        </td>
        <td className="table__cell">
          <label>
            <span className="ut-only-sr">ERVs</span>
            <input
              type="number"
              name={`${period.treatmentDate}ervs`}
              className={
                'input  input--small ' +
                (!period.isValid && !period.showZeroCases ? 'input--error' : '')
              }
              max="9999"
              min="0"
              value={period.numberOfErvs}
              onChange={handleErvChange}
            />
          </label>
        </td>
      </tr>
      {/* error row if xxx */}
      {period.errorMessage && (
        <tr className="table__row table__row--error table__row--error-visible">
          <td className="table__cell table__cell--error" colSpan="3">
            {period.errorMessage}
          </td>
        </tr>
      )}
    </>
  );
};
export default DetailTableRow;
